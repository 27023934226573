<template>
  <ion-card class="gift-item ion-no-margin"
            @click="() => { clickItem(id); ecommerceSelectPromotion(id, giftName, brandName, price); }">
    <div class="gift-item__image">
      <img :src="imgUrl" class="img"/>
    </div>
    <div class="gift-item__info">
      <ion-label class="brand-name">{{ brandName }}</ion-label>
      <ion-label class="gift-name" color="white">{{ giftName }}</ion-label>
      <ion-label class="price-area">
        <ion-note class="price" color="white">
          ¥{{ priceDisplay(price) }}<span v-if="hasVariation">〜</span>
        </ion-note>
        <ion-note v-if="isTaxDisplay" class="tax-text" color="white">{{ taxText }}</ion-note>
      </ion-label>
    </div>
  </ion-card>
</template>

<script>
import {getGiftLoadingImage} from "@/plugins/GiftHelper";
import {IonCard, IonLabel, IonNote} from "@ionic/vue";

export default {
  name: "GiftItem",
  components: {IonCard, IonLabel, IonNote},
  props: {
    id: {
      type: Number,
      default: 0,
    },
    imgUrl: {
      type: String,
      default: "",
    },
    brandName: {
      type: String,
      default: "",
    },
    giftName: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    taxText: {
      type: String,
      default: "税込",
    },
    isTaxDisplay: {
      type: Boolean,
      default: true,
    },
    hasVariation: {
      type: Boolean,
      default: true,
    },

  },
  data: () => ({
    imgSrc: getGiftLoadingImage(),
  }),
  computed: {},
  emits: ['seasonalOpenDetail', 'ecommerceSelectPromotion'],
  mounted() {
  },
  methods: {
    clickItem(id) {
      this.$emit('seasonalOpenDetail', id);
    },
    ecommerceSelectPromotion(giftId, giftName, brandName, price) {
      this.$emit('ecommerceSelectPromotion', giftId, giftName, brandName, price);
    },
    priceDisplay(price) {
      return Number(price).toLocaleString();
    },
  },
};
</script>

<style scoped lang="scss">
.gift-item {
  cursor: pointer;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

  &__image {
    height: 164px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 4px;

    .brand-name {
      overflow: hidden;
      color: $gray13;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font: $font-noto-sans-jp-400;

      @include font-size(10, 16);
    }

    .gift-name {
      overflow: hidden;
      display: -webkit-box;
      color: $black5;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: left;
      height: calc(22.4px * 2);
      font: $font-noto-sans-jp-400;

      @include font-size(14, 22.4);
    }

    .price-area {
      .price {
        margin-right: 4px;
        font: $font-roboto-700;
        color: $black5;
        font-weight: bold;
        @include font-size(16, 22.4);
      }

      .tax-text {
        font: $font-noto-sans-jp-400;
        color: $gray13;

        @include font-size(12, 19.2);
      }
    }

    .expiry-date {
      color: $gray6;
      font: $font-roboto-400;

      @include font-size(12, 16);
    }
  }
}
</style>
