<template>
  <Layout
    ref="layout"
    title="贈り主の名前を入力"
    active="gift"
    :show-back="false"
    :show-close="true"
    :show-menu="false"
    @click:close="clickBack"
  >
    <ion-content class="ion-padding">
      <div class="header">
        <span class="header__note">受け取った方が見る通常カードに表示するお名前を入力してください。</span>
      </div>
      <div class="input">
        <ion-item lines="none" class="input__item">
          <ion-input
            label="あなたのお名前・ニックネーム（最大8文字）"
            label-placement="stacked"
            :clear-input="true"
            maxlength="8"
            v-model="youName"
            class="custom"
          ></ion-input>
        </ion-item>
      </div>
      <NormalGift
        :sender-name="youName"
        :gift-url="null"
        :gift-name="giftName"
        :brand-name="brandName"
        :expiry-date="expiryDate"
        :price="price"
        :gift-image="giftImage"
        :is-tax-display="isTaxDisplay"
        :is-show-price="false"
      >
      </NormalGift>
      <div class="note" v-show="!isAuIdAuthorization">
        ギフトとカードを贈るには、au
        IDでのログインが必要です。（IDをお持ちでない場合は、次画面の「auかんたん決済」で新規登録してください）
      </div>
      <div class="footer">
        <Button
          label="作成完了して買い物カゴへ"
          type="fill"
          :disabled="!isInput"
          ref="createCard"
          @click="createCard()"
        />
        <Button
          label="カードタイプ選択へ戻る"
          type="primary"
          ref="back"
          @click="clickBack()"
        />
      </div>
    </ion-content>
    <Loading :is-show="isLoading"/>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import {
  IonContent,
  IonInput,
  IonItem,
} from "@ionic/vue";
import Button from "@/components/button/Button";
import NormalGift from "@/components/gift/NormalGift";
import {mapGetters} from "vuex";
import {postCreateNomalCard, updateCart} from "@/plugins/ApiHelper";
import _ from "lodash";
import Loading from "@/components/loading/Loading";
import store from "@/store";
import {gtmSendClick} from "@/plugins/GtmHelper";

export default {
  name: "PMG330",
  components: {
    Layout,
    IonContent,
    IonInput,
    IonItem,
    Button,
    Loading,
    NormalGift,
  },
  props: {},
  data: () => ({
    giftData: null,
    giftName: null,
    brandName: null,
    expiryDate: null,
    price: null,
    giftImage: null,
    isTaxDisplay: true,
    youName: "",
  }),
  computed: {
    ...mapGetters(["getCartItems", "isLoading", "isAuIdAuthorization", "lid", "getCardOriginId"]),
    isInput() {
      if (_.isEmpty(this.youName)) {
        return false;
      }
      return true;
    },
  },
  watch: {
    giftData(newVal) {
      if (!newVal || !Array.isArray(newVal) || newVal.length <= 0) {
        return;
      }
      const data = newVal[0];
      this.giftName = data.giftName;
      this.brandName = data.brandName;
      this.expiryDate = data.expiryDate;
      this.price = Number(data.price);
      this.giftImage = data.imgUrl;
      this.isTaxDisplay = data.isTaxDisplay;
    },
    getCartItems(newVal) {
      this.giftData = newVal;
    }
  },
  mounted() {
    this.giftData = this.getCartItems;
  },
  async updated() {
    this.giftData = this.getCartItems;
  },
  methods: {
    clickBack() {
      gtmSendClick(this.$refs['back'].$el, 'カード宛名入力（通常カード）', 'カードタイプ選択へ戻る', '');
      this.youName = null;
      this.$router.go(-1);
    },
    goPlame() {
      window.open("https://media2.kddi.com/msg/bot/audigitalgift/card/");
    },
    async createCard() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
      }

      gtmSendClick(this.$refs['createCard'].$el, 'カード宛名入力（通常カード）', '作成完了して買い物カゴへ', '');
      const data = {
        name: this.youName,
        giftId: this.getCartItems[0].giftId,
      };
      await postCreateNomalCard(data)
        .then((res) => {
          this.youName = null;
          store.commit("setCardOriginId", res.cardOriginId);
          return this.updateCartData();
        })
        .then(() => {
          this.$refs.layout.gtmPush("お買い物カゴ", "/cart");
          this.$router.push({name: "PMG700"});
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "PMG510" });
        });
    },
    async updateCartData() {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }
      const data = {
        lid: this.lid,
        cardOriginId: this.getCardOriginId,
        updateMode: 2,
      };
      await updateCart(data).catch((err) => {
        console.error(err);
        if (err.response.data && err.response?.data?.card_origin_id_already_used) {
          //カードが既に決済済みの決済情報と紐づいた場合
          this.$router.push({name: "PMG512"});
        }
        else{
          this.$router.push({name: "PMG510"});
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
ion-content {
  --background: #{$modelBackground};
}

ion-input.custom {
  border-bottom: solid 2px #0000008A;

  :deep(.input-wrapper) {
    margin: 4px 16px 4px;
  }
}

.header {
  padding-bottom: 16px;

  &__note {
    font: $font-noto-sans-jp-400;
    font-size: 14px;
    line-height: 22.4px;
  }
}

.input {
  --ion-background-color: #{$modelBackground};
  padding: 8px 0 8px;

  &__item {
    --padding-start: 0px;
    --inner-padding-end: 0;
  }

}

.note {
  display: flex;
  align-items: center;
  justify-content: center;
  font: $font-noto-sans-jp-400;
  font-size: 14px;
  line-height: 22.4px;
  color: $red2;
  padding-top: 8px;
  padding-bottom: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  gap: 12px;

  :deep(.button) {
    position: inherit;
    min-width: 311px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>
