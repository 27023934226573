<template>
  <div class="pmg-Page end-rules">
    <section class="pmg-End">
      <p class="pmg-End__text">ギフトの販売は2024年7月29日をもって終了しました。</p>
    </section>
    <h1 class="pmg-Page__title pmg-Page__title-padding">プラメギフトサービス利用規約</h1>

    <div class="pmg-Page__section">
      <p class="pmg-Page__paragraph">
        KDDI株式会社および沖縄セルラー電話株式会社（以下、併せて「当社」といいます）が提供するプラメギフトサービス（以下「本サービス」といいます）は、以下のプラメギフトサービス利用規約（以下「本利用規約」といいます）に従って提供します。お客様が本利用規約に同意されない場合、本サービスをご利用いただくことはできません。ご利用の前に、必ず本利用規約をご確認ください。
      </p>
    </div>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第1条（定義）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          「お客様」とは、本サービスを利用する全ての利用者をいいます。
        </li>
        <li class="pmg-Page__olItem">
          「購入者」とは、お客様のうち、第3条第1項に定める商品購入サービスを利用し、商品を購入した利用者をいいます。
        </li>
        <li class="pmg-Page__olItem">
          「受取人」とは、お客様のうち、購入者から第3条第1項第1号に定める本チケットを贈呈された利用者をいいます。
        </li>
        <li class="pmg-Page__olItem">
          「au ID」とは、当社が別途定める「ID利用規約」（以下「ID利用規約」といいます）に定める「au
          ID」をいいます。
        </li>
        <li class="pmg-Page__olItem">
          「auかんたん決済」とは、当社が別途定める「auかんたん決済会員規約」に基づき提供する決済サービスをいいます。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第2条（適用）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          本利用規約は、お客様による本サービスの利用の全てに適用されます。お客様が本サービスを利用するためには、本利用規約を遵守していただくものとします。
        </li>
        <li class="pmg-Page__olItem">
          当社は、本利用規約のほか、本サービスの利用に係る注意事項その他ルール（以下「個別規約」といいます）を別途定めることがあります。この場合、個別規約は本利用規約の一部を構成するものとし、本利用規約の規定と個別規約の規定とが異なる場合は、個別規定の規定が優先して適用されるものとします。
        </li>
        <li class="pmg-Page__olItem">
          お客様は、本利用規約に同意の上で本サービスを利用するものとし、お客様の本利用規約への同意により、お客様と当社との間で本サービスのご利用に係る契約（以下「本サービス利用契約」といいます）が成立するものとします。
        </li>
        <li class="pmg-Page__olItem">
          当社は、民法の定めに従い、本利用規約を変更することができます。この場合、本サービスの提供条件は、変更後の本利用規約によります。なお、当社は、変更後の本利用規約およびその効力発生時期を、「＋メッセージ」アプリ(以下「本アプリ」といいます)上の本サービスに係る公式アカウント「プラメギフト」からの配信、または当社所定のWEBサイトにおいて周知するものとし、変更後の本利用規約は、当該効力発生時期が到来した時点で効力を生じるものとします。
        </li>
        <li class="pmg-Page__olItem">
          当社は、本サービスの運営業務の一部を業務委託先に委託することができます。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第3条（本サービスの内容）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          商品購入サービス
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">
              商品購入サービスは、当社所定の方法で、お客様が本サービスWEBサイト上に掲載された電子チケット等（以下「本チケット」といいます）を当社から購入し、購入者自身で利用、または購入者から受取人に対する通知により贈呈することができるサービスです。本チケットは、本チケットごとに指定する特定の店舗（以下「対象店舗」といいます）において本チケットごとに指定する特定の商品または役務（以下「対象商品等」といいます）の提供を受けることができる権利であり、本チケットに付された条件に従い対象商品等の提供を受けることにのみ使用できます。
            </li>
            <li class="pmg-Page__olItem">
              本チケットは、対象商品等とのみ交換することができ、現金との交換および対象商品等以外の商品または役務との交換はできません。
            </li>
            <li class="pmg-Page__olItem">
              対象商品等は、当社ではなく対象店舗が提供します。対象商品等のご利用にあたっては、対象店舗が別途定める規約等に従うものとします。当社は、当社の責に帰すべき事由がある場合を除き、対象商品等に関し何ら保証または責任を負うものではなく、対象商品等の完全性、正確性、安全性、目的適合性または有用性等その他一切を保証しません。また、購入者、または受取人は各自の責任において対象商品等を利用するものとし、対象商品等に関する問い合わせは、対象店舗に直接行うものとします。
            </li>
            <li class="pmg-Page__olItem">
              購入者が本チケットに関する売買契約成立後は、以下の場合を除き、本チケットの購入のキャンセル・返品・交換はできません。
              <ol class="pmg-Page__ol -roman">
                <li class="pmg-Page__olItem">
                  一定期間の本サービスの停止により、本チケットと対象商品等との交換が不能となった場合
                </li>
                <li class="pmg-Page__olItem">
                  システムの障害により、本チケットと対象商品等との交換が不能になった場合
                </li>
              </ol>
            </li>
            <li class="pmg-Page__olItem">
              購入者は本チケットに対し、当社が無料で提供するデジタルメッセージカードを作成して添付することができます。
            </li>
            <li class="pmg-Page__olItem">
              受取人は、あらかじめ本利用規約の内容を確認した上で本チケットを対象商品等と交換するものとします。
            </li>
            <li class="pmg-Page__olItem">
              受取人は、当社所定の方法で通知することにより、本チケットに関する全ての権利（対象店舗において対象商品等と交換する権利を含みます）を放棄することができるものとし、購入者はこれに対する異議を述べることはできないものとします。受取人が当該通知をした後は、受取人は、本チケットに関する権利の放棄を撤回し、または取り消すことはできません。
            </li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          情報提供サービス
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">
              情報提供サービスは、お客様が本アプリをダウンロードの上、＋メッセージサービスの利用契約を締結し、本アプリで公式アカウント「プラメギフト」を利用開始することでご利用いただけます。
            </li>
            <li class="pmg-Page__olItem">
              当社が提供する情報は、本サービスに係る運営上のお知らせ、およびお客様にとって当社が有益と考える情報(当社グループ会社または第三者の提供する商品またはサービスに関する広告等を含みます。以下、併せて「お知らせ等」といいます）であり、以下の方法で提供します。
              <ol class="pmg-Page__ol -roman">
                <li class="pmg-Page__olItem">本アプリ上に掲載する方法。</li>
                <li class="pmg-Page__olItem">
                  お客様の対応端末（本アプリがインストールされている端末をいい、以下同じとします）にインストールされたクライアントソフトウェアにより表示されるプッシュ通知により送信する方法。
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          第1項で定める受取人への通知、前項で定めるお知らせ等の通知は、お客様の対応端末の電源、電波等の状況により掲載、送信ができない場合や遅延する場合があります。
        </li>
        <li class="pmg-Page__olItem">
          当社は、お客様へ事前の通知または周知を行うことにより、本サービスの全部または一部を変更し、もしくは終了することがあります。本サービスを終了する場合、第8条2項に関わらず、本サービス終了と同時にお客様がデジタルメッセージカードを作成するにあたりアップロードした画像・動画・テキスト等の内容（以下「アップロード情報」といいます）を当社が削除できるものとし、お客様は、当該アップロード情報の削除について異議を申し立てないことをあらかじめ承諾するものとします。
        </li>
        <li class="pmg-Page__olItem">
          当社は、本サービスの提供にあたり、お客様に通知または連絡をする必要があると判断した場合、＋メッセージ公式アカウント、SMS（Cメール）、メール、電話等、当社が適切と判断する方法により連絡します。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第4条（本サービスの利用条件）</h2>
      <p class="pmg-Page__paragraph">
        第3条第1項および第2項に定める本サービスのご利用条件は、以下の各項に記載の条件のほか、別途個別のキャンペーン毎に条件を定めるものとします。
      </p>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          第３条第１項に定める商品購入サービス
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">
              購入者が、au IDの発行を受けており、当該au IDが有効に存続していること。
            </li>
            <li class="pmg-Page__olItem">購入者が、auかんたん決済を利用できること。</li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          第３条第２項に定める情報提供サービス
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">お客様が、＋メッセージサービスの利用者であること。</li>
            <li class="pmg-Page__olItem">
              お客様が、＋メッセージサービスを提供する各社所定の方法で、本アプリを利用することができること。
            </li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          第3条第1項および第2項に定める本サービス共通
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">
              お客様が、その費用と責任において、本サービスの提供を受けるために必要な対応端末、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持
              （ウィルス感染の防止対策等、セキュリティ対策を含みます） を行うこと。
            </li>
            <li class="pmg-Page__olItem">
              お客様が、反社会的勢力でなく、反社会的勢力を利用したことがないこと。
            </li>
            <li class="pmg-Page__olItem">お客様が、日本に在住する個人であること。</li>
          </ol>
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第5条（本チケットの購入申込みと代金支払い）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          本チケットの購入を希望するお客様は、当社所定の方法により本チケットの購入に関する申込み（以下「申込み」といいます）を行うものとします。お客様は購入申込み後、当社が承諾した場合を除き、これを変更・キャンセルすることはできません。但し、当社の責に帰すべき事由がある場合は、この限りではありません。
        </li>
        <li class="pmg-Page__olItem">
          前項に定める申込みを当社が承諾した時点で、お客様と当社との間に申込みのあった本チケットに関する売買契約が成立するものとします。
        </li>
        <li class="pmg-Page__olItem">
          本チケットに関する売買契約が成立した場合、次項に定める方法に従い、当社に対して本チケットに係る代金全額を支払うものとします。
        </li>
        <li class="pmg-Page__olItem">
          本チケットの支払には、Pontaポイントおよびauかんたん決済（通信料合算、au PAYカード、au PAY残高支払い）を利用できるものとします。なお、これらのご利用に際しては、それぞれau Pontaポイントプログラム規定およびauかんたん決済会員規約が適用されます。
        </li>
        <li class="pmg-Page__olItem">
          当社は、前項に定める支払の完了を確認した後に、購入者に対し、本チケットを発行するものとします。また、本チケットの売買契約上の当社の義務の履行は、本チケットの発行をもって完了するものとします。
        </li>
        <li class="pmg-Page__olItem">
          本チケット発行後に、購入者、または受取人がデータ削除等を理由に本チケットを利用できなくなったことにより、購入者、または受取人に損害が生じた場合でも、当社はその責任を負わないものとします。
        </li>
        <li class="pmg-Page__olItem">
          お客様が、本サービスにおいてPontaポイントの加算対象となる本チケットを購入した場合、当社が定める加算条件に従いPontaポイントを加算するものとします。加算条件の詳細に関しては、当社所定のWEBサイトで定めるものとします。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第6条（本チケットの有効期限）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          本チケットを対象商品等と交換できる有効期限は各商品ページで定めるもとし、有効期限を経過した場合は利用できません。また、有効期限を経過した場合、第3条第1項第4号に定める本チケットのキャンセル・返品・交換を行なうことができません。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第7条（個人情報の取扱)</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          当社は、次項に定めるお客様の情報(以下「お客様情報」といいます)を以下の目的で利用します。
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">本サービスの提供およびキャンペーン等の特典付与</li>
            <li class="pmg-Page__olItem">
              商品・サービス等(当社のものに限定しない)に関する調査の実施・分析等
            </li>
            <li class="pmg-Page__olItem">
              当社または提携先企業が提供する各サービスの利用状況分析、品質向上、その他新商品開発等
            </li>
            <li class="pmg-Page__olItem">
              官公庁、公共団体、一般企業等への、マーケティング分析等に係る調査結果等の提供(この場合、当社は、個人を特定できない形式に加工した上で提供するものとし、提供先に対し、個人を再特定しないように義務付けます。)
            </li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          お客様情報とは、以下の情報をいいます。
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">
              お客様が＋メッセージサービスの利用に際して、当社に提供した電話番号およびau、UQ
              mobileまたはpovo回線契約の場合は、ご利用料金プラン、ご利用機種情報、性別、年齢、居住地(市区町村、郵便番号)等のご契約情報
            </li>
            <li class="pmg-Page__olItem">
              au ID情報およびau
              IDに紐づく情報（氏名、郵便番号、住所、生年月日、メールアドレス、電話番号 等）
            </li>
            <li class="pmg-Page__olItem">
              本サービスの利用に関する情報(本チケットの購入および利用履歴、デジタルメッセージカードの作成履歴、アンケートへの回答内容等)
            </li>
            <li class="pmg-Page__olItem">次項に定めるauサービス等のご利用に関する情報</li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          お客様情報には、以下のauサービス等のご利用に関する情報を含みます。
          <ol class="pmg-Page__ol -alphabet">
            <li class="pmg-Page__olItem">Pontaポイントに関する情報(保有ポイント数、加算実績等)</li>
            <li class="pmg-Page__olItem">
              au PAY（コード支払い)の利用に関する情報(登録有無、利用内容、利用状況等)
            </li>
            <li class="pmg-Page__olItem">
              au PAY カードの利用に関する情報(保有有無、利用内容、利用状況等)
            </li>
            <li class="pmg-Page__olItem">
              au PAY プリペイドカードの利用に関する情報(保有有無、チャージ実績および決済の状況等)
            </li>
            <li class="pmg-Page__olItem">
              auスマートパスの利用に関する情報(加入有無、サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              ニュースパスの利用に関する情報(アカウント情報、設定情報、サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">データお預かりの利用に関する情報(利用内容、利用状況等)</li>
            <li class="pmg-Page__olItem">
              auサービスTodayの利用に関する情報(パーソナル設定情報、サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              TELASAの利用に関する情報(加入有無、保有コインに関する情報、作品購入・視聴・サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              うたパスの利用に関する情報(加入有無、保有コインに関する情報、視聴・サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              ブックパスの利用に関する情報(加入有無、保有コインに関する情報、作品購入・閲覧・サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              au PAY マーケットの利用に関する情報(会員状態、商品購入・サービス利用履歴等)
            </li>
            <li class="pmg-Page__olItem">
              auでんきの契約に関する情報(加入有無、auセット割適用有無、契約情報等)
            </li>
            <li class="pmg-Page__olItem">auでんきの利用に関する情報(利用状況等)</li>
            <li class="pmg-Page__olItem">当社が提供する各種キャンペーンおよびクーポンの適用状況</li>
            <li class="pmg-Page__olItem">
              お客様が当社との間で締結した各種サービスの提供に係る契約の契約内容、当該サービスの利用に関する情報および登録情報
            </li>
          </ol>
        </li>
        <li class="pmg-Page__olItem">
          お客様情報は別途当社の定める「アプリケーションプライバシーポリシー」および「KDDIプライバシーポリシー」に従い取り扱うものとします。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">
        第8条（お客様がアップロードした画像・動画・テキスト等について）
      </h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          当社は、お客様がアップロードした画像・動画・テキスト等（アップロード情報）については、一切の責任を負わないものとします。
        </li>
        <li class="pmg-Page__olItem">
          アップロード情報の保持期限はアップロード日より３年間となります。保持期間を超えたアップロード情報については当社にて自動削除等が適用されるものとし、お客様は、当該アップロード情報の削除について異議を申し立てないことをあらかじめ承諾するものとします。
        </li>
        <li class="pmg-Page__olItem">
          当社は、お客様が第10条各号に該当する行為、またはそのおそれのある行為をした場合に、お客様の許諾なくアップロード情報を削除することができるものとします。
        </li>
        <li class="pmg-Page__olItem">
          お客様は、アップロード情報について、当社に対し、非独占的、無償な使用、表示および実行に関するライセンスを付与するものとします。また、お客様は、アップロード情報が第10条各号に該当せず、アップロード情報に起因する当社に対するすべての請求について当社に対し補償することを表明および保証いたします。
        </li>
        <li class="pmg-Page__olItem">
          お客様は、アップロード情報を含むデジタルメッセージカードに係る情報の改変および削除等を自ら行うことはできないものとします。また、お客様は、当社に対して、著作者人格権を行使せず、異議を申し立てないことをあらかじめ同意するものとします。
        </li>
        <li class="pmg-Page__olItem">
          お客様のアップロード情報により、当社に損害が生じた場合、当社に対し、その損害を賠償するものとします。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第9条（知的財産）</h2>
      <p class="pmg-Page__paragraph">
        本サービスおよび本アプリに係る著作権等を含む知的財産権、その他一切の権利は当社または正当な権限を有する第三者に帰属します。なお、本利用規約によるお客様への本サービスおよび本アプリの利用許諾はお客様に対する権利移転等を意味するものではありません。
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第10条（禁止事項）</h2>
      <p class="pmg-Page__paragraph">
        お客様は、本サービスのご利用にあたり、以下の各号に該当する行為、またはそのおそれのある行為をしてはなりません。<br />
        お客様が以下の各号に定める禁止事項に該当する行為を行ったと当社が判断した場合、当社は、お客様が有する本チケットを無効（当該本チケットの交換対象が配送を伴う対象商品等である場合には、対象商品等の配送行為の停止も含みます）とすることができ、第3条第1項第4項に定める本チケットのキャンセル・返品・交換、本チケットと対象商品等との交換を認めないことができるものとし、お客様に対する何らの催告なく本サービス利用契約または本チケットに関する売買契約を解除することができるものとします。
      </p>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          違法行為、公序良俗に反する行為または不正の目的をもって利用する行為
        </li>
        <li class="pmg-Page__olItem">虚偽、不正確な情報、わいせつな表現を入力する行為</li>
        <li class="pmg-Page__olItem">
          当社グループその他第三者等の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為、および侵害するおそれのある行為
        </li>
        <li class="pmg-Page__olItem">他のお客様による本サービスの利用を妨害する行為</li>
        <li class="pmg-Page__olItem">
          当社グループの営業活動を妨害する行為、または妨害するおそれのある行為
        </li>
        <li class="pmg-Page__olItem">
          本アプリについて、逆アセンブル、逆コンパイル、リバースエンジニアリング等、またはその他ソースコード、構造、アイディア等を解析するような行為
        </li>
        <li class="pmg-Page__olItem">
          本アプリを複製、改変、翻案等し、または他のソフトウェアと結合等する行為
        </li>
        <li class="pmg-Page__olItem">
          本利用規約に基づく本サービスの利用権を第三者に再許諾、譲渡、移転し、またはその他の方法で処分する行為
        </li>
        <li class="pmg-Page__olItem">
          本サービスに付されている著作権表示およびその他の権利表示を除去または変更する行為
        </li>
        <li class="pmg-Page__olItem">第三者が前各号の行為を行うことを助長する行為</li>
        <li class="pmg-Page__olItem">その他、当社が不適切と判断する行為</li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第11条（本サービス利用契約の終了等について）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          お客様は、本サービス利用契約の解除を希望する場合は、当社所定の方法により、その旨を当社に申し出ることにより、本サービス利用契約を解除して、本サービスの利用を終了することができます。
        </li>
        <li class="pmg-Page__olItem">
          前項に定める場合のほか、お客様と当社の間の＋メッセージサービスの利用契約が終了した場合、当該利用契約が終了した時点で本サービス利用契約も終了するものとします。
        </li>
        <li class="pmg-Page__olItem">
          お客様が前条各号の定めに違反する行為をした場合、その他本利用規約の定めに違反する場合には、当社は、お客様に本サービスの全部または一部の利用を停止し、または本サービス利用契約を解除することができます。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第12条（免責事項）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">
          お客様は、本サービスをお客様の責任で利用するものとし、当社が提供する本サービスを利用した結果の正確性、有用性等について、当社は、一切責任を負いません。
        </li>
        <li class="pmg-Page__olItem">
          当社は、本サービスの利用にあたり、当社が提供する機能に中断、中止その他の障害が生じないことを保証せず、これらの障害が生じても、当社の責に帰すべき事由がある場合を除き、当社は一切責任を負いません。
        </li>
        <li class="pmg-Page__olItem">
          本サービスの利用にあたり、当社の責に帰すべき事由によりお客様が損害を被った場合、当社は当該損害の発生の直接の原因となった本チケットの代金相当額を上限として、当該損害を賠償するものとします。ただし、当社の故意または重大な過失に基づく損害については、当該上限を適用しないものとします。
        </li>
      </ol>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">第13条（準拠法および管轄裁判所）</h2>
      <ol class="pmg-Page__ol">
        <li class="pmg-Page__olItem">本利用規約は、日本法に従って解釈・適用されるものとします。</li>
        <li class="pmg-Page__olItem">
          本サービスに関し当社とお客様との間で紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </li>
      </ol>
    </section>

    <div class="pmg-Page__section -date">
      <p class="pmg-Page__paragraph">本利用規約制定日　2023年9月12日</p>
      <p class="pmg-Page__paragraph">本利用規約改定日　2024年3月27日</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Rules',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
<style lang="css" scoped>
.pmg-Page__olItem {
  list-style: inherit !important;
}
</style>
