<template>
  <div class="auid">
    <ion-label class="auid__message ion-text-wrap">
      ギフトの購入にはPontaポイントをご利用いただけます。
    </ion-label>
    <div v-if="isAuIdAuthorization" class="auid__ponta__point">
      <PontaPoint :isMark="isMark" :isPontaLogo="isPontaLogo"></PontaPoint>
    </div>
    <Button v-else label="au IDでログイン" type="fill-orange" size="small" ref="login" @click="login"/>
  </div>
</template>

<script>
import Button from '@/components/button/Button';
import PontaPoint from '@/components/point/PontaPoint';
import { mapGetters } from 'vuex';
import { auIdLogin } from '@/plugins/AuId';
import { IonLabel } from '@ionic/vue';
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: 'AuLogin',
  components: {
    Button,
    IonLabel,
  },
  props: {
    GALabel1: {
      type: String,
      default: '',
    },
    isMark: {
      type: Boolean,
      default: true,
    },
    isPontaLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isAuIdAuthorization']),
  },
  methods: {
    login() {

      gtmSendClick(this.$refs['login'].$el,this.GALabel1,'au IDでログイン','');
      const redirectUrl = process.env.VUE_APP_BASE_APP_URL + this.$route.path;
      auIdLogin(redirectUrl);
    },

  },
};
</script>

<style scoped lang="scss">
.auid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__ponta {
    margin: 18px 0 4px;
    font: $font-inter-400;

    @include font-size(12, 20);
  }

  &__message {
    margin-right: 16px;
    color: $gray10;
    font: $font-noto-sans-jp-400;
    @include font-size(12, 19.2);
    text-align: left;
  }

  :deep(.fill-orange) {
    position: inherit;
    min-width: 126px;
    height: 28px;
  }
}
</style>
