/**
 * 検索フィルターのカテゴリー
 * label: フィルターのラベル
 * lowerBudget: フィルターの下限価格
 * upperBudget: フィルターの上限価格
 * priceFilterId: フィルターのID
 * enableSelected: フィルターが選択可能か
 */
export const PRICE_FILTER_BUTTONS = [
    {label: '500円以下', lowerBudget: null, upperBudget: 500, priceFilterId: 1, enableSelected: true}, // どの価格フィルターを選択しているか判別するためにpriceFilterIdを定義
    {label: '1,000円以下', lowerBudget: null, upperBudget: 1000, priceFilterId: 2,  enableSelected: true},
    {label: '1,000円台', lowerBudget: 1000, upperBudget: 1999, priceFilterId: 3,  enableSelected: false},
    {label: '2,000円台', lowerBudget: 2000, upperBudget: 2999, priceFilterId: 4,  enableSelected: false},
    {label: '3,000円台', lowerBudget: 3000, upperBudget: 3999, priceFilterId: 5,  enableSelected: false},
    {label: '5,000円以上', lowerBudget: 5000, upperBudget: null, priceFilterId: 6,  enableSelected: true},
]


// 0: 通常, 1: sceneスクロール中, 2: sceneスクロール完了, 3: categoryスクロール中, 4: categoryスクロール完了, 5:priceスクロール中, 6: priceスクロール完了, 7: 完了

export const FILTER_SCROLL_PROCESS = {
    START: 0,
    SCENE_SCROLLING: 1,
    SCENE_SCROLL_END: 2,
    CATEGORY_SCROLLING: 3,
    CATEGORY_SCROLL_END: 4,
    PRICE_SCROLLING: 5,
    PRICE_SCROLL_END: 6,
    COMPLETE: 7,
}
