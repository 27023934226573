<template>
  <div class="first-view">
    <component :is="component" :message="showValue.openingMessage" :date="showValue.openingDate" :disable-animation="editable"/>

    <template v-if="editable">
      <div class="first-view__editable" :class="editableClasses" >
        <Button class="button" :label="buttonLabel" icon="edit" @click="openModal"/>
      </div>

      <FormModal ref="modal" title="オープニングメッセージ" :disabled-save="isFormEmpty" @click:save="save">
        <FormBlock label="オープニング・メッセージ" :sub-label="messageSubLabel" help="50文字・3行以内を目安としてください。" :required="true">
          <FormTextarea v-model="form.openingMessage" size="medium" :maxlength="100" placeholder="メッセージを入力"/>
        </FormBlock>
        <FormBlock label="メッセージを贈る日" help="カードに記載される日付です。">
          <FormDate v-model="form.openingDate"/>
        </FormBlock>
      </FormModal>
    </template>
  </div>
</template>

<script>
import DSI0101 from '@/components/messageCard/FirstViews/DSI0101'
import DSI0102 from '@/components/messageCard/FirstViews/DSI0102'
import DSI0103 from '@/components/messageCard/FirstViews/DSI0103'
import DSI0201 from '@/components/messageCard/FirstViews/DSI0201'
import DSI0202 from '@/components/messageCard/FirstViews/DSI0202'
import DSI0203 from '@/components/messageCard/FirstViews/DSI0203'
import DSI0301 from '@/components/messageCard/FirstViews/DSI0301'
import DSI0302 from '@/components/messageCard/FirstViews/DSI0302'
import DSI0401 from '@/components/messageCard/FirstViews/DSI0401'
import Button from '@/components/button/Button'
import FormModal from '@/components/modal/FormModal'
import FormBlock from '@/components/form/FormBlock'
import FormTextarea from '@/components/form/FormTextarea'
import FormDate from '@/components/form/FormDate'

export default {
  name: 'FirstView',
  components: {
    DSI0101,
    DSI0102,
    DSI0103,
    DSI0201,
    DSI0202,
    DSI0203,
    DSI0301,
    DSI0302,
    DSI0401,
    Button,
    FormModal,
    FormBlock,
    FormTextarea,
    FormDate,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    designId: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    sampleValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  data: () => ({
    form: {
      openingMessage: '',
      openingDate: '',
    },
  }),
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    component() {
      return this.designId;
    },
    // editable
    isEmpty() {
      return !this.value.openingMessage;
    },
    showValue() {
      return this.isEmpty ? this.sampleValue : this.value;
    },
    editableClasses() {
      let classes = [];
      if (this.isEmpty) classes.push('is-empty');
      return classes;
    },
    buttonLabel() {
      return this.isEmpty ? 'タップして編集' : '';
    },
    // modal
    isFormEmpty() {
      return !this.form.openingMessage;
    },
    messageSubLabel() {
      return `${this.form.openingMessage.length}/50`;
    },
  },
  methods: {
    // modal
    openModal() {
      this.form.openingMessage = this.value.openingMessage;
      this.form.openingDate = this.value.openingDate;
      this.$refs.modal.openModal();
    },
    save() {
      this.value.openingMessage = this.form.openingMessage;
      this.value.openingDate = this.form.openingDate;
    },
  },
}
</script>

<style scoped lang="scss">
.first-view {
  position: relative;
  width: 100%;
  height: 100%;

  &__editable {
    position: absolute;
    left: 50%;
    bottom: 32.5%;
    width: 100%;
    max-width: 375px;
    min-width: 300px;
    padding: 0 10px;
    transform: translateX(-50%);
    text-align: right;
    z-index: 5;

    &.is-empty {
      bottom: 18.1%;
      text-align: center;
    }
  }

  .form-modal {
    z-index: 100;
  }
}
</style>
