<template>
  <IonicModal
    ref="model"
    title="贈り方・受け取り方"
    :showFooter="false"
    :contentClasses="['ion-no-padding']"
  >
    <template #body>
      <Guide :modal-mode="true" />
    </template>
  </IonicModal>
</template>

<script>
import IonicModal from "@/components/ionic/IonicModal";
import Guide from "@/components/static/Guide";
import { emitter } from "@/eventBus";

export default {
  name: "HowToGiveReceive",
  components: {
    IonicModal,
    Guide,
  },
  mounted() {
    const self = this;
    emitter.on('showFaq', () => {
      self.hideModal();
      self.$router.push({
        name: 'PMG640',
        params: { url: 'faq', title: 'よくある質問・お問い合わせ' },
      });
    });
    emitter.on('showRules', () => {
      self.hideModal();
      self.$router.push({
        name: 'PMG640',
        params: { url: 'rules', title: '利用規約' },
      });
    });
    emitter.on('showGifts', () => {
      self.hideModal();
      self.$router.push({ name: 'PMG600' });
    });
  },
  methods: {
    openModal() {
      this.$refs.model.openModal();
    },
    hideModal() {
      this.$refs.model.hideModal();
    },
  },
};
</script>
<style scoped lang="scss">
ion-modal {
  --height: 95%;
  align-items: flex-end;
}
.how-to-frame {
  width: 100%;
  height: 100%;
}
</style>
