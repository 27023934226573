export const SORT_ORDER = {
    POPULAR: 1,
    LOW_PRICE: 2,
    HIGH_PRICE: 3
}

/*
  ボトムナビゲーションのメニューID
 */
export const BOTTOME_NAVIGATION_IDS = {
    HOME: 1,
    GIFT: 2,
    CART: 3,
    PURCHASE_HISTORY: 4,
}

export const CATEGORY_CLASSIFICATION = {
    SCENE: 2,
    CATEGORY: 1,
    RECEIVES: 3,
}
