<template>
  <IonicModal ref="modal" title="カード作成の完了"  @on-close-dom="closeGA">
    <template #body>
      <div class="messages">
        <ion-label class="messages__message1">
          {{ '作成を完了すると、再編集できません。' }}
        </ion-label>
        <br />
        <ion-label class="messages__message2">
          {{
            '保存して終了する場合は「作成完了して買い物カゴへ」を選択してください。ギフトの購入決済手続きに進みます。'
          }}
        </ion-label>
        <br />
        <ion-label v-if="!isAuIdAuthorization" class="messages__message3" color="danger">
          {{
            'ギフトとカードを贈るには、au IDでのログインが必要です。（IDをお持ちでない場合は、次画面の「auかんたん決済」で新規登録してください）'
          }}
        </ion-label>
      </div>
      <div class="buttons">
        <Button label="作成完了して買い物カゴへ" type="fill" ref="completed" @click="completed" />
        <Button label="作成に戻る" type="none" ref="close" @click="close" />
      </div>
    </template>
  </IonicModal>
</template>

<script>
import IonicModal from '@/components/ionic/IonicModal';
import Button from '@/components/button/Button';
import { IonLabel } from '@ionic/vue';
import { mapGetters } from 'vuex';

import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: 'ConfirmCreateCardModal',
  components: { IonicModal, Button, IonLabel },
  emits: ['click:completed'],
  computed: {
    ...mapGetters(['isAuIdAuthorization']),
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    completed() {
      gtmSendClick(this.$refs['completed'].$el,'カード作成の完了モーダル','作成完了して買い物カゴへ','');

      this.hideModal();
      this.$emit('click:completed');
    },
    close() {
      gtmSendClick(this.$refs['close'].$el,'カード作成の完了モーダル','作成に戻る','');

      this.hideModal();
    },
    closeGA(dom){
      gtmSendClick(dom,'カード作成の完了モーダル','閉じる','');
    },
  },
};
</script>

<style scoped lang="scss">
ion-modal {
  --max-height: 95%;
  --height: 370px;
  align-items: flex-end;
}

.messages {
  &__message1 {
    font: $font-roboto-700;
    @include font-size(16, 25.6);
  }

  &__message2 {
    font: $font-noto-sans-jp-400;
    @include font-size(14, 22.4);
  }

  &__message3 {
    font: $font-noto-sans-jp-400;
    @include font-size(14, 22.4);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0;
  gap: 16px;

  :deep(.button) {
    width: 100%;
  }
}
</style>
