<template>
  <transition name="fade">
    <div v-if="isShow" class="loading">
      <div class="loading__spinner"/>
    </div>
  </transition>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Loading',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $white1;
  z-index: 10000;

  &.fade-enter-active {
    animation: fade-in 0ms;
  }

  &.fade-leave-active {
    animation: fade-in 200ms reverse;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__spinner {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: solid 7px $gold1;
    border-right-color: transparent;
    animation: rotate 1.1s infinite linear;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
