<template>
  <div class="sample-item" @click="clickSample">
    <img class="sample-item__image" :src="data.imagePath" />
    <div class="sample-item__card_sample" />
  </div>
</template>

<script>
export default {
  name: 'SampleItem',
  props: {
    data: {
      type: Object,
      default: () => ({
        categoryId: 'CTG****',
        designId: 'DSI****',
        designName: 'string',
        title: 'string',
        imageText: 'string',
        cardText: 'string',
        description: 'string',
        order: 0,
      }),
    },
  },
  emits: ['click:sample'],
  methods: {
    clickSample() {
      this.$emit('click:sample');
    },
  },
};
</script>

<style scoped lang="scss">
.sample-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc((100% - 4px) / 2);
  background: $white1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;

  &__image {
    object-fit: cover;
    aspect-ratio: 1 / 1.283;
    width: 100%;
  }

  &__card_sample {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 28px;
    height: 28px;
    background-image: url('../../assets/images/icon/card_sample.svg');
    background-repeat: no-repeat;
  }
}
</style>
