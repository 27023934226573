<template>
  <div class="dsi0103">
    <FirstViewBase
      :message="message"
      :date="date"
      :animation-data="animationData"
      :animation-image="animationImage"
      :background-images="backgroundImages"
      :disable-animation="disableAnimation"
    />
  </div>
</template>

<script>
import FirstViewBase from '@/components/messageCard/FirstViewBase'

import animationData from '@/assets/lottie/DSI0103/animationData.json'
import animationImage from '@/assets/images/FirstView/DSI0103/animation.svg'
import bg01 from '@/assets/images/FirstView/DSI0103/bg01.jpg'
import bg02 from '@/assets/images/FirstView/DSI0103/bg02.jpg'
import bg03 from '@/assets/images/FirstView/DSI0103/bg03.jpg'
import bg04 from '@/assets/images/FirstView/DSI0103/bg04.jpg'

export default {
  name: 'DSI0103',
  components: {
    FirstViewBase,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    animationData,
    animationImage,
    backgroundImages: [
      bg01,
      bg02,
      bg03,
      bg04,
    ],
  }),
}
</script>

<style scoped lang="scss">
.dsi0103 {
  width: 100%;
  height: 100%;

  :deep(.first-view-base) {
    .lottie {
      width: 72%;

      &__text {
        top: 50.01%;
        bottom: 14.55%;
        width: 75%;
        color: $black2;
        font: $font-fot-chiaro-std-700;
      }
    }
  }
}
</style>
