const axios = require('axios');
import router from '@/router';
import store from '@/store';

// アカウント認証用API
export const authorization = async () => {
  await store.dispatch('startLoading');

  return new Promise((resolve) => {
    const config = {
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_API_URL}/authorization`,
      params: {
        id: sessionStorage.getItem('UUID'),
      },
      headers: {
        'accept': 'application/json',
      },
    };

    axios(config)
      .then((res) => {
        store.commit('setApiKey', res.data.apiKey);
        store.commit('setCarrier', res.data.carrier);
        resolve();
      })
      .catch((error) => {
        store.dispatch('stopLoading');
        const status = error.response.status;
        if (status === 503) {
          router.push({ name: 'PMG500' });
        } else {
          router.push({ name: 'PMG510' });
        }
      });
  });
};

// ファイルアップロード
export const upload = async (file, url) => {
  await store.dispatch('startLoading');

  return new Promise((resolve) => {
    const config = {
      method: 'PUT',
      url: url,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: file,
    };

    axios(config)
      .then(res => {
        resolve(res);
      })
      .catch(() => {
        router.push({ name: 'PMG510' });
      })
      .finally(() => {
        store.dispatch('stopLoading');
      });
  });
};


const execute = async (method, apiPath, requestData = {}, catchPass = false, nonLoader = false) => {
  if (!nonLoader) {
    await store.dispatch('startLoading');
  }

  return new Promise((resolve, reject) => {
    const config = {
      method: method,
      url: `${process.env.VUE_APP_BASE_API_URL}${apiPath}`,
      headers: {
        'accept': 'application/json',
        'x-api-key': store.getters.apiKey,
      },
    };

    if (requestData) {
      if (method === 'GET') {
        config.params = requestData;
      } else {
        config.data = requestData;
      }
    }

    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch(error => {
        if (catchPass) {
          reject(error);
        } else {
          const status = error.response.status;
          if (status === 503) {
            router.push({ name: 'PMG500' });
          } else if (status === 400) {
            reject(error);
          } else {
            router.push({ name: 'PMG510' });
          }
        }
      })
      .finally(async () => {
        if (!nonLoader) {
          await store.dispatch('stopLoading');
        }
      });
  });
};

// 対象の電番に対して、送信方法を選択するリッチカード形式のメッセージを送信する
export const chooseMethodApi = data => {
  return execute('POST', '/choose-method', data);
};

// 写真ファイル用の署名付き URL を返却します。
export const uploadPhotoApi = data => {
  return execute('POST', '/upload-photo', data);
};

// 動画ファイル用の署名付き URL を返却します。
export const uploadVideoApi = data => {
  return execute('POST', '/upload-video', data);
};

// カードを作成します。
export const createCardApi = data => {
  return execute('POST', '/create-card', data);
};

// 選択可能なデザインパターンのメタ情報を返却するAPI
export const listCategoriesApi = data => {
  return execute('GET', '/list-categories', data);
};

// 選択可能なデザインパターンのメタ情報を返却するAPI
export const listDesignsApi = data => {
  return execute('GET', '/list-designs', data);
};

// 選択されたカードデザインのサンプル表示用の文言を返却するAPI
export const designSampleApi = data => {
  return execute('GET', '/design-sample', data);
};

// 作成したメッセージカードを最大1000件まで取得します。
export const createdCardListApi = data => {
  return execute('GET', '/created-card-list', data);
};

// 受け取ったのメッセージカードを最大1000件まで取得します。
export const receivedCardListApi = data => {
  return execute('GET', '/received-card-list', data);
};

// メッセージカードの表示に使用する詳細データを取得します。
export const messageCardDataApi = data => {
  return execute('GET', '/message-card-data', data);
};

// au ID 認可要求
export const authAuId = async (data) => {
  return await execute('POST', '/auid-auth', data, true);
}

// かんたん決済認可要求
export const auPayAuth = async (data) => {
  return await execute('POST', '/au-pay-auth', data, true);
}

// かんたん決済認可要求
export const auPayAuthResult = async (data) => {
  return await execute('POST', '/au-pay-auth-result', data, true);
}

// かんたん決済確定処理
export const auPayAuthorySold = async (data) => {
  return await execute('POST', '/au-pay-authory-sold', data, true);
}

// 全額ポイント確定処理
export const pointSold = async (data) => {
  return await execute('POST', '/point-sold', data, true);
}

// ギフトのカテゴリを返却するAPI
export const listGiftCategoriesApi = data => {
  return execute('GET', '/list-gift-categories', data, true, true);
};

// ギフトの価格（予算）を返却するAPI
export const listGiftPricesApi = data => {
  return execute('GET', '/list-gift-prices', data, true, true);
};

// 絞り込み条件に一致するギフトを返却するAPI
export const listGiftsApi = data => {
  return execute('POST', '/list-gifts', data);
};

// 条件に一致するギフトの詳細を返却するAPI
export const detailGiftApi = data => {
  return execute('GET', '/detail-gift', data);
};

// ギフトの購入情報を返却するAPI
export const listPurchasesApi = data => {
  return execute('GET', '/list-purchases', data);
};

// ギフトの購入履歴（カード作成・受取履歴）を返却するAPI
export const listPurchaseHistoriesApi = data => {
  return execute('POST', '/list-purchase-histories', data);
};

// ポンタポイントの取得
export const getPontaPointApi = (data) => {
  return execute('POST', '/ponta-point', data, true, true);
};

// ブランド別ギフト一覧
export const getSameBrandGift = (data) => {
  return execute('GET', '/brand-gifts', data);
}

// ブランド一覧取得
export const ListBrandsApi = (data) => {
  return execute('GET', '/list-brands', data);
}

// お知らせ一覧取得
export const getGiftInfoList = () => {
  return execute('GET', '/info-list');
}

// 通常版カード作成
export const postCreateNomalCard = (data) => {
  return execute('POST', '/create-normal-card', data);
}

// カート内のカード情報取得
export const getCartCardInfo = (data) => {
  return execute('GET', '/cart-card-info', data);
}

// カード削除
export const delCard = (data) => {
  return execute('POST', '/delete-card', data);
}

// おすすめ商品取得
export const getPopularGift = () => {
  return execute('GET', '/popular-gift');
}

// 購入完了後のギフト、カードデータ
export const getPaymentCompletionInfo = (data) => {
  return execute('POST', '/payment-completion-info', data);
}

// 購入上限超過チェック
export const checkPurchaceLimit = (data) => {
  return execute('POST', '/check-purchace-limit', data, false, false);
}

// 買い物カゴ更新
export const updateCart = (data) => {
  return execute('POST', '/update-cart', data, true);
}

// 買い物カゴ取得
export const getCart = (data) => {
  return execute('POST', '/get-cart', data, false, false);
}
