<template>
  <div class="base-template" :class="classes">
    <div class="base-template__container" :style="style.container">
      <div class="first-view-content" :style="style.firstView">
        <slot name="default"/>
      </div>

      <div class="message-content">
        <Button v-if="editable && !isCardEmpty" class="card__editable-button" icon="edit" @click="openCardModal"/>
        <div class="card" :class="cardClasses">
          <div class="card__to">{{ showCardValue.receiverName }}</div>
          <div class="card__text">{{ replaceWrap(showCardValue.mainMessage)}}</div>
          <div class="card__date">{{ formatCardDate(showCardValue.openingDate) }}</div>
          <div class="card__from">{{ showCardValue.creatorName }}</div>

          <div v-if="editable && isCardEmpty" class="card__editable-empty">
            <Button class="button" label="タップして編集" icon="edit" @click="openCardModal"/>
          </div>
        </div>

        <div v-for="(media, i) in value.contents" :key="i" class="media">
          <div class="media__content" :class="mediaTapeClasses(i)">
            <img v-if="media.mimeType.startsWith('image')" class="image" :src="media.objectUrl" alt="media.caption">
            <video v-if="media.mimeType.startsWith('video')" class="video" :src="media.objectUrl" controls playsinline muted autoplay @loadeddata="onLoadeddata"/>

            <template v-if="editable">
              <button class="remove" @click="openConfirmModal(i)"/>
              <Button class="editable" icon="edit" @click="openMediaModal(i)"/>
            </template>
          </div>
          <div class="media__text">{{ replaceWrap(media.caption) }}</div>
        </div>

        <div v-if="editable" class="add-media">
          <div class="add-media__text">思い出につながる<br>写真・動画を追加できます。</div>
          <div class="add-media__buttons">
            <Button class="button" label="写真" icon="image" :disabled="disabledAddImage" @click="openAddMediaModal('image')"/>
            <Button class="button" label="動画" icon="video" :disabled="disabledAddVideo" @click="openAddMediaModal('video')"/>
          </div>
          <div class="add-media__sub-text">※写真は5枚まで、動画は1つまで登録できます。</div>
        </div>

        <div v-if="!isEdit" class="gift">
          <div class="gift__header">あなたも大切な人へ<br>ギフトを贈りませんか？</div>
          <div class="gift__text">誕生日やお礼の気持ちを届けたいときに<br>ぜひご利用ください♪</div>
          <img class="gift__image" src="@/assets/images/BaseTemplate/girl.svg" alt="gift">

          <div class="gift__plame" @click="goPlame()">
            <img class="icon" src="@/assets/images/icon/gift_icon.png" />
            <span class="label"> プラメギフトについて </span>
          </div>

          <div class="gift__sub-text">※au、UQ mobile、povoのお客さまがご利用いただけます</div>
        </div>
      </div>

      <footer class="footer">© 2022 gift. All rights reserved</footer>
    </div>

    <FormModal ref="cardModal" title="メインメッセージ" :disabled-save="isCardFormEmpty" @click:save="saveCard">
      <FormBlock label="贈り先のお名前" help="〇〇、〇〇へ、〇〇さんへ、など。">
        <FormInput v-model="form.receiverName" placeholder="夏海さんへ" :maxlength="50"/>
      </FormBlock>
      <FormBlock label="メインメッセージ" :sub-label="mainMessageSubLabel" help="400文字・20行以内を目安としてください。" :required="true">
        <FormTextarea v-model="form.mainMessage" size="large" :maxlength="800" placeholder="メッセージを入力"/>
      </FormBlock>
      <FormBlock label="あなたのお名前" help="〇〇、〇〇より、など。" :required="true">
        <FormInput v-model="form.creatorName" placeholder="麻衣より" :maxlength="50"/>
      </FormBlock>
    </FormModal>

    <FormModal ref="mediaModal" :title="`${mediaModalLabel}の追加`" :disabled-save="isMediaFormEmpty" @click:save="saveMedia">
      <FormBlock>
        <FormFile v-model="mediaForm.file" :type="mediaFormType"/>
      </FormBlock>
      <FormBlock label="キャプション" :help="`${mediaModalLabel}の下に表示される一言テキストです。`">
        <FormTextarea v-model="mediaForm.caption" size="small" :maxlength="50" placeholder="楽しかったね！"/>
      </FormBlock>
    </FormModal>

    <ConfirmModal ref="confirm" :messages="confirmMessages" @click:ok="removeMedia"/>

    <GiftToastUse :gift-data="giftData" :is-show-price="isShowPrice" />
  </div>
</template>

<script>
import Button from '@/components/button/Button'
import FormModal from '@/components/modal/FormModal'
import FormBlock from '@/components/form/FormBlock'
import FormInput from '@/components/form/FormInput'
import FormTextarea from '@/components/form/FormTextarea'
import FormFile from '@/components/form/FormFile'
import ConfirmModal from '@/components/modal/ConfirmModal'
import GiftToastUse from '@/components/gift/GiftToastUse'
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { getUrl } from '@/plugins/UrlHelper';
import { DateTime } from 'luxon'

export default {
  name: 'BaseTemplate',
  components: {
    Button,
    FormModal,
    FormBlock,
    FormInput,
    FormTextarea,
    FormFile,
    ConfirmModal,
    GiftToastUse,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        openingMessage: '',
        openingDate: '',
        mainMessage: '',
        creatorName: '',
        receiverName: '',
      }),
    },
    designId: {
      type: String,
      default: null,
    },
    scrollParent: {
      default: window,
    },
    hideGift: {
      type: Boolean,
      default: false,
    },
    disabledGiftLink: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    sampleValue: {
      type: Object,
      default: () => ({}),
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    giftData: null,
    isShowPrice: {
      type: Boolean,
      default: true,
    },
    isLinkEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'click:giftLink'],
  data: () => ({
    isShowCard: false,
    style: null,
    form: {
      openingMessage: '',
      openingDate: '',
      mainMessage: '',
      creatorName: '',
      receiverName: '',
      contents: [],
      gift: null,
    },
    mediaForm: {
      file: null,
      caption: '',
    },
    mediaModalType: 'add', // add, update
    mediaFormType: 'image', // image, video
    mediaFormIndex: null,
    removeMediaIndex: null,
    confirmMessages: [
      '削除しますか？',
    ],
    resizeObserver: null,
  }),
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    classes() {
      let classes = [];

      if (this.designId) classes.push(`font-${this.designId.toLowerCase()}`);

      return classes;
    },
    // card
    cardClasses() {
      let classes = [];

      if (this.isShowCard || this.editable) classes.push('is-show');

      return classes;
    },
    isCardEmpty() {
      return !this.value.mainMessage || !this.value.creatorName;
    },
    showCardValue() {
      return this.isCardEmpty ? this.sampleValue : this.value;
    },
    // card modal
    isCardFormEmpty() {
      return !this.form.mainMessage || !this.form.creatorName;
    },
    mainMessageSubLabel() {
      return `${this.form.mainMessage.length}/400`;
    },
    // media modal
    mediaModalLabel() {
      return this.mediaFormType === 'image' ? '写真' : '動画'
    },
    isMediaFormEmpty() {
      return !this.mediaForm.file;
    },
    // add media
    disabledAddImage() {
      const images = this.value.contents.filter(content => content.mimeType.startsWith('image'));
      return images.length >= 5;
    },
    disabledAddVideo() {
      const videos = this.value.contents.filter(content => content.mimeType.startsWith('video'));
      return videos.length >= 1;
    },
    // gift
    giftLinkClasses() {
      let classes = [];
      if (this.disabledGiftLink) classes.push('is-disabled');
      return classes;
    },
    isEdit() {
      if (this.$route.name == 'PMG320') {
        return true;
      }
      return false;
    }
  },
  watch: {
    scrollParent: {
      handler(n) {
        if (n) {
          n.addEventListener('scroll', this.checkScrollTop);
          this.checkScrollTop();
        }
      },
      immediate: true,
    },
    scrollTop(newVal, oldVal) {
      if (this.isShowCard) return;
      const scrollTop = newVal || window.scrollY;
      this.isShowCard = scrollTop >= 200;
    },
  },
  created () {
    this.setStyle();
  },
  mounted () {
    this.resizeObserver = new ResizeObserver(() => {
      this.setStyle();
    });
    this.resizeObserver.observe(document.body);
    window.addEventListener('orientationchange', this.setStyle);
  },
  beforeUnmount () {
    this.scrollParent.removeEventListener('scroll', this.checkScrollTop);
    this.resizeObserver.disconnect();
    window.removeEventListener('orientationchange', this.setStyle);
  },
  methods: {
    setStyle() {
      this.style = {
        container: {
          backgroundPosition: `center top, center top ${window.innerHeight}px`,
          backgroundSize: `100% ${window.innerHeight}px, 768px auto`,
        },
        firstView: {
          height: `${window.innerHeight}px`,
        },
      }
    },
    checkScrollTop() {
      if (this.isShowCard) return;

      const scrollTop = this.scrollParent.scrollTop || window.scrollY;
      this.isShowCard = scrollTop >= 200;
    },
    replaceWrap(str) {
      return str ? str.replaceAll('\\n', '\n') : str;
    },
    formatCardDate(date) {
      return date
        ? DateTime.fromSQL(date).toFormat('yyyy.M.d')
        : '';
    },
    mediaTapeClasses(index) {
      if (this.value.contents[index].mimeType.startsWith('image')) {
        let count = 0;
        for (let i = 0; i <= index; i++) {
          if (this.value.contents[i].mimeType.startsWith('image')) {
            ++count;
          }
        }

        return [`tape-${count}`];
      } else {
        return [];
      }
    },
    createMessage() {
      if (this.disabledGiftLink) return false;
      this.$emit('click:giftLink');
    },
    // card
    openCardModal() {
      this.form = { ...this.value };
      this.$refs.cardModal.openModal();
    },
    saveCard() {
      this.value = { ...this.form };
    },
    // media
    onLoadeddata(e) {
      e.target.pause();
    },
    openConfirmModal(index) {
      this.removeMediaIndex = index;
      this.$refs.confirm.openModal();
    },
    removeMedia() {
      let order = 0;
      this.value.contents = this.value.contents.reduce((acc, content, i) => {
        if (i !== this.removeMediaIndex) {
          order++;
          acc.push({ ...content, order});
        }

        return acc;
      }, []);
    },
    openMediaModal(index) {
      this.mediaModalType = 'update';
      this.mediaForm = { ...this.value.contents[index] };
      this.mediaFormType = this.mediaForm.mimeType.split('/')[0];
      this.mediaFormIndex = index;
      this.$refs.mediaModal.openModal();
    },
    saveMedia() {
      const file = this.mediaForm.file;
      const contents = {
        file: file,
        objectUrl: URL.createObjectURL(file),
        mimeType: file.type,
        caption: this.mediaForm.caption,
      };

      if (this.mediaModalType === 'update') {
        this.value.contents[this.mediaFormIndex] = {
          ...contents,
          order: this.mediaFormIndex + 1,
        };
      } else if (this.mediaModalType === 'add') {
        this.value.contents.push({
          ...contents,
          order: this.value.contents.length + 1,
        });
      }
    },
    // add media
    openAddMediaModal(type) {
      this.mediaModalType = 'add';
      this.mediaFormType = type;
      this.mediaForm = {
        file: null,
        caption: '',
      };
      this.$refs.mediaModal.openModal();
    },
    goPlame() {
      if (!this.isLinkEnabled) {
        return;
      }
      const url = 'about';
      const title = "プラメギフトとは";
      const virtualPageUrl = getUrl(`/gifts/static/${url}&${title}`);
      gtmDataLayerPush(virtualPageUrl, title);
      this.$router.push({
        name: "PMG640",
        params: {
          url: url,
          title: title,
        },
      });
    },
  }
}
</script>

<style scoped lang="scss">
.base-template {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 300px;
  background: $white1;
  overflow: hidden;

  &.font {
    &-dsi0101,
    &-dsi0301,
    &-dsi0401 {
      .card,
      .media {
        font: $font-fot-tsukuardgothic-std-700;
      }
    }

    &-dsi0102 {
      .card,
      .media {
        font: $font-sawarabi-mincho-500;
      }
    }

    &-dsi0103 {
      .card,
      .media {
        font: $font-fot-chiaro-std-700;
      }
    }

    &-dsi0201,
    &-dsi0302 {
      .card,
      .media {
        font: $font-dnp-shuei-nmincho-std-400;
      }
    }

    &-dsi0202 {
      .card,
      .media {
        font: $font-fot-tsukubrdgothic-std-700;
      }
    }

    &-dsi0203 {
      .card {
        font: $font-source-han-serif-japanese-700;

        &__text {
          font: $font-source-han-serif-japanese-400;
        }
      }

      .media {
        font: $font-source-han-sans-japanese-400;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(#f6e0e2, #f6e0e2), url("../../assets/images/BaseTemplate/confetti.svg");
    background-repeat: no-repeat, no-repeat;

    .first-view-content {
      width: 100%;
      max-width: 768px;
    }

    .message-content {
      position: relative;
      width: 100%;
      max-width: 375px;
      padding: 0 20px;

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -52px 5px 0;
        padding: 76px 40px 62px;
        background-color: $white1;
        background-image: url("../../assets/images/BaseTemplate/card_top.svg"), url("../../assets/images/BaseTemplate/card_bottom.svg");
        background-position: center top 23px, center bottom 23px;
        background-size: 166px auto, 138px auto;
        background-repeat: no-repeat, no-repeat;
        border-radius: 10px;
        box-shadow: 2px 2px 8px $shadow1;
        transition: transform 1000ms, opacity 1000ms;
        transform: translateY(20px) rotateZ(0deg);
        opacity: 0;

        &.is-show {
          transform: translateY(0) rotateZ(2deg);
          opacity: 1;
        }

        +.media {
          margin-top: 80px;
        }

        &__to {
          margin: -7px 0 26px;
          word-break: break-all;
          color: $black2;

          @include font-size(20, 34);
        }

        &__text {
          margin: -2px 0 28px;
          word-break: break-all;
          white-space: pre-wrap;
          color: $black2;

          @include font-size(16, 20);
        }

        &__date {
          margin: -5px 0;
          color: $black2;

          @include font-size(14, 24);
        }

        &__from {
          margin: 0 0 -7px;
          word-break: break-all;
          color: $black2;

          @include font-size(20, 34);
        }

        &__editable-button {
          position: absolute;
          top: -61px;
          right: 10px;
          z-index: 1;
        }

        &__editable-empty {
          position: absolute;
          top: 66px;
          bottom: 52px;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background: rgba($white1, 0.85);

          .button {
            transform: rotateZ(-2deg);
          }
        }
      }

      .media {
        margin-top: 100px;

        &__content {
          position: relative;
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: auto;
          padding: 10px;
          background-color: $white1;
          box-shadow: 0 0 10px $shadow1;

          $tape-colors: "red", "blue", "green", "yellow", "purple";

          @for $i from 1 through length($tape-colors) {
            $tape-color: nth($tape-colors, $i);
            &.tape-#{$i} {
              position: relative;

              &::before {
                content: url("../../assets/images/BaseTemplate/tape_#{$tape-color}.png");
                position: absolute;
                width: 170px;

                @if $i == 1 or $i == 4 {
                  top: -9.5px;
                  right: -40px;
                  transform: rotateZ(20deg);
                } @else if $i == 2 or $i == 5 {
                  top: -16px;
                  left: -35px;
                  transform: rotateZ(-20deg);
                } @else if $i == 3 {
                  top: -26px;
                  left: 50%;
                  transform: translateX(-50%) rotateZ(5deg);
                }
              }
            }
          }

          .image,
          .video {
            width: 100%;
            vertical-align: middle;
          }

          .remove {
            position: absolute;
            top: -17px;
            right: 3px;
            width: 32px;
            height: 32px;
            background: $black1;
            border-radius: 50%;
            filter: drop-shadow(0 2px 4px $shadow2) drop-shadow(0 4px 5px $shadow3) drop-shadow(0 1px 10px $shadow4);
            z-index: 1;

            &::before {
              content: url("../../assets/images/icon/close_fff.svg");
              width: 8px;
              height: 8px;
            }
          }

          .editable {
            position: absolute;
            bottom: -6px;
            right: -3px;
          }
        }

        &__text {
          width: 100%;
          margin: 18px 0 -2px;
          overflow: hidden;
          color: $black2;
          text-align: center;
          word-break: break-all;
          white-space: pre-wrap;

          @include font-size(16, 20);
        }
      }

      .add-media {
        width: 100%;
        padding: 26px 13px 38px;
        margin: 72px 0 70px;
        background: $gray5;
        border: solid 10px $white1;
        box-shadow: 0 0 10px $shadow1;
        text-align: center;

        &__text {
          font: $font-roboto-700;
          color: $black1;
          letter-spacing: 1.25px;

          @include font-size(12, 18);
        }

        &__buttons {
          display: flex;
          justify-content: center;
          margin: 18px 0 24px;
        }

        &__sub-text {
          font: $font-roboto-500;
          color: var(--ion-color-medium);
          letter-spacing: 1.25px;

          @include font-size(10, 16);
        }
      }

      .add-media {
        &__buttons {
          .button {
            &:last-child {
              margin-left: 24px;
            }
          }
        }
      }

      .gift {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 70px 0 30px;
        font: $font-fot-tsukuardgothic-std-700;

        &__header {
          margin-bottom: 18px;
          color: $gray1;
          text-align: center;

          @include font-size(20, 26);
        }

        &__text {
          margin-bottom: 21px;
          color: $gray1;
          text-align: center;

          @include font-size(13, 17);
        }

        &__image {
          width: 151.4px;
          margin-bottom: 11px;
        }

        &__link {
          margin-bottom: 3px;
          cursor: pointer;
          color: $blue1;
          text-decoration: underline;

          @include font-size(14, 22);

          &:hover {
            text-decoration: none;
          }

          &.is-disabled {
            cursor: not-allowed;
            color: $gray9;
          }
        }

        &__sub-text {
          color: $gray1;
          text-align: center;

          @include font-size(12, 18);
        }

        &__plame {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 8px 16px;
          margin: 8px 0;
          border: 1px solid var(--ion-color-secondary-tint);
          border-radius: 50px;
          .icon {
            width: 20px;
            height: 20px;
          }
          .label {
            color: var(--ion-color-primary);
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 45px;
      background-color: $blue2;
      color: $white1;
      font: $font-noto-sans-jp-500;

      @include font-size(12, 22);
    }
  }

  .form-modal {
    z-index: 100;
  }
}
</style>
