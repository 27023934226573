<template>
  <div class="dsi0203">
    <FirstViewBase
      :message="message"
      :date="date"
      :animation-data="animationData"
      :animation-image="animationImage"
      :background-images="backgroundImages"
      :disable-animation="disableAnimation"
    />
  </div>
</template>

<script>
import FirstViewBase from '@/components/messageCard/FirstViewBase'

import animationData from '@/assets/lottie/DSI0203/animationData.json'
import animationImage from '@/assets/images/FirstView/DSI0203/animation.svg'
import bg01 from '@/assets/images/FirstView/DSI0203/bg01.jpg'
import bg02 from '@/assets/images/FirstView/DSI0203/bg02.jpg'
import bg03 from '@/assets/images/FirstView/DSI0203/bg03.jpg'
import bg04 from '@/assets/images/FirstView/DSI0203/bg04.jpg'

export default {
  name: 'DSI0203',
  components: {
    FirstViewBase,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    animationData,
    animationImage,
    backgroundImages: [
      bg01,
      bg02,
      bg03,
      bg04,
    ],
  }),
}
</script>

<style scoped lang="scss">
.dsi0203 {
  width: 100%;
  height: 100%;

  :deep(.first-view-base) {
    .lottie {
      top: -4%;
      width: 150%;
      min-width: 150%;

      &__text {
        top: 55.5%;
        bottom: 26.5%;
        width: 45%;
        color: $white1;
        font: $font-source-han-sans-japanese-400;
      }
    }
  }
}
</style>
