<template>
  <input v-model="value" class="form-input" :type="type" :placeholder="placeholder" :maxlength="maxlength"
         :class="{ 'disabled-input': disabled, 'error-input': hasError }"
         :disabled="disabled"
         @input="clearError" />
  <span class="input-unit">P</span>

</template>

<script>
export default {
  name: 'PointFormInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    maxlength: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'clearError'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
  },
  data() {
    return {
      // inputValue: null,
    }
  },
  methods: {
    clearError() {
      this.$emit('clearError');
    },
  },
}
</script>

<style scoped lang="scss">
.form-input {
  width: 100%;
  height: 42px;
  padding: 8px 28px 8px 12px;
  background: $white1;
  border: solid 1px $gray2;
  border-radius: 6px;
  font: $font-inter-400;
  color: $black1;
  outline: none;
  float: right;

  @include font-size(16, 20);

  &:focus {
    border-color: $gold1;
  }

  &::placeholder {
    color: $gray3;
  }
}

.disabled-input {
  background: $modelBackground; /* グレーの背景色 */
}

.error-input {
  border-color: $red2;
}

.input-unit {
  position: absolute;
  right: 11.5px;
  top: 11.5px;
  color: #666666;
}
</style>
