import { ALLOW_NOINDEX_STATIC_PAGES } from '@/constants/meta'

/**
 * コンテンツ（オーバースクロールを除外）をスクロールしたかどうか
 * @param target HTML DOM
 * @returns {boolean}
 */
export const isScrollContents = target => {
  const scrollTop = target.scrollTop;
  const scrollBottom = target.scrollTop + target.clientHeight;
  return scrollTop >= 0 && target.scrollHeight >= scrollBottom;
}


/*
  * エンドポイントURLを生成する
  * @param relativePath エンドポイントの相対パス
  * @returns {string} エンドポイントURL
 */
export const createEndpointUrl = (relativePath) => {
  if (!relativePath) {
    return '';
  }
  const FRONT_HOST = process.env.VUE_APP_BASE_APP_URL;
  // エンドポイントと相対パスを結合してURLを生成
  return `${FRONT_HOST}/${relativePath}`;
}

/*
  * NOINDEXを許可する静的ページかどうかを判定する
  * @param path パス
  * @returns {boolean} NOINDEXを許可する静的ページかどうか
 */
export const shouldNoIndexPage = (path) => {
  return ALLOW_NOINDEX_STATIC_PAGES.some(allowedPath => path.includes(allowedPath));
}

/*
  * メタデータを設定する
 */
export const setMetaData = (to) => {
  // もしmeta.noIndex = Trueならnoindexのmeta要素をheadに追加する。
  const existingNoindexMeta = document.querySelector('meta[name="robots"][content="noindex"]');
  if (existingNoindexMeta) {
    existingNoindexMeta.remove();
  }

  if (to.meta.noindex && to.meta.is_static_page) {
    // 静的ページは許可されているページのみnoindexを設定する
    if (shouldNoIndexPage(to.path) === true) {
      const noindexMeta = document.createElement('meta');
      noindexMeta.name = 'robots'
      noindexMeta.content = 'noindex'

      document.head.appendChild(noindexMeta);
    }
  } else if (to.meta.noindex && !to.meta.is_static_page) {
    const noindexMeta = document.createElement('meta');
    noindexMeta.name = 'robots'
    noindexMeta.content = 'noindex'

    document.head.appendChild(noindexMeta);
  }
}

export const queryStringDelete = (query) => {
  // クエリストリングを削除した形に変更
  if (query && typeof query === 'string') {
    const parts = query.split('&');
    return decodeURIComponent(parts[0]);
  }
  return ""
}
