<template>
  <ion-alert
    class="ionic-alert"
    :is-open="isOpen"
    :header="header"
    :message="message"
    :buttons="buttons"
    @didDismiss="didDismiss($event)"
  />
</template>

<script>
import { IonAlert } from '@ionic/vue';

export default {
  name: 'IonicAlert',
  props: {
    header: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  components: { IonAlert },
  emits: ['click:didDismiss'],
  data: () => ({
    isOpen: false,
  }),
  methods: {
    openAlert() {
      this.isOpen = true;
    },
    hideAlert() {
      this.isOpen = false;
    },
    didDismiss(e) {
      this.hideAlert();
      this.$emit('click:didDismiss', e);
      this.$emit('click:didDismissDom',e,this.$el);
    },
  },
};
</script>

<style lang="scss">
ion-alert {
  &.ionic-alert {
    --width: 249px;
    .alert-title {
      &.sc-ion-alert-ios {
        font: $font-roboto-700;
        @include font-size(16, 22.4);
      }
    }

    .alert-message {
      &.sc-ion-alert-ios {
        font: $font-noto-sans-jp-400;
        @include font-size(12, 19.2);
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    button {
      &.alert-button {
        font: $font-noto-sans-jp-400;
        @include font-size(16, 20.8);
      }
    }
  }
}
</style>
