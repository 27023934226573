<template>
  <Layout ref="layout" title="" class="pmg420" :default-hide-header="true">
    <ion-content class="ion-padding">
      <div class="header">
        <img
          v-show="!isLoading && giftDataLoaded"
          class="header__image"
          ref="giftAnime"
          src="@/assets/images/gift/gift_anime.gif"
        />
        <span class="header__text">ご購入ありがとうございました。</span>
        <span class="header__text">贈り先に受け取り用URLをお知らせください。</span>
      </div>
      <div class="purchase-info">
        <div class="purchase-info__header">
          <div class="purchase-info__header__text">
            購入日 : {{ purchaseDatetimeDisplay }}
          </div>
          <div class="purchase-info__header__text">
            注文番号 : {{ dispOrderNumber }}
          </div>
        </div>

        <div class="purchase-info__gift">
          <div class="purchase-info__gift__header">
            <span class="title">購入したギフト</span>
          </div>
          <div class="purchase-info__gift__content">
            <GiftInfo
              :gift-name="giftData.gift_name"
              :brand-name="giftData.brand_name"
              :expiry-date="giftData.expiry_date"
              :price="giftData.gift_price"
              :gift-image="giftImage"
              :is-show-price="true"
            ></GiftInfo>
          </div>
        </div>

        <div class="purchase-info__card" v-if="!cardData.is_for_myself">
          <div class="purchase-info__card__header">
            <span class="title">一緒に贈るカード</span>
          </div>
          <div class="purchase-info__card__content">
            <CardViewMini
              :category-id="cardData.category_id"
              :main-receiver-name="cardData.main_receiver_name"
              :op-message="cardData.op_message"
              :design-name="cardData.category_name"
              :thumbnail-url="cardData.thumbnail_url"
              :is-show-card-replace="false"
            ></CardViewMini>
          </div>
        </div>

        <PointHistoryDetailBox
          :used-points="pointData.used_points"
          :is-only-points="pointData.is_only_points"
          :price="pointData.payment_price"
        />

        <ReceivingURL :card-url="giftCardUrl" :gift-url="giftData.gift_url" :is-for-myself="cardData.is_for_myself"/>

      </div>
      <div class="history">
        <span class="history__jump" @click="openPurchaseHistories()"
        >購入履歴へ</span
        >
      </div>

      <PlusMessageGuide
        class="guide"
        sendTrClickEvent="true"
        GALabel1="決済完了"
      ></PlusMessageGuide>
    </ion-content>
    <Loading :is-show="isLoading || !giftDataLoaded"/>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import Loading from "@/components/loading/Loading";
import GiftInfo from "@/components/gift/GiftInfo";
import CardViewMini from "@/components/messageCard/CardViewMini";
import PlusMessageGuide from "@/components/etc/PlusMessageGuide";
import ReceivingURL from "@/components/etc/ReceivingURL";
import {mapGetters} from "vuex";
import {IonContent} from "@ionic/vue";
import {getPaymentCompletionInfo, updateCart} from "@/plugins/ApiHelper";
import _ from "lodash";
import {DateTime} from "luxon";
import {getItemImageUrl} from "@/plugins/GiftHelper";
import {GtmEcommerce, GtmEcommerceItem} from '@/plugins/GtmEcommerce';
import PointHistoryDetailBox from "@/components/etc/PointHistoryDetailBox";

export default {
  name: "PMG420",
  components: {
    Layout,
    IonContent,
    Loading,
    GiftInfo,
    CardViewMini,
    PlusMessageGuide,
    ReceivingURL,
    PointHistoryDetailBox,
  },
  props: {},
  data: () => ({
    purchasesInfoId: null,
    giftData: {
      gift_id: null,
      purchase_datetime: null,
      payment_info_no: null,
      payment_price: null,
      gift_url: null,
      gift_name: null,
      expiry_date: null,
      brand_name: null,
      gift_image_url: null,
      is_tax_display: null,
      gift_price: null,
      category_ids: null,
      order_number: null
    },
    cardData: {
      op_message: null,
      main_receiver_name: null,
      category_id: null,
      design_name: null,
      thumbnail_url: null,
      card_url: null,
      is_for_myself: false,
    },
    pointData: {
      used_points: null,
      is_only_points: null,
      payment_price: null,
    },
    giftDataLoaded: false,
  }),
  computed: {
    ...mapGetters(["isLoading", "lid", "isAuIdAuthorization"]),
    purchaseDatetimeDisplay() {
      if (!this.giftData.purchase_datetime) {
        return "";
      }
      const day = DateTime.fromFormat(
        this.giftData.purchase_datetime,
        "yyyy-LL-dd TT"
      ).toFormat("yyyy/LL/dd");
      return day;
    },
    priceDisplay() {
      if (!this.giftData.payment_price) {
        return 0;
      }
      return Number(this.giftData.payment_price).toLocaleString();
    },
    dispOrderNumber() {
      if(!this.giftData.order_number) {
        return ""
      }

      return this.giftData.order_number;
    },
    giftImage() {
      if (!this.giftData.gift_image_url) {
        return "";
      }
      return getItemImageUrl(this.giftData.gift_image_url);
    },
    giftCardUrl() {
      if (!this.cardData || !this.cardData.card_url) {
        return null;
      }
      const u = this.cardData.card_url;
      const idx = u.lastIndexOf("card-origin-id=");
      const url = process.env.VUE_APP_BASE_APP_URL + "/card-display.html?displayMode=external&" + u.substring(idx);
      return url;
    },
  },
  watch: {},
  mounted() {
    this.purchasesInfoId = Number(this.$route.params.purchasesInfoId);

    this.getCardGiftInfo().then(() => {
      this.giftDataLoaded = true;
      this.repleyAnimation();
      this.ecommercePurchasePush()
    }).catch((e) => {
      console.error(e)
      this.$router.push({name: "PMG510"});
    });
  },
  methods: {
    async getCardGiftInfo() {
      if (_.isNaN(this.purchasesInfoId) || _.isNil(this.purchasesInfoId)) {
        // 不正な遷移
        this.$refs.layout.gtmPush('ギフトを購入', '/gifts');
        this.$router.push({name: "PMG600"});
        return;
      }

      if (!this.isAuIdAuthorization) {
        // 未ログイン
        this.$refs.layout.gtmPush('ギフトを購入', '/gifts');
        this.$router.push({name: "PMG600"});
        return;
      }

      const data = {
        purchases_info_id: this.purchasesInfoId,
        lid: this.lid,
      };
      return new Promise((resolve, reject) => {
        getPaymentCompletionInfo(data)
          .then((res) => {
            this.giftData = res.gift_data;
            this.cardData = res.card_data;
            this.pointData = res.point_data;
          })
          .then(() => {
            this.updateCartData();
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    repleyAnimation() {
      const srcEnd = ".gif";

      let src = this.$refs.giftAnime.src;
      let index = src.lastIndexOf(srcEnd + "?");
      if (index > 0) {
        src = src.slice(0, srcEnd.length + index);
      }
      this.$refs.giftAnime.src = src + "?" + (new Date).getTime();
    },

    openPurchaseHistories() {
      this.$refs.layout.gtmPush('購入履歴', '/purchase-histories');
      this.$router.push({name: "PMG800"});
    },
    updateCartData() {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      const data = {
        lid: this.lid,
        giftId: null,
        cardOriginId: null,
        updateMode: 0,
      };

      updateCart(data).catch(() => {
        this.$router.push({name: "PMG510"});
      });
    },
    // GA(eコマース) event: purchase
    ecommercePurchasePush() {
      const items = []
      const data = {
        "giftId": this.giftData.gift_id,
        "giftName": this.giftData.gift_name,
        "brandName": this.giftData.brand_name,
        "price": this.giftData.payment_price,
        "categoryIds": this.giftData.category_ids
      }
      const is_purchase = true
      const item = GtmEcommerceItem.getEcommerceItem(data, is_purchase)
      items.push(item)
      GtmEcommerce.purchase(parseInt(this.giftData.payment_price), this.giftData.payment_info_no, items)
    }
  },
};
</script>
<style scoped lang="scss">
ion-content {
  --background: #{$modelBackground};
}

.pmg420 {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 3rem 0;

    &__image {
      width: 90px;
    }

    &__text {
      font: $font-roboto-700;
      @include font-size(16, 22.4);
    }
  }

  .purchase-info {
    .button {
      width: 311px;
    }

    &__header {
      display: flex;
      justify-content: space-around;
      margin-bottom: 1rem;

      &__text {
        font: $font-noto-sans-jp-400;
        @include font-size(12, 19.2);
        color: $gray10;
      }
    }

    &__gift,
    &__card {
      display: flex;
      flex-direction: column;
      border-radius: 0.8rem;
      background-color: $white1;
      margin-bottom: 1rem;
      margin-right: 0px !important;
      margin-left: 0px !important;

      &__header {
        .title {
          font: $font-noto-sans-jp-400;
          @include font-size(14, 22.4);
          color: $gray10;
        }
      }
    }

    &__card,
    &__gift {
      &__header {
        padding: 1rem 1rem 0 1rem;
      }

      &__content {
        padding: 1rem;
      }

      &__footer {
        padding: 1rem;
        border-top: solid 1px $gray11;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          color: var(--ion-color-dark);
          font: $font-noto-sans-jp-400;
          @include font-size(14, 22.4);
        }

        .price {
          color: $black6;
          font: $font-roboto-700;
          @include font-size(22, 28.6);
        }
      }
    }
  }

  .history {
    display: flex;
    justify-content: center;
    margin: 3rem 0;

    &__jump {
      font: $font-roboto-400;
      @include font-size(16, 20.8);
      color: $gold2;
    }
  }

  .ponta-present {
    max-width: 343px;
    margin: 0 auto 1.25rem;
  }

  .guide {
    margin-bottom: 3rem;
  }
}
</style>
