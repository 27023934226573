
export const gtmDataLayerPush = (virtualPageUrl, virtualPageTitle, event) => {
    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
        dataLayer.push({
            event: event || 'SPA_pageview',
            virtualPageUrl: virtualPageUrl || window.location.href,
            virtualPageTitle: virtualPageTitle || 'egift',
        });
    }
}

export const gtmSendClick = (dom, label1, label2, label3) => {
    try {
        trEventClick(dom, label1, label2, label3);
    } catch (error) {
        console.error(error);
    }
}

export const gtmEcommerceDataLayerPush = (data) => {
    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
        dataLayer.push(data);
    }
}