<template>
  <Layout
    ref="layout"
    title="カードを編集"
    class="pmg320"
    :showMenu="false"
    :scrollEvents="true"
    :scroll-to-hide-header="true"
    :default-hide-header="true"
    :fixedHeader="true"
  >
    <template #headerStart>
      <ion-button
        id="pmg320-header-cancel"
        fill="clear"
        shape="round"
        size="small"
        class="pmg320__header__start-button"
        @click="openConfirmAlert"
      >
        中止
      </ion-button>
    </template>

    <template #headerEnd>
      <ion-button
        fill="solid"
        shape="round"
        size="small"
        class="pmg320__header__end-button"
        :disabled="disabledCompleted"
        @click="openConfirmModal"
      >
        作成完了
      </ion-button>
    </template>

    <div class="pmg320__card-edit">
      <BaseTemplate v-model="value" :design-id="designId" :editable="true" :sample-value="sample">
        <FirstView v-model="value" :design-id="designId" :editable="true" :sample-value="sample" />
      </BaseTemplate>
    </div>

    <ConfirmCreateCardModal ref="confirmCreateCardModal" @click:completed="clickCompleted" />

    <IonicAlert
      ref="confirmAlert"
      header="作成を中止しますか？"
      message="これまで作成途中の内容は削除され、保存されません。中止すると、カードタイプの選択に戻ります。よろしいですか？"
      :buttons="confirmAlertButtons"
      @click:didDismiss="confirmResult($event)"
      @click:didDismissDom="dissmissGA"
    />

    <IonicAlert
      ref="createFailedAlert"
      message="入力いただいた値に誤りがありました。編集画面より入力内容を編集してください。"
      :buttons="createFailedAlertButtons"
    />

    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import BaseTemplate from '@/components/messageCard/BaseTemplate';
import FirstView from '@/components/messageCard/FirstView';
import Button from '@/components/button/Button';
import Loading from '@/components/loading/Loading';
import ConfirmCreateCardModal from '@/components/modal/Gift/ConfirmCreateCardModal';
import IonicAlert from '@/components/ionic/IonicAlert';
import store from '@/store';
import { IonButton } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { updateCart } from "@/plugins/ApiHelper";
import { gtmSendClick } from "@/plugins/GtmHelper";

import {
  designSampleApi,
  createCardApi,
  uploadPhotoApi,
  uploadVideoApi,
  upload,
} from '@/plugins/ApiHelper';

export default {
  name: 'PMG320',
  components: {
    Layout,
    BaseTemplate,
    FirstView,
    Button,
    Loading,
    ConfirmCreateCardModal,
    IonicAlert,
    IonButton,
  },
  data: () => ({
    designId: null,
    sample: {},
    value: {
      openingMessage: '',
      openingDate: '',
      mainMessage: '',
      creatorName: '',
      receiverName: '',
      contents: [],
      gift: null,
    },
    confirmAlertButtons: [
      { text: '中止しない', role: 'notCancel' },
      { text: '作成を中止', role: 'cancel', cssClass: 'cancel' },
    ],
    createFailedAlertButtons: [{ text: 'OK', role: 'ok' }],
  }),
  computed: {
    ...mapGetters(['isLoading', 'getCartItems', 'isAuIdAuthorization', 'lid', 'getCardOriginId']),
    disabledCompleted() {
      return !this.value.openingMessage || !this.value.mainMessage || !this.value.creatorName;
    },
  },
  created() {
    this.clearInput();
    this.designId = this.$route.params.designId;
    this.fetchDesignSample(this.designId);
    this.getGift();
  },
  methods: {
    fetchDesignSample(designId) {
      const data = {
        'design-id': designId,
      };
      designSampleApi(data)
        .then((res) => {
          this.sample = {
            ...res,
            designId: this.designId,
          };
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    openConfirmAlert() {
      this.$refs.confirmAlert.openAlert();
    },
    confirmResult(e) {
      if (e.detail.role === 'cancel') {
        // 買い物カゴに遷移
        this.clearInput();
        this.$refs.layout.gtmPush('お買い物カゴ', '/cart');
        this.$router.push({ name: 'PMG700' });
      }
    },
    dissmissGA(e,dom){
      gtmSendClick(dom,'カード作成中止確認アラート',e.detail.role === 'cancel' ? '作成を中止' : '中止しない','');
    },
    openCreateFailedAlert() {
      this.$refs.createFailedAlert.openAlert();
    },
    openConfirmModal() {
      this.$refs.confirmCreateCardModal.openModal();
    },
    getGift() {
      const cartItems = this.getCartItems;
      if (cartItems && cartItems.length > 0) {
        this.value.gift = cartItems[0];
      }
    },
    async clickCompleted() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
      }

      this.getGift();
      this.createCard().then(async (res) => {
        if (this.value.gift && this.value.gift.giftId) {
          store.commit('setCardOriginId', res.cardOriginId);
          store.commit('setGiftId', this.value.gift.giftId);
          await this.updateCartData();
          this.clearInput();
          this.$refs.layout.gtmPush('お買い物カゴ', '/cart');
          await this.$router.push({ name: 'PMG700' });
        }
      });
    },
    async updateCartData() {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }
      const data = {
        lid: this.lid,
        cardOriginId: this.getCardOriginId,
        updateMode: 2,
      };
      await updateCart(data).catch((err) => {
        console.error(err);
        if (err.response.data && err.response?.data?.card_origin_id_already_used) {
          //カードが既に決済済みの決済情報と紐づいた場合
          this.$router.push({name: "PMG512"});
        } 
        else{
          this.$router.push({name: "PMG510"});
        }
      });
    },
    async createCard() {
      let form = {
        ...this.value,
        designId: this.designId,
        contents: [],
      };

      try {
        if (this.value.contents.length > 0) {
          let putContentsPromise = [];

          const images = this.value.contents.filter((content) =>
            content.mimeType.startsWith('image')
          );
          if (images.length > 0) {
            const uploadImages = await this.fetchUploadPhoto(images);
            images.forEach((image, i) => {
              putContentsPromise.push(upload(image.file, uploadImages[i].url));
              form.contents.push({
                objectName: uploadImages[i].objectName,
                mimeType: image.mimeType,
                contentLength: image.file.size,
                caption: image.caption,
                order: image.order,
              });
            });
          }

          const videos = this.value.contents.filter((content) =>
            content.mimeType.startsWith('video')
          );
          if (videos.length > 0) {
            const uploadVideos = await this.fetchUploadVideo(videos);
            videos.forEach((video, i) => {
              putContentsPromise.push(upload(video.file, uploadVideos[i].url));
              form.contents.push({
                objectName: uploadVideos[i].objectName,
                mimeType: video.mimeType,
                contentLength: video.file.size,
                caption: video.caption,
                order: video.order,
              });
            });
          }

          await Promise.all(putContentsPromise);
        }

        const data = await this.fetchCreateCard(form);
        return Promise.resolve(data);
      } catch {
        this.openCreateFailedAlert();
        return Promise.reject();
      }
    },
    fetchUploadPhoto(images) {
      return new Promise((resolve, reject) => {
        const data = images.map((image) => ({
          fileName: image.file.name,
          mimeType: image.mimeType,
          contentLength: image.file.size,
        }));
        uploadPhotoApi(data)
          .then((res) => {
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchUploadVideo(videos) {
      return new Promise((resolve, reject) => {
        const data = videos.map((video) => ({
          fileName: video.file.name,
          mimeType: video.mimeType,
          contentLength: video.file.size,
        }));
        uploadVideoApi(data)
          .then((res) => {
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    fetchCreateCard(form) {
      return new Promise((resolve, reject) => {
        createCardApi(form)
          .then((res) => {
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    clearInput() {
      this.value = {
        openingMessage: '',
        openingDate: '',
        mainMessage: '',
        creatorName: '',
        receiverName: '',
        contents: [],
        gift: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.pmg320 {
  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    &__start-button {
      font: $font-roboto-400;
      @include font-size(14, 18.2);
    }

    &__end-button {
      font: $font-roboto-400;
      @include font-size(14, 18.2);
    }
  }

  ion-button {
    --padding-top: 5px;
    --padding-bottom: 5px;
    --padding-start: 12px;
    --padding-end: 12px;
  }
}
</style>

<style lang="scss">
button {
  &.alert-button {
    &.cancel {
      color: var(--ion-color-tertiary);
    }
  }
}
</style>
