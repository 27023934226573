<template>
  <Layout ref="layout" title="" class="au-pay" :default-hide-header="true">
    <div class="au-pay__content">
      <div class="pay__content__message">
        {{ message }}
      </div>
      <div class="au-pay__content__detail" v-show="processStatus === 1">
        <div class="au-pay__content__detail__title">au IDログインの処理に失敗しました</div>
        <div class="au-pay__content__detail__text">ギフト一覧からお手続きをやり直してください。</div>
        <div class="au-pay__content__detail__buttons">
          <Button label="ギフト一覧へ" @click="showGiftList()"></Button>
        </div>
      </div>
      <div class="au-pay__content__detail" v-show="processStatus === 2 || processStatus === 3 || processStatus === -1">
        <div class="au-pay__content__detail__title">auかんたん決済の処理に失敗しました</div>
        <div class="au-pay__content__detail__text">買い物カゴからお手続きをやり直してください。</div>
        <div class="au-pay__content__detail__buttons">
          <Button label="買い物カゴへ" @click="goCart()"></Button>
        </div>
      </div>
      <div class="au-pay__content__detail giftee-error" v-show="processStatus === 4">
        <div class="au-pay__content__detail__title">ギフトの購入手続きに失敗しました</div>
        <div class="au-pay__content__detail__text">ギフトの販売期間が終了しているか、システムエラーのため購入できませんでした。</div>
        <div class="au-pay__content__detail__target-gift">対象ギフト：{{ giftName }}</div>
        <div class="au-pay__content__detail__text">買い物カゴを空にして商品を選び直してください。</div>
        <div class="au-pay__content__detail__buttons">
          <Button label="買い物カゴへ" @click="goCart()"></Button>
        </div>
      </div>
      <div class="au-pay__content__detail giftee-error" v-show="processStatus === 5">
        <div class="au-pay__content__detail__title">ギフトの購入手続きに失敗しました</div>
        <div class="au-pay__content__detail__text">ギフトの販売期間が終了しているか、システムエラーのため購入できませんでした。</div>
        <div class="au-pay__content__detail__target-gift">対象ギフト：{{ giftName }}</div>
        <div class="au-pay__content__detail__text">買い物カゴを空にして商品を選び直してください。</div>
        <div class="au-pay__content__detail__buttons">
          <Button label="買い物カゴへ" @click="goCart()"></Button>
        </div>
      </div>

      <div class="au-pay__content__detail" v-show="processStatus === 9">
        <div class="au-pay__content__detail__title">ポイント利用の処理に失敗しました</div>
        <div class="au-pay__content__detail__text">買い物カゴからお手続きをやり直してください。</div>
        <div class="au-pay__content__detail__buttons">
          <Button label="買い物カゴへ" @click="goCart()"></Button>
        </div>
      </div>

      <div class="au-pay__content__detail status-0" v-show="processStatus === 98">
        <div class="au-pay__content__detail__title">auかんたん決済の処理に失敗しました</div>
        <div class="au-pay__content__detail__text-left">
          お支払い金額が、指定されたお支払い方法のご利用上限や残高を超過しています。<br/>
          別のお支払い方法でお手続きをやり直してください。
        </div>
        <div class="au-pay__content__detail__buttons">
          <Button label="買い物カゴへ" @click="goCart()"></Button>
        </div>
      </div>

      <div class="au-pay__content__detail status-0" v-show="processStatus === 99">
        <div class="au-pay__content__detail__title">購入手続き中にエラーが発生したため<br />ページを表示できません</div>
        <div class="au-pay__content__detail__text-left">
          購入履歴より購入手続きが完了したかどうかをご確認ください。<br />
          履歴が表示されている場合は、正常に完了しています。<br />
          履歴が表示されない場合は、もう一度お手続きをやり直してください。<br />
        </div>
        <div class="au-pay__content__detail__text-left">
          ＜ このメッセージが表示される例 ＞<br />
          ・購入手続き中にブラウザの戻るボタンや更新ボタンを押してしまった。<br />
          ・購入手続き中にブラウザのタブを複製してしまった。<br />
          ・購入手続き中に一度ブラウザを閉じてしまった。
        </div>
        <div class="au-pay__content__detail__buttons">
          <Button label="購入履歴へ" @click="goHistory()"></Button>
        </div>
      </div>
    </div>
    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import Loading from "@/components/loading/Loading";
import Button from "@/components/button/Button";
import _ from "lodash";
import store from "@/store";
import { checkVtktPay } from "@/plugins/AuId";
import { mapGetters } from "vuex";
import { auPayAuth, auPayAuthResult, auPayAuthorySold, updateCart } from "@/plugins/ApiHelper";
import { getPurchaceLimit } from "@/plugins/GiftHelper";
import { DateTime } from 'luxon';
import {logDOM} from "@storybook/testing-library";

export default {
  name: "AuPay",
  components: {
    Layout,
    Button,
    Loading,
  },
  props: {
  },
  data: () => ({
    message: null,
    processStatus: 0,
    issueIdentity: null,
    payStates: null,
    tranStatus: null,
  }),
  computed: {
    ...mapGetters([
      "isLoading",
      "lid",
      "getGiftId",
      "getTransactionId",
      "aid",
      "lid",
      "getPurchasesInfoId",
      "getCardOriginId",
      "getCartItems",
      "getPointCondition"
    ]),
    giftName() {
      if (this.getCartItems && this.getCartItems.length > 0) {
        return this.getCartItems[0]?.giftName;
      }
      return '';
    },
  },
  async updated() {
    if (this.$route.name != 'au_pay') {
      return;
    }

    // 商品の陳列有効期限チェック
    const isValid = await this.canDisplay()
    if (!isValid) {
      return
    }

    // lid が空なら最大5回スリープ処理
    if (!this.lid) {
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log("retry to get lid ...", i + 1);
        if (!this.lid) {
          break;
        }
      }
    }

    const purchaceLimit = await this.$refs.layout.checkPurchaceLimit();
    if (purchaceLimit && purchaceLimit.isLimitOver) {
      return;
    }

    const payAuthResult = this.$route.query["pay-auth"];
    if (_.isNil(this.getGiftId)) {
      //
      this.goGiftList();
    } else if (payAuthResult === "OK") {
      // ユーザー認証成功
      this.payAuthResult()
        .then(async () => {
          await this.payAuthorySold();
        }).catch((err) => {
          console.error('auかんたん決済ページ失敗', err);
          store.dispatch("stopLoading");
          this.message = null;
        });
    } else if (payAuthResult === "NG") {
      // ユーザー認証失敗
      this.payAuthResult()
        .then(() => {
          store.dispatch("stopLoading");
          this.message = null;
          this.processStatus = 2;
        })
    } else {
      this.message = "処理開始";
      this.payStart();
    }
  },
  methods: {
    goCart() {
      store.commit('clearAuPayProcessInfo');
      this.$refs.layout.gtmPush('お買い物カゴ', '/cart');
      // this.$router.push({ name: "PMG700" });
      location.href = '/cart';
    },
    goHistory() {
      store.commit('clearAuPayProcessInfo');
      this.$refs.layout.gtmPush('購入履歴', '/purchase-histories');
      this.$router.push({ name: 'PMG800'});
    },
    /**
     * 決済開始
     */
    async payStart() {
      this.message = "au ID チェック中";
      await store.dispatch("startLoading");
      // authAuId 認可処理
      const pointCondition = this.getPointCondition
      let usePoints = 0
      let isOnlyPoints = false
      if (pointCondition && pointCondition.usePoints) {
        usePoints = pointCondition.usePoints
        isOnlyPoints = pointCondition.isOnlyPoints
      }

      await checkVtktPay(
        this.getPurchasesInfoId,
        this.getGiftId,
        this.getCardOriginId,
        usePoints,
        isOnlyPoints
      )
        .then((res) => {
          if (_.isNil(res)) {
            return;
          }
          const lid = res.lid;
          const aid = res.aid;
          const purchases_info_id = res.purchases_info_id;
          if (!_.isNil(lid) && !_.isNil(aid) && !_.isNil(purchases_info_id)) {
            // トークン有効
            this.payAuth();
          }
        })
        .catch((err) => {
          console.error(err);
          this.message = null;
          if (err.message === 'Error without KiT') {
            this.processStatus = 98;
          } else if (err.response?.data?.process_already_done)
            this.processStatus = 99;
          else if(err.response?.data?.process_status_code_error)
            this.processStatus = -1;       
          else
            this.processStatus = 1;
        })
        .finally(() => {
          store.dispatch("stopLoading");
        });
    },
    /*
      * 陳列有効期限チェック
      * 陳列有効期限が過ぎている場合はエラー画面へ遷移する
     */
    async canDisplay() {
      // 商品が買い物カゴに入っていない場合はチェックしない
      if (!this.getCartItems || this.getCartItems.length === 0) {
        return true
      }
      try {
        let isValid = true
        for (let cartItem of this.getCartItems) {
          const displayEndDate = cartItem.displayEndDate;
          const diff = DateTime.fromSQL(displayEndDate).diffNow();
          if (diff.milliseconds < 0) {
            isValid = false
            this.processStatus = 5;
            break
          }
        }
        return isValid
      } catch (e) {
        console.error(e)
        this.processStatus = 5;
        return false
      }
    },
    /**
     * 認可処理の再実行
     */
    rePayAuth() {
      this.payStates = null;
      this.tranStatus = null;
      this.payAuth();
    },
    /**
     * 認可処理
     */
    async payAuth() {
      this.message = "かんたん決済 認可処理中";
      await store.dispatch("startLoading");
      const data = {
        lid: this.lid,
        "gift_id": this.getGiftId,
        "purchases_info_id": this.getPurchasesInfoId,
      };
      await auPayAuth(data)
        .then((res) => {
          // KiTに通知しないエラーが発生した場合
          if (res.is_error_without_kit) {
            this.processStatus = 98;
            throw new Error('Error without KiT')
          }

          store.commit("setMemberManageNo", res.member_manage_no);
          store.commit("setTransactionId", res.transaction_id);
          // ユーザー認証へリダイレクト
          const uri = process.env.VUE_APP_AUPAY_USER_PERMIT + encodeURIComponent(res.transaction_id);
          location.assign(uri);
        })
        .catch((err) => {
          store.dispatch("stopLoading");
          console.error('auPayAuth', err);
          this.message = null;
          if (err.message === 'Error without KiT') {
            this.processStatus = 98;
            throw new Error('Error without KiT')
          }
          if (err.response?.data?.process_already_done)
            this.processStatus = 99;
          else if(err.response?.data?.process_status_code_error)
            this.processStatus = -1;
          else
            this.processStatus = 2;
        });
    },
    /**
     * ユーザー認証結果の更新
     */
    async payAuthResult() {
      this.message = "かんたん決済 認可処理中";
      await store.dispatch("startLoading");
      const query = new URLSearchParams(location.search);
      const code = query.get('X-ResultCd');
      const data = {
        lid: this.lid,
        'result_code': code,
        'purchases_info_id': this.getPurchasesInfoId
      }
      await auPayAuthResult(data)
        .then((res) => {
          // KiTに通知しないエラーが発生した場合
          if (res.is_error_without_kit) {
            throw new Error('Error without KiT')
          }
        })
        .catch((err) => {
          console.error(err);
          this.message = null;
          if (err.message === 'Error without KiT') {
            this.processStatus = 98;
            throw err
          } else if (err.response?.data?.process_already_done)
            this.processStatus = 99;
          else if(err.response?.data?.process_status_code_error)
            this.processStatus = -1;  
          else
            this.processStatus = 2;
        })
        .finally(() => {
          store.dispatch("stopLoading");
        });
    },
    /**
     * 決済確定
     */
    async payAuthorySold() {
      this.message = "かんたん決済確定処理";
      await store.dispatch("startLoading");
      const data = {
        purchases_info_id: this.getPurchasesInfoId,
        transaction_id: this.getTransactionId,
        gift_id: this.getGiftId,
        lid: this.lid,
        aid: this.aid,
        issue_identity: this.issueIdentity,
      };
      let purchases_info_id = null;
      await auPayAuthorySold(data)
        .then(async (res) => {
          // KiTに通知しないエラーが発生した場合
          if (res.is_error_without_kit) {
            throw new Error('Error without KiT')
          }

          purchases_info_id = res.purchases_info_id; // 購入番号
          store.commit('clearAuPayProcessInfo');
          store.commit('setCartItems', []);
          store.commit('setCardOriginId', null);
          store.commit('setPointCondition', null);
          return await this.updateCartData();
        })
        .then(() => {
          getPurchaceLimit(this.lid);
          this.$refs.layout.gtmPush('購入完了', `/cart/payment-completion/${purchases_info_id}`);
          this.$router.push({name: "PMG420", params: {'purchasesInfoId': purchases_info_id}});
        })
        .catch((err) => {
          console.error(err);
          this.payStates = null;
          if (err.response?.data && !err.response?.data?.process_already_done) {
            try {
              this.issueIdentity = err.response.data.issue_identity;
              this.payStates = err.response.data.pay_states;
              this.tranStatus = err.response.data.tran_status;
            } catch (error) {
              console.error(error);
            }
          }

          this.message = null;
          if (err.message === 'Error without KiT') {
            this.processStatus = 98;
            throw err
          } else if (err.response?.data?.process_already_done) {
            this.processStatus = 99;
          } else if(err.response?.data?.process_status_code_error){
            this.processStatus = -1;      
          } else if (this.payStates == 'NG5' || this.payStates == 'NG8') {
            this.processStatus = 4;
          } else if (this.payStates == 'NG9') {
            this.processStatus = 9;
          } else {
            this.processStatus = 3;
          }
        })
        .finally(() => {
          store.dispatch("stopLoading");
        });
    },
    async updateCartData() {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      const data = {
        lid: this.lid,
        giftId: null,
        cardOriginId: null,
        updateMode: 0,
      };
      await updateCart(data).catch(() => {
        this.$router.push({name: "PMG510"});
      });
    },
    showGiftList() {
      this.$refs.layout.gtmPush('ギフトを購入', '/gifts/search');
      this.$router.push({name: 'PMG660'});
    },
  },
};
</script>
<style scoped lang="scss">
.au-pay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    &__message {
      font: $font-inter-600;
      color: $black4;

      @include font-size(16, 24);
    }
    &__detail {
      margin-top: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__message {
        margin-bottom: 1.6rem;
      }
      &__title {
        margin-bottom: 16px;
        color: $gray3;
        font: $font-roboto-700;
        text-align: center;
        letter-spacing: 0.15px;

        @include font-size(14, 20);
      }
      &__target-gift,
      &__text {
        color: $gray3;
        font: $font-roboto-400;
        text-align: center;
        letter-spacing: 0.15px;
        margin-bottom: 16px;

        @include font-size(14, 20);
      }
      &__text-left {
        color: $gray3;
        font: $font-roboto-400;
        text-align: left;
        letter-spacing: 0.15px;
        margin-bottom: 16px;

        @include font-size(14, 20);
      }
      &__target-gift {
        margin-bottom: 32px;
      }
      &__buttons {
        .button {
          min-width: 248px;
        }
      }
    }
    .giftee-error {
      max-width: 330px;
    }
    .status-0 {
      max-width: 330px;
    }
    &__footer {
      margin-top: 1.4rem;
    }
  }
}
</style>
