<template>
  <div class="pmg-Page -about">

    <section class="pmg-End">
      <p class="pmg-End__text">ギフトの販売は2024年7月29日をもって終了しました。</p>
    </section>

    <section class="pmg-Hero">
      <div class="pmg-Hero__inner">
        <div class="pmg-Hero__logo">
          <img src="../../assets/images/hero_logo.png" alt="+メッセージ, au, UQmobile, povo">
        </div>
        <h1 class="pmg-Hero__Title">
          <img src="../../assets/images/hero_title.png" alt="プラメギフト">
        </h1>
        <p class="pmg-Hero__subTitle">スマホで簡単に贈れる<br>便利に受け取れる・気持ちが伝わる</p>
        <p class="pmg-Hero__text">購入はau, UQ mobile, povoのお客さまがご利用いただけます。<br>受け取りはどなたでもご利用いただけます。</p>
        <div class="pmg-Hero__ponta">
          <span>Pontaポイントがたまる・つかえる !</span>
        </div>
      </div>
    </section>

    <section class="pmg-GiftType">
      <h2 class="pmg-Title">簡単・便利なソーシャルギフト</h2>
      <div class="pmg-GiftType__lead">
        <div class="pmg-GiftType__leadInner">
          <div class="pmg-GiftType__leadImg">
            <img src="../../assets/images/giftType_lead.png" alt="">
          </div>
          <div class="pmg-GiftType__leadBody">
            <p class="pmg-GiftType__leadText1">ギフトの受け取り用URLを<br>相手の方にを送るだけなので</p>
            <p class="pmg-GiftType__leadText2">ちょっとした気持ちをすぐに贈れる<br>住所を知らなくても贈れる</p>
          </div>
        </div>
      </div>
      <section class="pmg-GiftType__contents">
        <div class="pmg-GiftType__label">eギフト</div>
        <h3 class="pmg-GiftType__subTitle">身近な店舗でいつでも<br>好きな商品に引き換えられる</h3>
        <div class="pmg-GiftType__img">
          <img src="../../assets/images/giftType_egift_20240321.png" alt="">
        </div>
      </section>
      <section class="pmg-GiftType__contents">
        <div class="pmg-GiftType__label">配送ギフト</div>
        <h3 class="pmg-GiftType__subTitle">受け取る人が住所を入力して<br>好きなタイミングで受け取れる</h3>
        <div class="pmg-GiftType__img">
          <img src="../../assets/images/giftType_real.png" alt="">
        </div>
      </section>
    </section>

    <section class="pmg-HowToGive">
      <h2 class="pmg-Title">贈り方</h2>
      <div class="pmg-HowToGive__flow">
        <div class="pmg-HowToGive__item">
          <div class="pmg-HowToGive__step">
            <span class="pmg-HowToGive__stepText">Step</span>
            <span class="pmg-HowToGive__stepNum">1</span>
          </div>
          <div class="pmg-HowToGive__icon">
            <img src="../../assets/images/howToSend_step1.svg" alt="">
          </div>
          <p class="pmg-HowToGive__text">相手やシーンに合ったギフトを選ぶ</p>
        </div>
        <div class="pmg-HowToGive__item">
          <div class="pmg-HowToGive__step">
            <span class="pmg-HowToGive__stepText">Step</span>
            <span class="pmg-HowToGive__stepNum">2</span>
          </div>
          <div class="pmg-HowToGive__icon">
            <img src="../../assets/images/howToSend_step2_20240321.png" alt="" width="81">
          </div>
          <p class="pmg-HowToGive__text">Pontaポイント・auかんたん決済で購入</p>
        </div>
        <div class="pmg-HowToGive__item">
          <div class="pmg-HowToGive__step">
            <span class="pmg-HowToGive__stepText">Step</span>
            <span class="pmg-HowToGive__stepNum">3</span>
          </div>
          <div class="pmg-HowToGive__icon">
            <img src="../../assets/images/howToSend_step3.svg" alt="">
          </div>
          <p class="pmg-HowToGive__text">受け取りURLを送付</p>
        </div>
      </div>
      <ul class="pmg-HowToGive__annotationList">
        <li class="pmg-HowToGive__annotationItem">※購入はau, UQ mobile, povoのお客さまがご利用いただけます。受け取りはどなたでもご利用いただけます。</li>
        <li class="pmg-HowToGive__annotationItem">※auかんたん決済については、<a href="https://kantan.auone.jp/service/" target="_blank" onclick="trEventClick(this,'プラメギフトとは','かんたん決済','');">auかんたん決済の概要</a>をご覧ください。</li>
      </ul>
    </section>

    <div class="pmg-GiftTypeCard">
      <h2 class="pmg-Title">
        <span>ギフトを演出</span>
        ギフトカードは2タイプ
      </h2>
      <div class="pmg-GiftTypeCard__items">
        <section class="pmg-GiftTypeCard__item">
          <div class="pmg-GiftTypeCard__label">通常カード</div>
          <h3 class="pmg-GiftTypeCard__subTitle">シンプルに気持ちを<br>贈りたい方向け</h3>
          <div class="pmg-GiftTypeCard__description">
            <div class="pmg-GiftTypeCard__img -small">
              <img src="../../assets/images/giftCardType_normal.png" alt="">
            </div>
            <p class="pmg-GiftTypeCard__text">作成不要のシンプルなカード</p>
          </div>
        </section>
        <section class="pmg-GiftTypeCard__item">
          <div class="pmg-GiftTypeCard__label">リッチカード</div>
          <h3 class="pmg-GiftTypeCard__subTitle">写真や動画で<br>演出したい方向け</h3>
          <div class="pmg-GiftTypeCard__description">
            <div class="pmg-GiftTypeCard__img">
              <img src="../../assets/images/giftCardType_rich.png" alt="">
            </div>
            <p class="pmg-GiftTypeCard__text">写真・動画などを自由に設定できるあなただけのカード</p>
          </div>
        </section>
      </div>
    </div>

    <div class="pmg-HowToUse">
      <h2 class="pmg-Title">受け取り後のご利用方法</h2>
      <div class="pmg-HowToUse__items">
        <section class="pmg-HowToUse__item">
          <div class="pmg-HowToUse__label">eギフト</div>
          <div class="pmg-HowToUse__icon">
            <img src="../../assets/images/howToUse_egift.svg" alt="">
          </div>
          <h3 class="pmg-HowToUse__subTitle">店舗で好きな<br>商品に交換</h3>
          <p class="pmg-HowToUse__text">受け取ったURLをタップして店舗で利用画面を表示し、好きな商品に交換</p>
        </section>
        <section class="pmg-HowToUse__item">
          <div class="pmg-HowToUse__label">配送ギフト</div>
          <div class="pmg-HowToUse__icon">
            <img src="../../assets/images/howToUse_real.svg" alt="">
          </div>
          <h3 class="pmg-HowToUse__subTitle">配送先に<br>商品が届く</h3>
          <p class="pmg-HowToUse__text">受け取ったURLをタップ、住所や受け取り日などを入力して商品が届く</p>
        </section>
      </div>
      <p class="pmg-HowToUse__annotation">※購入はau, UQ mobile, povoのお客さまがご利用いただけます。受け取りはどなたでもご利用いただけます。</p>
      <div class="pmg-Button">
        <a
          href="javascript:void(0)"
          ref="guide"
          @click="clickGuide();clickGA('guide','ご利用ガイド','')"
        >
          ご利用ガイド
        </a>
      </div>
    </div>

    <div class="pmg-Faq">
      <h2 class="pmg-Title">よくある質問</h2>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q2-07" onclick="trEventClick(this,'プラメギフトとは','FAQ2-07','');">複数の人にギフトを贈れますか？</summary>
          <div class="pmg-Faq__answer">
            <p>1つずつギフトを購入することで贈れます。購入したそれぞれのギフト受け取り用URLを、一人ひとりにお送りください。<br>ギフト受け取り用URLは誰でもアクセスすることができ、URLさえ知っていればギフトを利用することができますので、メッセージアプリのグループトークやSNS等でURLを公開してしまわないようご注意ください。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q4-05" onclick="trEventClick(this,'プラメギフトとは','FAQ4-05','');">auかんたん決済に進むと「MPLE0272」のエラーが表示される</summary>
          <div class="pmg-Faq__answer">
            <p><h3>エラーの内容</h3>プラメギフトでは、お客さまにより安心してご利用いただくために、auかんたん決済ご利用時のSMS認証を導入しており、決済画面へ進むと確認コード付きのSMSをお客さまのスマートフォンへ送付しております（注1）。<br>SMSの送付先は、お客さまのau IDに登録されている代表契約となり、その代表契約がau、UQ mobile、povo以外のご契約となっている場合に本エラーが表示されます（例：代表契約がauひかりのご契約になっている）。<br><h3>対処方法</h3>au IDに登録されている代表契約をau、UQ mobile、povoいずれかのご契約に変更してください。変更方法は下記のauのよくあるご質問ページからご確認をお願いします。<br><a href="https://www.au.com/support/faq/detail/33/a00000000833/" target="_blank">https://www.au.com/support/faq/detail/33/a00000000833/</a><br><br>お客さまのau IDにau、UQ mobile、povoのご契約が紐づいていない場合は、それらのご契約が紐づいている別のau IDでログインしなおしてください。<br><br>（注1）auかんたん決済のSMS認証については、詳しくはauかんたん決済サービスサイトをご覧ください。<a href="https://kantan.auone.jp/guide/sms_authentication/" target="_blank">https://kantan.auone.jp/guide/sms_authentication/</a></p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q2-03" onclick="trEventClick(this,'プラメギフトとは','FAQ2-03','');">購入後にキャンセルはできますか？</summary>
          <div class="pmg-Faq__answer">
            <p>購入後のキャンセルや返金は承ることができません。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q3-05" onclick="trEventClick(this,'プラメギフトとは','FAQ3-05','');">メッセージアプリのトーク画面でギフト受け取り用URL消してしまうと、ギフトは使えなくなりますか？</summary>
          <div class="pmg-Faq__answer">
            <p>ギフト受け取り用URLが記載されたトーク（メッセージ）を削除しても、ギフトは削除されません。贈り主の方にご連絡いただき、購入履歴からギフト受け取り用URLを再送していただくようお伝えください。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q3-06" onclick="trEventClick(this,'プラメギフトとは','FAQ3-06','');">受け取ったギフトを利用しなかった場合、どうなりますか？</summary>
          <div class="pmg-Faq__answer">
            <p>ギフトを利用しないまま有効期限・引換期限を超えると無効になります。その場合もキャンセルやご返金は承ることができませんのでご注意ください。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary class="pmg-Faq__label" for="q1-02" onclick="trEventClick(this,'プラメギフトとは','FAQ1-02','');">推奨環境について</summary>
          <div class="pmg-Faq__answer">
            <p><h3>プラスメッセージアプリ</h3>　最新バージョン<h3>OS</h3>　・Android OS 11.x以上<br>　・iOS 16.x以上<h3>ブラウザ</h3>　OS標準ブラウザ（Android：Google Chrome、iOS：Safari）の最新バージョン<br>　※上記に当てはまる全ての端末での動作を保障するものではございません。</p>
          </div>
        </details>
      <div class="pmg-Faq__anchor">
        <a
          href="javascript:void(0)"
          @click="clickFaq();clickGA('clickFaq','FAQその他','')"
          ref="clickFaq"
        >
          その他の質問・お問い合わせはこちら
        </a>
      </div>
    </div>

    <div class="pmg-Notes">
      <h2 class="pmg-Title">注意事項</h2>
      <h3 class="pmg-Title -h3">プラメギフトについて</h3>
      <ul class="pmg-Notes__list">
        <li class="pmg-Notes__item">購入はau, UQ mobile, povoのお客さまがご利用いただけます。</li>
        <li class="pmg-Notes__item">受け取りはどなたでもご利用いただけます。</li>
        <li class="pmg-Notes__item">お支払いには、auかんたん決済（通信料合算支払い、au PAY カード支払い、au PAY 残高支払い）をご利用いただけます。povo2.0をご契約のお客さまは、プラメギフトでは通信料合算支払いをご利用いただけません。</li>
        <li class="pmg-Notes__item">お支払い手続き中にブラウザの戻るボタンや更新ボタンの押下、タブの複製を行なうと、手続きが正常に完了しない場合がございますのでご注意ください。</li>
        <li class="pmg-Notes__item">
          必ず
          <a
            href="javascript:void(0)"
            @click="clickRules();clickGA('clickRules','利用規約','')"
            ref="clickRules"
          >
            利用規約
          </a>
          をお読みの上、ご利用ください。
        </li>
      </ul>
      <h3 class="pmg-Title -h3">「＋メッセージ（プラスメッセージ）」について</h3>
      <p class="pmg-Notes__paragraph"><a href="https://www.au.com/mobile/service/plus-message/" target="_blank" onclick="trEventClick(this,'プラメギフトとは','プラメ対象機種','');">「＋メッセージ」アプリの対象機種はこちら</a>よりご確認ください。2018年11月29日以降にauで発売を開始したケータイ（4GLTE）は公式アカウント機能をご利用いただけないため、サービスをご利用いただけません。</p>
      <h3 class="pmg-Title -h3">動作環境・Cookie情報の利用について</h3>
      <p class="pmg-Notes__paragraph">
        こちらのページでご確認ください。<br>
        <a href="https://www.kddi.com/terms/requirements/" target="_blank" onclick="trEventClick(this,'プラメギフトとは','動作環境','');">https://www.kddi.com/terms/requirements/</a>
      </p>
    </div>

    <div class="pmg-Notes" id="purchase">
        <h2 class="pmg-Title">ご購入特典</h2>
        <h3 class="pmg-Title -h3">特典内容</h3>
        <p class="pmg-Notes__paragraph">「プラメギフト」で購入した金額の0.5%をPontaポイントで還元（200円ごとに1ポイント還元）。</p>
        <ul class="pmg-Notes__list -annotation">
          <li class="pmg-Notes__item">※お支払いにはPontaポイントとauかんたん決済がご利用いただけます。</li>
          <li class="pmg-Notes__item">※Pontaポイントで支払った分もポイント還元の対象となります。</li>
          <li class="pmg-Notes__item">※ポイントの還元上限はありません。</li>
        </ul>
        <h3 class="pmg-Title -h3">対象</h3>
        <p class="pmg-Notes__paragraph">2024年3月27日(水)以降のご購入</p>
        <h3 class="pmg-Title -h3">ポイント加算時期</h3>
        <p class="pmg-Notes__paragraph">ご購入日の翌々月末頃まで</p>
        <ul class="pmg-Notes__list -annotation">
          <li class="pmg-Notes__item">※諸事情により遅れる場合もございますのでご了承ください。</li>
        </ul>
        <h3 class="pmg-Title -h3">特典に関する注意事項</h3>
        <ul class="pmg-Notes__list">
          <li class="pmg-Notes__item">加算するPontaポイントは、小数点以下切り捨てとなります。</li>
          <li class="pmg-Notes__item">ポイント加算日までに、ポイント加算対象のau IDに紐づく回線を解約または停止または変更された場合、もしくは別のau IDに統合した場合はポイント加算の対象外となる場合があります。</li>
          <li class="pmg-Notes__item">加算されたPontaポイントを他のau IDへ譲渡したり、au ID間で共有することはできません。</li>
          <li class="pmg-Notes__item">法人名義のau IDは対象外となります。</li>
          <li class="pmg-Notes__item">本特典は予告なく、終了する場合がございます。</li>
          <li class="pmg-Notes__item">本特典の内容及び本注意事項等は予告なく変更されることあります。その場合、変更後の本注意事項が閲覧可能な状態となった時点よりその効力が生じるものとし、以後変更後の本注意事項によるものとします。</li>
          <li class="pmg-Notes__item">本特典は、当社が同時期に実施するキャンペーンとの重複適用ができない場合があります。</li>
        </ul>
      </div>

    <div class="pmg-Notes" id="follow">
      <h2 class="pmg-Title">公式アカウントフォロー特典</h2>
      <h3 class="pmg-Title -h3">特典内容</h3>
      <p class="pmg-Notes__paragraph">公式アカウント「プラメギフト」を対象期間中にフォローすると、10Pontaポイントをプレゼント。</p>
      <ul class="pmg-Notes__list -annotation">
        <li class="pmg-Notes__item">※対象期間より前にフォローしていたお客さまは、本特典の対象外となりますのでご了承ください。</li>
        <li class="pmg-Notes__item">※対象期間中にフォローを解除し、再度フォローしなすことで複数回フォローをした場合も、ポイント加算は1回のみとなります。</li>
      </ul>
      <h3 class="pmg-Title -h3">対象期間</h3>
      <p class="pmg-Notes__paragraph">2023年12月7日(木)～　終了日未定</p>
      <h3 class="pmg-Title -h3">ポイント加算時期</h3>
      <p class="pmg-Notes__paragraph">公式アカウントをフォローした日の翌々月末頃まで</p>
      <ul class="pmg-Notes__list -annotation">
        <li class="pmg-Notes__item">※諸事情により遅れる場合もございますのでご了承ください。</li>
      </ul>
      <h3 class="pmg-Title -h3">特典に関する注意事項</h3>
      <ul class="pmg-Notes__list">
        <li class="pmg-Notes__item">ポイント加算には、公式アカウントをフォローしてからポイント加算までの間、次の(1)～(5)をすべて満たす必要があります。
          <ol class="pmg-Notes__list -num">
            <li class="pmg-Notes__item">(1)au, UQ mobile, povo回線を利用していること</li>
            <li class="pmg-Notes__item">(2)個人名義の回線契約であること（法人名義は対象外）</li>
            <li class="pmg-Notes__item">(3)au IDに契約回線が登録されていること</li>
            <li class="pmg-Notes__item">(4)「＋メッセージ」アプリの最新バージョンを利用していること（対象バージョン：Android2.0.13以上、iOS 2.0.13以上）</li>
            <li class="pmg-Notes__item">(5)公式アカウント「プラメギフト」の利用許諾に同意し、利用（フォロー）していること</li>
          </ol>
        </li>
        <li class="pmg-Notes__item">ポイント加算日までに、公式アカウント「プラメギフト」のフォローを解除、または「＋メッセージ」アプリを削除もしくは初期化された場合はポイント加算の対象外となる場合があります。</li>
        <li class="pmg-Notes__item">ポイント加算日までに、ポイント加算対象のau IDに紐づく回線を解約または停止または変更された場合、もしくは別のau IDに統合した場合はポイント加算の対象外となる場合があります。 </li>
        <li class="pmg-Notes__item">契約回線の電話番号を変更された場合、公式アカウントは自動的にフォローが解除され、ポイント加算の対象外となる場合があります。</li>
        <li class="pmg-Notes__item">加算されたPontaポイントを他のau IDへ譲渡したり、au ID間で共有することはできません。</li>
        <li class="pmg-Notes__item">au IDに電話番号を未登録のお客さまは、事前に「＋メッセージ」アプリでご利用になる「電話番号」の登録をお願いいたします。</li>
        <li class="pmg-Notes__item">au IDに登録した会員情報を変更する方法については、<a href="https://id.auone.jp/id/pc/help/setting/32.html" target="_blank">こちら</a>をご確認ください。</li>
        <li class="pmg-Notes__item">本特典は予告なく、終了や変更をする場合がございます。</li>
        <li class="pmg-Notes__item">本特典の内容及び本注意事項等は予告なく変更されることあります。その場合、変更後の本注意事項が閲覧可能な状態となった時点よりその効力が生じるものとし、以後変更後の本注意事項によるものとします。</li>
        <li class="pmg-Notes__item">本特典は、当社が同時期に実施するキャンペーンとの重複適用ができない場合があります。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { emitter } from '@/eventBus';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';
import { mapGetters } from 'vuex';

export default {
  name: 'About',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
    data: () => ({
    isFirstLoading: false
  }),
  computed: {
    ...mapGetters(['isLoading']),
  },
  updated() {
    this.isFirstLoading = false
  },
  mounted() {
    // データの取得中でない かつ 画面表示後スクロールをしていない場合
    const hash = this.$route.hash
    // hashが存在する場合
    if (hash && hash.startsWith("#")) {
      // 1. Vue.jsの非同期処理を利用してDOMの変更が反映されるのを待つ
      this.$nextTick(() => {
        // 2. requestAnimationFrameを使ってアニメーションフレームが開始される前に関数を実行する
        requestAnimationFrame(() => {
          if(this.isFirstLoading === false){
            // #を取り除く
            const id = hash.substring(1)
            this.scrollTo(id);
          }
        });
      });
    }
  },
  watch: {
    isLoading(newValue) {
      // データの取得中でない かつ 画面表示後スクロールをしていない場合
      if (newValue === false) {
        const hash = this.$route.hash
        // hashが存在する場合
        if (hash && hash.startsWith("#")) {
          // 1. Vue.jsの非同期処理を利用してDOMの変更が反映されるのを待つ
          this.$nextTick(() => {
            // 2. requestAnimationFrameを使ってアニメーションフレームが開始される前に関数を実行する
            requestAnimationFrame(() => {
              if(this.isFirstLoading === false){
                // #を取り除く
                const id = hash.substring(1)
                this.scrollTo(id);
              }
            });
          });
        }
      }
    },
  },
  methods: {
    clickAbout() {
      this.$router.push('/gifts/static/about#follow');
    },
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        if (el){
          el.scrollIntoView({
            behavior: 'smooth',
          });
          this.isFirstLoading = true
        }

      } catch (error) {
        console.error(error);
      }
    },
    gtmPush(title, path, params = {}) {
      const virtualPageUrl = getUrl(path, params);
      gtmDataLayerPush(virtualPageUrl, title);
    },
    clickGuide() {
      emitter.emit('showStaticPage', {
        params: { url: 'guide', title: 'ご利用ガイド' },
        query: { ppsm: true },
      });
    },
    clickFaq() {
      emitter.emit('showStaticPage', {
        params: { url: 'faq', title: 'よくある質問・お問い合わせ' },
        query: { ppsm: true },
      });
    },
    clickRules() {
      emitter.emit('showStaticPage', {
        params: { url: 'rules', title: '利用規約' },
        query: { ppsm: true },
      });
    },
    clickGift() {
      this.gtmPush('ギフトを購入', '/gifts/search');
      this.$router.push({ name: 'PMG660', query: { ppsm: true } });
    },
    clickGiftDetail(giftId) {
      this.gtmPush('ギフト詳細', `/gifts/${giftId}`);
      this.$router.push({ name: 'PMG610', params: { giftId: giftId } });
    },
    clickGA(refName,label2,label3){
      gtmSendClick(this.$refs[refName],'プラメギフトとは',label2,label3);
    }
  },
};
</script>

<style src="@/assets/css/main.css" scoped>
</style>

<style scoped lang="scss">
.pmg-Download {
  &__stepLinkDetail {
    > span {
      color: $orange1;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
