<template>
  <div class="animation-lottie"/>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: 'AnimationLottie',
  props: {
    animationData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['completed'],
  data: () => ({
    lottie: null,
  }),
  mounted () {
    this.initLottie();
  },
  methods: {
    initLottie() {
      this.lottie = lottie.loadAnimation({
        container: this.$el,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: this.animationData,
      });
      this.lottie.onComplete = this.onComplete;
    },
    play() {
      this.lottie.play();
    },
    onComplete() {
      this.$emit('completed');
    }
  }
}
</script>

<style lang="scss" scoped>
.animation-lottie {
  display: inline-block;
}
</style>
