<template>
  <Modal
    ref="modal"
    class="preview-modal"
    name="preview"
    close-icon="back"
    :scroll-to-hide-header="true"
    :default-hide-header="true"
    header-align="center"
    :close-func="hideModal"
    :is-scroll-stop="isOpenEditModal"
  >
    <template #header>{{ header }}</template>
    <template #body>
      <BaseTemplate :modelValue="value" :design-id="designId" :scroll-parent="scrollParent" :disabled-gift-link="disabledGiftLink" @click:giftLink="clickGiftLink">
        <FirstView :modelValue="value" :design-id="designId"/>
      </BaseTemplate>
      <Button v-if="buttonText" type="fill" size="medium" :label="buttonText" @click="click"/>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal'
import BaseTemplate from '@/components/messageCard/BaseTemplate'
import FirstView from '@/components/messageCard/FirstView'
import Button from '@/components/button/Button'

import { mapGetters } from 'vuex'

export default {
  name: 'PreviewModal',
  components: {
    Modal,
    BaseTemplate,
    FirstView,
    Button,
  },
  props: {
    designId: {
      type: String,
      default: null,
    },
    header: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'preview', // preview, send, sample
    },
    disabledGiftLink: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click:button', 'click:giftLink', 'click:close'],
  data: () => ({
    scrollParent: null,
  }),
  computed: {
    ...mapGetters(['isOpenEditModal']),
    buttonText() {
      switch (this.type) {
        case 'send': return 'このカードを贈る';
        case 'sample': return 'このカードを作成';
        default: return null;
      }
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
      this.$nextTick(() => {
        this.scrollParent = this.$el.querySelector('.modal__container');
      });
    },
    hideModal() {
      this.$refs.modal.hideModal();
      this.$emit('click:close');
    },
    click() {
      this.$emit('click:button');
    },
    clickGiftLink() {
      this.$emit('click:giftLink');
    },
  }
}
</script>

<style scoped lang="scss">
.preview-modal {
  .button {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 10;
  }
}
</style>
