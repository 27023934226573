<template>
  <Layout
    ref="layout"
    title="カードタイプを選ぶ"
    active="gift"
    :show-back="false"
    :show-close="false"
    :show-menu="false"
    :show-custom-close="true"
    :custom-close-func="clickBack"
  >
    <ion-content class="ion-padding">
      <div class="header">
        <span class="header__label">ギフトを買い物カゴに追加しました</span>
      </div>
      <div class="gift-info">
        <div class="gift-info__content">
          <GiftInfo
            :gift-name="giftName"
            :brand-name="brandName"
            :expiry-date="expiryDate"
            :price="price"
            :gift-image="giftImage"
            :is-show-price="true"
            :is-tax-display="isTaxDisplay"
          ></GiftInfo>
        </div>
      </div>
      <div class="message-card">
        <div class="message-card__header">
            <span class="message-card__header__label"
            >一緒に贈るカードを選択してください</span
            >
          <span class="message-card__header__note"
          >(作成した後でも選び直すことができます)</span
          >
        </div>
        <CardTypeSelect v-model="cardVal"></CardTypeSelect>
      </div>
      <div class="buttons">
        <Button label="カードの作成に進む" type="fill" ref="createdCard" @click="goCreateCard"/>
        <Button label="後で作成（買い物カゴへ）" type="fill-outline" ref="goCart" @click="goCart"/>
      </div>
    </ion-content>
    <Loading :is-show="isLoading"/>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import Loading from "@/components/loading/Loading";
import {mapGetters} from "vuex";
import {DateTime} from "luxon";
import CardTypeSelect from '@/components/messageCard/CardTypeSelect';

import Button from "@/components/button/Button";
import GiftInfo from "@/components/gift/GiftInfo";

import {IonContent} from "@ionic/vue";
import {detailGiftApi} from "@/plugins/ApiHelper";
import {
  getItemImageUrl,
} from '@/plugins/GiftHelper';
import {gtmSendClick} from "@/plugins/GtmHelper";


export default {
  name: "PMG720",
  components: {
    Layout,
    Loading,
    IonContent,
    Button,
    GiftInfo,
    CardTypeSelect,
  },
  data: () => ({
    cardVal: "0",
    giftName: null,
    brandName: null,
    expiryDate: null,
    price: null,
    giftImage: null,
    isTaxDisplay: true,
    giftData: {
      giftId: null,
      gifteeGiftId: null,
      giftName: null,
      description: null,
      note: null,
      price: null,
      image: null,
      usingDescription: null,
      usingDescriptionDetail: null,
      brandId: null,
      brandName: null,
      brandImage: null,
      brandDescription: null,
      expiryDate: null,
      isForMySelf: null
    },
  }),
  computed: {
    ...mapGetters(["isLoading", 'getCartItems', "isAuIdAuthorization", "lid"]),
    giftId() {
      return Number(this.$route.params.giftId);
    },
    expiryDateDisplay() {
      if (!this.giftData.expiryDate) {
        return "";
      }
      const day = DateTime.fromFormat(
        this.giftData.expiryDate,
        "yyyy-LL-dd TT"
      ).toFormat("yyyy年LL月dd日")
      return day;
    },
    priceDisplay() {
      return Number(this.giftData.price).toLocaleString();
    },
  },
  watch: {
    giftData: {
      handler(newVal) {
        if (!newVal) {
          return;
        }
        this.giftName = newVal.giftName;
        this.brandName = newVal.brandName;
        this.expiryDate = newVal.expiryDate;
        this.price = Number(newVal.price);
        this.giftImage = getItemImageUrl(newVal.image);
        this.isTaxDisplay = newVal.isTaxDisplay;
      },
      deep: true,
    }
  },
  async mounted() {
    this.getGiftDetail();
  },
  methods: {
    clickBack() {
      this.goCart();
    },
    getGiftDetail() {
      detailGiftApi({"gift-id": this.giftId})
        .then((res) => {
          this.giftData = res.gifts.find((e) => e.giftId == this.giftId);
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({name: "PMG510"});
        });
    },
    clickCard(val) {
      this.cardVal = val;
    },
    async goCreateCard() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
      }

      if (this.cardVal == '0') {
        // 通常カード
        gtmSendClick(this.$refs['createdCard'].$el, 'カードタイプを選ぶ', 'カードの作成に進む（通常カード）', '');

        this.$refs.layout.gtmPush('贈り主の名前を入力', `/gifts/${this.giftId}/input-name`);
        await this.$router.push({name: "PMG330", params: {giftId: this.giftId}});
      } else {
        // リッチ版カード
        gtmSendClick(this.$refs['createdCard'].$el, 'カードタイプを選ぶ', 'カードの作成に進む（リッチカード）', '');

        this.$refs.layout.gtmPush('カード一覧', '/cards');
        await this.$router.push({name: "PMG300"});
      }
    },
    goCart() {
      gtmSendClick(this.$refs['goCart'].$el, 'カードタイプを選ぶ', 'あとで作成（買い物カゴへ）', '');

      this.$refs.layout.gtmPush('お買い物カゴ', '/cart');
      this.$router.push({name: "PMG700"});
    },
  },
};
</script>
<style scoped lang="scss">
ion-content {
  --background: #{$modelBackground};
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  &__label {
    font: $font-noto-sans-jp-700;
    color: $black6;
    size: 14px;
    line-height: 22.4px;
  }
}

.gift-info {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: $white1;
  margin-bottom: 1rem;

  &__header {
    font: $font-noto-sans-jp-400;
    @include font-size(12, 22.4);
    padding: 1rem;
  }

  &__content {
    padding: 1rem;
  }

  &__footer {
    padding: 1rem;
    border-top: solid 1px $gray11;
    display: flex;
    justify-content: space-between;
  }
}

.message-card {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;

    &__label {
      font: $font-noto-sans-jp-700;
      color: $black6;
    }

    &__note {
      font: $font-noto-sans-jp-400;
      color: $black6;
    }

    &__label,
    &__note {
      font-size: 14px;
      line-height: 22.4px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  gap: 24px;

  :deep(.button) {
    position: inherit;
    min-width: 311px;
  }
}
</style>
