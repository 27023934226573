import store from "@/store";
import _ from "lodash";
import Cookies from "js-cookie";
import { CookieName } from "@/plugins/Constants";
import { authAuId } from '@/plugins/ApiHelper';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';

/**
 * au ID 認可&ログイン処理
 * 決済処理用
 */
export const checkVtktPay = async (purchases_info_id, gift_id, card_origin_id, points = 0, is_only_points = false) => {
  // Cookieの確認
  let vtkt = Cookies.get(CookieName.VTKT);
  const vtktDev = process.env.VUE_APP_DEV_AUID_VTKT;
  if (!_.isEmpty(vtktDev)) {
    // NOTE 開発用vtkt
    vtkt = vtktDev;
  }
  if (!_.isNil(vtkt)) {
    // Point利用設定を付与
    const data = {
      'vtkt': vtkt,
      'purchases_info_id': purchases_info_id,
      'gift_id': gift_id,
      'card_origin_id': card_origin_id,
      'points': points,
      'is_only_points': is_only_points,
    };
    const res = await authAuId(data);
    const lid = res.lid;
    const aid = res.aid;
    const pid = res.purchases_info_id;
    if (!_.isNil(lid) && !_.isNil(aid) && !_.isNil(pid)) {
      store.commit('setAid', aid);
      store.commit('setLid', lid);
      store.commit('setPurchasesInfoId', pid)
      return res;
    }
    store.commit('setPurchasesInfoId', pid)
  }
  store.commit('clearAidLid');
  store.commit('setAuIdAuthorization', false);
  // au ID ログイン
  redirectAuIdLogin();
  return null;
};

/**
 * au ID ログイン画面へリダイレクト
 */
const redirectAuIdLogin = () => {
  const loginUri = new URL(process.env.VUE_APP_AUID_LOGIN_URL);
  location.assign(loginUri.href);
}

/**
 * au ID 認可
 */
export const isAuthorization = async () => {
  // Cookieの確認
  let vtkt = Cookies.get(CookieName.VTKT);
  const vtktDev = process.env.VUE_APP_DEV_AUID_VTKT;
  if (!_.isEmpty(vtktDev)) {
    // NOTE 開発用vtkt
    vtkt = vtktDev;
  }
  if (!_.isEmpty(vtkt)) {
    const data = {
      'vtkt': vtkt,
    };
    const res = await authAuId(data);
    console.log('isAuthorization res', res)
    const lid = res.lid;
    const aid = res.aid;
    if (!_.isEmpty(lid) && !_.isEmpty(aid)) {
      store.commit('setAid', aid);
      store.commit('setLid', lid);
      store.commit('setAuIdAuthorization', true);
      return true;
    }
  }
  store.commit('clearAidLid');
  store.commit('setAuIdAuthorization', false);
  return false;
};

/**
 * au ID ログイン
 *
 * @param {*} redirectUrl リダイレクトURL
 * @param {*} additionalParams リダイレクトURLに含めるパラメータ
 */
export const auIdLogin = (redirectUrl = null, additionalParams = {}) => {
  const url = new URL(process.env.VUE_APP_AUID_LOGIN_BASE_URL);
  if (redirectUrl) {
    const params = {
      loggedin: true,
      ...additionalParams
    }
    const paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    url.searchParams.append('targeturl', `${redirectUrl}?${paramStr}`);
  }
  gtmDataLayerPush(url.href, 'auIDログイン');
  location.assign(url.href);
}

/**
 * au ID ログアウト
 *
 * @param {*} redirectUrl リダイレクトURL
 */
export const auIdLogout = (redirectUrl = null) => {
  const url = new URL(process.env.VUE_APP_AUID_LOGOUT_BASE_URL);
  if (redirectUrl) {
    url.searchParams.append('targeturl', redirectUrl);
  }
  store.commit('clearAidLid');
  store.commit('setAuIdAuthorization', false);
  gtmDataLayerPush(url.href, 'auIDログアウト');
  location.assign(url.href);
}
