/*
  *シーズナルイベントのデーター
 */

const BASE_URL = process.env.VUE_APP_BASE_APP_URL
/**
 * シーズナルイベントのデータ
 * promotion_id: キャンペーンID
 * href: キャンペーンの詳細ページのURL（同期的な遷移）
 * redirectUrlInfo: キャンペーンの詳細ページのURL情報（非同期的な遷移）
 * redirectButtonLabel: キャンペーンの詳細ページのボタンラベル
 * title: キャンペーンのタイトル
 * subTitle: キャンペーンのサブタイトル
 * image: キャンペーンの画像
 * imageWidth: キャンペーンの画像の幅
 * backgroundImage: キャンペーン要素の背景画像
 * giftItems: キャンペーンのギフトアイテム
 *    giftId: ギフトID
 *    brandName: ブランド名
 *    giftName: ギフト名
 *    price: 価格
 *    img: 画像
 *    hasVariation: バリエーションがあるかどうか
 *    isTaxDisplay: 税金が表示されるかどうか
 */
export const SEASONAL_DATA = [
  {
    promotion_id: 'C1',
    href: '/gifts/search?scenes=14',
    redirectUrlInfo: {
      name: 'PMG660',
      query: {
        scenes: 14,
      }
    },
    redirectButtonLabel: 'もっと見る',
    title: 'お世話になった方へ',
    subTitle: '職場の方や家族への感謝を、ギフトに込めて伝えてみませんか？',
    image: '/images/SeasonalEvent/seasonalImage3.png',
    imageWidth: 88.3,
    backgroundImage: '/images/SeasonalEvent/seasonalEventBackgroundImage1_20231205.png',
    giftItems: [
      {
        giftId: 26,
        brandName: "ミスタードーナツ",
        giftName: "ギフトチケット（1,000円）",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/misterdonut/misterdonut_003.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 207,
        brandName: "bloomeeギフト",
        giftName: "銀座千疋屋フルーツサンドお花付きギフト",
        price: "3980",
        img: `${BASE_URL}/gift-item-image/bloomee/bloomee_004.png`,
        hasVariation: false,
        isTaxDisplay: true,
      },
      {
        giftId: 136,
        brandName: "AOYAMA GIFT SALON (アオヤマギフトサロン)",
        giftName: "WEBカタログ　銀座千疋屋　ケーキ・タルトセレクト",
        price: "5500",
        img: `${BASE_URL}/gift-item-image/aoyamaLab/aoyamaLab_020.png`,
        hasVariation: false,
        isTaxDisplay: true,
      },
      {
        giftId: 42,
        brandName: "コメダ珈琲店",
        giftName: "コメダ珈琲店 eギフト1,000円",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/komeda/komeda_002.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 22,
        brandName: "サーティワン アイスクリーム",
        giftName: "サーティワン アイスクリーム 500円ギフト券",
        price: "500",
        img: `${BASE_URL}/gift-item-image/31ice/31ice_005.png`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 44,
        brandName: "TULLY'S COFFEE",
        giftName: "タリーズデジタルギフト（1000円）",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/tullys/tullys_002.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
    ],
  },
  {
    promotion_id: 'C2',
    href: '/gifts/search?scenes=15',
    redirectUrlInfo: {
      name: 'PMG660',
      query: {
        scenes: 15,
      }
    },
    redirectButtonLabel: 'もっと見る',
    title: '自分へのご褒美に',
    subTitle: '忙しい日々にほっと一息。いつも頑張る自分を労わってみませんか？',
    image: '/images/SeasonalEvent/seasonalImage4.png',
    imageWidth: 88,
    backgroundImage: '/images/SeasonalEvent/seasonalEventBackgroundImage2_20231205.png',
    giftItems: [
      {
        giftId: 151,
        brandName: "AOYAMA GIFT SALON (アオヤマギフトサロン)",
        giftName: "岐阜 「新杵堂」 ストロベリースイーツセット",
        price: "4400",
        img: `${BASE_URL}/gift-item-image/aoyamaLab/aoyamaLab_037.jfif`,
        hasVariation: false,
        isTaxDisplay: true,
      },
      {
        giftId: 30,
        brandName: "すかいらーく",
        giftName: "すかいらーくご優待券(1000円)",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/skylark/skylark_002.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 88,
        brandName: "てもみん・グローバル治療院",
        giftName: "てもみんリラクゼーションチケット（2,200円分）",
        price: "2200",
        img: `${BASE_URL}/gift-item-image/temomin/temomin_002.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 167,
        brandName: "Afternoon Tea TEAROOM",
        giftName: "ギフトチケット1,000円",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/afternoon_tea/afternoon_tea_002.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
      {
        giftId: 148,
        brandName: "AOYAMA GIFT SALON (アオヤマギフトサロン)",
        giftName: "北海道　十勝フロマージュブリュレ",
        price: "3850",
        img: `${BASE_URL}/gift-item-image/aoyamaLab/aoyamaLab_034.jfif`,
        hasVariation: false,
        isTaxDisplay: true,
      },
      {
        giftId: 100,
        brandName: "ビアードパパ",
        giftName: "1,000円ギフト券",
        price: "1000",
        img: `${BASE_URL}/gift-item-image/beardpapa/beardpapa_003.jfif`,
        hasVariation: false,
        isTaxDisplay: false,
      },
    ],
  }
]

