<template>
  <div class="box-list">
    <div class="box-search">
      <div class="title-label-container">
        <span class="title-label">カテゴリーから探す</span>
      </div>
      <div class="divider-line"></div>
      <div class="item-list">
        <div
            v-for="classification in classifications"
            :key="classification.id">
          <div class="category-item" @click="redirectGiftList(classification.categoryId)">
            <div>{{ classification.categoryName }}</div>
            <div class="arrow-icon">
              <img src="@/assets/images/icon/arrow.svg" alt=""/>
            </div>
          </div>
          <div class="divider-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CategoryHelper} from "@/plugins/CategoryHelper";
import {listGiftCategoriesApi, updateCart} from '@/plugins/ApiHelper';

export default {
  name: "CategoryList",
  props: {},
  components: {},
  data() {
    return {
      classifications: [
        {id: 2, name: 'シーン', class: 'scenes', list: [], ids: []},
        {id: 3, name: 'ギフトの利用方法', class: 'receives', list: [], ids: []},
        {id: 1, name: 'カテゴリー', class: 'categories', list: [], ids: []},
      ],
    };
  },
  async mounted() {
    // カテゴリーの上位6件取得
    const {category} = await this.fetchListCategories(6)
    this.classifications = [...category]
  },
  methods: {
    async fetchListCategories(limit = 6) {

      const tasks = this.classifications.map((classification) =>
          listGiftCategoriesApi({classification: classification.id})
      );
      let cList = []
      await Promise.all(tasks)
          .then((res) => {
            this.classifications.forEach((classification, i) => {
              const list = res[i];
              this.classifications[i].list = list;
              cList.push(list)
            });
          })
          .catch((err) => {
            console.error(err);
          });
      const storageCategoryList = [].concat(...cList)

      const sceneList = []
      const categoryList = []
      const receiveList = []

      storageCategoryList.forEach(category => {
        if (category.classification === 2) {
          sceneList.push(category)
        } else if (category.classification === 1) {
          categoryList.push(category)
        } else if (category.classification === 3) {
          receiveList.push(category)
        }
      })
      // priority順にソート
      sceneList.sort((a, b) => a.priority - b.priority);
      categoryList.sort((a, b) => a.priority - b.priority);
      categoryList.sort((a, b) => a.priority - b.priority);

      // 上位limit件のみ取得
      if (limit) {
        sceneList.splice(limit)
        categoryList.splice(limit)
        receiveList.splice(limit)
      }

      return {
        "scene": sceneList,
        "category": categoryList,
        "receive": receiveList
      }
    },
    redirectGiftList(categoryId) {
      location.href = '/gifts/search?categories=' + categoryId
    },
  },
};
</script>

<style scoped lang="scss">
.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}

.box-list {
  margin-top: 24px;
  padding: 0 0 30px 0;

  .box-search {
    border-radius: 12px;
    background-size: cover;
    background-position: center center;

    .title-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 16px;

      .title-label {
        @include font-size(16, 22.4);
        font: $font-roboto-700;
        color: black;
      }
    }

    .box-search__condition {
      padding: 24px 0 20px 4px;
      display: flex;
      justify-content: space-between;

      .box-label-container {
        //display: flex;
        flex-direction: column;
        color: $black1;

        .box-label {
          font: $font-roboto-700;
          @include font-size(16, 22.4);
          text-align: left;
        }

        .box-label-text {
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
          // text-align: left;
          margin-top: 4px;
          white-space: pre-line;
        }
      }
    }

    .item-list {

      .category-item {
        font: $font-roboto-400;
        @include font-size(14, 20);
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .arrow-icon {
          width: 16px;
          height: 16px;
          text-align: center;
        }
      }

      .list-info {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 16px 24px;
        border-top: solid 1px rgba($white1, 0.5);

        .list-info-text {
          color: $white1;
          @include font-size(14, 22.4);
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
