<template>
  <div class="gift-list">

    <div class="search" :class="searchClasses">
      <div class="search__condition">
        <div class="search__condition__filter">
          <div class="filter" @click="openFilter">
            <span v-if="isFiltered" class="filterd">詳細絞り込み（{{ filterCount }}）</span>
            <span v-else>詳細絞り込み</span>
          </div>
        </div>
      </div>

      <div class="search__condition">
        <div class="search__condition__total-count">
          <span v-if="isFiltered">検索結果 : </span>
          <span v-else>全</span>
          <span>{{ totalCountDisp }}件</span>
        </div>
        <div class="search__condition__filter-sort">

          <div class="sort" @click="openSort">
            <span>{{ selectedSortCondition?.label }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="gifts">
      <GiftItem
        v-for="gift in gifts"
        :key="gift.giftId"
        :id="gift.giftId"
        :imgUrl="gift.image"
        :brandName="gift.brandName"
        :giftName="gift.giftName"
        :price="gift.price"
        :variationGroup="gift.variationGroup"
        :isTaxDisplay="gift.isTaxDisplay"
        @click-item="openDetail"
        GALabel1="ギフト一覧"
        GALabel2="ギフト選択"
      ></GiftItem>
    </div>

    <ion-infinite-scroll
      v-if="isLoading || hasNext"
      @ionInfinite="ionInfinite"
      :disabled="gifts.length === 0"
    >
      <div class="infinite-scroll-content">
        <transition name="fade">
          <div class="loading">
            <div class="loading__spinner"/>
          </div>
        </transition>
      </div>
    </ion-infinite-scroll>

    <transition name="fade" v-if="isLoading && gifts.length === 0">
      <div class="loading">
        <div class="loading__spinner"/>
      </div>
    </transition>

    <div v-if="!isLoading" class="gifts__message">
      <div v-if="totalCount === 0" class="gifts__message__inner">
        <ion-label color="medium">条件に一致するギフトは見つかりませんでした</ion-label>
        <Button label="絞り込み条件を変更する" type="none" @click="openFilter"/>
      </div>
    </div>

    <GiftSortActionSheet
      ref="giftSortActionSheet"
      :sortConditions="sortConditions"
      @change:sort="changeSort"
    />
  </div>
</template>

<script>
import store from '@/store';
import Button from '@/components/button/Button';
import GiftItem from '@/components/gift/GiftItem';
import GiftSortActionSheet from '@/components/gift/GiftSortActionSheet';
import {mapGetters} from 'vuex';

import {IonLabel, IonList, IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/vue';
import {listGiftsApi} from '@/plugins/ApiHelper';
import {GtmEcommerce, GtmEcommerceItem} from '@//plugins/GtmEcommerce';
import {emitter} from '@/eventBus';

export default {
  name: 'GiftList',
  components: {
    Button,
    GiftItem,
    GiftSortActionSheet,
    IonLabel,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  emits: ['click:openFilter', 'click:openDetail'],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortConditions: [
      {label: '人気順', code: 1},
      {label: '価格 : 安い順', code: 2},
      {label: '価格 : 高い順', code: 3},
    ],
    selectedSortCondition: null,
    sortOrder: 1,
    pageNo: 1,
    defaultFilters: null,
    hasNext: true,
    totalCount: 0,
    totalCountDisp: 0,
    gifts: [],
    isFetching: false,
  }),
  computed: {
    ...mapGetters(['isLoading']),
    searchClasses() {
      let classes = [];
      if (this.stickyHeader) classes.push('sticky');
      return classes;
    },
    isFiltered() {
      return this.filters['filter-count'] > 0;
    },
    filterCount() {
      return this.filters['filter-count'];
    },

  },
  watch: {
    filters: {
      async handler() {
        if (this.$route.name === 'PMG660') {
          let attempts = 0;
          while (this.isFetching && attempts < 10) {
            // console.log('waiting for isFetching to be false', attempts)
            attempts++;
            // isFetchingがfalseになるまで待機
            await new Promise(resolve => setTimeout(resolve, 100));
          }
          await this.filterListGifts();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.defaultFilters = JSON.parse(JSON.stringify(this.filters));
    this.selectedSortCondition = this.sortConditions[0];
    const self = this;
    emitter.on('resetFilterListGifts', async () => {
      self.selectedSortCondition = self.sortConditions[0];
      self.sortOrder = 1;
      self.pageNo = 1;
      self.gifts = [];
    });
  },
  methods: {
    async ionInfinite(e) {
      await this.fetchNextListGifts();
      setTimeout(() => e.target.complete(), 200);
    },
    async fetchListGifts() {
      // console.log('fetchListGifts call', this.filters, this.isFetching)

      // 高速で連続で呼ばれた場合は処理をスキップする。API実行中、かつフィルターがかかっていない場合は処理をスキップする
      if(this.isFetching && !this.isFiltered) {
        console.log('fetchListGifts skip', this.filters, this.isFetching)
        return
      }
      let attempts = 0;
      while (this.isFetching && attempts < 10) {
        // console.log('waiting for isFetching to be false', attempts)
        attempts++;
        // isFetchingがfalseになるまで待機
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      if (!this.hasNext) {
        // 初期化
        this.gifts = [];
      }

      this.isFetching = true

      const data = {
        'sort-order': this.sortOrder,
        'page-no': this.pageNo,
        ...this.filters,
      };

      // console.log('fetchListGifts start', data, this.gifts.length)
      await listGiftsApi(data)
        .then((res) => {
          // console.log('listGiftsApi', res)
          this.totalCount = res['totalCount'];
          this.totalCountDisp = Number(this.totalCount).toLocaleString();
          this.gifts.push(...res['list']);
          this.hasNext = res['hasNext'];
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.totalCount = 0;
            this.totalCountDisp = Number(this.totalCount).toLocaleString();
            this.gifts = [];
            this.hasNext = false;
          } else {
            console.error(err);
            this.$router.push({name: 'PMG510'});
          }
        });
      // console.log('fetchListGifts end', data, this.gifts.length)
      this.isFetching = false
      this.ecommerceViewItemPush();
    },
    async filterListGifts() {
      this.pageNo = 1;
      this.gifts = [];

      let attempts = 0;
      while (this.isFetching && attempts < 10) {
        // console.log('waiting for isFetching to be false', attempts)
        attempts++;
        // isFetchingがfalseになるまで待機
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      await this.fetchListGifts();
    },
    async fetchNextListGifts() {
      if (!this.hasNext) {
        return;
      }
      let attempts = 0;
      while (this.isFetching && attempts < 10) {
        // console.log('waiting for isFetching to be false', attempts)
        attempts++;
        // isFetchingがfalseになるまで待機
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      this.pageNo += 1;
      await this.fetchListGifts();
    },
    initialSort() {
      //URLパラメータに並び順が設定されていたら反映する
      const sortQuery = this.$route.query?.sort;
      if (sortQuery) {
        let index = this.sortConditions.map((e) => e.code).findIndex((e) => e == sortQuery);
        if (index >= 0) {
          this.selectedSortCondition = this.sortConditions[index];
          this.sortOrder = this.selectedSortCondition.code
        }
      }
    },
    openFilter() {
      this.$emit('click:openFilter');
    },
    openDetail(id) {
      this.$emit('click:openDetail', id);
    },
    openSort() {
      this.$refs.giftSortActionSheet.open();
    },
    async changeSort(code) {
      this.sortOrder = code;
      const filterd = this.sortConditions.filter((condition) => condition.code === code);
      this.selectedSortCondition = filterd[0];
      await this.filterListGifts();
    },
    // GA(eコマース) event:view_item_list
    ecommerceViewItemPush() {
      const items = []
      for (let gift of this.gifts) {
        const item = GtmEcommerceItem.getEcommerceItem(gift)
        items.push(item)
      }
      GtmEcommerce.viewItemList(items)
    },
    getGiftData(giftId) {
      return this.gifts.find(item => item.giftId === giftId);
    }
  },
};
</script>

<style scoped lang="scss">
.gift-list {
  margin-top: 14px;

  .search {
    padding: 0 16px;
    background-color: var(--ion-color-light);

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    &__condition {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      &__filter {
        color: $gray10;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        font: $font-roboto-400;

        @include font-size(14, 18);

        .filter {
          cursor: pointer;
          padding: 8px 14px;

          span {
            display: flex;
            align-items: center;
            column-gap: 8px;

            &.filterd {
              font: $font-roboto-700;

              @include font-size(14, 18);
            }
          }

          span::after {
            content: '';
            background-image: url(@/assets/images/icon/gift_filter_20240201.svg);
            display: inline-block;
            height: 14px;
            width: 14px;
          }
        }
      }

      &__total-count {
        padding: 8px 14px;
        align-self: center;
        color: $gray1;
        font: $font-roboto-400;

        @include font-size(14, 18);
      }

      &__filter-sort {
        margin-left: auto;
        color: $gray10;
        display: inline-flex;
        flex-wrap: nowrap;
        margin-left: auto;
        justify-content: space-between;
        font: $font-roboto-400;

        @include font-size(14, 18);


        .sort {
          padding: 8px 14px;
          cursor: pointer;

          span {
            display: flex;
            align-items: center;
            column-gap: 8px;
          }

          span::after {
            content: '';
            background-image: url(@/assets/images/icon/gift_sort_20240201.svg);
            display: inline-block;
            height: 14px;
            width: 14px;
          }
        }

        &:focus {
          border-color: $gold1;
        }
      }

    }
  }

  .gifts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    margin-top: 16px;
    padding: 0 16px 12px;
    gap: 15px;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &__message {
      padding: 32px 0;
      color: $black3;

      &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        font: $font-roboto-400;

        @include font-size(14, 20);
      }
    }
  }

  .loading {
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10000;

    &.fade-enter-active {
      animation: fade-in 0ms;
    }

    &.fade-leave-active {
      animation: fade-in 200ms reverse;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &__spinner {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: solid 7px $gold1;
      border-right-color: transparent;
      animation: rotate 1.1s infinite linear;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  :deep(.button) {
    position: inherit;
    width: auto;
  }
}
</style>
