<template>
  <IonicModal ref="modal" title="購入上限額の超過" @on-close-dom="closeGA">
    <template #body>
      <div class="body">
        <div class="messages">
          <ion-label class="messages__message1">
            {{
              '買い物カゴに登録いただいたギフトが購入上限額を超過しているためご購入いただくことができません。上限以下におさまるように、ギフトを選びなおしてください。'
            }}
          </ion-label>
          <ion-label class="messages__message2">
            {{ '※セキュリティ対策のため購入上限額を設定しております。' }}
          </ion-label>
        </div>
        <div class="purchases">
          <div class="purchases__label">
            <ion-label>{{ periodDisplay }}</ion-label>
            <ion-label>{{ 'お客様の利用済金額' }}</ion-label>
            <ion-label>{{ 'ご利用可能額' }}</ion-label>
          </div>
          <div class="purchases__price">
            <ion-label>¥{{ possiblePriceDisplay }}</ion-label>
            <ion-label>¥{{ usedPriceDisplay }}</ion-label>
            <ion-label>¥{{ availablePriceDisplay }}</ion-label>
          </div>
        </div>
        <div class="buttons">
          <Button label="買い物カゴを確認" type="fill" ref="checkCart" @click="checkCart" />
        </div>
      </div>
    </template>
  </IonicModal>
</template>

<script>
import IonicModal from '@/components/ionic/IonicModal';
import Button from '@/components/button/Button';
import { IonLabel } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';

export default {
  name: 'PurchaseLimitOverModal',
  components: { IonicModal, Button, IonLabel },
  props: {
    purchaseLimit: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['lid']),
    periodDisplay() {
      let period = 0;
      if (this.purchaseLimit?.period) {
        period = this.purchaseLimit?.period;
      }
      return `${period}日あたりの購入可能額`;
    },
    possiblePriceDisplay() {
      let possiblePrice = 0;
      if (this.purchaseLimit?.possiblePrice) {
        possiblePrice = this.purchaseLimit?.possiblePrice;
      }
      return Number(possiblePrice).toLocaleString();
    },
    usedPriceDisplay() {
      let usedPrice = 0;
      let usedPoints = 0
      if (this.purchaseLimit?.usedPrice) {
        usedPrice = Number(this.purchaseLimit?.usedPrice);
      }
      if (this.purchaseLimit?.usedPoints) {
        usedPoints = Number(this.purchaseLimit?.usedPoints);
      }

      const total = usedPrice + usedPoints;
      return Number(total).toLocaleString();
    },
    availablePriceDisplay() {
      let availablePrice = 0;
      if (this.purchaseLimit?.availablePrice) {
        availablePrice = this.purchaseLimit?.availablePrice;
      }
      return Number(availablePrice).toLocaleString();
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    checkCart() {
      gtmSendClick(this.$refs['checkCart'].$el,'購入上限の超過','買い物カゴを確認','');
      this.hideModal();
      const virtualPageUrl = getUrl('/cart');
      gtmDataLayerPush(virtualPageUrl, 'お買い物カゴ');
      this.$router.push({ name: 'PMG700' });
    },
    close() {
      this.hideModal();
    },
    closeGA(dom){
      gtmSendClick(dom,'購入上限の超過','閉じる','');
    },
  },
};
</script>

<style scoped lang="scss">
ion-modal {
  --max-height: 95%;
  --height: 460px;
  align-items: flex-end;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .messages {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__message1 {
      color: $black6;
      font: $font-noto-sans-jp-400;
      @include font-size(14, 22.4);
    }

    &__message2 {
      color: $black6;
      font: $font-noto-sans-jp-400;
      @include font-size(12, 19.2);
    }
  }

  .purchases {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background: var(--ion-color-light);
    border-radius: 12px;

    &__label {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > ion-label {
        color: $gray10;
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
      }
    }

    &__price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;

      & > ion-label {
        color: $black6;
        font: $font-noto-sans-jp-700;
        @include font-size(14, 22.4);
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    :deep(.button) {
      width: 100%;
    }
  }
}
</style>
