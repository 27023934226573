<template>
  <ion-action-sheet
    header="並び替え"
    :is-open="isOpen"
    :buttons="actionSheetButtons"
    @willDismiss="close($event)"
  >
  </ion-action-sheet>
</template>

<script>
import { IonActionSheet } from '@ionic/vue';
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: 'GiftSortActionSheet',
  components: { IonActionSheet },
  props: {
    sortConditions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change:sort'],
  data: () => ({
    isOpen: false,
    actionSheetButtons: [],
  }),
  mounted() {
    this.sortConditions.map((condition) => {
      this.actionSheetButtons.push({
        text: condition.label,
        data: {
          action: 'changeSort',
          code: condition.code,
        },
      });
    });
    this.actionSheetButtons.push({
      text: 'キャンセル',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    });
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close(event) {
      if (
        event.detail.data &&
        event.detail.data.action
        ){
        let label3 = ""; 
        if(event.detail.data.action === 'cancel'){
          label3 = 'キャンセル';
        }
        else{
          let index = this.actionSheetButtons.findIndex((e) => e.data.code !== undefined && e.data.code ==  event.detail.data.code);
          if(index >=0 ){
            label3 = this.actionSheetButtons[index].text;
          }
        }       
        gtmSendClick(this.$el,'ギフト一覧','並べ替え',label3);
      }

      this.isOpen = false;
      if (
        event.detail.data &&
        event.detail.data.action &&
        event.detail.data.action === 'changeSort'
      ) {
        this.$emit('change:sort', event.detail.data.code);
      }
    },
  },
};
</script>
