export const AuthorizationPage = [
];

export const HideFooterMenuPage = [
  'PMG300',
  'PMG310',
  'PMG320',
  'PMG210',
  'au_pay',
];

export const OpenSideMenuPage = [
  'PMG640',
];

export const NotOpenSideMenuToPage = [
  'PMG210',
  'PMG610',
  'PMG640',
  'PMG810',
  'special-feature',
]

export const RetentionGiftFilterPage = [
  'PMG600',
  'PMG610',
  'PMG640',
];

export const ValidationFileSize = Object.freeze({
  image: {
    size: 20971520,
    message: '写真のアップロードサイズ上限（10MB）を超えています。',
  },
  video: {
    size: 314572800,
    message: '動画のアップロードサイズ上限（300MB）を超えています。',
  }
});

export const ValidationFileType = Object.freeze({
  image: {
    type: [
      'image/jpeg',
      'image/png',
      'image/heic',
      'image/gif',
    ],
    message: 'ファイル形式が.jpg/.png/.heic/.gifの写真を選択してください。',
  },
  video: {
    type: [
      'video/mp4',
      'video/quicktime',
    ],
    message: 'ファイル形式が.mp4/.movの動画を選択してください。',
  }
});

/** クッキー名定義 */
export const CookieName = Object.freeze({
  VTKT: 'VTKT', // au ID の認可チケット格納先
})
