<template>
  <div id="wrapper">
    <main>
      <section id="#" class="heading_container">
        <h1>
          <img
            class="img_auto_width"
            src="@/assets/images/special-feature-002/main-visual.png"
            width="375"
            height="493"
            alt="感謝の気持ち 溢れる笑顔 ちょっとした気持ちを伝えてみませんか"
          />
        </h1>
        <p class="sub_text">
          <img
            class="img_auto_width"
            src="@/assets/images/special-feature-002/lead-sentence.png"
            width="375"
            height="269"
            alt='「がんばってるね」「ありがとう」心には浮かぶけど言葉になって出てこない"ちょっとした気持ち"。それをそのまま贈れたら、生活のなかに笑顔が増えていくと思いませんか。ぜひ、あなたの暮らしのいろんなシーンにソーシャルギフトを。'
          />
        </p>
        <nav class="heading_sentence">
          <ul>
            <li>
              <a
                href="javascript:undefined"
                class="nav-link"
                ref="anker01"
                @click="scrollTo('parents')"
                onclick="trEventBe(this,'シーン別ギフト特集','アンカー','口で伝えるのは照れくさい両親に',event);"
              ><span>口で伝えるのは照れくさい両親に</span></a>
            </li>
            <li>
              <a
                href="javascript:undefined"
                class="nav-link"
                ref="anker02"
                @click="scrollTo('child')"
                onclick="trEventBe(this,'シーン別ギフト特集','アンカー','いつも頑張っている子どもに',event);"
              ><span>いつも頑張っている子どもに</span></a>
            </li>
            <li>
              <a
                href="javascript:undefined"
                class="nav-link"
                ref="anker03"
                @click="scrollTo('friend')"
                onclick="trEventBe(this,'シーン別ギフト特集','アンカー','なかなか会えない友人に',event);"
              ><span>なかなか会えない友人に</span></a>
            </li>
            <li>
              <a
                href="javascript:undefined"
                class="nav-link"
                ref="anker04"
                @click="scrollTo('office')"
                onclick="trEventBe(this,'シーン別ギフト特集','アンカー','仕事でお世話になったあの人に',event);"
              ><span>仕事でお世話になったあの人に</span></a>
            </li>
          </ul>
        </nav>
      </section>

      <section id="parents" class="container">
        <h2 class="title">
          <img
            src="@/assets/images/special-feature-002/title-parents.png"
            alt="伝えそびれていた感謝をカタチに。近くにいる存在だからこそ、口で伝えるのは照れくさい。"
          />
        </h2>
        <p class="lead">
          節目や記念日でなくても大丈夫。「いつもありがとう」をソーシャルギフトで届けてみませんか？
        </p>

        <div class="slide">
          <div class="swiper swiper-feature002">
            <div class="swiper-wrapper">
              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="parents01"
                  @click="() => { clickEvent('parents02','シーン両親_02','コメダ珈琲店 eギフト500円_41_500');
                                  ecommerceSelectPromotionPush(41, 'コメダ珈琲店 eギフト500円', 'コメダ珈琲店', 500);
                                  openDetail(41);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/parents/komeda.png"
                    width="240"
                    height="240"
                    alt="コメダ珈琲店"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">コメダ珈琲店</p>
                    <p class="card_title">コメダ珈琲店 eギフト500円</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>


              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="parents03"
                  @click="() => { clickEvent('parents03','シーン両親_03','岐阜 「新杵堂」 抹茶ふわふわ＆抹茶ムース大福_152_2750');
                                  ecommerceSelectPromotionPush(152, '岐阜 「新杵堂」 抹茶ふわふわ＆抹茶ムース大福', 'AOYAMA GIFT SALON (アオヤマギフトサロン)', 2750);
                                  openDetail(152);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/parents/aoyama.png"
                    width="240"
                    height="240"
                    alt="アオヤマギフトサロン"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">
                      AOYAMA GIFT SALON (アオヤマギフトサロン)
                    </p>
                    <p class="card_title">
                      岐阜 「新杵堂」 抹茶ふわふわ＆抹茶ムース大福
                    </p>
                    <p class="card_price">¥2,750<span>税込</span></p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="parents03"
                  @click="() => { clickEvent('parents03','シーン両親_04','てもみんリラクゼーションチケット（2,200円分）_88_2200');
                                  ecommerceSelectPromotionPush(88, 'てもみんリラクゼーションチケット（2,200円分）', 'てもみん・グローバル治療院', 2200);
                                  openDetail(88);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/parents/temomin.png"
                    width="240"
                    height="240"
                    alt="てもみん・グローバル治療院"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">てもみん・グローバル治療院</p>
                    <p class="card_title">
                      てもみんリラクゼーションチケット（2,200円分）
                    </p>
                    <p class="card_price">¥2,200</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination002"></div>
          </div>
        </div>

        <a
          class="button"
          href="/gifts/search?scenes=14#gift-list"
          ref="parents_list"
          @click="clickEvent('parents_list','シーン両親_一覧','ギフトをもっと見る')"
        >ギフトをもっと見る</a>
      </section>

      <section id="child" class="container">
        <h2 class="title">
          <img
            src="@/assets/images/special-feature-002/title-child.png"
            alt="頑張っているから、いつだって応援したい。一生懸命な姿に、小さなギフトで大きなエール。"
          />
        </h2>
        <p class="lead">
          気軽に使えるソーシャルギフトは励ましや応援にもおすすめです。「いつもお疲れさま」をカードと一緒に届けましょう。
        </p>

        <div class="slide">
          <div class="swiper swiper-feature002">
            <div class="swiper-wrapper">
              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="child01"
                  @click="() => { clickEvent('child01','シーン子供_01','ローソンお買い物券（500円）_4_500');
                                  ecommerceSelectPromotionPush(4, 'ローソンお買い物券（500円）', 'ローソン', 500);
                                  openDetail(4);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/child/lawson.png"
                    width="240"
                    height="240"
                    alt="ローソン"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">ローソン</p>
                    <p class="card_title">ローソンお買い物券（500円）</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="child02"
                  @click="() => { clickEvent('child02','シーン子供_02','大戸屋500円ギフトチケット_68_500');
                                  ecommerceSelectPromotionPush(68, '大戸屋500円ギフトチケット', '大戸屋ごはん処', 500);
                                  openDetail(68);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/child/ootoya.png"
                    width="240"
                    height="240"
                    alt="大戸屋ごはん処"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">大戸屋ごはん処</p>
                    <p class="card_title">大戸屋500円ギフトチケット</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="child03"
                  @click="() => { clickEvent('child03','シーン子供_03','ファミリーマート500円お買い物券_37_500');
                                  ecommerceSelectPromotionPush(37, 'ファミリーマート500円お買い物券', 'ファミリーマート', 500);
                                  openDetail(37);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/child/family-mart.jpg"
                    width="240"
                    height="240"
                    alt="ファミリーマート"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">ファミリーマート</p>
                    <p class="card_title">ファミリーマート500円お買い物券</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="child04"
                  @click="() => { clickEvent('child04','シーン子供_04','500円ギフト券_99_500');
                                  ecommerceSelectPromotionPush(99, '500円ギフト券', 'ビアードパパ', 500);
                                  openDetail(99);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/child/beard-papa.png"
                    width="240"
                    height="240"
                    alt="ビアードパパ"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">ビアードパパ</p>
                    <p class="card_title">500円ギフト券</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination002"></div>
          </div>
        </div>

        <a
          class="button"
          href="/gifts/search?scenes=16#gift-list"
          ref="child_list"
          @click="clickEvent('child_list','シーン子供_一覧','ギフトをもっと見る')"
        >ギフトをもっと見る</a>
      </section>

      <section id="friend" class="container">
        <h2 class="title">
          <img
            src="@/assets/images/special-feature-002/title-friend.png"
            width="375"
            height="375"
            alt="なかなか会えなくても、伝えたい。 今日はあの人の誕生日だった！「おめでとう」と祝いたい。"
          />
        </h2>
        <p class="lead">
          例えば住所を知らなくても、スマホで贈れるソーシャルギフトなら大丈夫です。友人からの久しぶりの連絡に話が弾むかもしれませんね。
        </p>

        <div class="slide">
          <div class="swiper swiper-feature002">
            <div class="swiper-wrapper">
              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="frend01"
                  @click="() => { clickEvent('frend01','シーン友人_01','ギフトチケット（500円）_25_500');
                                  ecommerceSelectPromotionPush(25, 'ギフトチケット（500円）', 'ミスタードーナツ', 500);
                                  openDetail(25);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/frend/mister-donut.png"
                    width="240"
                    height="240"
                    alt="ミスタードーナツ"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">ミスタードーナツ</p>
                    <p class="card_title">ギフトチケット（500円）</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="frend02"
                  @click="() => { clickEvent('frend02','シーン友人_02','サーティワン アイスクリーム 500円ギフト券_22_500');
                                  ecommerceSelectPromotionPush(22, 'サーティワン アイスクリーム 500円ギフト券', 'サーティワン アイスクリーム', 500);
                                  openDetail(22);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/frend/31.png"
                    width="240"
                    height="240"
                    alt="サーティワン アイスクリーム"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">サーティワン アイスクリーム</p>
                    <p class="card_title">
                      サーティワン アイスクリーム 500円ギフト券
                    </p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="frend04"
                  @click="() => { clickEvent('frend04','シーン友人_04','【BRUNO online】3,000円 ギフトチケット_188_3000');
                                  ecommerceSelectPromotionPush(188, '【BRUNO online】3,000円 ギフトチケット', 'BRUNO', 3000);
                                  openDetail(188);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/frend/bruno.png"
                    width="240"
                    height="240"
                    alt="BRUNO"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">BRUNO</p>
                    <p class="card_title">
                      【BRUNO online】3,000円 ギフトチケット
                    </p>
                    <p class="card_price">¥3,000</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination002"></div>
          </div>
        </div>

        <a
          class="button"
          href="/gifts/search?scenes=13#gift-list"
          ref="frend_list"
          @click="clickEvent('frend_list','シーン友人_一覧','ギフトをもっと見る')"
        >ギフトをもっと見る</a>
      </section>

      <section id="office" class="container">
        <h2 class="title">
          <img
            src="@/assets/images/special-feature-002/title-office.png"
            width="375"
            height="375"
            alt="お疲れさまとありがとうを一緒に。仕事でお世話になったあの人に、ちょっとしたお礼の気持ちを込めたギフト。"
          />
        </h2>
        <p class="lead">
          みんなで行っても、一人で行っても便利なカフェチケットは気軽に贈るお礼の代表格。ソーシャルギフトなら、かさばらないところも嬉しいですよね。
        </p>

        <div class="slide">
          <div class="swiper swiper-feature002">
            <div class="swiper-wrapper">
              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="office01"
                  @click="() => { clickEvent('office01','シーン職場_01','タリーズデジタルギフト（500円）_43_500');
                                  ecommerceSelectPromotionPush(43, 'タリーズデジタルギフト（500円）', 'TULLY\'S COFFEE', 500);
                                  openDetail(43);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/office/tullys.png"
                    width="240"
                    height="240"
                    alt="TULLY'S COFFEE"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">TULLY'S COFFEE</p>
                    <p class="card_title">タリーズデジタルギフト（500円）</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="office02"
                  @click="() => { clickEvent('office02','シーン職場_02','カフェギフトチケット500円_8_500');
                                  ecommerceSelectPromotionPush(8, 'カフェギフトチケット500円', 'サンマルクカフェ', 500);
                                  openDetail(8);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/office/saint-marc.png"
                    width="240"
                    height="240"
                    alt="サンマルクカフェ"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">サンマルクカフェ</p>
                    <p class="card_title">カフェギフトチケット500円</p>
                    <p class="card_price">¥500</p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="office03"
                  @click="() => { clickEvent('office03','シーン職場_03','【お持ち帰り限定】対象のハーゲンダッツ（税込351円）いずれか1つ_1_351');
                                  ecommerceSelectPromotionPush(1, '【お持ち帰り限定】対象のハーゲンダッツ（税込351円）いずれか1つ', 'ローソン', 351);
                                  openDetail(1);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/office/haagen-dazs.png"
                    width="240"
                    height="240"
                    alt="ローソン"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">ローソン</p>
                    <p class="card_title">
                      【お持ち帰り限定】対象のハーゲンダッツ（税込351円）いずれか1つ
                    </p>
                    <p class="card_price">¥351<span>税込</span></p>
                  </div>
                </a>
              </div>

              <div class="swiper-slide card">
                <a
                  href="javascript:void(0);"
                  ref="office03"
                  @click="() => { clickEvent('office04','シーン職場_04','すかいらーくご優待券(1000円)_30_1000');
                                  ecommerceSelectPromotionPush(30, 'すかいらーくご優待券(1000円)', 'すかいらーく', 1000);
                                  openDetail(30);}"
                >
                  <img
                    src="@/assets/images/special-feature-002/carousel/office/skylark.png"
                    width="240"
                    height="240"
                    alt="すかいらーく"
                  />
                  <div class="card_text_container">
                    <p class="card_brand_name">すかいらーく</p>
                    <p class="card_title">すかいらーくご優待券(1000円)</p>
                    <p class="card_price">¥1,000</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination002"></div>
          </div>
        </div>

        <a
          class="button"
          href="/gifts/search?scenes=17#gift-list"
          ref="office_list"
          @click="clickEvent('office_list','シーン職場_一覧','ギフトをもっと見る')"
        >ギフトをもっと見る</a>
      </section>

      <div class="app_induction">
        <div class="contents_gap">
          <p class="app_induction_title">便利・お得なご案内</p>
          <img
            class="plus-message-logo"
            src="@/assets/images/special-feature-002/plus-message-logo.png"
            width="143"
            height="34"
            alt="プラスメッセージ"
          />
          <div class="app_description">
            <p>
              購入履歴へのアクセスは「＋メッセージ」のプラメギフト公式アカウントのフォローが便利です。<br/>また同アカウントではギフトに関するお得な情報を配信します。是非ご利用ください。
            </p>
          </div>
        </div>
        <div class="contents_gap">
          <div class="step_box_container">
            <div class="step_box">
              <p>Step</p>
              <span>1</span>
            </div>
            <p>＋メッセージアプリをダウンロード</p>
          </div>
          <div class="badge_box">
            <a
              href="https://apps.apple.com/jp/app/apple-store/id1358576433"
              ref="Appeal01"
              @click="clickEvent('Appeal01','アプリ訴求','Apple Storeからダウンロード')"
            >
              <img
                src="@/assets/images/special-feature-002/apple_store.png"
                width="143"
                height="43"
                alt="Apple Storeからダウンロード"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.kddi.android.cmail"
              ref="Appeal02"
              @click="clickEvent('Appeal02','アプリ訴求','Google Playからダウンロード')"
            >
              <img
                src="@/assets/images/special-feature-002/google-play-badge.png"
                width="142"
                height="55"
                alt="Google Playで手に入れよう"
              />
            </a>
          </div>
        </div>
        <div class="contents_gap">
          <div class="step_box_container">
            <div class="step_box">
              <p>Step</p>
              <span>2</span>
            </div>
            <p>公式アカウントをフォロー</p>
          </div>
          <div class="step2_contents">
            <a
              href="https://bot.plus-msg.com/?botId=sip:audigitalgift65@maap.plus-msg.com&msg="
            >
              <img
                src="@/assets/images/special-feature-002/plamegift_official_account.png"
                width="295"
                height="56"
                alt="プラメギフト公式アカウント"
              />
            </a>
            <p class="caution">
              ※＋メッセージの最新バージョンをダウンロードしてからタップしてください
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { gtmSendClick } from "@/plugins/GtmHelper";
import { GtmEcommerce, GtmEcommerceItem } from '@/plugins/GtmEcommerce';

export default {
  name: "SpecialFeature002",
  components: {},
  data: () => ({
    gifts: [],
    promotion_id: "B2",
    swiper: null
  }),
  watch: {
    $route() {
      if (this.swiper && this.$route.name === "special-feature") {
        this.swiper.forEach(element => {
          element.destroy()
        });
        this.initSwiper();
      }
    },
  },
  mounted() {
    this.initSwiper();
    this.ecommerceViewPromotionPush()
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper-feature002", {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination002",
          clickable: true,
        },
        on: {
          resize: () => {
            this.swiper.forEach(element => {
              element.autoplay.start();
            });
          },
        },
      });
    },
    clickEvent(refName, label2, label3) {
      gtmSendClick(this.$refs[refName], "シーン別ギフト特集", label2, label3);
    },
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        el.scrollIntoView({
          behavior: 'smooth',
        });
      } catch (error) {
        console.error(error);
      }
    },
    // GA(eコマース) event: view_promotion
    ecommerceViewPromotionPush() {
      const items = []
      this.pushItems()
      for (const gift of this.gifts) {
        const item = GtmEcommerceItem.getEcommerceItem(gift, false, true)
        items.push(item)
      }
      GtmEcommerce.viewPromotion(this.promotion_id, "シーン別ギフト特集", items)
    },
    pushItems() {
      const data = [
        // 伝えそびれていた感謝をカタチに。近くにいる存在だからこそ、口で伝えるのは照れくさい。
        {
          "giftId": 41,
          "giftName": "コメダ珈琲店 eギフト500円",
          "brandName": "コメダ珈琲店",
          "price": 500
        },
        {
          "giftId": 152,
          "giftName": "岐阜 「新杵堂」 抹茶ふわふわ＆抹茶ムース大福",
          "brandName": "AOYAMA GIFT SALON (アオヤマギフトサロン)",
          "price": 2750
        },
        {
          "giftId": 88,
          "giftName": "てもみんリラクゼーションチケット（2,200円分）",
          "brandName": "てもみん・グローバル治療院",
          "price": 2200
        },
        // 頑張っているから、いつだって応援したい。一生懸命な姿に、小さなギフトで大きなエール。
        {
          "giftId": 4,
          "giftName": "ローソンお買い物券（500円）",
          "brandName": "ローソン",
          "price": 500
        },
        {
          "giftId": 68,
          "giftName": "大戸屋500円ギフトチケット",
          "brandName": "大戸屋ごはん処",
          "price": 500
        },
        {
          "giftId": 37,
          "giftName": "ファミリーマート500円お買い物券",
          "brandName": "ファミリーマート",
          "price": 500
        },
        {
          "giftId": 99,
          "giftName": "500円ギフト券",
          "brandName": "ビアードパパ",
          "price": 500
        },
        // なかなか会えなくても、伝えたい。 今日はあの人の誕生日だった！「おめでとう」と祝いたい。
        {
          "giftId": 25,
          "giftName": "ギフトチケット（500円）",
          "brandName": "ミスタードーナツ",
          "price": 500
        },
        {
          "giftId": 22,
          "giftName": "サーティワン アイスクリーム 500円ギフト券",
          "brandName": "サーティワン アイスクリーム",
          "price": 500
        },
        {
          "giftId": 188,
          "giftName": "【BRUNO online】3,000円 ギフトチケット",
          "brandName": "BRUNO",
          "price": 3000
        },
        // お疲れさまとありがとうを一緒に。仕事でお世話になったあの人に、ちょっとしたお礼の気持ちを込めたギフト。
        {
          "giftId": 43,
          "giftName": "タリーズデジタルギフト（500円）",
          "brandName": "TULLY'S COFFEE",
          "price": 500
        },
        {
          "giftId": 8,
          "giftName": "カフェギフトチケット500円",
          "brandName": "サンマルクカフェ",
          "price": 500
        },
        {
          "giftId": 1,
          "giftName": "【お持ち帰り限定】対象のハーゲンダッツ（税込351円）いずれか1つ",
          "brandName": "ローソン",
          "price": 351
        },
        {
          "giftId": 30,
          "giftName": "すかいらーくご優待券(1000円)",
          "brandName": "すかいらーく",
          "price": 1000
        },
      ]

      this.gifts = data
    },
    // GA(eコマース) event: select_promotion
    ecommerceSelectPromotionPush(giftId, giftName, brandName, price) {
      const items = []
      const data = {
        "giftId": giftId,
        "giftName": giftName,
        "brandName": brandName,
        "price": price
      }
      const item = GtmEcommerceItem.getEcommerceItem(data, false, true)
      items.push(item)
      GtmEcommerce.selectPromotion(this.promotion_id, "シーン別ギフト特集", items)
    },
    // ギフト詳細に遷移
    openDetail(id) {
      this.$router.push({ name: 'PMG610', params: { giftId: id } });
    },
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
<style src="@/assets/css/special-feature-002.css" scoped></style>
