<template>
  <Layout
    :title="title"
    :showMenu="false"
    :showBack="false"
    :show-custom-back="true"
    :custom-back-func="backView"
    backLabel="戻る"
  >
    <div class="content">
      <component
        v-if="currentFeature"
        :key="currentFeature.page"
        :is="currentFeature.component"
        :ref="'Content_' + currentFeature.page"
        :data-title="currentFeature.title"
      ></component>
    </div>
    <Loading :is-show="isLoading"/>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import Loading from '@/components/loading/Loading';
import {mapGetters} from 'vuex';
import {queryStringDelete} from '@/plugins/Utils'
import {SPECIAL_FEATURE_PAGE} from "@/constants/router";
import SpecialFeatureSwi001 from '@/components/specialFeature/SpecialFeature001';
import SpecialFeatureSwi002 from '@/components/specialFeature/SpecialFeature002';
// 2024年バレンタイン対応
// import SpecialFeatureSwi004 from '@/components/specialFeature/SpecialFeature004';


export default {
  name: 'SpecialFeatureView',
  components: {
    Layout,
    Loading,
    SpecialFeatureSwi001,
    SpecialFeatureSwi002,
    // 2024年バレンタイン対応
    // SpecialFeatureSwi004,
  },
  props: {},
  data() {
    return {
      title: null,
      //表示する特集ページを管理
      specialFeatures: SPECIAL_FEATURE_PAGE,
      page: false,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),

    currentFeature(){
      return this.specialFeatures.find(e => e.page === Number(this.page));
    },
  },
  watch: {

    page(newValue) {
      if (newValue === false) {
        this.$router.push({name: 'PMG510'});
      }
    },
  },

  mounted() {

  },

  ionViewWillEnter(){
    
    if (!this.checkSpecialPage()) {
      console.warn(`特集ページが存在しません。page: ${this.page}`)
      this.$router.push({name: 'PMG510'});

      return;
    }

    const feature = this.specialFeatures.find(item => item.page === this.page);
    this.setTitle(feature.name);

  },
  methods: {
    setTitle(title) {
      this.title = title;
    },
    backView() {
      this.$router.push({name: 'PMG600'});
    },
    /*
      * 特集ページの存在チェック
     */
    checkSpecialPage() {

      const pageNumber = Number(queryStringDelete(this.$route?.params?.page))
      if (pageNumber || pageNumber === 0) {
        this.page = pageNumber;
      }else {
        this.page = false;
      }

      const feature = this.specialFeatures.find(item => item.page === this.page);

      return feature !== undefined;

    },

  },
};
</script>

<style scoped lang="scss">
.button-back {
  font: $font-roboto-400;
  font-size: 14px;
  line-height: 18.2px;
  color: $gold2;
  display: flex;
  align-items: center;
}

ion-toolbar {
  height: 74px;
  display: flex;
  align-items: flex-end;

  ion-title {
    font: $font-roboto-400;
    font-size: 16px;
    line-height: 22.4px;
    color: $black6;
  }
}
</style>
