<template>
  <div class="form-date">
    <input v-model="value" class="form-date__input" type="date">
    <input :value="formatCardDate(value)" class="form-date__dummy" type="text" :placeholder="placeholder">
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'FormDate',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'YYYY.M.D',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
  },
  methods: {
    formatCardDate(date) {
      return date
        ? DateTime.fromISO(date).toFormat('yyyy.M.d')
        : '';
    },
  },
}
</script>

<style scoped lang="scss">
.form-date {
  position: relative;
  width: 100%;
  height: 38px;

  &__input,
  &__dummy {
    position: absolute;
    font: $font-inter-400;

    @include font-size(16, 20);
  }

  &__input {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 8px 12px;
    background: $white1;
    border: solid 1px $gray2;
    border-radius: 6px;
    appearance: none;
    outline: none;
    overflow: visible;

    &:focus {
      border-color: $gold1;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 6px;
      padding: 0;
      z-index: 1;

      &:focus,
      &:active {
        border: solid 1px $gold1;
      }
    }

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      appearance: none;
    }
  }

  &__dummy {
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    width: calc(100% - 10px);
    padding: 4px 8px;
    background-color: $white1;
    background-image: url("../../assets/images/icon/calendar.svg");
    background-position: right 8px center;
    background-size: 12px auto;
    background-repeat: no-repeat;
    border: none;
    color: $black1;
    pointer-events: none;
    z-index: 1;

    &::placeholder {
      color: $gray3;
    }
  }
}
</style>
