<template>
  <div class="animation-background">
    <div
      v-for="(image, i) in images"
      :key="i"
      class="animation-background__image"
      :class="getImageClasses(i)"
      :style="getImageStyle(image)"
    />
  </div>
</template>

<script>
export default {
  name: 'AnimationBackground',
  props: {
    images: {
      type: Array,
      default: () => ([]),
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFirst: true,
    activeIndex: null,
  }),
  methods: {
    async play() {
      if (this.disableAnimation) return false;

      this.activeIndex = 0;
      await new Promise(resolve => setTimeout(resolve, 13300));
      this.isFirst = false;
      this.goToNextImage();
      setInterval(() => {
        this.goToNextImage();
      }, 5800);
    },
    goToNextImage() {
      this.activeIndex = this.activeIndex < this.images.length - 1 ? this.activeIndex + 1 : 0;
    },
    getImageClasses(index) {
      let classes = [];

      if (this.activeIndex !== null) {
        // next
        if (index === this.activeIndex + 1 || index === 0 && this.activeIndex === this.images.length - 1) {
          classes.push('next');
        }

        // active
        if (index === this.activeIndex) {
          classes.push('active');

          if (this.isFirst) {
            classes.push('is-first');
          }
        }
      }

      return classes;
    },
    getImageStyle(image) {
      return {
        backgroundImage: `url(${image})`,
      }
    },
  }
}
</script>

<style scoped lang="scss">
.animation-background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    z-index: 0;

    &:first-child {
      z-index: 1;
    }

    &.next {
      z-index: 2;
    }

    &.active {
      z-index: 3;
      animation-name: scale;
      animation-timing-function: linear;
      animation-duration: 5800ms;

      &.is-first {
        animation-name: scale-first;
        animation-duration: 13300ms;
      }
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }

    86.3% {
      transform: scale(1.05);
      opacity: 1;
    }

    100% {
      transform: scale(1.05);
      opacity: 0;
    }
  }

  @keyframes scale-first {
    0% {
      transform: scale(1);
    }

    94% {
      transform: scale(1.125);
      opacity: 1;
    }

    100% {
      transform: scale(1.125);
      opacity: 0;
    }
  }
}
</style>
