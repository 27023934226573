
<template>
  <div class="brand-list" ref="scrollContainer" @scroll="handleScroll">
    <div class="search">
      <div class="search__condition">
        <div class="brand-label-container">
          <span class="brand-label">ブランドから探す</span>
          <span class="search-brand" @click="openBrandlist">すべてのブランド</span>
        </div>
        <div class="search__condition__brand">
          <BrandItem v-for="brand in brands" :key="brand.brandId" :brandName="brand.brandName" :img-src="brand.image_file" @click="showSameBrandGift(brand.brandId)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandItem from '@/components/brand/BrandItem.vue'
import { ListBrandsApi } from "@/plugins/ApiHelper";
import { mapGetters } from 'vuex';

export default {
  name: 'BrandList',
  components: {
    BrandItem,
  },
  emits: ['click:openBrandlist'],
  data() {
    return {
      brands: [],
      itemsPerPage: 5,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters(['isLoading']),

  },
  mounted() {
    this.fetchListBrandsApi()
  },
  methods: {
    openBrandlist() {
      this.$router.push({ name: 'PMG650' });
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        // スクロールが一番下に到達したら、新しいページをロード
        this.loadMoreData();
      }
    },
    async fetchListBrandsApi() {
      return new Promise((resolve) => {
        const data = {
          'limit': 10,
        };
        ListBrandsApi(data)
          .then(async (res) => {
            for(let brand of res){
              const data = {
                "brandId": brand.brandId,
                "brandName": brand.brandName,
                "image_file": `${process.env.VUE_APP_GIFT_BRAND_IMG_BASE_URL}/${brand.image_file}`
              }
              this.brands.push(data)
            }
            resolve();
          })
          .catch((err) => {
            console.error(err)
          });
      });
    },
    // ギフト一覧に遷移
    showSameBrandGift(brandId) {
      this.$router.push({
        name: 'PMG620',
        params: {
          brandId: brandId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.brand-list {
  margin-top: 24px;
  .search {
    background-color: var( --ion-color-light);

    .brand-label {
      @include font-size(16, 22.4);
      font: $font-roboto-700;
      color: black;
    }

    .search-brand {
      @include font-size(14, 18.2);
      color: #8C6D46;
      margin-right: 4px;
      cursor: pointer;
    }

    .brand-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 3px 16px;
    }

    .search__condition__brand {
      display: flex;
      overflow-x: auto;
      margin-bottom: 24px;
      padding: 0 16px;
      gap: 12px;
      -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
    }
  }
}
</style>
