<template>
  <Layout
    ref="layout"
    :title="brandName"
    class="pmg620"
    :showMenu="false"
    :showBack="true"
    :isShowCloseWindow="true"
    backLabel="戻る"
  >
    <BrandIntroduction
      v-show="!isLoading"
      :brand-name="brandName"
      :brand-description="brandDescription"
      :brand-image-url="brandImageUrl"
    ></BrandIntroduction>

    <div class="gifts">
      <template v-if="!isLoading">
        <template v-if="gifts.length > 0">
          <GiftItem
            v-for="gift in gifts"
            :key="gift.giftId"
            :id="gift.giftId"
            :imgUrl="gift.image"
            :brandName="gift.brandName"
            :giftName="gift.giftName"
            :price="gift.price"
            :variationGroup="gift.variationGroup"
            :isTaxDisplay="gift.isTaxDisplay"
            @click-item="openDetail"
            GALabel1="ブランド商品一覧"
            GALabel2="ギフト選択"
          ></GiftItem>
         </template>
        <template v-else>
          <div class="notgifts">
            <div class="message">
              <div class="bold">現在取り扱い中のギフトがありません</div>
              <div class="main">ギフト一覧から商品を選びなおしてください。</div>
              </div>
            <div class="button">
              <Button
                label="ギフト一覧へ"
                @click="showGiftList"
              />
            </div>
          </div>
        </template>
      </template>
      <ion-infinite-scroll
        v-if="gifts.length > 0 && (isLoading || hasNext)"
        @ionInfinite="ionInfinite"
        :disabled="gifts.length === 0"
      >
        <div class="infinite-scroll-content">
          <transition name="fade">
            <div class="loading">
              <div class="loading__spinner" />
            </div>
          </transition>
        </div>
      </ion-infinite-scroll>
      <transition name="fade" v-if="isLoading && gifts.length === 0">
        <div class="loading">
          <div class="loading__spinner" />
        </div>
      </transition>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import { getSameBrandGift } from '@/plugins/ApiHelper';
import { mapGetters } from 'vuex';
import GiftItem from '@/components/gift/GiftItem';
import BrandIntroduction from '@/components/brand/BrandIntroduction';
import Button from '@/components/button/Button';
import { IonInfiniteScroll } from '@ionic/vue';
import { GtmEcommerce, GtmEcommerceItem } from '@//plugins/GtmEcommerce';

export default {
  name: 'PMG620',
  components: {
    Layout,
    GiftItem,
    BrandIntroduction,
    Button,
    IonInfiniteScroll,
  },
  data() {
    return {
      pageNo: 1,
      hasNext: true,
      gifts: [],
      brandName: null,
      brandDescription: null,
      brandImageUrl: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    brandId() {
      return Number(this.$route.params.brandId);
    },
  },
  async mounted() {
    await this.getBrandGiftList(); 
    this.isLoading = false; 
  },
  methods: {
    async ionInfinite(e) {
      await this.fetchNextListGifts();
      setTimeout(() => e.target.complete(), 200);
    },
    async fetchNextListGifts() {
      if (!this.hasNext) {
        return;
      }
      await this.getBrandGiftList();
    },
    async getBrandGiftList() {
      const data = {
        'brand-id': this.brandId,
        'page-no': this.pageNo,
      };
      try {
        const res = await getSameBrandGift(data); 
        const brand = res.brand;
        this.brandName = brand.name;
        this.brandDescription = brand.description;
        this.brandImageUrl = brand.image_file;
        this.gifts = [...this.gifts, ...res.gift];
        this.hasNext = res.has_next;
        this.pageNo++;
        this.ecommerceViewItemPush();
      } catch (err) {
        console.error(err);
        if (err.response && err.response.status === 400) {
          this.brandDescription = null;
          this.brandImageUrl = null;
          this.gifts = [];
          this.hasNext = false;
          this.pageNo = 1;
        } else {
          console.error(err);
          this.$router.push({ name: 'PMG510' });
        }
      }
    },
    openDetail(id) {
      this.ecommerceSelectItemPush(id)
      this.$refs.layout.gtmPush('ギフトの詳細', `/gifts/${id}`);
      this.$router.push({ name: 'PMG610', params: { giftId: id } });
    },
    // ギフト一覧に遷移
    showGiftList() {
      this.$router.push({ name: 'PMG600'});
    },
    backView() {
      this.$router.go(-1);
    },
    clickItem(index) {
      this.giftId = this.items[index].giftId;
      this.changeItem(index);
    },
    // GA(eコマース) event: view_item
    ecommerceViewItemPush() {
      const items = []
      for(const gifts of this.gifts){
        const item = GtmEcommerceItem.getEcommerceItem(gifts)
        items.push(item)
      }
      GtmEcommerce.viewItemList(items)
    },
    // GA(eコマース) event: select_item
    ecommerceSelectItemPush(giftId) {
      const selectGiftData = this.getGiftData(giftId);
      let ecommerceItems = [];
      const item = GtmEcommerceItem.getEcommerceItem(selectGiftData);
      ecommerceItems.push(item);
      GtmEcommerce.selectItem(ecommerceItems);
    },
    getGiftData(giftId) {
      return this.gifts.find(item => item.giftId === giftId);
    },
  },
};
</script>

<style scoped lang="scss">
ion-content {
  --background: #{$modelBackground};
}
ion-title {
  color: $black6;
}
ion-buttons {
  --ion-color-primary: #{$gold2};
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gifts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  overflow: auto;
  padding: 12px;
  gap: 16px;
  width: 100%;

  .notgifts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: 0.15px;
   
    .message {
      margin: 12px 0;
      max-width: 300px;
      color: $gray9;

      .bold{
        font: $font-noto-sans-jp-700;
        @include font-size(16, 25.6);
        margin-bottom: 1rem;
      }
      .main{
        font: $font-noto-sans-jp-400;
        @include font-size(16, 25.6);
        margin-bottom: 0.2rem;
        white-space: nowrap;
      }
    }

    .button{
      width: 270px;
      height: 45px;
      max-width: 95%;
    }
  
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__next {
    display: flex;
    justify-content: center;
    padding: 32px 0;
    width: 100%;
  }

  &__message {
    padding: 32px 0;
    color: $black3;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      font: $font-inter-400;

      @include font-size(14, 20);
    }
  }
}

.loading {
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10000;

    &.fade-enter-active {
      animation: fade-in 0ms;
    }

    &.fade-leave-active {
      animation: fade-in 200ms reverse;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &__spinner {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: solid 7px $gold1;
      border-right-color: transparent;
      animation: rotate 1.1s infinite linear;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

ion-modal {
  --border-radius: 12px 12px 0px 0px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  padding-top: 40px;
}
</style>
