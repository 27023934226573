<template>
  <ion-menu ref="menu" menu-id="app-menu" content-id="main-content" :swipe-gesture="false">
    <ion-content class="side-menu">
      <div class="content">
        <div class="content__header">
          <ion-menu-toggle><button class="close" /></ion-menu-toggle>
        </div>

        <div class="content__user-info">
          <div class="content__user-info__item">
            <img class="auid__logo" src="@/assets/images/auid.png" />
            <p v-if="isAuIdAuthorization" class="auid__aid">{{ aid }}</p>
            <div v-if="!isAuIdAuthorization" class="auid__loggedout__message">
              {{ auIdMessage }}
            </div>
            <Button
              v-if="!isAuIdAuthorization"
              label="au IDでログイン"
              type="fill-orange"
              ref="login"
              @click="login"
            />
          </div>

          <div v-if="isAuIdAuthorization" class="content__user-info__item">
            <img class="ponta__logo" src="@/assets/images/ponta.png" />
            <PontaPoint :is-mini="false"></PontaPoint>
          </div>

        </div>

        <ul class="content__menu">
          <li
            v-for="m in menu"
            :key="m.key"
            class="content__menu__item"
            @click="menuAction(m.action, m.url, m.name)"
          >
            {{ m.name }}
          </li>
          <li
            v-if="isAuIdAuthorization"
            class="content__menu__item"
            @click="menuAction('logout', null, 'ログアウト')"
          >
            ログアウト
          </li>
        </ul>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import Button from '@/components/button/Button';
import { mapGetters } from 'vuex';

import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';
import { auIdLogin, auIdLogout } from '@/plugins/AuId';
import { IonMenu, IonContent, IonMenuToggle } from '@ionic/vue';
import _ from 'lodash';
import { emitter } from '@/eventBus';
import store from '@/store';

export default {
  name: 'SideMenu',
  components: {
    Button,
    IonMenu,
    IonContent,
    IonMenuToggle,
  },
  data: () => ({
    auIdMessage:
      'auIDでログインいただくと、ギフト購入履歴をご確認いただけます。',
    menu: [
      {
        name: 'ご利用ガイド',
        action: 'page',
        url: 'guide',
      },
      {
        name: 'よくある質問・お問い合わせ',
        action: 'page',
        url: 'faq',
      },
      {
        name: 'プラメギフトとは',
        action: 'page',
        url: 'about',
      },
      {
        name: '利用規約',
        action: 'page',
        url: 'rules',
      },
      {
        name: 'プライバシーポリシー',
        action: 'link',
        url: process.env.VUE_APP_STATIC_PAGE_PRIVACY_POLICY,
      },
      {
        name: '動作環境・Cookie情報の利用について',
        action: 'link',
        url: process.env.VUE_APP_STATIC_PAGE_OPERATING_ENVIRONMENT,
      }
    ],
    availablePrice: '...',
  }),
  computed: {
    ...mapGetters(['aid', 'isAuIdAuthorization', 'getAvailablePrice']),
  },
  watch: {
    getAvailablePrice(newValue) {
      if (_.isNumber(newValue)) {
        this.availablePrice = '¥' + Number(newValue).toLocaleString();
      } else {
        this.availablePrice = '-----';
      }
    },
  },
  mounted() {
    const self = this;
    emitter.on('showSideMenu', (status) => {
      if (status) {
        self.$refs.menu.$el.open(false);
      } else {
        self.$refs.menu.$el.close(false);
      }
    });
  },
  methods: {
    login() {
      gtmSendClick(this.$refs['login'].$el,'ハンバーガーメニュー','au IDでログイン','');
      const redirectUrl = process.env.VUE_APP_BASE_APP_URL + this.$route.path;
      auIdLogin(redirectUrl);
    },
    logout() {
      // カート等クリア
      store.commit('setCartItems', []);
      store.commit('setCardOriginId', null);
      store.commit('clearAuPayProcessInfo');
      store.commit('clearAidLid');
      store.commit('setAuIdAuthorization', false);
      //
      const redirectUrl = process.env.VUE_APP_BASE_APP_URL + this.$route.path;
      auIdLogout(redirectUrl);
    },
    menuAction(action, url, title) {
      switch (action) {
        case 'link':
          gtmDataLayerPush(url, title);
          window.open(url, '_blank');
          break;
        case 'logout':
          this.logout();
          break;
        case 'page':
          this.$refs.menu.$el.close();
          const virtualPageUrl = getUrl(`/gifts/static/${url}`);
          gtmDataLayerPush(virtualPageUrl, title);
          this.$router.push({
            name: 'PMG640',
            params: { url: url, title: title},
            query: { showSideMenu: true },
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  .content {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 12px;
      height: 48px;

      .close {
        height: 24px;
        width: 24px;

        &.close {
          background-image: url('../../assets/images/icon/close_button.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &__user-info {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 12px 20px;
        gap: 4px;

        > .auid {
          &__logo {
            height: 12px;
          }

          &__aid {
            margin: 8px 0 4px;
            color: $black6;
            font: $font-roboto-700;
            @include font-size(16, 22.4);
          }

          &__loggedout {
            &__message {
              padding: 10px 0;
              color: $black6;
              font: $font-noto-sans-jp-400;
              @include font-size(12, 19.2);
            }
          }
        }

        > .ponta {
          &__logo {
            height: 18px;
          }
        }

        > .purchase-available {
          &__label {
            color: $black6;
            font: $font-noto-sans-jp-400;
            @include font-size(12, 19.2);
          }

          &__price {
            color: var(--ion-color-tertiary);
            font: $font-roboto-700;
            @include font-size(16, 22.4);
          }

          &__message {
            color: $gray10;
            font: $font-noto-sans-jp-400;
            @include font-size(10, 16);
          }
        }

        :deep(.button.fill-orange) {
          position: inherit;
          margin: 10px 0;
          width: 100%;
          height: 49px;
          @include font-size(16, 20.8);
        }
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      padding: 16px 20px;
      gap: 24px;

      &__item {
        color: $black6;
        font: $font-roboto-400;
        cursor: pointer;

        @include font-size(14, 16.41);
      }
    }
  }
}
</style>
