<template>
  <Layout
    ref="layout"
    title="カード一覧"
    class="pmg300"
    :showMenu="false"
    :showBack="false"
    :showClose="false"
    :showCustomClose="true"
    :customCloseFunc="customCloseToCart"
  >
    <template #subHeader>
      <div class="pmg300__header">
        <div class="messge">ギフトと一緒に贈るカードのデザインテンプレートを選んでください。</div>
      </div>

      <ul class="pmg300__category">
        <li
          v-for="category in categories"
          :key="category.categoryId"
          class="item"
          color="medium"
          @click="clickCategory(category.categoryId)"
        >
          <ion-button
            class="ion-no-margin"
            size="small"
            shape="round"
            :fill="categoryFill(category.categoryId)"
          >
            {{ category.categoryName }}
          </ion-button>
        </li>
      </ul>
    </template>

    <div class="pmg300__list">
      <SampleItem
        v-for="(design, i) in activeDesigns"
        :key="i"
        :data="design"
        @click:sample="clickSample(design,'design_'+i)"
        :ref="'design_'+i"
      />
    </div>

    <GiftToast :cart-items="getCartItems" />

    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import SampleItem from '@/components/layout/SampleItem';
import Loading from '@/components/loading/Loading';
import GiftToast from '@/components/gift/GiftToast';
import store from '@/store';

import { mapGetters } from 'vuex';
import { IonButton, IonItem, IonLabel } from '@ionic/vue';

import { listCategoriesApi, listDesignsApi } from '@/plugins/ApiHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";


export default {
  name: 'PMG300',
  components: {
    Layout,
    SampleItem,
    Loading,
    GiftToast,
    IonButton,
    IonItem,
    IonLabel,
  },
  data: () => ({
    categories: null,
    activeCategory: [],
    allDesigns: [],
    designs: {},
    form: {},
  }),
  computed: {
    ...mapGetters(['isLoading', 'getCartItems']),
    activeDesigns() {
      return this.designs[this.activeCategory.categoryId];
    },
  },
  async mounted() {
    await this.fetchListCategories();
    await this.fetchListDesigns();
    this.checkCartItems();
  },
  methods: {
    async fetchListCategories() {
      return new Promise((resolve) => {
        listCategoriesApi()
          .then((res) => {
            this.categories = res;
            if (this.categories.length > 0) {
              this.activeCategory = res[0];
            }
            resolve();
          })
          .catch(() => {
            this.$router.push({ name: 'PMG510' });
          });
      });
    },
    async fetchListDesigns() {
      const promises = this.categories.map((category) =>
        listDesignsApi({ 'category-id': category.categoryId })
      );
      const allDesigns = [];
      await Promise.all(promises)
        .then((res) => {
          this.categories.forEach((category, i) => {
            this.designs[category.categoryId] = res[i];
            allDesigns.push(...res[i]);
          });
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });

      // すべてのデザインカテゴリを追加、初期表示時アクティブする
      const all = { categoryId: 'ALL', categoryName: 'すべて', order: 0 };
      this.categories.unshift(all);
      this.designs['ALL'] = allDesigns;
      this.activeCategory = all;
    },
    // category
    categoryFill(id) {
      let fill = 'clear';

      if (this.activeCategory.categoryId === id) fill = 'solid';

      return fill;
    },
    // click
    clickCategory(id) {
      this.activeCategory = this.categories.find((category) => category.categoryId === id);
      this.$refs.layout.scrollToTop();
    },
    async clickSample(design,refName) {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
      }


      gtmSendClick(this.$refs[refName][0].$el,'カードを選ぶ','リッチカード選択',design.designName);
      this.$refs.layout.gtmPush('カードサンプル', `/cards/${design.designId}/preview`, {
        header: encodeURI(design.imageText),
      });
      await this.$router.push({
        name: 'PMG310',
        params: { designId: design.designId },
        query: { header: encodeURI(design.imageText) },
      });
    },
    customCloseToCart() {
      this.$router.push({ name: 'PMG700' });
    },
  },
};
</script>

<style scoped lang="scss">
.pmg300 {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 76px;
    text-align: center;
    letter-spacing: 0.15px;

    .messge {
      padding: 16px;
      font: $font-roboto-400;
      @include font-size(14, 22.4);
    }

    :deep(.button) {
      width: 160px;
    }
  }

  &__category {
    position: sticky;
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding: 8px;
    gap: 4px;
    top: 0;
    background: var(--ion-color-light);
    z-index: 1;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      cursor: pointer;

      &.is-active {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $gold1;
        }
      }

      ion-button {
        --padding-top: 5px;
        --padding-bottom: 5px;
        --padding-start: 12px;
        --padding-end: 12px;
        font: $font-roboto-400;
        @include font-size(14, 18.2);
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    padding-bottom: 112px;
    gap: 2px;
  }
}
</style>
