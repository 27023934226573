<template>
  <Layout
    ref="layout"
    :title="header"
    class="pmg310"
    backLabel="一覧"
    :showMenu="false"
    :showBack="false"
    :showCustomBack="true"
    :customBackFunc="customBackcardList"
    :scrollEvents="true"
    :scroll-func="scrollFunc"
    :scroll-to-hide-header="true"
    :default-hide-header="true"
    :fixedHeader="true"
  >
    <div class="pmg310__card-sample">
      <BaseTemplate :modelValue="value" :design-id="designId" :scrollTop="scrollTop">
        <FirstView :modelValue="value" :design-id="designId" />
      </BaseTemplate>
      <Button type="fill" size="medium" label="このカードを作成" ref="createCard" @click="createCard" />
    </div>

    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import BaseTemplate from '@/components/messageCard/BaseTemplate';
import FirstView from '@/components/messageCard/FirstView';
import Button from '@/components/button/Button';
import Loading from '@/components/loading/Loading';
import store from '@/store';
import { mapGetters } from 'vuex';

import { designSampleApi } from '@/plugins/ApiHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: 'PMG310',
  components: {
    Layout,
    BaseTemplate,
    FirstView,
    Button,
    Loading,
  },
  data: () => ({
    designId: null,
    header: '',
    value: {},
    scrollTop: 0,
  }),
  computed: {
    ...mapGetters(['isLoading', 'getCartItems']),
  },
  mounted() {
    this.designId = this.$route.params.designId;
    this.header = decodeURI(this.$route.query?.header);
    this.fetchDesignSample(this.designId);
  },
  methods: {
    fetchDesignSample(designId) {
      const data = {
        'design-id': designId,
      };
      designSampleApi(data)
        .then((res) => {
          this.value = {
            ...res,
            designId: this.designId,
            header: this.header,
          };
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    scrollFunc(e) {
      this.scrollTop = e.detail.scrollTop;
    },
    async createCard() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
      }

      gtmSendClick(this.$refs['createCard'].$el,'カードプレビュー','このカードを作成','');

      store.commit('setCardOriginId', null);
      this.$refs.layout.gtmPush('カード編集', `/cards/${this.designId}/edit`);
      await this.$router.push({
        name: 'PMG320',
        params: { designId: this.designId },
        query: { header: this.imageText },
      });
    },
    customBackcardList() {
      this.$router.push({ name: 'PMG300' });
    },
  },
};
</script>

<style scoped lang="scss">
.pmg310 {
  &::-webkit-scrollbar {
    display: none;
  }

  .button {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 10;
  }
}
</style>
