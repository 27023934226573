import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {IonicVue} from '@ionic/vue'
import VueLazyload from 'vue-lazyload'
import PontaPoint from '@/components/point/PontaPoint';
import SmoothScroll from 'smoothscroll-polyfill'
SmoothScroll.polyfill();



/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "@/assets/css/custom.css";

const errorimage = require('@/assets/images/gift/gift_no_image.jpg');
const loadimage = require('@/assets/images/gift/gift_loading.jpg');

createApp(App).use(store).use(IonicVue, {
    mode: 'ios'
}).use(VueLazyload, {
    // オプションは以下URL参照
    // https://www.npmjs.com/package/vue-lazyload
    error: errorimage,
    loading: loadimage,
}).use(router)
    .use(SmoothScroll)
    .component('PontaPoint', PontaPoint)
    .mount('#app')
