<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="willChange">
      <!-- NOTE 前ページの情報が残るためmodeはmd固定 -->
      <ion-router-outlet animated="false" mode="md"></ion-router-outlet>
      <ion-tab-bar v-if="showFooterMenu" slot="bottom">
        <ion-tab-button
            class="menu-tab"
            v-for="m in menu"
            :key="m.key"
            :tab="m.tab"
            :ref="m.tab"
            @mouseover="onMouseover(m)"
            @mouseleave="onMouseleave(m)"
            @click="onClick(m)"
        >
          <ion-icon aria-hidden="true" :src="m.icon"/>
          <ion-badge v-if="m.showBadge" color="danger">1</ion-badge>
          <ion-label :class="{ 'menu-active': m.active }">{{ m.name }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {emitter} from '@/eventBus';
import {mapGetters} from 'vuex';
import {
  IonPage,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonBadge,
} from '@ionic/vue';
import {gtmDataLayerPush} from '@/plugins/GtmHelper';
import {gtmSendClick} from "@/plugins/GtmHelper";
import {getUrl} from '@/plugins/UrlHelper';
import {BOTTOME_NAVIGATION_IDS} from '@/constants/base'

export default {
  name: 'FooterMenu',
  components: {
    IonPage,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
    IonBadge,
  },
  data: () => ({
    menu: [
      {
        menuId: BOTTOME_NAVIGATION_IDS.HOME,
        name: 'ホーム',
        tab: 'PMG600',
        href: '/gifts',
        icon: require('@/assets/images/icon/home_20240201.svg'),
        icons: {
          active: require('@/assets/images/icon/home_active_20240201.svg'),
          notActive: require('@/assets/images/icon/home_20240201.svg'),
        },
        showBadge: false,
        active: false,
      },
      // {
      //   menuId: BOTTOME_NAVIGATION_IDS.GIFT,
      //   name: 'ギフトを探す',
      //   tab: 'PMG660',
      //   href: '/gifts/search',
      //   icon: require('@/assets/images/icon/search_20240201.svg'),
      //   icons: {
      //     active: require('@/assets/images/icon/search_active_20240201.svg'),
      //     notActive: require('@/assets/images/icon/search_20240201.svg'),
      //   },
      //   showBadge: false,
      //   active: false,
      // },
      // {
      //   menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   name: '買い物カゴ',
      //   tab: 'PMG700',
      //   href: '/cart',
      //   icon: require('@/assets/images/icon/cart_20240201.svg'),
      //   icons: {
      //     active: require('@/assets/images/icon/cart_active_20240201.svg'),
      //     notActive: require('@/assets/images/icon/cart_20240201.svg'),
      //   },
      //   showBadge: false,
      //   active: false,
      // },
      {
        menuId: BOTTOME_NAVIGATION_IDS.PURCHASE_HISTORY,
        name: '購入履歴',
        tab: 'PMG800',
        href: '/purchase-histories',
        icon: require('@/assets/images/icon/history_20240201.svg'),
        icons: {
          active: require('@/assets/images/icon/history_active_20240201.svg'),
          notActive: require('@/assets/images/icon/history_20240201.svg'),
        },
        showBadge: false,
        active: false,
      },

    ],
    activeMenuTab: 'PMG600',
  }),
  computed: {
    ...mapGetters(['showFooterMenu', 'getCartItems']),
  },
  watch: {
    getCartItems(newVal) {
      let showBadge = newVal && Array.isArray(newVal) && newVal.length > 0;
      this.menu.forEach((m) => {
        if (m.tab === 'PMG700') {
          m.showBadge = showBadge;
        }
      });
    },
    $route(newVal) {
      this.selectMenu(newVal);
    },
  },
  mounted() {
    this.selectMenu(this.$router.currentRoute.value);
  },
  methods: {
    willChange(args) {
      this.activeMenuTab = args.tab;
      this.menu.forEach((m) => {
        if (m.tab === this.activeMenuTab) {
          setTimeout(() => (m.icon = m.icons.active), 10);
        } else {
          setTimeout(() => (m.icon = m.icons.notActive), 10);
        }
      });
    },
    findMenu(menuId) {
      return this.menu.find((m) => m.menuId === menuId);
    },
    onMouseover(m) {
      if (m.tab !== this.activeMenuTab) m.icon = m.icons.active;
    },
    onMouseleave(m) {
      if (m.tab !== this.activeMenuTab) m.icon = m.icons.notActive;
    },
    async onClick(m) {
      const virtualPageUrl = getUrl(m.href);
      gtmSendClick(this.$refs[m.tab][0].$el, 'ボトムナビ', m.name, '');
      gtmDataLayerPush(virtualPageUrl, m.name);
      await this.$router.push({
        name: m.tab,
        query: {ppsm: true},
      });
      emitter.emit('pageScrollTop');
    },
    selectMenu(route) {

      const menuId = route.meta.menuId
      this.menu.forEach((m) => {
        if (m.menuId === menuId) {
          this.activeMenuTab = m.tab;
          setTimeout(() => {
            m.active = true;
            m.icon = m.icons.active;
          }, 10);
        } else {
          setTimeout(() => {
            m.active = false;
            m.icon = m.icons.notActive;
          }, 10);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
ion-tab-bar {
  height: 56px;
}

ion-tab-button {
  cursor: pointer;
  flex: 1;
  max-width: calc(768px / 4);
}

ion-icon {
  font-size: 24px;
}

ion-badge {
  width: 12px;
  height: 12px;
  color: var(--ion-color-danger);
  transform: translate(1px,1px);
}

ion-label {
  font: $font-noto-sans-jp-400;
  @include font-size(10, 16);
  margin-top: 3px;
}

.button-inner {
  gap: 3px;
}

.menu-active {
  color: var(--ion-color-primary);
  font-weight: bold;
}

.menu-tab:hover ion-label {
  font-weight: bold;
}
</style>
