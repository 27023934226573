<template>
  <div class="gift">
    <div class="gift__img">
      <img :src="imgSrc" />
    </div>
    <div class="gift__info">
      <span class="gift__info__name">{{ giftName }}</span>
      <span class="gift__info__brand-name">{{ brandName }}</span>
      <span class="gift__info__expiration">{{ expiryDateDisplay }}まで</span>
      <div class="gift__info__price" v-if="isShowPrice">
        <span class="gift__info__price__label">
          金額<span v-if="isTaxDisplay">（税込）</span>
        </span>
        <span class="gift__info__price__value">¥{{ priceDisplay }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { getItemImageUrl } from "@/plugins/GiftHelper";

export default {
  name: "GiftInfo",
  components: {},
  props: {
    giftName: {
      type: String,
      default: null,
    },
    brandName: {
      type: String,
      default: null,
    },
    expiryDate: {
      type: String,
      default: null,
    },
    price: {
      type: [String, Number],
      default: 0,
    },
    giftImage: {
      type: String,
      default: null,
    },
    isShowPrice: {
      type: Boolean,
      default: true,
    },
    isTaxDisplay: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    expiryDateDisplay() {
      if (!this.expiryDate) {
        return "";
      }
      const day = DateTime.fromFormat(
        this.expiryDate,
        "yyyy-LL-dd TT"
      ).toFormat("yyyy年LL月dd日");
      return day;
    },
    priceDisplay() {
      if (!this.price) {
        return "";
      }
      return Number(this.price).toLocaleString();
    },
    imgSrc() {
      if (this.giftImage && this.giftImage.indexOf("http") >= 0) {
        return this.giftImage;
      }
      return getItemImageUrl(this.giftImage);
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.gift {
  display: flex;
  width: 100%;
  &__img {
    img {
      max-width: 112px;
      max-height: 112px;
      border-radius: 6px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    flex: 1;
    margin-left: 16px;
    font: $font-noto-sans-jp-400;
    &__name {
      @include font-size(14, 22.4);
      color: $black6;
    }
    &__brand-name {
      @include font-size(10, 16);
      color: $gray10;
    }
    &__expiration {
      @include font-size(10, 16);
      color: $black6;
    }
    &__price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__label {
        font-size: 10px;
        line-height: 16px;
        color: $black6;
      }
      &__value {
        font: $font-roboto-700;
        font-size: 16px;
        line-height: 22.4px;
        margin-left: auto;
        color: $black6;
      }
    }
  }
}
</style>
