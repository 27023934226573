<template>
  <div class="list-item">
    <div class="list-item__image" :style="imageStyle"/>
    <div class="list-item__body">
      <div class="title">{{ title }}</div>
      <div class="text">{{ data.messageText }}</div>
      <div class="sub">
        <div class="sub__category">{{ data.categoryName }}</div>
        <div class="sub__date">{{ formatCardDate(data[dateKey]) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'ListItem',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    titleKey: {
      type: String,
      default: '',
    },
    dateKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.data.thumbnailUrl})`,
      };
    },
    title() {
      return this.data[this.titleKey] || '（未設定）'
    },
  },
  methods: {
    formatCardDate(date) {
      return date
        ? DateTime.fromSQL(date).toFormat('yyyy.M.d')
        : '';
    },
  },
}
</script>

<style scoped lang="scss">
.list-item {
  display: flex;
  width: 100%;
  height: 120px;
  background: $white1;
  border-bottom: 1px solid $gray5;
  cursor: pointer;

  &:hover {
    background: $white2;
  }

  &__image {
    width: 120px;
    min-width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__body {
    flex-grow: 1;
    height: 100%;
    padding: 12px 16px;
    overflow: hidden;

    .title,
    .text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .title {
      margin-bottom: 8px;
      font: $font-roboto-700;
      color: rgba($black1, 0.87);
      letter-spacing: 0.15px;

      @include font-size(14, 16);
    }

    .text {
      margin-bottom: 17.5px;
      font: $font-roboto-400;
      color: $black1;
      letter-spacing: 0.25px;

      @include font-size(11, 13);
    }

    .sub {
      display: flex;
      justify-content: space-between;
      font: $font-roboto-400;
      color: rgba($black1, 0.6);

      @include font-size(11, 13);

      &__category {
        letter-spacing: 0.25px;
      }

      &__date {
        letter-spacing: 0.4px;
      }
    }
  }
}
</style>
