<template>
  <div class="box-list">
    <div class="box-search">
      <div class="title-label-container">
        <span class="title-label">これまでの特集から探す</span>
      </div>

      <div class="item-list">
        <div
          class="special-feature-item"
          v-for="specialFeature in specialFeatures"
          :key="specialFeature.id"
          @click="clickSpecialFeature(specialFeature.id)"
        >
          <img :src="specialFeature.image" alt="seasonal image" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeasonalEvent",
  props: {},
  components: {},
  data() {
    return {
      specialFeatures: [
        {
          id: 2,
          image: `/images/SpecialFeatureBanner/0020.png`,
        },
        {
          id: 1,
          image: `/images/SpecialFeatureBanner/SpecialFeatureBanner_20231221.jpg`,
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    clickSpecialFeature(page) {
      this.$router.push({
        name: 'special-feature',
        params: {
          page: page,
        },
      });
    }
  },
};
</script>

<style scoped lang="scss">
.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}

.box-list {
  margin: 24px 0 30px;

  .box-search {
    border-radius: 12px;
    background-size: cover;
    background-position: center center;

    .title-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 0 16px;

      .title-label {
        @include font-size(16, 22.4);
        font: $font-roboto-700;
        color: black;
      }
    }

    .box-search__condition {
      display: flex;
      justify-content: space-between;

      .box-label-container {
        display: flex;
        flex-direction: column;
        color: $black1;

        .box-label {
          font: $font-roboto-700;
          @include font-size(16, 22.4);
          text-align: left;
        }

        .box-label-text {
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
          // text-align: left;
          margin-top: 4px;
          white-space: pre-line;
        }
      }
    }

    .item-list {
      overflow-x: auto;
      display: flex;
      padding: 0 16px;
      gap: 8px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        display: none;
      }

      .special-feature-item {
        cursor: pointer;
        
        img {
          min-width: 226px;
          max-width: 226px;
          border-radius: 6px;
        }
      }

      .list-info {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 16px 24px;
        border-top: solid 1px rgba($white1, 0.5);

        .list-info-text {
          color: $white1;
          @include font-size(14, 22.4);
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
