<template>
  <Layout ref="layout" title="ギフトを探す" class="pmg600" :showAuLogin="true">

    <div class="pmg600__list" id="search-container">

      <SearchFilterSceneButtonList
          :scene-list="sceneFilters"
          :filters="filters"
          ref="sceneFilterButtonList"
          @click:filter="clickSceneFilterButton"
      />

      <SearchFilterCategoryButtonList
          :category-list="categoryFilters"
          :filters="filters"
          ref="categoryFilterButtonList"
          @click:filter="clickCategoryFilterButton"
      />

      <SearchFilterPriceButtonList
          :price-list="priceFilters"
          :filters="filters"
          ref="priceFilterButtonList"
          @click:filter="clickPriceFilterButton"
      />

      <GiftList
          ref="giftList"
          :filters="filters"
          :stickyHeader="false"
          @click:openFilter="openGiftFilterModal"
          @click:openDetail="openGiftDetailModal"
      />
    </div>

    <GiftFilterModal
        ref="giftFilterModal"
        @click:filter="filterListGifts"
    />
  </Layout>
</template>

<script>
import SearchFilterSceneButtonList from "@/components/search/SearchFilterSceneButtonList.vue";
import SearchFilterCategoryButtonList from "@/components/search/SearchFilterCategoryButtonList.vue";
import SearchFilterPriceButtonList from "@/components/search/SearchFilterPriceButtonList.vue";
import Button from '@/components/button/Button';
import store from '@/store';
import Layout from '@/components/layout/Layout';
import GiftList from '@/components/gift/GiftList';
import GiftFilterModal from '@/components/modal/Gift/GiftFilterModal';
import {mapGetters} from 'vuex';
import {emitter} from '@/eventBus';
import {GtmEcommerce, GtmEcommerceItem} from '@//plugins/GtmEcommerce';
import {CategoryHelper} from "@/plugins/CategoryHelper";
import {PRICE_FILTER_BUTTONS, FILTER_SCROLL_PROCESS} from "@/constants/searchFilter";
import {CATEGORY_CLASSIFICATION} from "@/constants/base";
import {listGiftCategoriesApi, updateCart} from '@/plugins/ApiHelper';


export default {
  name: 'PMG660',
  components: {
    Layout,
    GiftList,
    GiftFilterModal,
    Button,
    SearchFilterSceneButtonList,
    SearchFilterCategoryButtonList,
    SearchFilterPriceButtonList,
  },
  data: () => ({
    filters: {
      'price-from': null,
      'price-to': null,
      'price-filter-id': null,
      'category-ids': null,
      'scene-ids': null,
      'receive-ids': null,
      'filter-count': null,
      'initial-filter': false,
    },
    sceneFilters: [],
    categoryFilters: [],
    priceFilters: PRICE_FILTER_BUTTONS,
    items: null,
    isFirstLoading: false,
    classifications: [
      {id: 2, name: 'シーン', class: 'scenes', list: [], ids: []},
      {id: 3, name: 'ギフトの利用方法', class: 'receives', list: [], ids: []},
      {id: 1, name: 'カテゴリー', class: 'categories', list: [], ids: []},
    ],
  }),
  computed: {
    ...mapGetters(['isAuIdAuthorization', 'isLoading', 'getCategoryList', 'getFilter']),
  },
  async mounted() {
    store.commit('clearFilter')
    // 上位6件のカテゴリーを取得
    const {scene, category} = await this.fetchListCategories(6)
    this.sceneFilters = scene
    this.categoryFilters = category

    this.initialFilter();

    setTimeout(() => {
      this.$refs.layout.scrollToTop(0);
    }, 1000)

  },
  updated() {
    this.isFirstLoading = false
  },
  watch: {
    $route(to, from) {
      // ギフト一覧 → 商品詳細への遷移、または、商品詳細 → ギフト一覧への遷移の場合以外はフィルターをクリアする
      if (
          !(to.name === 'PMG610' && from.name === 'PMG660')
          && !(to.name === 'PMG660' && from.name === 'PMG610')
      ) {
        store.commit('clearFilter')
        this.$refs.giftFilterModal.clickReset()

        this.initialFilter()
      } else {
        this.filters = store.getters.getFilter
      }
    },
    filters() {
      store.commit('setFilter', this.filters)
    }
  },
  methods: {
    initialFilter() {
      const self = this;
      emitter.on('resetFilterListGifts', () => {
        self.filters = {
          'price-from': null,
          'price-to': null,
          'price-filter-id': null,
          'category-ids': null,
          'scene-ids': null,
          'receive-ids': null,
          'filter-count': null,
          'initial-filter': false,
        };
      });
      //フィルターの初期化後、URLパラメータによる初期条件を設定する
      Promise.resolve()
          .then(function () {
            return Promise.all([
              new Promise((resolve) => {
                self.$refs.giftFilterModal.fetchListBudgets()
                    .then(() => {
                      resolve();
                    });
              }),
              new Promise(async (resolve) => {
                await self.$refs.giftFilterModal.fetchListCategories();
                resolve();
              }),
            ])
          })
          .then(function () {
            if (!store.getters.getFilter || store.getters.getFilter['filter-count'] === 0) {
              let hasInitialFilter = self.$refs.giftFilterModal.initialFilter();
              self.$refs.giftList.initialSort();
              self.$refs.giftFilterModal.searchFilter(hasInitialFilter);
            }
          });
    },
    async fetchListCategories(limit = 6) {

      const tasks = this.classifications.map((classification) =>
          listGiftCategoriesApi({classification: classification.id})
      );
      let cList = []
      await Promise.all(tasks)
          .then((res) => {
            this.classifications.forEach((classification, i) => {
              const list = res[i];
              this.classifications[i].list = list;
              cList.push(list)
            });
          })
          .catch((err) => {
            console.error(err);
          });
      const storageCategoryList = [].concat(...cList)

      const sceneList = []
      const categoryList = []
      const receiveList = []

      storageCategoryList.forEach(category => {
        if (category.classification === 2) {
          sceneList.push(category)
        } else if (category.classification === 1) {
          categoryList.push(category)
        } else if (category.classification === 3) {
          receiveList.push(category)
        }
      })
      // priority順にソート
      sceneList.sort((a, b) => a.priority - b.priority);
      categoryList.sort((a, b) => a.priority - b.priority);
      categoryList.sort((a, b) => a.priority - b.priority);

      // 上位limit件のみ取得
      if (limit) {
        sceneList.splice(limit)
        categoryList.splice(limit)
        receiveList.splice(limit)
      }

      return {
        "scene": sceneList,
        "category": categoryList,
        "receive": receiveList
      }
    },
    openGiftFilterModal() {
      this.$refs.giftFilterModal.openModal();
    },
    /** ギフト詳細に遷移 */
    async openGiftDetail(giftId) {
      this.$refs.layout.gtmPush('ギフト詳細', `/gifts/${giftId}`);
      this.$router.push({name: 'PMG610', params: {giftId: giftId}});
    },
    /** シーズナルイベントギフト用 */
    async seasonalOpenGiftDetailModal(giftId) {
      this.openGiftDetail(giftId)
    },
    /** 通常ギフト用 */
    async openGiftDetailModal(giftId) {
      this.openGiftDetail(giftId)
      this.ecommerceSelectItemPush(giftId);
    },
    /*
    フィルターを適用してギフト一覧を取得する。
    @param {object} filters: フィルター情報
    @param {object} scrollOrder:
    スクロール情報（scene: シーン, category: カテゴリー, price: 予算）
     */
    async filterListGifts(filters, scrollOrder = null) {
      this.filters = filters;

      store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.START)
      if (scrollOrder) {
        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.SCENE_SCROLLING)
        this.$refs.priceFilterButtonList.scrollToActiveButton('price', scrollOrder.price);

        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.SCENE_SCROLL_END)

        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.CATEGORY_SCROLLING)
        this.$refs.categoryFilterButtonList.scrollToActiveButton('category', scrollOrder.category);
        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.CATEGORY_SCROLL_END)

        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.PRICE_SCROLLING)
        this.$refs.sceneFilterButtonList.scrollToActiveButton('scene', scrollOrder.scene);

        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.PRICE_SCROLL_END)

        store.commit('setFilterScrollProcess', FILTER_SCROLL_PROCESS.COMPLETE)

        setTimeout(() => {
          this.$refs.layout.scrollToTop(0);
        }, 1000)


      }
    },
    /** フィルターボタン押下時 */
    clickSceneFilterButton(id) {
      // モーダルのフィルターと同期
      let filterId = []
      if (id) {
        filterId = [id]
      }
      const filterCount = this.$refs.giftFilterModal.setCurrentFilters({scenes: filterId})
      // モーダルのチェックボックス変更
      this.$refs.giftFilterModal.changeFilterCheckBox(0, id, CATEGORY_CLASSIFICATION.SCENE);

      this.filters['scene-ids'] = id;
      this.filters['filter-count'] = filterCount;
    },
    clickCategoryFilterButton(id) {
      // モーダルのフィルターと同期
      let filterId = []
      if (id) {
        filterId = [id]
      }
      const filterCount = this.$refs.giftFilterModal.setCurrentFilters({categories: filterId})
      // モーダルのチェックボックス変更
      this.$refs.giftFilterModal.changeFilterCheckBox(2, id, CATEGORY_CLASSIFICATION.CATEGORY);

      this.filters['category-ids'] = id;
      this.filters['filter-count'] = filterCount;
    },
    findPriceFilter(priceFilterId) {
      return this.priceFilters.find((price) => price.priceFilterId === priceFilterId);
    },
    clickPriceFilterButton(priceFilterId) {
      const priceFilter = this.findPriceFilter(priceFilterId);
      let lowerBudget = priceFilter ? priceFilter.lowerBudget : null;
      let upperBudget = priceFilter ? priceFilter.upperBudget : null;
      // モーダルのフィルターと同期
      const filterCount = this.$refs.giftFilterModal.setCurrentFilters({
        lowerBudget: lowerBudget,
        upperBudget: upperBudget
      })

      this.filters['price-filter-id'] = priceFilterId;


      this.filters['price-from'] = lowerBudget;
      this.filters['price-to'] = upperBudget

      this.filters['filter-count'] = filterCount;

      //予算のselectを変更
      this.$refs.giftFilterModal.setSelectedPriceFilter(lowerBudget, upperBudget);
    },
    // GA(eコマース) event: select_item
    ecommerceSelectItemPush(giftId) {
      const selectGiftData = this.$refs.giftList.getGiftData(giftId);
      let ecommerceItems = [];
      const item = GtmEcommerceItem.getEcommerceItem(selectGiftData);
      ecommerceItems.push(item);
      GtmEcommerce.selectItem(ecommerceItems);
    },
    scrollToGiftList() {
      this.scrollTo('gift-list');
    },
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'auto',
          });
          this.isFirstLoading = true
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --min-height: 0px;
}
</style>


