<template>
  <div v-if="giftData != null" class="gift-toast-use">
    <div class="gift-toast-use__image">
      <img :src="imgSrc" @load="loadImage" @error="errorImage" />
    </div>
    <div class="gift-toast-use__info">
      <p class="gift-name">{{ giftData.giftName }}</p>
      <p class="brand-name">{{ giftData.brandName }}</p>
      <p v-if="isShowPrice" valss="price">¥{{ giftData.paymentPrice }}</p>
    </div>
    <div class="gift-toast-use__button">
      <Button label="表示" @click="giftUse()" />
    </div>
  </div>

  <GiftUseModal
    ref="giftUseModal"
    :gift-url="giftData?.giftUrl"
    :gift-name="giftData?.giftName"
    :brand-name="giftData?.brandName"
    :expiry-date="giftData?.expiryDate"
    :price="giftData?.paymentPrice"
    :gift-image="imageUrl"
  />
</template>

<script>
import Button from "@/components/button/Button";
import {
  getGiftLoadingImage,
  getGiftNoImage,
  getItemImageUrl,
} from "@/plugins/GiftHelper";
import GiftUseModal from "@/components/modal/Gift/GiftUseModel";

export default {
  name: "GiftToastUse",
  components: {
    Button,
    GiftUseModal,
  },
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
    giftData: null,
    isShowPrice: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    giftName: null,
    imgSrc: getGiftLoadingImage(),
  }),
  computed: {
    imageUrl() {
      if (this.giftData == null) {
        return null;
      }
      return getItemImageUrl(this.giftData.giftImageUrl);
    },
  },
  methods: {
    loadImage() {
      if (this.giftData != null) {
        this.imgSrc = getItemImageUrl(this.giftData.giftImageUrl);
      }
    },
    errorImage(e) {
      e.target.src = getGiftNoImage();
    },
    giftUse() {
      this.$refs.giftUseModal.openModal();
    },
  },
};
</script>

<style scoped lang="scss">
.gift-toast-use {
  position: fixed;
  display: flex;
  bottom: -24px;
  left: 50%;
  width: 90vw;
  max-width: calc($baseScreenWidth1 - 16px);
  height: 80px;
  border-radius: 6px;
  background-color: $white1;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 6px 0px #00000026;
  padding: 12px;
  z-index: 1;

  &__image {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    img {
      border-radius: 6px;
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .message {
      margin-bottom: 4px;
      color: $gray10;
      font: $font-roboto-400;

      @include font-size(12, 20);
    }

    .gift-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-align: left;
      color: $black1;
      font: $font-roboto-400;

      @include font-size(14, 20);
    }
    .brand-name {
      font: $font-roboto-400;
      color: $gray13;
      @include font-size(10, 16);
    }
    .price {
      font: $font-roboto-700;
      @include font-size(12, 19);
    }
  }

  &__button {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 15%;
  }

  :deep(.button) {
    position: inherit;
    width: auto;
  }
}
</style>
