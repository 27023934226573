<template>
  <div class="pmg200">
    <Layout
      title="受け取ったカード"
      :sub-title="count"
      active="inbox"
      :show-create-button ="isAu"
      @click:send="clickSend"
      @click:inbox="clickInbox"
      @click:edit="clickEdit"
      @click:create="clickCreate"
      @click:gift="clickGift"
      @click:cart="clickCart"
      @click:history="clickHistory"
    >
      <div v-if="cards.length > 0" class="pmg200__list">
        <ListItem
          v-for="card in cards"
          :key="card.cardOriginId"
          :data="card"
          title-key="creatorName"
          date-key="receivedAt"
          @click="clickCard(card.cardOriginId)"
        />
      </div>
      <div v-else class="pmg200__empty">
        受け取ったカードは<br>まだありません
      </div>
    </Layout>

    <PreviewModal
      ref="modal"
      :design-id="receivedCard?.designId"
      :header="receivedCard?.creatorName"
      :value="receivedCard"
      type="preview"
      :disabled-gift-link="!isAu"
      @click:giftLink="clickPreviewModalGiftLink"
    />

    <Loading :is-show="isLoading"/>
  </div>
</template>

<script>
import Layout from '@/components/layout/Layout'
import ListItem from '@/components/layout/ListItem'
import PreviewModal from '@/components/modal/MessageCard/PreviewModal'
import Loading from '@/components/loading/Loading'

import { mapGetters } from 'vuex';

import { receivedCardListApi, messageCardDataApi } from '@/plugins/ApiHelper'

export default {
  name: 'PMG200',
  components: {
    Layout,
    ListItem,
    PreviewModal,
    Loading,
  },
  data: () => ({
    count: null,
    cards: [],
    receivedCard: null,
  }),
  computed: {
    ...mapGetters(['isAu', 'isLoading']),
  },
  mounted () {
    const id = this.$route.query['card-origin-id'];
    if (id) this.clickCard(id);
    this.fetchReceivedCardList();
  },
  methods: {
    fetchReceivedCardList() {
      receivedCardListApi()
        .then(res => {
          this.count = res.count;
          this.cards = res.cards;
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    async fetchMessageCardData(cardOriginId) {
      return new Promise(resolve => {
        const data = {
          'card-origin-id': cardOriginId,
        };
        messageCardDataApi(data)
          .then(res => {
            this.receivedCard = res;
            resolve();
          })
          .catch(() => {
            this.$router.push({ name: 'PMG510' });
          });
      });
    },
    // modal
    openModal() {
      this.$refs.modal.openModal();
    },
    // click
    clickSend() {
      this.$router.push({ name: 'PMG100' });
    },
    clickInbox() {
      this.fetchReceivedCardList();
    },
    clickEdit() {
      this.$router.push({ name: 'PMG300' });
    },
    clickCreate() {
      this.$router.push({ name: 'PMG300' });
    },
    clickGift() {
      this.$router.push({ name: 'PMG600' });
    },
    clickCart() {
      this.$router.push({ name: 'PMG700' });
    },
    clickHistory() {
      this.$router.push({ name: 'PMG800' });
    },
    async clickCard(cardOriginId) {
      await this.fetchMessageCardData(cardOriginId);
      this.openModal();
    },
    clickPreviewModalGiftLink() {
      this.$router.push({ name: 'PMG300' });
    },
  },
}
</script>

<style scoped lang="scss">
.pmg200 {
  &__list {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font: $font-roboto-700;
    color: rgba($gray6, 0.87);
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 21);
  }
}
</style>
