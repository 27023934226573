<template>
  <div class="popular-gift-list">
    <div class="popular-gift-search">
      <div class="title-label-container">
        <span class="title-label">人気ギフトから探す</span>
        <span class="search-all" @click="redirectSearchGift">すべてのギフト</span>
      </div>

      <div class="popular-gift-slider">
        <div class="popular-gift-slider__wrapper">
          <div
            class="popular-gift-slider__slide"
            v-for="gift in giftItems"
            :key="gift.giftId">
            <GiftItem
              :id="gift.giftId"
              :imgUrl="gift.image"
              :brandName="gift.brandName"
              :giftName="gift.giftName"
              :price="gift.price"
              :isTaxDisplay="gift.isTaxDisplay || false"
              :variation-group="gift.variationGroup"
              imgType="popularItemImage"
              class="GiftItem"
              @click="redirectGiftDetail(gift.giftId)"
            ></GiftItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {listGiftsApi} from '@/plugins/ApiHelper';
import GiftItem from "@/components/gift/GiftItem.vue";
import {SORT_ORDER} from "@/constants/base.js";


export default {
  name: "PopularGiftList",
  props: {},
  components: {
    GiftItem
  },
  data() {
    return {
      giftItems: [],
    };
  },
  async mounted() {
    const data = {
        'sort-order': SORT_ORDER.POPULAR,
        'page-no': 1,
      };

    await listGiftsApi(data)
        .then((res) => {
          const gifts = res['list'];
          if(gifts) {
            const popularGifts = gifts.splice(0, 6);
            this.giftItems.push(...popularGifts);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {

            this.gifts = [];
          } else {
            console.error(err);
            this.$router.push({name: 'PMG510'});
          }
        });
  },
  emits: ['click:openDetail'],
  methods: {
    redirectSearchGift() {
      this.$router.push({
        name: 'PMG660'
      });
    },
    redirectGiftDetail(giftId) {
      this.$emit('click:openDetail', giftId);
    },
  },
};
</script>

<style scoped lang="scss">
.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}

.popular-gift-list {
  margin-top: 24px;

  .popular-gift-search {
    border-radius: 12px;
    background-size: cover;
    background-position: center center;

    .title-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 3px 16px;

      .title-label {
        @include font-size(16, 22.4);
        font: $font-roboto-700;
        color: black;
      }

      .search-all {
        @include font-size(14, 18.2);
        color: #8C6D46;
        // padding: 6px 6px;
        margin-right: 4px;
        cursor: pointer;
      }
    }

    .popular-gift-search__condition {
      padding: 24px 0 20px 4px;
      display: flex;
      justify-content: space-between;

      .popular-gift-label-container {
        display: flex;
        flex-direction: column;
        color: $black1;

        .popular-gift-label {
          font: $font-roboto-700;
          @include font-size(16, 22.4);
          text-align: left;
        }

        .popular-gift-label-text {
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
          // text-align: left;
          margin-top: 4px;
          white-space: pre-line;
        }
      }
    }

    .button .x-small {
      width: 118px;

    }

    .popular-gift-slider {
      &__wrapper {
        overflow-x: auto;
        display: flex;
        gap: 12px;
        padding: 0 16px 30px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }

      &__slide {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 152px;
        min-width: 152px;
        height: 278px;

        .GiftItem {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .list-info {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 16px 16px 24px;
      border-top: solid 1px rgba($white1, 0.5);

      .list-info-text {
        color: $white1;
        @include font-size(14, 22.4);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
