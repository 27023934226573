import store from "@/store";

export class CategoryHelper {

  /**
   * カテゴリーIDからカテゴリー名を取得（eコーマス用）
   * @param categoryIdsStr
   * @returns {{item_category2: null, item_category3: null, item_category: null}}
   */
  static getItemCategories = (categoryIdsStr) => {
    // 初期データは全てnull
    let data = {
      'item_category': null,
      'item_category2': null,
      'item_category3': null,
    }
    // ストレージに保存しているカテゴリーマスタ取得 (配列)
    const storegeCategoryList = store.getters.getCategoryList
    // カテゴリーIDがない場合 または カテゴリーマスタがない場合 return
    if (categoryIdsStr == null || storegeCategoryList.length === 0) {
      return data
    }

    const categoryIds = categoryIdsStr.split(",");
    let itemCategories = []
    for (const categoryId of categoryIds) {
      const result = this.findItemByCategoryId(storegeCategoryList, parseInt(categoryId))
      if(result) {
        itemCategories.push(result)
      }
    }
    const item_category = this.findItemByClassification(itemCategories, 3)
    data['item_category'] = this.getItemCategoryNameData(item_category)


    const item_category2 = this.findItemByClassification(itemCategories, 1)
    data['item_category2'] = this.getItemCategoryNameData(item_category2)

    const item_category3 = this.findItemByClassification(itemCategories, 2)
    data['item_category3'] = this.getItemCategoryNameData(item_category3)

    return data
  };

  static findItemByCategoryId(arr, categoryId) {
    return arr.find(item => item.categoryId === categoryId); // ここではIDが一致する要素を探します
  }

  static findItemByClassification(arr, classification) {
    return arr.filter(item => item.classification === classification); // ここではClassificationが一致する要素を探します
  }

  /**
   * GA(eコマース) dataLayer.push時、必要なパラメータitems.item_categoryを返す
   * @param {*} itemCategories
   * @returns
   */
  static getItemCategoryNameData(itemCategories) {
    let itemCategoryData = null
    if (itemCategories.length == 0) {
      return itemCategoryData
    }
    itemCategories.sort((a, b) => a.priority - b.priority);
    for (const itemCategory of itemCategories) {
      if (itemCategoryData == null) {
        itemCategoryData = itemCategory.categoryName
      } else {
        itemCategoryData += "&" + itemCategory.categoryName
      }
    }
    return itemCategoryData
  }

  /*
    * カテゴリーマスタをclassificationで分け、priority順にソートして返す
    * @param limit 取得件数
    * @returns {scene: [], category: []}
   */
  static getCategoriesByClassification = (limit=null) => {
    // ストレージに保存しているカテゴリーマスタ取得 (配列)
    const storageCategoryList = store.getters.getCategoryList
    if(!storageCategoryList) {
      return []
    }

    const sceneList = []
    const categoryList = []
    const receiveList = []

    storageCategoryList.forEach(category => {
      if(category.classification === 2) {
        sceneList.push(category)
      } else if(category.classification === 1) {
        categoryList.push(category)
      } else if(category.classification === 3) {
        receiveList.push(category)
      }
    })

    // priority順にソート
    sceneList.sort((a, b) => a.priority - b.priority);
    categoryList.sort((a, b) => a.priority - b.priority);
    categoryList.sort((a, b) => a.priority - b.priority);

    // 上位limit件のみ取得
    if(limit) {
      sceneList.splice(limit)
      categoryList.splice(limit)
      receiveList.splice(limit)
    }

    return {
      "scene": sceneList,
      "category": categoryList,
      "receive": receiveList
    }
  }
}
