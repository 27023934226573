
export const getUrl = (path, params = {}) => {
    const paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    const baseUrl = process.env.VUE_APP_BASE_APP_URL;
    let url = baseUrl + '/' + path.replace(/^\//, '');
    if (paramStr) {
        url += '?' + paramStr;
    }
    return url;
}
