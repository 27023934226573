<template>
  <Layout ref="layout" class="pmg610" :default-hide-header="true">
    <div id="body">
      <div class="pmg610__image">
        <button class="pmg610__image__back" @click="backToList" v-if="isGiftDetailBack"/>
        <img :src="imgUrl" @error="errorImage"/>
      </div>

      <div class="pmg610__content">
        <div class="basic">
          <div>
            <span class="basic__brand-name" ref="sameBrand1" @click="showSameBrandGift('sameBrand1')">
              {{ brandName }}
            </span>
          </div>
          <div class="basic__gift-name">{{ giftName }}</div>
          <div class="basic__price-area">
            <span class="price">¥{{ priceDisplay }}</span>
            <span v-if="isTaxDisplay" class="tax-text">{{ taxText }}</span>
            <span class="has-variation" v-if="isVariation">バリエーションあり</span>
          </div>

          <AuLogin v-if="!isAuIdAuthorization" class="au-login" GALabel1="商品詳細"/>
          <div v-if="isAuIdAuthorization" class="basic__ponta">
            <div class="basic__ponta__message">
              <p>購入にはPontaポイントを<br/>ご利用いただけます。</p>
            </div>
            <img class="ponta__logo" src="@/assets/images/ponta.png"/>
            <PontaPoint :is-mini="false" :is-mark="false"></PontaPoint>
          </div>

          <div class="basic__expiration">
            <img class="basic__expiration__icon" src="@/assets/images/icon/clock.svg"/>
            <span class="basic__expiration__date">引換期限：{{ expiryDateDisplay }}</span>
          </div>

          <div class="basic__using">
            <img class="basic__using__icon" src="@/assets/images/icon/gift_000.svg"/>
            <span class="basic__using__label">利用方法：</span>
            <span class="basic__using__description" @click="scrollUsing">
              {{ usingDescription }}
            </span>
          </div>

          <div v-if="notice" class="basic__notice" v-html="htmlText(notice)"></div>

          <div class="variation" v-show="isVariation">
            <div class="variation__title">バリエーション</div>
            <div class="variation__items">
              <div
                class="variation__items__button"
                :class="{ 'active-item': item.giftId === giftId }"
                v-for="(item, index) in items"
                :key="item.giftId"
                :ref="'variation_'+index"
                @click="clickItem(index)"
              >
                <p class="variation-name">{{ item.variationName }}</p>
                <p class="price">¥{{ Number(item.price).toLocaleString() }}</p>
              </div>
            </div>
          </div>
          <div class="buy_button">
            <Button label="ギフト用に購入" type="fill" ref="selectedGift" @click="selectedGift('gift')"/>
          </div>
          <div class="buy_button buy_button_for_self">
            <Button label="自分用に購入" type="fill-self" ref="selectedGift" @click="selectedGift('myself')"/>
          </div>
        </div>

        <div class="section detail">
          <div class="section section-title">ギフト詳細</div>
          <div class="spacing x_6"></div>
          <div v-html="htmlText(giftDescription)"></div>
        </div>
        <div class="spacing x_2"></div>

        <div class="section spec">
          <div class="section section-title">仕様・注意事項</div>
          <div class="spacing x_6"></div>
          <div v-html="htmlText(specNote)"></div>
        </div>
        <div class="spacing x_2"></div>

        <div id="giftUsing" class="section using">
          <div class="section section-title">利用方法</div>
          <div class="spacing x_6"></div>
          <div v-html="htmlText(usingDescriptionDetail)"></div>
        </div>
        <div class="spacing x_2"></div>

        <div class="section brand">
          <div class="section section-title">ブランド情報</div>
          <div class="spacing x_6"></div>
          <div class="brand__basic">
            <div class="brand__basic__image">
              <img :src="brandImgUrl"/>
            </div>
            <div class="brand__basic__name">{{ brandName }}</div>
          </div>
          <div class="spacing x_6"></div>
          <div class="section paragraph-12" v-html="htmlText(brandDescription)"></div>
        </div>
        <div class="spacing x_2"></div>

        <div v-if="sameBrandItems?.length > 0" class="section same-brand-gift">
          <div class="section section-title">このブランドの他のギフト</div>
          <div class="spacing x_6"></div>
          <div class="same-brand-gift__gifts">
            <GiftItem
              v-for="gift in sameBrandItems"
              :key="gift.giftId"
              :id="gift.giftId"
              :imgUrl="gift.image"
              :brandName="gift.brandName"
              :giftName="gift.giftName"
              :price="gift.price"
              :variationGroup="gift.variationGroup"
              :isTaxDisplay="gift.isTaxDisplay"
              @click-item="openDetail"
              GALabel1="商品詳細"
              GALabel2="このブランドの他のギフト"
            ></GiftItem>
          </div>
          <div class="spacing x_4"></div>
          <div class="same-brand-gift__show-gift">
            <span class="same-brand-gift__show-gift__label" ref="sameBrand2" @click="showSameBrandGift('sameBrand2')"
            >このブランドのギフトすべて
            </span>
          </div>
        </div>
      </div>
    </div>

    <IonicAlert
      ref="expiredAlert"
      message="有効期限切れのためギフトを贈ることはできません。"
      :buttons="expiredAlertButtons"
    />

    <GiftReplacModal
      ref="giftReplacModal"
      @click:checkCart="clickCheckCart()"
      @click:replaceGift="clickReplaceGift()"
      :replaceGiftName="giftName"
    ></GiftReplacModal>

    <PurchaseLimitOverModal ref="purchaseLimitOverModal" :purchaseLimit="purchaseLimit"/>

    <Loading :is-show="isLoading"/>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import Loading from '@/components/loading/Loading';
import Button from '@/components/button/Button';
import AuLogin from '@/components/layout/AuLogin';
import GiftReplacModal from '@/components/modal/Gift/GiftReplacModal';
import GiftItem from '@/components/gift/GiftItem';
import IonicAlert from '@/components/ionic/IonicAlert';
import PurchaseLimitOverModal from '@/components/modal/Gift/PurchaseLimitOverModal';
import store from '@/store';
import {GtmEcommerce, GtmEcommerceItem} from '@//plugins/GtmEcommerce';
import {mapGetters} from 'vuex';
import {DateTime} from 'luxon';
import {detailGiftApi, updateCart, delCard, postCreateNomalCard} from '@/plugins/ApiHelper';
import {getPurchaceLimit} from '@/plugins/GiftHelper';
import {
  formatExpiryDate,
  getGiftLoadingImage,
  getGiftNoImage,
  getItemImageUrl,
  getBrandImageUrl,
} from '@/plugins/GiftHelper';
import _ from 'lodash';
import {gtmSendClick} from "@/plugins/GtmHelper";
import {CARD_NAME_FOR_MYSELF} from "@/constants/card";

export default {
  name: 'PMG610',
  components: {
    Layout,
    Loading,
    Button,
    AuLogin,
    GiftReplacModal,
    GiftItem,
    IonicAlert,
    PurchaseLimitOverModal,
  },
  data: () => ({
    giftId: null,
    imgUrl: null,
    giftName: null,
    price: null,
    priceDisplay: null,
    taxText: '税込',
    isTaxDisplay: true,
    displayEndDate: null, // 陳列有効期限
    expiryDate: null,
    expiryDateDisplay: null,
    usingDescription: null,
    giftDescription: null,
    specNote: null,
    usingDescriptionDetail: null,
    brandId: null,
    brandName: null,
    brandImgUrl: null,
    brandDescription: null,
    expiredAlertButtons: [{text: 'OK', role: 'ok'}],
    items: null,
    sameBrandItems: null,
    isVariation: false,
    saveGift: null,
    notice: null,
    purchaseLimit: null,
    isForMyself: false,
    referrercheck: true,
    ageVerification: false,
  }),
  computed: {
    ...mapGetters(['isLoading', 'getCartItems', 'isAuIdAuthorization', 'lid', 'getCardOriginId', 'isGiftDetailBack']),
    paramGiftId() {
      return Number(this.$route.params.giftId);
    },
  },
  mounted() {
    this.giftId = Number(this.$route.params.giftId);
    this.fetchDetailGift();

  },
  watch: {
    paramGiftId(newVal, oldVal) {
      if (!_.isNil(oldVal) && !_.isNil(newVal) && !_.isNaN(newVal) && oldVal != newVal) {
        this.giftId = newVal;
        this.fetchDetailGift();
      }
    }
  },
  methods: {
    openExpiredAlert() {
      this.$refs.expiredAlert.openAlert();
    },
    backToList() {
      this.$router.go(-1);
    },
    async fetchDetailGift() {
      this.imgUrl = getGiftLoadingImage();
      return new Promise((resolve) => {
        detailGiftApi({'gift-id': this.giftId})
          .then(async (res) => {
            this.items = res.gifts;
            this.sameBrandItems = res.sameBrandGifts;
            if (this.items.length > 1) {
              this.isVariation = true;
            } else {
              this.isVariation = false;
            }
            const idx = this.items.findIndex((e) => e.giftId == this.giftId);

            // 陳列有効期限バリデーション
            const isValid = this.canDisplay(idx);
            if (!isValid) {
              await this.$router.push({name: 'PMG510'});
              return;
            }

            this.changeItem(idx);

            this.ecommerceviewItemPush();
            resolve();
          })
          .catch((error) => {
            console.error(error);
            this.$router.push({name: 'PMG510'});
          });
      });
    },
    /*
      * 陳列有効期限チェック
      * 陳列有効期限が過ぎている場合はエラー画面へ遷移する
     */
    canDisplay(idx) {
      try {
        // 商品の陳列有効期限を確認する。もし有効期限が切れていたら、エラーページに遷移
        const item = this.items[idx]
        const diff = DateTime.fromSQL(item.displayEndDate).diffNow();
        if (diff.milliseconds < 0) {
          console.warn(item.giftId + 'の陳列有効期限が切れています。');
          return false
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    errorImage(e) {
      e.target.src = getGiftNoImage();
    },
    htmlText(msg) {
      if (msg) {
        return msg.replace(/\r?\n/g, '<br>');
      }
    },
    scrollUsing() {
      try {
        const el = document.getElementById('giftUsing');
        el.scrollIntoView({
          behavior: 'smooth',
        });
      } catch (error) {
        console.error(error);
      }
    },
    async selectedGift(selectionType) {
      this.$data.isForMyself = selectionType === 'myself';
      gtmSendClick(this.$refs[selectionType], '商品詳細', this.$data.isForMyself ? '自分用に購入' : 'ギフト用に購入', this.giftName);

      // 有効期限切れのギフトは購入不可
      const diff = DateTime.fromSQL(this.expiryDate).diffNow();
      if (diff.milliseconds < 0) {
        this.openExpiredAlert();
        return;
      }
      const gift = {
        giftId: this.giftId,
        ...this.$data,
      };
      if (this.checkBasket()) {
        if (!this.$data.isForMyself) {
          await this.updateSaverCartData(this.giftId);
        }
        console.log('selectedGift', gift)
        store.commit('setCartItems', [gift]);
        if (this.lid) {
          // 上限チェック
          const result = await this.checkPurchaceLimit(gift.giftId);
          if (result?.isLimitOver) {
            // オーバーしているのでカートクリア
            store.commit('setCartItems', []);
            await this.updateSaverCartData(null);
            return;
          }
        }
        this.ecommerceAddToCartPush();

        // 検索フィルターをクリア
        store.commit('clearFilter')

        if (this.$data.isForMyself) {
          // 自分用のカードを作成
          return this.createCardForMyself()
        } else {
          this.$refs.layout.gtmPush('カードを選ぶ', `/gifts/${this.giftId}/chose-card-type`);
          await this.$router.push({name: 'PMG720', params: {giftId: this.giftId}});
        }
      } else {
        // ギフト差し替え確認ダイアログ
        this.saveGift = gift;
        this.$refs.giftReplacModal.openModal();
      }
    },
    /*
      * 自分用のカードを作成
     */
    async createCardForMyself() {
      const data = {
        name: CARD_NAME_FOR_MYSELF,
        giftId: this.giftId,
        isForMyself: true
      };
      console.log('createCardForMyself', data)
      await postCreateNomalCard(data)
        .then((res) => {
          this.youName = null;
          store.commit("setCardOriginId", res.cardOriginId);
          return this.updateSaverCartData(this.giftId)
        })
        .then(async () => {
          // 自分用のギフト購入する場合は買い物カゴのページに遷移
          this.$refs.layout.gtmPush("お買い物カゴ", "/cart");
          await this.$router.push({name: "PMG700"});
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({name: "PMG510"});
        });
    },

    async updateSaverCartData(giftId) {
      // ログインしていない場合
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      const data = {
        lid: this.lid,
        giftId: giftId,
        cardOriginId: null,
        updateMode: 1,
      };
      await updateCart(data).catch(() => {
        this.$router.push({name: "PMG510"});
      });
    },
    showSameBrandGift(refName) {
      gtmSendClick(this.$refs[refName], '商品詳細', refName == 'sameBrand1' ? 'ブランド名' : 'このブランドのギフトすべて', this.brandName);
      this.$refs.layout.gtmPush('ブランド別ギフト一覧', `/gifts/brands/${this.brandId}`);
      this.$router.push({
        name: 'PMG620',
        params: {
          brandId: this.brandId,
        },
      });
    },
    openDetail(id) {
      if (this.giftId != id) {
        this.$refs.layout.gtmPush('ギフトの詳細', `/gifts/${id}`);
        this.$router.push({name: 'PMG610', params: {giftId: id}});
      }
    },
    changeItem(index) {
      this.imgUrl = getItemImageUrl(this.items[index].image);
      this.giftName = this.items[index].giftName;
      this.price = this.items[index].price;
      this.priceDisplay = Number(this.items[index].price).toLocaleString();
      this.isTaxDisplay = this.items[index].isTaxDisplay;
      this.displayEndDate = this.items[index].displayEndDate; // 陳列有効期限
      this.expiryDate = this.items[index].expiryDate;
      this.expiryDateDisplay = formatExpiryDate(this.items[index].expiryDate);
      this.usingDescription = this.items[index].usingDescription;
      this.giftDescription = this.items[index].description;
      this.usingDescriptionDetail = this.items[index].usingDescriptionDetail;
      this.specNote = this.items[index].note;
      this.brandId = this.items[index].brandId;
      this.brandName = this.items[index].brandName;
      this.brandImgUrl = getBrandImageUrl(this.items[index].brandImage);
      this.brandDescription = this.items[index].brandDescription;
      this.notice = this.items[index].notice;
      this.ageVerification = this.items[index].ageVerification;
    },
    clickItem(index) {
      gtmSendClick(this.$refs['variation_' + index][0], '商品詳細', 'バリエーション', this.items[index].price);

      this.giftId = this.items[index].giftId;
      this.ecommerceviewItemPush()
      this.changeItem(index);
    },
    checkBasket() {
      if (
        !this.getCartItems ||
        !Array.isArray(this.getCartItems) ||
        this.getCartItems.length <= 0
      ) {
        return true;
      }

      return false;
    },
    clickCheckCart() {
      // 買い物カゴへ
      this.$refs.giftReplacModal.hideModal();
      this.$refs.layout.gtmPush('お買い物カゴ', '/cart');
      this.$router.push({name: 'PMG700'});
    },
    async clickReplaceGift() {
      // ギフト差し替え
      this.$refs.giftReplacModal.hideModal();
      await this.updateSaverCartData(this.giftId);
      store.commit('setCartItems', [this.saveGift]);
      // ポイント利用設定は初期化
      store.commit('setPointCondition', null);
      this.ecommerceAddToCartPush();
      // 現在のギフトカード削除
      if (this.getCardOriginId) {
        const data = {
          'card_origin_id': this.getCardOriginId,
        };
        await delCard(data)
          .then(() => {
            store.commit('setCardOriginId', null);
          });
        await this.updateDbCard(null);
      }
      if (this.lid) {
        const result = await this.checkPurchaceLimit(this.giftId);
        if (result.isLimitOver) {
          // オーバーしているのでカートクリア
          store.commit('setCartItems', []);
          await this.updateSaverCartData(null);
          return;
        }
      }
      this.$refs.layout.gtmPush('カードを選ぶ', `/gifts/${this.giftId}/chose-card-type`);

      if (this.$data.isForMyself) {
        // 自分用のカードを作成
        return this.createCardForMyself()
      } else {
        this.$refs.layout.gtmPush('カードを選ぶ', `/gifts/${this.giftId}/chose-card-type`);
        await this.$router.push({name: 'PMG720', params: {giftId: this.giftId}});
      }
    },
    async updateDbCard(cardId) {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      const data = {
        lid: this.lid,
        cardOriginId: cardId,
        updateMode: 2,
      };

      await updateCart(data).catch(() => {
        this.$router.push({name: "PMG510"});
      });
    },
    async checkPurchaceLimit(check_gift_id) {
      this.purchaseLimit = await getPurchaceLimit(this.lid, check_gift_id);
      if (this.purchaseLimit?.isLimitOver) {
        this.openPurchaseLimitOverModal();
      }
      return this.purchaseLimit;
    },
    openPurchaseLimitOverModal() {
      this.$refs.purchaseLimitOverModal.openModal();
    },

    // GA(eコマース) event:view_item
    ecommerceviewItemPush() {
      const items = []
      let value = 0

      const item = this.giftFindByGiftId()
      value += parseInt(item.price)
      const ecommerceItem = GtmEcommerceItem.getEcommerceItem(item)
      items.push(ecommerceItem)

      GtmEcommerce.viewItem(value, items)
    },

    // GA(eコマース) event:add_to_cart
    ecommerceAddToCartPush() {
      const items = []
      let value = 0

      const item = this.giftFindByGiftId()
      value += parseInt(item.price)
      const ecommerceItem = GtmEcommerceItem.getEcommerceItem(item)
      items.push(ecommerceItem)

      GtmEcommerce.addToCart(value, items)
    },
    giftFindByGiftId(){
      return this.items.find(item => item.giftId === this.giftId);
    }
  },
};
</script>

<style scoped lang="scss">
.pmg610 {
  &__image {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      border-bottom: 1px solid $gray5;
    }

    &__back {
      position: absolute;
      top: 16px;
      left: 16px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-image: url('@/assets/images/icon/back_button_D9D9D9.svg');
      background-position: center;
    }
  }

  &__content {
    background: $white1;

    .basic {
      padding: 24px 16px 32px;

      &__brand-name {
        margin-bottom: 4px;
        border-bottom: 1px solid var(--ion-color-primary);
        color: var(--ion-color-primary);
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
        cursor: pointer;
      }

      &__gift-name {
        margin-top: 16px;
        color: var(--ion-color-dark);
        font: $font-roboto-400;
        @include font-size(20, 26);
      }

      &__price-area {
        margin: 16px 0;
        display: flex;
        align-items: baseline;

        .price {
          margin-right: 4px;
          font: $font-roboto-700;
          @include font-size(26, 33.8);
        }

        .tax-text {
          color: var(--ion-color-medium);
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
        }

        .has-variation {
          margin-left: auto;
          color: $gray10;
          @include font-size(12, 19.2);
        }
      }

      &__ponta {
        display: flex;
        align-items: center;

        &__message {
          padding: 10px 10px 10px 0;
          margin-right: auto;
          color: $gray10;
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
        }

        &__point {
          display: flex;
        }

        .ponta__logo {
          height: 18px;
        }
      }

      &__using,
      &__expiration {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;

        &__icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        &__date,
        &__label,
        &__description {
          color: var(--ion-color-dark);
          font: $font-noto-sans-jp-400;
          @include font-size(14, 22.4);
        }

        &__label {
          white-space: nowrap;
        }

        &__description {
          cursor: pointer;
        }
      }

      &__notice {
        margin-top: 16px;
        color: var(--ion-color-tertiary);
        font: $font-noto-sans-jp-700;
        @include font-size(12, 19.2);
      }
    }

    .variation {
      margin: 32px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title {
        color: $gray10;
        font: $font-roboto-500;
        @include font-size(14, 24);
      }

      &__items {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        gap: 8px;

        &::-webkit-scrollbar {
          display: none;
        }

        &__button {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 0 0 auto;
          padding: 8px 12px;
          max-width: 160px;
          border: $gold1 solid 1px;
          border-radius: 6px;
          background: $white1;
          color: $gold1;
          cursor: pointer;

          .variation-name {
            font: $font-noto-sans-jp-400;
            @include font-size(12, 19.2);
          }

          .price {
            font: $font-roboto-500;
            @include font-size(14, 22.4);
          }
        }

        .active-item {
          background: $gold1;
          color: $white1;
        }
      }
    }

    .buy_button {
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      :deep(.button) {
        position: inherit;
        min-width: 312px;
      }

    }

    .buy_button_for_self {
      margin-top: 16px;
    }

    .detail,
    .spec,
    .using,
    .brand,
    .same-brand-gift {
      padding: 16px;
    }

    .brand {
      &__basic {
        display: flex;
        align-items: center;
        gap: 16px;

        &__image {
          img {
            width: 80px;
            border-radius: 8px;
            border: 1px solid var(--ion-color-light-shade);
          }
        }

        &__name {
          color: var(--ion-color-dark);
          font: $font-roboto-700;
          @include font-size(16, 19.2);
        }
      }
    }

    .same-brand-gift {
      &__gifts {
        padding-bottom: 6px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: auto;
        gap: 16px;
        width: 100%;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__show-gift {
        padding: 14px 16px;
        display: flex;
        justify-content: center;

        &__label {
          color: var(--ion-color-primary);
          font: $font-roboto-400;
          @include font-size(16, 20.8);
          cursor: pointer;
        }
      }
    }

    .au-login {
      margin: 32px 0;
    }
  }
}
</style>
