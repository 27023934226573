<template>
  <Layout ref="layout" title="" :default-hide-header="true">
    <div class="pmg510">
      <div class="pmg510__title">ページが表示できません</div>
      <div class="pmg510__text">
        ネットワークエラーか、URLが存在しないためページが表示できません。<br />ホームからお手続きをやり直してください。
      </div>
      <div class="pmg510__buttons">
        <Button label="ホームへ" type="fill" @click="showHome" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import Button from '@/components/button/Button';
export default {
  name: 'PMG510',
  components: {
    Layout,
    Button,
  },
  data: () => ({
    link: process.env.VUE_APP_CHAT_BOT_DEEP_LINK,
  }),
  methods: {
    showGiftList() {
      this.$refs.layout.gtmPush('ギフトを購入', '/gifts/search');
      this.$router.push({ name: 'PMG660' });
    },
    showHome() {
      this.$router.push({ name: 'PMG600' });
    },
  }
};
</script>

<style scoped lang="scss">
.pmg510 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: $white4;
  height: 100%;

  &__title {
    margin-bottom: 16px;
    color: $gray3;
    font: $font-roboto-700;
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 20);
  }

  &__text {
    width: 100%;
    max-width: 310px;
    color: $gray3;
    font: $font-roboto-400;
    text-align: center;
    letter-spacing: 0.15px;
    margin-bottom: 16px;

    @include font-size(14, 20);

    .link {
      color: $gray3;
      text-decoration: underline;
    }
  }

  &__buttons {
    .button {
      min-width: 248px;
    }
  }
}
</style>
