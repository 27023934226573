<template>
  <div class="brand">
    <div class="brand__header">
      <img class="brand__header__image" :src="imageUrl" :alt="brandName" />
      <span class="brand__header__name">{{ brandName }}</span>
    </div>
    <div class="brand__content">
      <div class="brand__content__description" v-html="brandDescriptionHtml"></div>
    </div>
  </div>
</template>

<script>
import { getBrandImageUrl } from '@/plugins/GiftHelper';

export default {
  name: 'BrandIntroduction',
  props: {
    brandName: {
      type: String,
      default: null,
    },
    brandDescription: {
      type: String,
      default: null,
    },
    brandImageUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    brandDescriptionHtml() {
      let html = '';
      if (this.brandDescription) {
        html = this.brandDescription.replace(/\r?\n/g, '<br>');
      }
      return html;
    },
    imageUrl() {
      return getBrandImageUrl(this.brandImageUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.brand {
  padding: 16px 16px 0;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &__image {
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }
    &__name {
      text-align: center;
      margin: 1rem;
      font: $font-roboto-700;
      font-size: 18px;
      line-height: 25.2px;
      letter-spacing: 0px;
      color: $black5;
    }
  }
  &__content {
    margin-bottom: 1rem;
    &__description {
      font: $font-noto-sans-jp-400;
      font-size: 12px;
      line-height: 19.2px;
      letter-spacing: 0px;
      color: $black5;
    }
  }
}
</style>
