<template>
  <span class="radio-img">
    <input type="checkbox" :id="id" :name="name" v-model="checked" :class="{active: isActive}"
           @change="clickCheckbox"
    >
    <label :for="id">
    </label>
  </span>
</template>

<script>
export default {
  name: "ComponentName",
  components: {},
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'checkbox',
    },
  },
  emits: ['clickCheckbox'],
  data: () => ({}),
  computed: {
    isActive() {
      return this.checked;
    },
    id() {
      return this.name + '_checkbox';
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    clickCheckbox() {
      this.$emit('clickCheckbox', this.checked);
    }
  }
}
</script>
<style scoped lang="scss">
input[type=checkbox] {
  display: none;

  & + label {
    position: relative;
    display: inline-block;
    background-image: url(@/assets/images/icon/checkbox_off.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
  }

  &.active {
    & + label {
      background-image: url(@/assets/images/icon/checkbox_on.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 23px;
      height: 23px;
    }
  }
}
</style>
