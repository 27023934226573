<template>
  <div class="dsi0101">
    <FirstViewBase
      :message="message"
      :date="date"
      :animation-data="animationData"
      :animation-image="animationImage"
      :background-images="backgroundImages"
      :disable-animation="disableAnimation"
    />
  </div>
</template>

<script>
import FirstViewBase from '@/components/messageCard/FirstViewBase'

import animationData from '@/assets/lottie/DSI0101/animationData.json'
import animationImage from '@/assets/images/FirstView/DSI0101/animation.svg'
import bg01 from '@/assets/images/FirstView/DSI0101/bg01.jpg'
import bg02 from '@/assets/images/FirstView/DSI0101/bg02.jpg'
import bg03 from '@/assets/images/FirstView/DSI0101/bg03.jpg'
import bg04 from '@/assets/images/FirstView/DSI0101/bg04.jpg'

export default {
  name: 'DSI0101',
  components: {
    FirstViewBase,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    animationData,
    animationImage,
    backgroundImages: [
      bg01,
      bg02,
      bg03,
      bg04,
    ],
  }),
}
</script>

<style scoped lang="scss">
.dsi0101 {
  width: 100%;
  height: 100%;

  :deep(.first-view-base) {
    .lottie {
      width: 91%;

      &__text {
        top: 66.5%;
        bottom: 13%;
        width: 70%;
        color: $black2;

        .message {
          font: $font-fot-tsukuardgothic-std-700;
        }

        .date {
          font: $font-bodoni-urw-500;
        }
      }
    }
  }
}
</style>
