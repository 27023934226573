<template>
  <ion-app>
    <SideMenu />
    <ion-router-outlet id="main-content" animated="false" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import SideMenu from '@/components/layout/SideMenu';

export default defineComponent({
  name: 'App',
  components: { IonApp, IonRouterOutlet, SideMenu },
});
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
