<template>
  <ion-page class="layout__page" mode="ios">
    <ion-header v-if="checkShowHeader" class="header" :class="headerClasses">
      <ion-toolbar class="header__toolbar">
        <ion-buttons class="header__toolbar__start" slot="start">
          <ion-menu-button v-if="showMenu" color="medium"/>
          <ion-back-button v-if="showBack" :default-href=defaultHref :text="backLabel"/>
          <ion-back-button v-if="showClose" :default-href=defaultHref text="" :icon="close" @click="BackPage()"/>
          <ion-button v-if="isShowCloseWindow" @click="closeWindow()">
            <ion-icon slot="icon-only" :icon="close"></ion-icon>
          </ion-button>
          <ion-button v-if="showCustomClose" @click="customClose()">
            <ion-icon slot="icon-only" :icon="close"></ion-icon>
          </ion-button>
          <ion-button v-if="showCustomBack" :text="backLabel" @click="customBack()">
            <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
            {{ backLabel }}
          </ion-button>
          <slot name="headerStart"/>
        </ion-buttons>
        <ion-title>
          {{ title }}
          <span class="sub-title">{{ subTitle }}</span>
        </ion-title>
        <ion-buttons slot="end" class="buttons">
          <div v-if="showAuLogin">
            <PontaPoint v-show="isAuIdAuthorization"></PontaPoint>
            <ion-button v-show="!isAuIdAuthorization" class="login" color="tertiary" ref="login" @click="login">
              ログイン
            </ion-button>
          </div>
          <slot name="headerEnd"/>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content
      ref="body"
      class="body ion-content-scroll-host"
      :class="bodyClasses"
      color="light"
      :scroll-events="scrollEvents"
      @ionScroll="onScroll"
    >
      <slot name="subHeader"/>
      <div v-if="showAuOnly" class="au-only">
        au, UQ mobile, povo, MVNO（au回線）を<br/>ご利用のお客さまのみカードを作<br/>成いただけます。
      </div>
      <slot v-else name="default"/>
    </ion-content>

    <PurchaseLimitOverModal ref="purchaseLimitOverModal" :purchaseLimit="purchaseLimit"/>
    <GiftReplacModal
      ref="giftReplacModal"
      @click:checkCart="clickCheckCart()"
      @click:replaceGift="clickReplaceGift()"
      @click:clockClose="clickReplaceClose()"
      :replaceGiftName=" saveCartData ? saveCartData.giftName : ''"
    />
  </ion-page>
</template>

<script>
import {emitter} from '@/eventBus';
import {mapGetters} from 'vuex';
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonBackButton,
  IonIcon,
} from '@ionic/vue';
import {close, chevronBackOutline} from 'ionicons/icons';
import {isScrollContents} from '@/plugins/Utils';
import {isAuthorization, auIdLogin} from '@/plugins/AuId';
import {getPurchaceLimit} from '@/plugins/GiftHelper';
import {gtmDataLayerPush} from '@/plugins/GtmHelper';
import {gtmSendClick} from "@/plugins/GtmHelper";
import {getUrl} from '@/plugins/UrlHelper';
// import {getCartInfo} from '@/plugins/Cart';
import {updateCart, listGiftCategoriesApi} from '@/plugins/ApiHelper';

import PurchaseLimitOverModal from '@/components/modal/Gift/PurchaseLimitOverModal';
import GiftReplacModal from '@/components/modal/Gift/GiftReplacModal';
import store from '@/store';
import {PURCHASE_FLOW_ROUTE_NAMES, ALWAYS_SHOW_HEADER_PAGE, NOT_SHOW_HEADER_PAGE} from '@/constants/router';


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Layout',
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonBackButton,
    IonIcon,
    PurchaseLimitOverModal,
    GiftReplacModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: [String, Number],
      default: '',
    },
    showAuOnly: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Boolean,
      default: false,
    },
    backLabel: {
      type: String,
      default: null,
    },
    showAuLogin: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    scrollEvents: {
      type: Boolean,
      default: false,
    },
    scrollFunc: {
      type: Function,
      default: null,
    },
    scrollToHideHeader: {
      type: Boolean,
      default: false,
    },
    defaultHideHeader: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    showCloseWindow: {
      type: Boolean,
      default: false,
    },
    showCustomClose: {
      type: Boolean,
      default: false,
    },
    customCloseFunc: {
      type: Function,
      default: null,
    },
    showCustomBack: {
      type: Boolean,
      default: false,
    },
    customBackFunc: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    scrollTop: 0,
    isShowHeader: false,
    isShowScrollHeader: true,
    purchaseLimit: null,
    close,
    chevronBackOutline,
    saveCartData: null,
    saveCardId: null,
    savePointCondition: null,
    isExternalAccess: false,
    classifications: [
      {id: 2, name: 'シーン', class: 'scenes', list: [], ids: []},
      {id: 3, name: 'ギフトの利用方法', class: 'receives', list: [], ids: []},
      {id: 1, name: 'カテゴリー', class: 'categories', list: [], ids: []},
    ],
    defaultHref: '/'
  }),
  computed: {
    ...mapGetters([
      'lid',
      'isOpenDialogModal',
      'isOpenPreviewModal',
      'isOpenEditModal',
      'isAuIdAuthorization',
      'getCartItems',
      'getCardOriginId',
      'getCategoryList',
      'getPointCondition'
    ]),
    bodyClasses() {
      let classes = [];
      if (this.isOpenDialogModal || this.isOpenPreviewModal || this.isOpenEditModal)
        classes.push('is-scroll-stop');
      return classes;
    },
    headerClasses() {
      let classes = [];
      if (this.fixedHeader) {
        classes.push('position-fixed');
      }

      if (this.scrollToHideHeader) {
        classes.push('scroll-to-hide-header');
        if (this.isShowScrollHeader) classes.push('is-show-header');
      }
      return classes;
    },
    isShowCloseWindow() {
      if (this.$route.name != 'PMG210') {
        // 決済処理画面、購入完了画面はスキップ
        return false;
      } else if (window.opener ||
        (window === window.top && history.length <= 1)) {
        return true;
      }
      return false;
    },
    checkShowHeader() {
      store.commit('setIsGiftDetailBack', true)
      let isGiftDetailPage = false
      // ギフト詳細画面かどうか
      if (this.$route.name === 'PMG610') {
        isGiftDetailPage = true
      }
      // ヘッダー非表示設定の場合は非表示にする
      if (this.defaultHideHeader && !this.scrollToHideHeader && !isGiftDetailPage) {
        return false;
      }
      let isHistoryCheck = false
      // 内部から遷移してきた履歴があるか
      if (history && history.state && (history.state.back || history.state.forward)) {
        isHistoryCheck = true;
      }
      // 内部からの遷移の場合
      if (window.opener || (window === window.top && isHistoryCheck)) {
        // ギフト詳細の場合は非表示にする
        if (isGiftDetailPage) {
          return false
        }
        return true
      } else {
        // 外部時からの遷移の場合
        const isShowHeaderPage = ALWAYS_SHOW_HEADER_PAGE.find(item => item === this.$route.name);
        // 常時ヘッダー表示するページかの確認
        if (isShowHeaderPage) {
          return true
        }
        // ギフト詳細の場合はボタンも非表示にする
        if (isGiftDetailPage) {
          store.commit('setIsGiftDetailBack', false)
        }
        return false
      }
    }
  },
  watch: {
    scrollTop(newValue, oldValue) {
      if (this.isExternalAccess) {
        return;
      }
      this.isShowScrollHeader = oldValue < 0 || newValue < oldValue;
    },
    isAuIdAuthorization(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.checkSavedCartInfo();
      }
    },
    $route(to, from) {
      // hashがあれば
      if (to.hash) {
        this.scrollTo(to.hash.replace('#', ''))
      } else {
        this.scrollToTop()
      }
    },
  },
  async mounted() {
    const self = this;
    emitter.on('pageScrollTop', () => self.scrollToTop());
    // await this.fetchListCategories();
    await isAuthorization();
    this.isShowHeader = !this.defaultHideHeader || this.scrollToHideHeader;
    this.isShowScrollHeader = !this.defaultHideHeader

    if (this.$route.name == 'PMG210') {
      // 受け取ったギフトの場合のみ、リファラーを判定し、eギフトドメイン（関連ドメイン）以外はヘッダーを非表示にする
      const referrer = document.referrer;
      if (!referrer.includes(window.location.host + '/card-display.html')) {
        const allowReferrerUrl = process.env.VUE_APP_ALLOW_REFERER_URL;
        const allowUrl = [window.location.hostname, ...allowReferrerUrl.split(',')];
        const contains = allowUrl.some((url) => referrer.includes(url));
        this.isShowHeader = (referrer.length > 0 && contains);
      } else {
        //遷移元がcard-display.htmlの場合はURLパラメータで判断
        this.isShowHeader = (this.$route.query['hide-header'] === undefined);
      }
      this.isExternalAccess = !this.isShowHeader;
    }

    if (this.$route.query.loggedin) {
      // ログイン直後のパラメータ削除
      const url = location.href.replace('loggedin=true', '');
      history.replaceState(null, null, url);
    } else if (this.getCartItems && this.getCartItems.length > 0) {
      // 画面更新時にカートのバッヂ反応させるために一時退避&クリア&復帰
      this.saveBeforeCartItem(this.getCartItems[0]);
      await store.commit('setCartItems', []);
      store.commit('setCartItems', [this.saveCartData]);
      this.saveCartData = null;
    }

    // 商品購入フローで使用されている画面にて未ログイン状態であれば、カートの中に商品がない場合はエラーページに遷移する。
    if (PURCHASE_FLOW_ROUTE_NAMES.includes(this.$route.name)) {
      if (!this.isAuIdAuthorization || !this.lid) {
        await this.checkCartItems()
      }
    }
  },
  methods: {
    /*
      * カートの中に商品があるかチェック。
      * 商品がなければエラーページに遷移する。
     */
    async checkCartItems() {
      const cartItems = this.getCartItems;
      const hasItems = cartItems && cartItems.length > 0;

      // カートに商品がない場合は、エラー画面に遷移
      return hasItems ? true : await this.$router.push({name: "PMG510"});

    },
    onScroll(e) {
      if (this.scrollFunc) this.scrollFunc(e);
      if (!this.scrollToHideHeader) return false;
      if (isScrollContents(e.srcElement.scrollEl)) this.scrollTop = e.detail.scrollTop;
    },
    scrollToTop(duration = 300) {
      if (this.$refs.body) {
        if (this.$refs.body?.$el) {
          this.$refs.body.$el.scrollToTop(duration);
        } else {
          this.$refs.body.scrollTop = 0;
        }
      }
    },
    login(additionalParams = {}) {
      gtmSendClick(this.$refs['login'].$el, 'ヘッダー', 'ログイン', '');
      const redirectUrl = process.env.VUE_APP_BASE_APP_URL + this.$route.path;
      auIdLogin(redirectUrl, additionalParams);
    },
    async checkPurchaceLimit(check_gift_id) {
      if (!this.lid) {
        console.log('checkPurchaceLimit: lid is null')
        return;
      }

      this.purchaseLimit = await getPurchaceLimit(this.lid, check_gift_id);
      console.log('checkPurchaceLimit', this.purchaseLimit)
      if (this.purchaseLimit?.isLimitOver) {
        this.openPurchaseLimitOverModal();
      } else if (this.saveCartData != null) {
        // 上限チェックOK & 退避したカート情報あるのでカート戻す
        store.commit('setCartItems', [this.saveCartData]);
        store.commit('setCardOriginId', this.saveCardId);
        // TODO: ポイント利用設定も保存
        // store.commit('setPointCondition', this.savePointCondition);

        this.saveCartData = null;
        this.saveCardId = null;
        this.savePointCondition = null;
      }
      return this.purchaseLimit;
    },
    openPurchaseLimitOverModal() {
      this.$refs.purchaseLimitOverModal.openModal();
    },
    async checkSavedCartInfo() {
      const skip_path_names = ['au_pay', 'PMG420'];
      if (skip_path_names.includes(this.$route.name)) {
        // 決済処理画面、購入完了画面はスキップ
        return;
      }

      // await getCartInfo().then(async (cartData) => {
      //   console.log('getCartInfo', cartData)
        
      //   let usedCard = false;
      //   //カートデータがDBから取得できた場合、同じ情報のままアップデートを試すことで決済済みカードと紐づいているかチェックする
      //   if(cartData?.card?.cardOriginId)
      //     await updateCart({
      //       lid: this.lid,
      //       cardOriginId: cartData.card.cardOriginId,
      //       updateMode: 2,
      //     })
      //     .catch(() => {
      //       usedCard = true;
      //     });

      //   // DBにカート情報がないか決済済みカードだった場合
      //   if ( !cartData || !cartData.gift || usedCard){
      //     let check_gift_id = null;
      //     // セッションストレージのカート情報がある場合
      //     if (this.getCartItems && this.getCartItems.length > 0) {
      //       check_gift_id = this.getCartItems[0].giftId;

      //       // カートのバッヂ反応させるために一時退避
      //       this.saveBeforeCartItem(this.getCartItems[0]);
      //       this.saveCardId = this.getCardOriginId;
      //       // TODO: ポイント利用設定も一時退避
      //       this.savePointCondition = this.getPointCondition;

      //       // カートクリア&復帰
      //       await store.commit('setCartItems', []);
      //       store.commit('setCartItems', [this.saveCartData]);
      //     }

      //     // 上限チェックを行い、問題なければ再度セッションストレージにセット
      //     const result = await this.checkPurchaceLimit(check_gift_id);

      //     if (result?.isLimitOver) {
      //       // カートクリア
      //       store.commit('setCartItems', []);
      //       store.commit('setCardOriginId', null);
      //       store.commit('setPointCondition', null);

      //       await this.resetDBCart();
      //     } else {
      //       // カート更新
      //       await this.updateDBCart(check_gift_id, this.getCardOriginId, this.getPointCondition)
      //         .catch((err) => {
      //           console.error(err);
      //           // カートクリア
      //           store.commit('setCartItems', []);
      //           store.commit('setCardOriginId', null);
      //           this.resetDBCart();
      //         });
      //       // ログインからの決済処理へ
      //       this.loginToPayment();
      //     }
      //     return;
      //   }


      //   // セッションストレージにカート情報がない場合
      //   if (!this.getCartItems || this.getCartItems.length == 0) {
      //     // DBに保存しているカート情報をセット
      //     this.saveBeforeCartItem(cartData.gift);
      //     this.saveCardId = cartData?.card?.cardOriginId;
      //     this.savePointCondition = cartData?.pointCondition;

      //     const result = await this.checkPurchaceLimit();
      //     if (result?.isLimitOver) {
      //       // 上限チェックNGのためカートクリア
      //       store.commit('setCartItems', []);
      //       store.commit('setCardOriginId', null);
      //       store.commit('setPointCondition', null);
      //       await this.resetDBCart();
      //     } else {
      //       // 問題ないためセッションストレージにカード情報とポイント利用設定をセット
      //       if (cartData.card) {
      //         store.commit('setCardOriginId', cartData.card.cardOriginId);
      //       }
      //       // if(cartData.pointCondition){
      //       //   store.commit('setPointCondition', cartData.pointCondition);
      //       // }
      //     }

      //     // セッションストレージに保存している商品情報とカード情報が同じ
      //   } else if (this.getCartItems[0]?.giftId == cartData.gift.giftId
      //     && this.getCardOriginId
      //     && this.getCardOriginId == cartData.card?.cardOriginId) {

      //     // カートのバッヂ反応させるために一時退避&クリア&復帰
      //     this.saveBeforeCartItem(this.getCartItems[0]);
      //     await store.commit('setCartItems', []);
      //     store.commit('setCartItems', [this.saveCartData]);
      //     this.saveCartData = null;

      //   } else {
      //     // 入れ替えダイアログ表示
      //     this.saveBeforeCartItem(this.getCartItems[0]);
      //     this.saveCardId = this.getCardOriginId;
      //     await store.commit('setCartItems', []);
      //     await store.commit('setCartItems', [cartData.gift]);
      //     let card_id = null;
      //     if (cartData.card) {
      //       card_id = cartData.card.cardOriginId;
      //     }
      //     store.commit('setCardOriginId', card_id);

      //     // TODO: ポイント利用設定はクリア
      //     this.savePointCondition = null
      //     store.commit('setPointCondition', null);
      //     // 確認ダイアログ
      //     this.$refs.giftReplacModal.openModal();
      //   }
      // });
    },
    saveBeforeCartItem(giftItem) {
      // ログイン前のカート情報退避
      this.saveCartData = null;
      let categoryIds = giftItem.categoryIds
      if (!categoryIds && giftItem.items) {
        categoryIds = this.getGiftCategoryIdsByGiftId(giftItem.items, giftItem.giftId)
      }
      this.saveCartData = {
        giftId: giftItem.giftId,
        gifteeGiftId: giftItem.gifteeGiftId,
        giftName: giftItem.giftName,
        description: giftItem.description,
        note: giftItem.note,
        price: giftItem.price,
        imgUrl: giftItem.imgUrl,
        usingDescription: giftItem.usingDescription,
        usingDescriptionDetail: giftItem.usingDescriptionDetail,
        variationGroup: giftItem.variationGroup,
        variationName: giftItem.variationName,
        isTaxDisplay: giftItem.isTaxDisplay,
        brandId: giftItem.brandId,
        brandName: giftItem.brandName,
        brandImage: giftItem.brandImage,
        brandDescription: giftItem.brandDescription,
        expiryDate: giftItem.expiryDate,
        displayEndDate: giftItem.displayEndDate,
        ageVerification: giftItem.ageVerification,
        categoryIds: categoryIds
      };
    },
    clickCheckCart() {
      // 買い物カゴへ
      this.saveCartData = null;
      this.$refs.giftReplacModal.hideModal();
      this.gtmPush('お買い物カゴ', '/cart');
      this.$router.push({name: 'PMG700'});
    },
    async clickReplaceGift() {
      // ギフト差し替え
      if (!this.saveCartData) {
        return
      }
      store.commit('setCartItems', [this.saveCartData]);
      store.commit('setCardOriginId', this.saveCardId);
      // TODO: ギフトを差し替えるときはポイント利用設定はクリア
      store.commit('setPointCondition', null);

      this.saveCartData = null;
      this.saveCardId = null;
      this.savePointCondition = null;
      this.$refs.giftReplacModal.hideModal();
      // DBのカート更新
      await this.updateDBCart(this.getCartItems[0].giftId, this.getCardOriginId, null)
        .then(async () => {
          return await this.checkPurchaceLimit();
        })
        .then(async (result) => {
          if (result?.isLimitOver) {
            // カートクリア
            store.commit('setCartItems', []);
            store.commit('setCardOriginId', null);
            store.commit('setPointCondition', null);
            await this.resetDBCart();
          }
        })
        .catch((err) => {
          console.error(err);
          // カードのみクリア
          store.commit('setCardOriginId', null);
          this.updateDBCart(this.getCartItems[0].giftId, null, null);
        });
    },
    async clickReplaceClose() {
      // ギフト差し替えモーダルの"閉じる"
      this.saveCartData = null;
      return;
    },
    gtmPush(title, path, params = {}) {
      const virtualPageUrl = getUrl(path, params);
      gtmDataLayerPush(virtualPageUrl, title);
    },
    async updateDBCart(giftId, cardOriginId, pointCondition = null) {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      // TODO: ポイント利用設定追加
      const data = {
        lid: this.lid,
        giftId: giftId,
        cardOriginId: cardOriginId,
        updateMode: 0,
        usedPoints: pointCondition ? pointCondition.usedPoints : null,
        isOnlyPoints: pointCondition ? pointCondition.isOnlyPoints : false,
      };

      await updateCart(data).catch((err) => {
        console.error(err);
        if (err.response.data && err.response?.data?.card_origin_id_already_used) {
          //カードが既に決済済みの決済情報と紐づいた場合
          throw err;
        };
      });

    },
    async resetDBCart() {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }
      const data = {
        lid: this.lid,
        giftId: null,
        cardOriginId: null,
        updateMode: 0,
      };
      await updateCart(data).catch(() => {
        this.$router.push({name: "PMG510"});
      });
    },
    closeWindow() {
      window.close();
    },
    customClose() {
      if (this.$props.customCloseFunc) {
        this.$props.customCloseFunc();
      } else {
        this.$router.go(-1);
      }
    },
    customBack() {
      if (this.$props.customBackFunc) {
        this.$props.customBackFunc();
      } else {
        this.$router.go(-1);
      }
    },
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        if (el){
          el.scrollIntoView({
            behavior: 'smooth',
          });
        }

      } catch (error) {
        console.error(error);
      }
    },
    loginToPayment() {
      // 決済時のログインだったか判定
      if (this.$route.query.loginBeforePurchase
        && this.getCartItems && this.getCartItems.length > 0
        && this.getCardOriginId
      ) {
        store.commit('clearAuPayProcessInfo');
        store.commit('setGiftId', this.getCartItems[0].giftId);
        store.commit('setCardOriginId', this.getCardOriginId);
        this.gtmPush('auかんたん決済', '/au_pay');
        this.$router.push({name: 'au_pay'});
      }

      // TODO: 全額ポイント決済時のログインだったか判定
      if (this.$route.query.loginBeforePointPay) {
        // store.commit('clearAuPayProcessInfo');
        store.commit('setGiftId', this.getCartItems[0].giftId);
        store.commit('setCardOriginId', this.getCardOriginId);

        this.$router.push({name: 'PMG700'});
      }
    },
    // async fetchListCategories() {
    //   const tasks = this.classifications.map((classification) =>
    //     listGiftCategoriesApi({classification: classification.id})
    //   );
    //   let categoryList = []
    //   await Promise.all(tasks)
    //     .then((res) => {
    //       this.classifications.forEach((classification, i) => {
    //         const list = res[i];
    //         this.classifications[i].list = list;
    //         categoryList.push(list)
    //       });
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    //   const storageCategoryList = [].concat(...categoryList)
    //   store.commit("setCategoryList", storageCategoryList)
    // },
    getGiftCategoryIdsByGiftId(arr, gift_id) {
      const gift = arr.find(item => item.giftId === gift_id);
      return gift.categoryIds
    },
    BackPage() {
      // 贈り主の名前入力画面の場合 お買い物カゴに遷移するようにする
      if (this.$route.name === 'PMG330') {
        this.$router.push({name: 'PMG700'});
      }
    }
  },
};
</script>

<style scoped lang="scss">
.layout {
  &__page {
    width: 100%;
    max-width: $baseScreenWidth1;
    margin: 0 auto;
  }
}

.header {

  &__toolbar {
    display: flex;
    align-items: flex-end;
    --background: var(--ion-color-light);

    &__start {
      ion-back-button {
        padding-left: 6px;
      }
    }

    .buttons {
      margin-right: 6px;
    }
  }


  &.scroll-to-hide-header {
    transition: transform 300ms;
    transform: translateY(-70px);

    &.is-show-header {
      transform: translateY(0);
    }
  }

  &.position-fixed {
    position: fixed;
  }

}

.body {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: $white4;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-scroll-stop {
    overflow: hidden;
  }

  .au-only {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font: $font-roboto-700;
    color: rgba($gray6, 0.87);
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 20);
  }
}

.login {
  font: $font-roboto-400;
  @include font-size(14, 18.2);
}

ion-title {
  font: $font-roboto-700;
  @include font-size(16, 22.4);
}
</style>
