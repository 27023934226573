<template>
  <div class="brand-item">
    <img :class="brandItem()" :src="imgSrc" :alt="brandName"/>
  </div>
</template>

<script>
export default {
  name: 'BrandItem',
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
  },
  methods: {
    brandItem() {
      // ギフト一覧
      if (this.$route.name == "PMG600"){
        return "img-600";
      }
      // ブラン一覧
      else if (this.$route.name == "PMG650") {
        return "img-650";
      }
    }
  },
};
</script>

<style scoped lang="scss">
.brand-item {
  .img-600 {
    max-width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 8px;
  }
  .img-650 {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }


}
</style>
