<template>
  <div class="form-textarea" :class="size">
    <textarea ref="textarea" class="form-textarea__input" v-model="value" :placeholder="placeholder"/>
    <div class="form-textarea__dummy">{{ `${value}\n` }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    size: {
      type: String,
      default: 'medium',
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.maxlength) {
          value = value.slice(0, this.maxlength);
          this.$refs.textarea.value = value;
        }
        this.$emit('update:modelValue', value);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.form-textarea {
  position: relative;
  width: 100%;

  &.small {
    min-height: 38px;
  }

  &.medium {
    min-height: 98px;
  }

  &.large {
    min-height: 198px;
  }

  &__input,
  &__dummy {
    padding: 8px 12px;
    border: solid 1px $gray2;
    border-radius: 6px;
    font: $font-inter-400;

    @include font-size(16, 20);
  }

  &__input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: $white1;
    color: $black1;
    resize: none;
    outline: none;
    overflow: hidden;

    &:focus {
      border-color: $gold1;
    }

    &::placeholder {
      color: $gray3;
    }
  }

  &__dummy {
    position: relative;
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    text-rendering: geometricprecision;
    opacity: 0;
    z-index: -1;
  }
}
</style>
