<template>
  <p class="point" @click="goPontaPortal()">
    <span v-if="isMark" class="mark">P</span>
    <span v-if="isPontaLogo" class="ponta-logo"><img src="@/assets/images/ponta.png" alt=""></span>
    <span class="value">{{ pointDisplay }}</span>
  </p>
</template>

<script>
import _ from "lodash";
import { getPontaPointApi } from "@/plugins/ApiHelper";
import { mapGetters } from "vuex";

export default {
  name: "PontaPoint",
  props: {isMini: {
      type: Boolean,
      default: true,
    },
    isMark: {
      type: Boolean,
      default: true,
    },
    isPontaLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["lid", "isAuIdAuthorization"]),
    pointDisplay() {
      if (_.isNaN(this.point) || _.isNil(this.point)) {
        return "";
      }
      if (!_.isNumber(this.point)) {
        return this.point;
      }

      if (!this.isMini) {
        return Number(this.point).toLocaleString() + "P";
      }

      const pointStr = String(this.point);

      if (pointStr.length >= 12) {
        // 兆
        return pointStr.slice(0, pointStr.length - 11) + "兆+P";
      } else if (pointStr.length >= 9) {
        // 億
        return pointStr.slice(0, pointStr.length - 8) + "億+P";
      } else if (pointStr.length >= 6) {
        // 万
        return pointStr.slice(0, pointStr.length - 4) + "万+P";
      }

      return Number(this.point).toLocaleString() + "P";
    },
  },
  data: () => ({
    point: null,
  }),
  watch: {
    lid(newVal) {
      if (newVal) {
        this.getPoint();
      }
    },
  },
  mounted() {
    this.getPoint();
  },
  methods: {
    async getPoint() {
      if (_.isNil(this.lid) || !this.isAuIdAuthorization) {
        this.point = "";
        return;
      }

      const param = {
        lid: this.lid,
      };
      this.point = "...";

      this.point = await getPontaPointApi(param)
        .then((res) => {
          return  _.isNumber(res.point) ? Number(res.point) : "-----";
        })
        .catch(() => {
          return "-----";
        });
    },
    goPontaPortal() {
      window.open(process.env.VUE_APP_PONTA_PORTAL);
    },
  },
};
</script>

<style scoped lang="scss">
.point {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .mark {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    text-align: center;
    background: $orange1;
    color: $white1;
    font: $font-inter-500;

    @include font-size(10, 14);
  }

  .ponta-logo {
    width: 60px;
  }

  .value {
    margin-left: 8px;
    color: $orange1;
    font: $font-inter-600;

    @include font-size(14, 20);
  }
}
</style>
