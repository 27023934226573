<template>
  <transition name="fade">
    <div v-if="isShowModal" class="dialog-modal">
      <div class="dialog-modal__container">
        <button class="close" @click="hideModal"/>
        <div class="header">
          <slot name="header">ヘッダー</slot>
        </div>
        <div class="body">
          <slot name="body">
            <p class="body__text">ボディ</p>
            <p class="body__text">テキスト</p>
          </slot>
        </div>
        <div class="buttons">
          <slot name="buttons">
            <button class="buttons__button">ボタン1</button>
            <button class="buttons__button">ボタン2</button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DialogModal',
  data: () => ({
    isShowModal: false,
  }),
  unmounted () {
    this.setOpenModal(false);
  },
  methods: {
    openModal() {
      this.isShowModal = true;
      this.setOpenModal(true);
    },
    hideModal() {
      this.isShowModal = false;
      this.setOpenModal(false);
    },
    setOpenModal(value) {
      this.$store.commit('setOpenModal', {
        key: 'dialog',
        value: value
      });
    },
  }
}
</script>

<style scoped lang="scss">
.dialog-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($black1, 0.6);
  z-index: 99999;

  &.fade-enter-active {
    animation: fade-in 200ms;
  }

  &.fade-leave-active {
    animation: fade-in 200ms reverse;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__container {
    position: relative;
    width: calc(100% - 30px);
    max-width: 344px;
    background: $white1;
    box-shadow: 0 2px 10px $shadow1;
    border-radius: 6px;
    overflow: hidden;

    .close {
      position: absolute;
      top: 24px;
      right: 23px;
      width: 20px;
      height: 20px;
      background-image: url("../../assets/images/icon/close_button_9CA3AF.svg");
      background-position: center;
      background-size: 20px 20px;
      background-repeat: no-repeat;
    }

    .header {
      width: 100%;
      height: 1em;
      padding: 24px 58px 15px 24px;
      box-sizing: content-box;
      font: $font-roboto-700;
      color: $black4;

      @include font-size(16, 20);
    }

    .body {
      padding: 0 24px 24px;
      font: $font-roboto-400;
      color: $black1;

      @include font-size(16, 24);

      &__text,
      :slotted(.body__text) {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;

      &__button,
      :slotted(.buttons__button) {
        width: 100%;
        padding: 0;
        background: $white1;
        border-top: 1px solid $gray7;
        font: $font-roboto-500;
        color: $gold1;
        letter-spacing: 1.2px;

        @include font-size(20, 56);

        &:hover,
        &:focus,
        &:active {
          background: $white3;
        }
      }
    }
  }
}
</style>
