<template>
  <Layout title="" :default-hide-header="true">
    <div class="pmg500">
      <div class="pmg500__title">大変申し訳ございません<br>ただいまメンテナンス中です</div>
      <div class="pmg500__text">しばらく時間を置いて再度ご利用ください。</div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
export default {
  name: 'PMG500',
  components: {
    Layout,
  },
}
</script>

<style scoped lang="scss">
.pmg500 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: $white4;
  height: 100%;

  &__title {
    margin-bottom: 16px;
    color: $gray3;
    font: $font-roboto-700;
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 20);
  }

  &__text {
    width: 223px;
    color: $gray3;
    font: $font-roboto-400;
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 20);
  }
}
</style>
