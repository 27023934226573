<template>
  <div class="pmg-Page">
    <h1 class="pmg-Page__title">特定商取引法に基づく表記</h1>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">サービス名称</h2>
      <p class="pmg-Page__paragraph">プラメギフト</p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">販売業者および運営統括責任者</h2>
      <p class="pmg-Page__paragraph">
        KDDI株式会社<br />
        高野　将
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">所在地</h2>
      <p class="pmg-Page__paragraph">
        KDDI株式会社<br />
        〒102-8460<br />
        東京都千代田区飯田橋3丁目10番10号 ガーデンエアタワー
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">お問い合わせ先</h2>
      <p class="pmg-Page__paragraph">
        お客さまセンター(年中無休)<br />
        ＜メッセージサポート＞<br />
        <a
          href="https://www.au.com/support/inquiry/message/"
          target="_blank"
          onClick="trEventBe(this,'特商法','お客さまセンター','',event);"
          >https://www.au.com/support/inquiry/message/</a
        ><br />
        受付時間：（AI）24時間、（アドバイザー）10～22時<br />
        ＜電話サポート＞<br />
        au携帯電話から：局番なし 157 (無料)、一般電話から：0077-7-111 (無料)<br />
        受付時間：9:00～20:00
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">商品代金</h2>
      <p class="pmg-Page__paragraph">
        各商品詳細ページに表示しています（一部課税対象外の商品を除き、消費税を含む）。<br />
        ※配送商品の価格には、配送料を含みます。
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">商品代金以外の料金</h2>
      <p class="pmg-Page__paragraph">
        当サイトへのインターネット接続料金または携帯電話通信料金は、お客さま負担となります。
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">お支払方法</h2>
      <p class="pmg-Page__paragraph">
        Pontaポイント
      </p>
      <p class="pmg-Page__paragraph">
        auかんたん決済（通信料金合算支払い、au PAY カード支払い、au PAY 残高支払い）
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">お支払期限</h2>
      <p class="pmg-Page__paragraph">
        購入手続完了時に決済処理を行い、auかんたん決済会員規約の定めに従ってご請求いたします。
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">引き渡し時期</h2>
      <p class="pmg-Page__paragraph">
        購入手続完了後、商品を利用するための電子チケットURLを即時に発行いたします。
      </p>
    </section>

    <section class="pmg-Page__section">
      <h2 class="pmg-Page__title -h2">返品・交換、キャンセルについて</h2>
      <p class="pmg-Page__paragraph">
        商品購入後は、以下の場合を除き、返品・交換、キャンセルはできません。
      </p>
      <ul class="pmg-Page__ul">
        <li class="pmg-Page__olItem">
          本サービスの停止により、本チケットと対象商品等との交換が不能となった場合
        </li>
        <li class="pmg-Page__olItem">
          システムの障害により、本チケットと対象商品等との交換が不能になった場合
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Commerce',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
