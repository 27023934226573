<template>
  <div class="gift-item">
    <div class="gift-item__image">
      <img :src="imgSrc" />
    </div>
    <div class="gift-item__info">
      <p class="gift-name">{{ giftName }}</p>
      <p class="brand-name">{{ brandName }}</p>
      <p class="expiry-date">{{ expiryDateDisplay(expiryDate) }}</p>
      <div class="gift-total">
        <span>金額<span v-if="isTaxDisplay">（税込）</span></span>
        <span class="amount">¥{{ amountDisplay }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatExpiryDate,
  getItemImageUrl,
} from "@/plugins/GiftHelper";

export default {
  name: "PurchaseGiftItem",
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    brandName: {
      type: String,
      default: "",
    },
    giftName: {
      type: String,
      default: "",
    },
    price: {
      type: [String, Number],
      default: "",
    },
    expiryDate: {
      type: String,
      default: "",
    },
    amount: {
      type: Number,
      default: 1,
    },
    isTaxDisplay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    imgSrc() {
      if (this.imgUrl.indexOf("http") >= 0) {
        return this.imgUrl;
      }
      return getItemImageUrl(this.imgUrl);
    },
    amountDisplay() {
      return Number(this.amount).toLocaleString();
    },
  },
  methods: {
    expiryDateDisplay() {
      return this.expiryDate ? formatExpiryDate(this.expiryDate) : null;
    },
  },
};
</script>

<style scoped lang="scss">
.gift-item {
  display: flex;
  background: $white1;

  &__image {
    width: calc(112 / 311 * 100%);

    img {
      max-width: 100%;
      border: 1px solid $gray5;
      border-radius: 8px;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    align-items: flex-start;
    row-gap: 4px;
    padding: 0 0 0 16px;
    flex: 1;

    .brand-name {
      color: $gray10;
      font: $font-noto-sans-jp-400;

      @include font-size(10, 16);
    }

    .gift-name {
      overflow: hidden;
      text-align: left;
      color: $black6;
      font: $font-noto-sans-jp-400;

      @include font-size(14, 22.4);
    }

    .gift-total {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $black6;
      font: $font-noto-sans-jp-400;

      @include font-size(10, 16);

      .amount {
        @include font-size(14, 22.4);
        font-weight: bold;
      }
    }

    .expiry-date {
      color: $black6;
      font: $font-noto-sans-jp-400;

      @include font-size(10, 16);
    }
  }
}
</style>
