<template>
  <IonicModal
    ref="modal"
    title="Pontaポイント利用"
    :showFooter="false"
    @on-close-dom="onCloseDom"
  >
    <template #body>
      <div class="block">
        <div class="block__header">

          <div class="point-balance">
            <span class="title">保有しているPontaポイント</span>
            <span class="point"><span class="available-points">{{ pointBalanceDisplay }}（円分）</span></span>
          </div>

          <div class="point-menu-box">
            <div class="point-menu" @click="selectedPointMenuVal='1'">

              <CheckButton
                :name="'pointMenu'"
                :on-value="'1'"
                v-model="selectedPointMenuVal"
              ></CheckButton>

              <span v-if="pointsEqualOrOverPrice">ポイントで全額支払う<br/>
                （{{ priceDisplay }}P）
              </span>
              <span v-else>利用可能なポイントをすべて利用する<br/>
                （{{ pointBalanceDisplay }}P）
              </span>
            </div>
            <div>
              <div class="point-menu" @click="selectedPointMenuVal='2'">
                <CheckButton
                  :name="'pointMenu'"
                  :on-value="'2'"
                  v-model="selectedPointMenuVal"
                ></CheckButton>
                <span>一部のポイントを利用する</span>

              </div>
              <div class="point-input">

                <div class="form-input-area">
                  <FormInput
                    v-model="inputPoints"
                    :placeholder="null"
                    :disabled="!enableInputPoints"
                    :type="'number'"
                    :hasError="error !== ''"
                    @clearError="clearError"
                  ></FormInput>
                  <span class="error">{{ error }}</span>
                </div>
              </div>
            </div>

            <div class="point-menu" @click="selectedPointMenuVal='3'">
              <CheckButton
                :name="'pointMenu'"
                :on-value="'3'"
                v-model="selectedPointMenuVal"
              ></CheckButton>
              <span>ポイントを利用しない</span>
            </div>

          </div>
        </div>
        <div class="block__footer">
          <Button label="利用ポイントを適用" type="fill" ref="apply-point" @click="applyPoint()"/>
          <Button label="キャンセル" type="fillwhite-nonline-nonshadow" ref="cancel-point-selection" @click="cancelPointSelection()"/>
        </div>
      </div>
    </template>
  </IonicModal>
</template>

<script>

import IonicModal from "@/components/ionic/IonicModal";
import CheckButton from "@/components/button/CheckButton.vue";
import Button from "@/components/button/Button";
import FormInput from '@/components/form/PointFormInput'
import {POINT_SELECT_MENUS, ERROR_MESSAGES_FOR_POINT_SELECT} from "@/constants/cart";

export default {
  name: "CartReplaceCardMocal",
  components: {
    FormInput,
    IonicModal,
    Button,
    CheckButton,
  },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    pointBalance: {
      type: Number,
      default: 0,
    },
    selectedPointMenu: {
      type: String,
      default: POINT_SELECT_MENUS.NO_USE_POINT,
    }
  },
  data: () => ({
    inputPoints: null,
    error: ''
  }),
  computed: {
    selectedPointMenuVal: {
      get() {
        return this.selectedPointMenu;
      },
      set(value) {
        this.$emit("update:selectedPointMenu", value);
      },
    },
    pointsEqualOrOverPrice() {
      // 保有ポイントが商品価格以上かどうか
      return this.pointBalance >= this.price;
    },
    priceDisplay() {
      return `${Number(this.price).toLocaleString()}`;
    },
    pointBalanceDisplay() {
      if (this.pointBalance === null) {
        return "ー"
      } else {
        return `${Number(this.pointBalance).toLocaleString()}`
      }
    },
    enableInputPoints() {
      return this.selectedPointMenu === POINT_SELECT_MENUS.USE_PART_OF_POINTS;
    },
  },
  watch: {},
  emit: [
    'apply:usePoints',
    'update:selectedPointMenu'
    // 'click:cancelPointSelection',
    // 'click:cancelPointSelectionDom',
  ],
  mounted() {
  },
  updated() {
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    cancelPointSelection() {
      this.error = '';

      // this.$emit('click:cancelPointSelection');
      // this.$emit('click:cancelPointSelectionDom', this.$refs['cancel-point-selection'].$el);
      this.hideModal();
    },

    checkInputPoints() {
      console.log('checkInputPoints', this.inputPoints, this.pointBalance)
      if (this.inputPoints === null) {
        this.error = ERROR_MESSAGES_FOR_POINT_SELECT.NO_INPUT;
        return false;
      }

      if(Number(this.inputPoints) <= 0) {
        this.error = ERROR_MESSAGES_FOR_POINT_SELECT.NOT_NUMBER;
        return false;
      }

      // 保有ポイント以上のポイントを入力した場合
      if (Number(this.inputPoints) > Number(this.pointBalance)) {
        this.error = ERROR_MESSAGES_FOR_POINT_SELECT.OVER_POINT_BALANCE;
        return false;
      }
      // 商品価格以上のポイントを入力した場合
      if (Number(this.inputPoints) > Number(this.price)) {
        this.error = ERROR_MESSAGES_FOR_POINT_SELECT.OVER_PRICE;
        return false;
      }
      // 半角数字以外の場合
      if (!/^[0-9]+$/.test(this.inputPoints)) {
        this.error = ERROR_MESSAGES_FOR_POINT_SELECT.NOT_NUMBER;
        return false;
      }
      return true;
    },
    setInputPoints(value) {
      console.log('setInputPoints', value)
      this.inputPoints = value;
    },
    clearInputPoints() {
      console.log('clearInputPoints')
      this.inputPoints = null;
    },
    clearError() {
      this.error = '';
    },
    applyPoint() {
      if (this.selectedPointMenu === POINT_SELECT_MENUS.USE_ALL_POINTS) {
        if (this.pointsEqualOrOverPrice) {
          // ポイントで全額支払う
          this.$emit('apply:usePoints', this.price, true);
        } else {
          // 利用可能なポイントをすべて利用する
          this.$emit('apply:usePoints', this.pointBalance, false);
        }

        this.inputPoints = null;
      } else if (this.selectedPointMenu === POINT_SELECT_MENUS.USE_PART_OF_POINTS) {
        // validate
        if (this.checkInputPoints() === false) {
          return
        }

        if(Number(this.inputPoints) === Number(this.price)) {
          this.$emit('apply:usePoints', this.inputPoints, true);
        } else {
          this.$emit('apply:usePoints', this.inputPoints, false);
        }

      } else {
        this.$emit('apply:usePoints', 0, false);
        this.inputPoints = null;
      }

      this.hideModal();
    },
  },
};
</script>
<style scoped lang="scss">
.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 1rem;
  .red {
    color: $red2;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    font: $font-noto-sans-jp-400;
    @include font-size(14, 22.4);
    padding: 0 0 32px 0;

    &__text {
      font: $font-noto-sans-jp-700;
      @include font-size(16, 25.6);
      margin-bottom: 1rem;;
    }

    .warn {
      font: $font-noto-sans-jp-400;
    }

    .point-balance {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 24px;

      .title {
        color: #666666;
        font-weight: normal !important;
        font: $font-noto-sans-jp-700;
        @include font-size(14, 22.4);
      }

      .point {
        color: $orange1;
        font-weight: bold;
        font: $font-noto-sans-jp-400;
        @include font-size(16, 25.6);

        .available-points {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    .point-menu-box {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .point-menu {
        display: flex;
        align-items: center;

        .radio-img {
          display: flex;
          padding-right: 12px;
        }
      }
    }

    .point-input {
      margin-top: 8px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    button {
      width: 311px;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

ion-modal {
  --max-height: 95%;
  --height: 488px;
  align-items: flex-end;
}

.error {
  color: $red2;
  font: $font-noto-sans-jp-400;
  @include font-size(12, 19.2);
}

.form-input-area {
  width: 90%;
  float: right;
  position: relative;

}

</style>
