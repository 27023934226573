<template>
  <div class="link__info" style="display: flex; justify-content: center;">
    <a href="https://bot.plus-msg.com/?botId=sip:audigitalgift65@maap.plus-msg.com&amp;msg="
        @click="clickLink" ref="link"
        style="">
        <img src="@/assets/images/btn_follow.png" alt="プラメギフト公式アカウント 便利でおトクな情報をチェック">
    </a>
  </div>
</template>

<script>
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: "PlusMessageImageBadges",
  props: {
    sendTrClickEvent: {
      type: Boolean,
      default: false,
    },
    GALabel1: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickLink(){
      if(!this.sendTrClickEvent) 
        return;

      gtmSendClick(this.$refs['link'],this.GALabel1,'プラメギフト公式アカウント','');
    },
  },
};
</script>
<style scoped lang="scss"></style>
