<template>
  <Layout :title="title" :showMenu="false" :showBack="true" backLabel="戻る">
    <div class="content">
      <iframe class="content__iframe" :src="url"></iframe>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import { getGiftInfoList } from "@/plugins/ApiHelper";
import { queryStringDelete } from '@/plugins/Utils'

export default {
  name: "PMG630",
  components: {
    Layout,
  },
  props: {
  },
  data() {
    return {
      info_list: [],
      info: null
    };
  },
  computed: {
    title(){
      let title = this.$route.params.title
      if (!title && this.info){
        title = this.info.title
      }
      document.title = `${title} | プラメギフト`;
      
      return title;
    },
    url(){
      return queryStringDelete(this.$route.params.url);
    },
  },
  watch: {},
  mounted() {
    this.getInfoList()
  },
  methods: {
    backView() {
      this.$router.go(-1);
    },
    getInfoList() {
      getGiftInfoList()
        .then((res) => {
          this.info_list = res.info_list;
          this.info = this.info_list.find(item => item.url === this.url);
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "PMG510" });
        })
    },
  },
};
</script>

<style scoped lang="scss">
.button-back {
  font: $font-roboto-400;
  font-size: 14px;
  line-height: 18.2px;
  color: $gold2;
  display: flex;
  align-items: center;
}
ion-toolbar {
  height: 74px;
  display: flex;
  align-items: flex-end;
  ion-title {
    font: $font-roboto-400;
    font-size: 16px;
    line-height: 22.4px;
    color: $black6;
  }
}

.content {
  width: 100%;
  height: 100%;
  &__iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
