export const POINT_SELECT_MENUS = {
  USE_ALL_POINTS: '1', // 全てのポイントを利用する
  USE_PART_OF_POINTS: '2', // 一部のポイントを利用する
  NO_USE_POINT: '3', // ポイントを利用しない
  ERROR: '99', // ポイント取得エラー
}


export const ERROR_MESSAGES_FOR_POINT_SELECT = {
  OVER_POINT_BALANCE: '保有する以上のポイントは利用できません',
  OVER_PRICE: 'ギフトの購入金額以内のポイントを入力してください',
  NOT_NUMBER: '利用するポイントを半角数字で入力してください',
  NO_INPUT: '利用するポイントを半角数字で入力してください',
}

export const CART_UPDATE_MODE = {
  DEFAULT: 0,
  ADD_GIFT: 1,
  CREATE_CARD: 2,
  SELECT_POINT: 3,
}
