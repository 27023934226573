<template>
    <div id="wrapper">
      <main>
        <h1>
        <div class="lptrans">
          <img class="img_auto_width" src="@/assets/images/special-feature-006/eye_catching_cowork.png" width="375" height="375" alt="同僚・仕事仲間に贈るギフト特集~" />
        </div>
        </h1>
        <div class="introduction">
          <h1>プラメギフトとは</h1>
          <ul class="decoration_card">
            <li>
              <img src="@/assets/images/special-feature-005/Illustration_gift.svg" width="60" height="60" alt="" />ギフト受け取り<br />URLを相手に<br />
              送るだけ！
            </li>
            <li>
              <img src="@/assets/images/special-feature-005/Illustration_hand.svg" width="60" height="60" alt="" />ちょっとした<br />気持ちを<br />
              すぐに贈れる！
            </li>
            <li>
              <img src="@/assets/images/special-feature-005/Illustration_home.svg" width="60" height="60" alt="" />住所を<br />知らなくても<br />
              贈れる！
            </li>
          </ul>
        </div>
        <a class="button" href="/gifts/static/about/" target="_blank"
          ref="about01"
          @click="clickEvent3('about01','プラメギフトとは','サービス紹介','');"
        >プラメギフトについて詳しくみる</a>
  
        <div class="card_container">
        <!--<h1>シーン別おすすめギフトをご紹介♪</h1>-->
        <h2>ちょっとした感謝を伝えたいとき</h2>
        <h3>例えば...同僚にトラブル対応を手伝ってもらい、<br>「ありがとう」を伝えたい！</h3>
        <ul class="card_list">
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_54"
              @click="clickEvent('gift_54','ちょっとした感謝_01','ミニストップ_54_143');
              ecommerceSelectPromotionPush(54, '【お持ち帰り限定】ガルボチョコポケットパック', 'ミニストップ', 143); 
              openDetail(54);
            ">
              <img src="@/assets/images/special-feature-006/card/ministop_002.png" width="164" height="164" alt="ミニストップ" />
              <div class="card_text_container">
                <p class="card_brand_name">ミニストップ</p>
                <p class="card_title">【お持ち帰り限定】ガルボチョコポケットパック</p>
                <p class="card_price">¥143<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_27"
              @click="clickEvent('gift_27','ちょっとした感謝_02','コカ・コーラ_27_150');
              ecommerceSelectPromotionPush(27, 'Coke ON ドリンクチケット（お好きな「ジョージア」製品1本）', 'コカ・コーラ', 150); 
              openDetail(27);
            ">
              <img src="@/assets/images/special-feature-006/card/cocacola_001.png" width="164" height="164" alt="コカ・コーラ" />
              <div class="card_text_container">
                <p class="card_brand_name">コカ・コーラ</p>
                <p class="card_title">Coke ON ドリンクチケット（お好きな「ジョージア」製品1本）</p>
                <p class="card_price">¥150<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_186"
              @click="clickEvent('gift_186','ちょっとした感謝_03','サンマルクカフェ_186_220');
              ecommerceSelectPromotionPush(186, 'チョコクロチケット', 'サンマルクカフェ', 220); 
              openDetail(186);
            ">
              <img src="@/assets/images/special-feature-006/card/saintmarccafe_006.png" width="164" height="164" alt="サンマルクカフェ" />
              <div class="card_text_container">
                <p class="card_brand_name">サンマルクカフェ</p>
                <p class="card_title">チョコクロチケット</p>
                <p class="card_price">¥220<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_238"
              @click="clickEvent('gift_238','ちょっとした感謝_04','ファミリーマート_238_351');
              ecommerceSelectPromotionPush(238, 'ハーゲンダッツ　各種　いずれか1点（税込351円）引換券', 'ファミリーマート', 351); 
              openDetail(238);
            ">
              <img src="@/assets/images/special-feature-006/card/familymart_014.png" width="164" height="164" alt="ファミリーマート" />
              <div class="card_text_container">
                <p class="card_brand_name">ファミリーマート</p>
                <p class="card_title">ハーゲンダッツ　各種　いずれか1点（税込351円）引換券</p>
                <p class="card_price">¥351<span>税込</span></p>
              </div>
            </a>
          </li>
        </ul>
        <a class="button" href="/gifts/search?priceto=500&scenes=14" ref="scene_14" @click="clickEvent('scene_14','ピックアップギフト_一覧','さらにギフトを見る');">さらにギフトを見る</a>

        <h2>同僚・部下をねぎらいたいとき</h2>
        <h3>例えば...プロジェクトがひと段落し、<br>部下に「お疲れさま」を伝えたい！</h3>
        <ul class="card_list">
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_184"
              @click="clickEvent('gift_184','ねぎらい_01','ローソン_184_120');
              ecommerceSelectPromotionPush(184, '【お持ち帰り限定】マチカフェ コーヒーS　ホット/アイス（税込120円）', 'ローソン', 120); 
              openDetail(184);
            ">
              <img src="@/assets/images/special-feature-006/card/lawson_003.png" width="164" height="164" alt="ローソン" />
              <div class="card_text_container">
                <p class="card_brand_name">ローソン</p>
                <p class="card_title">【お持ち帰り限定】マチカフェ コーヒーS　ホット/アイス（税込120円）</p>
                <p class="card_price">¥120<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_43"
              @click="clickEvent('gift_43','ねぎらい_02','TULLY\'S COFFEE_43_500');
              ecommerceSelectPromotionPush(43, 'タリーズデジタルギフト（500円）', 'TULLY\'S COFFEE', 500); 
              openDetail(43);
            ">
              <img src="@/assets/images/special-feature-006/card/tullys_001.png" width="164" height="164" alt="TULLY'S COFFEE" />
              <div class="card_text_container">
                <p class="card_brand_name">TULLY'S COFFEE</p>
                <p class="card_title">タリーズデジタルギフト（500円）</p>
                <p class="card_price">¥500</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_24"
              @click="clickEvent('gift_24','ねぎらい_03','ミスタードーナツ_24_200');
              ecommerceSelectPromotionPush(24, 'ギフトチケット（200円）', 'ミスタードーナツ', 200); 
              openDetail(24);
            ">
              <img src="@/assets/images/special-feature-006/card/misterdonut_001.png" width="164" height="164" alt="ミスタードーナツ" />
              <div class="card_text_container">
                <p class="card_brand_name">ミスタードーナツ</p>
                <p class="card_title">ギフトチケット（200円）</p>
                <p class="card_price">¥200</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_82"
              @click="clickEvent('gift_82','ねぎらい_04','一蘭_82_980');
              ecommerceSelectPromotionPush(82, '最高の一杯（ラーメン1杯分）', '一蘭', 980); 
              openDetail(82);
            ">
              <img src="@/assets/images/special-feature-006/card/ichiran_004.png" width="164" height="164" alt="一蘭" />
              <div class="card_text_container">
                <p class="card_brand_name">一蘭</p>
                <p class="card_title">最高の一杯（ラーメン1杯分）</p>
                <p class="card_price">¥980<span>税込</span></p>
              </div>
            </a>
          </li>
        </ul>
        <a class="button" href="/gifts/search?priceto=1000&scenes=17" ref="scenes_17" @click="clickEvent('scenes_17','ピックアップギフト_一覧','さらにギフトを見る');">さらにギフトを見る</a>

        <h2>異動や退職する方への餞別に</h2>
        <h3>例えば...リモートワークで直接会えないけれど、<br>お世話になった方に餞別を贈りたい！</h3>
        <ul class="card_list">
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_170"
              @click="clickEvent('gift_170','異動・退職_01','ShirokaneSweetsTOKYO_170_6284');
              ecommerceSelectPromotionPush(170, '白金スイーツ 焼き菓子アソートセット 20個入り【shirokane sweets】', 'shirokane sweets TOKYO', 6284); 
              openDetail(170);
            ">
              <img src="@/assets/images/special-feature-006/card/shirokanesweets_001.png" width="164" height="164" alt="shirokane sweets TOKYO" />
              <div class="card_text_container">
                <p class="card_brand_name">shirokane sweets TOKYO</p>
                <p class="card_title">白金スイーツ 焼き菓子アソートセット 20個入り【shirokane sweets】</p>
                <p class="card_price">¥6,284<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_188"
              @click="clickEvent('gift_188','異動・退職_02','BRUNO_188_3000');
              ecommerceSelectPromotionPush(188, '【BRUNO online】3,000円 ギフトチケット', 'BRUNO', 3000); 
              openDetail(188);
            ">
              <img src="@/assets/images/special-feature-006/card/bruno_007.png" width="164" height="164" alt="BRUNO" />
              <div class="card_text_container">
                <p class="card_brand_name">BRUNO</p>
                <p class="card_title">【BRUNO online】3,000円 ギフトチケット</p>
                <p class="card_price">¥3,000</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_207"
              @click="clickEvent('gift_207','異動・退職_03','bloomeeギフト_207_3980');
              ecommerceSelectPromotionPush(207, '銀座千疋屋フルーツサンドお花付きギフト', 'bloomeeギフト', 3980); 
              openDetail(207);
            ">
              <img src="@/assets/images/special-feature-006/card/bloomee_004.png" width="164" height="164" alt="bloomeeギフト" />
              <div class="card_text_container">
                <p class="card_brand_name">bloomeeギフト</p>
                <p class="card_title">銀座千疋屋フルーツサンドお花付きギフト</p>
                <p class="card_price">¥3,980<span>税込</span></p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_274"
              @click="clickEvent('gift_274','異動・退職_04','コーシェリジャパン_274_3000');
              ecommerceSelectPromotionPush(274, '【香りが選べる】天然由来の九州産キャンドル', 'KOSelig JAPAN（コーシェリジャパン）', 3000); 
              openDetail(274);
            ">
              <img src="@/assets/images/special-feature-006/card/koselig_001.png" width="164" height="164" alt="KOSelig JAPAN（コーシェリジャパン）" />
              <div class="card_text_container">
                <p class="card_brand_name">KOSelig JAPAN（コーシェリジャパン）</p>
                <p class="card_title">【香りが選べる】天然由来の九州産キャンドル</p>
                <p class="card_price">¥3,000<span>税込</span></p>
              </div>
            </a>
          </li>
        </ul>
        <a class="button" href="/gifts/search?pricefrom=3000&scenes=18" ref="scenes_18" @click="clickEvent('scenes_18','ピックアップギフト_一覧','さらにギフトを見る');">さらにギフトを見る</a>

        <h2>頑張る自分へのご褒美に</h2>
        <h3>例えば...毎日頑張っているので、<br>ちょっと贅沢したい！</h3>
        <ul class="card_list">
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_99"
              @click="clickEvent('gift_99','自分へのご褒美_01','ビアードパパ_99_500');
              ecommerceSelectPromotionPush(99, '500円ギフト券', 'ビアードパパ', 500); 
              openDetail(99);
            ">
              <img src="@/assets/images/special-feature-006/card/beardpapa_002.png" width="164" height="164" alt="ビアードパパ" />
              <div class="card_text_container">
                <p class="card_brand_name">ビアードパパ</p>
                <p class="card_title">500円ギフト券</p>
                <p class="card_price">¥500</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_22"
              @click="clickEvent('gift_22','自分へのご褒美_02','サーティワン アイスクリーム_22_500');
              ecommerceSelectPromotionPush(22, 'サーティワン アイスクリーム 500円ギフト券', 'サーティワン アイスクリーム', 500); 
              openDetail(22);
            ">
              <img src="@/assets/images/special-feature-006/card/31ice.png" width="164" height="164" alt="サーティワン アイスクリーム" />
              <div class="card_text_container">
                <p class="card_brand_name">サーティワン アイスクリーム</p>
                <p class="card_title">サーティワン アイスクリーム 500円ギフト券</p>
                <p class="card_price">¥500</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_87"
              @click="clickEvent('gift_87','自分へのご褒美_03','サーティワン てもみん・グローバル治療院_87_1100');
              ecommerceSelectPromotionPush(87, 'てもみんリラクゼーションチケット（1,100円分）', 'てもみん・グローバル治療院', 1100); 
              openDetail(87);
            ">
              <img src="@/assets/images/special-feature-006/card/temomin_001.png" width="164" height="164" alt="てもみん・グローバル治療院" />
              <div class="card_text_container">
                <p class="card_brand_name">てもみん・グローバル治療院</p>
                <p class="card_title">てもみんリラクゼーションチケット（1,100円分）</p>
                <p class="card_price">¥1,100</p>
              </div>
            </a>
          </li>
          <li class="card small_card">
            <a href="javascript:void(0);"
              ref="gift_200"
              @click="clickEvent('gift_200','自分へのご褒美_04','サーティワン 大戸屋ごはん処_200_1000');
              ecommerceSelectPromotionPush(200, '大戸屋1000円ギフトチケット', '大戸屋ごはん処', 1000); 
              openDetail(200);
            ">
              <img src="@/assets/images/special-feature-006/card/ootoya_002.png" width="164" height="164" alt="大戸屋ごはん処" />
              <div class="card_text_container">
                <p class="card_brand_name">大戸屋ごはん処</p>
                <p class="card_title">大戸屋1000円ギフトチケット</p>
                <p class="card_price">¥1,000</p>
              </div>
            </a>
          </li>
        </ul>
        <a class="button" href="/gifts/search?scenes=15" ref="scenes_15" @click="clickEvent('scenes_15','ピックアップギフト_一覧','さらにギフトを見る');">さらにギフトを見る</a>
      </div>
  
        <div class="app_induction">
          <div class="contents_gap">
            <p class="app_induction_title">便利・お得なご案内</p>
            <img class="plus-message-logo" src="@/assets/images/special-feature-005/plus-message-logo.png" width="143" height="34" alt="プラスメッセージ" />
            <div class="app_description">
              <p>購入履歴へのアクセスは「＋メッセージ」のプラメギフト公式アカウントのフォローが便利です。<br />また同アカウントではギフトに関するお得な情報を配信します。是非ご利用ください。</p>
            </div>
          </div>
          <div class="contents_gap">
            <div class="step_box_container">
              <div class="step_box">
                <p>Step</p>
                <span>1</span>
              </div>
              <p>＋メッセージアプリをダウンロード</p>
            </div>
            <div class="badge_box">
              <a href="https://app.adjust.com/uqzkuf3?redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1358576433%3Fpt%3D119001307%26ct%3Dau_point_202009%26mt%3D8" 
              ref="apple_store"
              @click="clickEvent('apple_store','アプリ訴求','Apple Storeからダウンロード');"  
              >
                <img src="@/assets/images/special-feature-005/apple_store.png" width="143" height="43" alt="Apple Storeからダウンロード" />
              </a>
              <a href="https://app.adjust.com/p5cjgfd?redirect=https%3A%2F%2Fmedia2.kddi.com%2Fmsg%2Fdownload%2F"
              ref="google_play"
              @click="clickEvent('google_play','アプリ訴求','Google Playからダウンロード');"  
               >
                <img src="@/assets/images/special-feature-005/google-play-badge.png" width="142" height="55" alt="Google Playで手に入れよう" />
              </a>
            </div>
          </div>
          <div class="contents_gap">
            <div class="step_box_container">
              <div class="step_box">
                <p>Step</p>
                <span>2</span>
              </div>
              <p>公式アカウントをフォロー</p>
            </div>
            <div class="step2_contents">
              <a href="https://bot.plus-msg.com/?botId=sip:audigitalgift65@maap.plus-msg.com&msg=">
                <img src="@/assets/images/special-feature-005/plamegift_official_account.png" width="295" height="56" alt="プラメギフト公式アカウント" />
              </a>
              <p class="caution">※＋メッセージの最新バージョンをダウンロードしてからタップしてください</p>
            </div>
          </div>
        </div>
        
        <div class="pmg">
        <div id="puramenotice" class="pmg-Notes">
          <span class="pmg-Notes__mark" id="campaign"></span>
          <h2 class="pmg-Title">注意事項</h2>
          <h3 class="pmg-Title -h3">プラメギフトについて</h3>
          <ul class="pmg-Notes__list">
            <li class="pmg-Notes__item">購入はau, UQ mobile, povoのお客さまがご利用いただけます。</li>
            <li class="pmg-Notes__item">受け取りはどなたでもご利用いただけます。</li>
            <li class="pmg-Notes__item">お支払いには、auかんたん決済（通信料合算支払い、au PAY カード支払い、au PAY 残高支払い）をご利用いただけます。povo2.0をご契約のお客さまは、プラメギフトでは通信料合算支払いをご利用いただけません。</li>
            <li class="pmg-Notes__item">お支払い手続き中にブラウザの戻るボタンや更新ボタンの押下、タブの複製を行なうと、手続きが正常に完了しない場合がございますのでご注意ください。</li>
            <li class="pmg-Notes__item">必ず<a href="/gifts/static/rules?ppsm=true">ご利用規約</a>をお読みの上、ご利用ください。</li>
          </ul>
          <h3 class="pmg-Title -h3">「＋メッセージ（プラスメッセージ）」について</h3>
          <p class="pmg-Notes__paragraph"><a href="https://www.au.com/mobile/service/plus-message/">「＋メッセージ」アプリの対象機種</a>はこちらよりご確認ください。2018年11月29日以降にauで発売を開始したケータイ（4GLTE）は公式アカウント機能をご利用いただけません。</p>
          <h3 class="pmg-Title -h3">動作環境・Cookie情報の利用について</h3>
          <p class="pmg-Notes__paragraph">こちらのページでご確認ください。<br/><a href="https://www.kddi.com/terms/requirements/">https://www.kddi.com/terms/requirements/</a></p>
        </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  
  import { GtmEcommerce, GtmEcommerceItem } from '@/plugins/GtmEcommerce';
  import { gtmDataLayerPush, gtmSendClick } from '@/plugins/GtmHelper';
  import { getUrl } from '@/plugins/UrlHelper';
  
  export default {
  name: "SpecialFeature006",
  components: {},
  data: () => ({
    gifts: [],
    promotion_id: "B6"
  }),
  mounted() {
    this.ecommerceViewPromotionPush()
  },
  methods: {
    clickEvent(refName, label2, label3) {
      gtmSendClick(this.$refs[refName], "同僚・仕事仲間に贈るギフト特集2024", label2, label3);
    },
    clickEvent3(refName,label1, label2, label3) {
      gtmSendClick(this.$refs[refName], label1, label2, label3);
    },
    // GA(eコマース) event: view_promotion
    ecommerceViewPromotionPush() {
      const items = []
      this.pushItems()
      for(const gift of this.gifts){
        const item = GtmEcommerceItem.getEcommerceItem(gift, false, true)
        items.push(item)
      }
      GtmEcommerce.viewPromotion(this.promotion_id, "同僚・仕事仲間に贈るギフト特集2024", items)
    },
    pushItems(){
      const data = [
        // ちょっとした感謝を伝えたいとき
        {
          "giftId": 54,
          "giftName": "【お持ち帰り限定】ガルボチョコポケットパック",
          "brandName": "ミニストップ",
          "price": 143
        },
        {
          "giftId": 27,
          "giftName": "Coke ON ドリンクチケット（お好きな「ジョージア」製品1本）",
          "brandName": "コカ・コーラ",
          "price": 150 
        },
        {
          "giftId": 186,
          "giftName": "チョコクロチケット",
          "brandName": "サンマルクカフェ",
          "price": 220
        },
        {
          "giftId": 238,
          "giftName": "ハーゲンダッツ　各種　いずれか1点（税込351円）引換券",
          "brandName": "ファミリーマート",
          "price": 351
        },
        // 同僚・部下をねぎらいたいとき
        {
          "giftId": 184,
          "giftName": "【お持ち帰り限定】マチカフェ コーヒーS　ホット/アイス（税込120円）",
          "brandName": "ローソン",
          "price": 120
        },
        {
          "giftId": 43,
          "giftName": "タリーズデジタルギフト（500円）",
          "brandName": "TULLY'S COFFEE",
          "price": 500
        },
        {
          "giftId": 24,
          "giftName": "ギフトチケット（200円）",
          "brandName": "ミスタードーナツ",
          "price":  200
        },
        {
          "giftId": 82,
          "giftName": "最高の一杯（ラーメン1杯分）",
          "brandName": "一蘭",
          "price":  980
        },
        // 異動や退職する方への餞別に
        {
          "giftId": 170,
          "giftName": "白金スイーツ 焼き菓子アソートセット 20個入り【shirokane sweets】",
          "brandName": "shirokane sweets TOKYO",
          "price":  6284
        },
        {
          "giftId": 188,
          "giftName": "【BRUNO online】3,000円 ギフトチケット",
          "brandName": "BRUNO",
          "price":  3000
        },
        {
          "giftId": 207,
          "giftName": "銀座千疋屋フルーツサンドお花付きギフト",
          "brandName": "bloomeeギフト",
          "price":  3980
        },
        {
          "giftId": 274,
          "giftName": "【香りが選べる】天然由来の九州産キャンドル",
          "brandName": "KOSelig JAPAN（コーシェリジャパン）",
          "price":  3000
        },
        // 頑張る自分へのご褒美に
        {
          "giftId": 99,
          "giftName": "500円ギフト券",
          "brandName": "ビアードパパ",
          "price":  500
        },
        {
          "giftId": 22,
          "giftName": "サーティワン アイスクリーム 500円ギフト券",
          "brandName": "サーティワン アイスクリーム",
          "price":  500
        },
        {
          "giftId": 87,
          "giftName": "てもみんリラクゼーションチケット（1,100円分）",
          "brandName": "てもみん・グローバル治療院",
          "price":  1100
        },
        {
          "giftId": 200,
          "giftName": "大戸屋1000円ギフトチケット",
          "brandName": "大戸屋ごはん処",
          "price":  1000
        },
      ]
      this.gifts = data
      },
      // GA(eコマース) event: select_promotion
      ecommerceSelectPromotionPush(giftId, giftName, brandName, price) {
        const items = []
        const data = {
          "giftId": giftId,
          "giftName": giftName,
          "brandName": brandName,
          "price": price
        }
        const item = GtmEcommerceItem.getEcommerceItem(data, false, true)
        items.push(item)
        GtmEcommerce.selectPromotion(this.promotion_id, "同僚・仕事仲間に贈るギフト特集2024", items)
      },
      // ギフト詳細に遷移
      openDetail(id) {
        this.$router.push({ name: 'PMG610', params: { giftId: id } });
      },
    },
  };
  </script>
  
  <style src="@/assets/css/special-feature-006.css" scoped></style>
  <style scoped lang="scss">
  
  
  </style>
  