import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { FILTER_SCROLL_PROCESS } from "../constants/searchFilter";

// note reducerに永続化させたい項目を追加する
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    aid: state.aid,
    lid: state.lid,
    cardOriginId: state.cardOriginId,
    giftId: state.giftId,
    memberManageNo: state.memberManageNo,
    transactionId: state.transactionId,
    purchasesInfoId: state.purchasesInfoId,
    cartItems: state.cartItems,
    isAuIdAuthorization: state.isAuIdAuthorization,
    categoryList: state.categoryList,
    filter: state.filter,
    pointCondition: state.pointCondition,
  })
});

export default createStore({
  state: {
    apiKey: null,
    carrier: null,
    loadingCount: 0,
    openModal: {
      edit: false,
      preview: false,
      dialog: false,
      form: false,
    },
    aid: null,
    lid: null,
    cardOriginId: null,
    giftId: null,
    cartItems: [],
    memberManageNo: null,
    transactionId: null,
    purchasesInfoId: null,
    availablePrice: null,
    showFooterMenu: true,
    isAuIdAuthorization: false,
    categoryList: [],
    isGiftDetailBack: true,
    filter: null,
    filterScrollProcess: FILTER_SCROLL_PROCESS.COMPLETE,
    pointCondition: null,
  },
  getters: {
    apiKey: (state) => {
      return state.apiKey;
    },
    isAu: (state) => {
      return state.carrier === "au";
    },
    isLoading: (state) => {
      return state.loadingCount > 0;
    },
    isOpenEditModal: (state) => {
      return state.openModal.edit;
    },
    isOpenPreviewModal: (state) => {
      return state.openModal.preview;
    },
    isOpenDialogModal: (state) => {
      return state.openModal.dialog;
    },
    isOpenFormModal: (state) => {
      return state.openModal.form;
    },
    aid: (state) => {
      return state.aid;
    },
    lid: (state) => {
      return state.lid;
    },
    getCardOriginId: (state) => {
      return state.cardOriginId;
    },
    getGiftId: (state) => {
      return state.giftId;
    },
    getCartItems: (state) => {
      return state.cartItems;
    },
    getMemberManageNo: (state) => {
      return state.memberManageNo;
    },
    getTransactionId: (state) => {
      return state.transactionId;
    },
    getPurchasesInfoId: (state) => {
      return state.purchasesInfoId;
    },
    getAvailablePrice: (state) => {
      return state.availablePrice;
    },
    showFooterMenu: (state) => {
      return state.showFooterMenu;
    },
    isAuIdAuthorization: (state) => {
      return state.isAuIdAuthorization;
    },
    getCategoryList(state) {
      return state.categoryList;
    },
    isGiftDetailBack(state) {
      return state.isGiftDetailBack;
    },
    getFilter(state) {
      return state.filter;
    },
    getFilterScrollProcess(state) {
      return state.filterScrollProcess;
    },
    getPointCondition(state) {
      return state.pointCondition;
    }
  },
  mutations: {
    setApiKey(state, apiKey) {
      state.apiKey = apiKey;
    },
    setCarrier(state, carrier) {
      state.carrier = carrier;
    },
    addLoading(state, addend) {
      const count = state.loadingCount + addend;
      state.loadingCount = count >= 0 ? count : 0;
    },
    clearLoading(state) {
      state.loadingCount = 0;
    },
    setOpenModal(state, payload) {
      state.openModal[payload.key] = payload.value;
    },
    setAid(state, id) {
      state.aid = id;
    },
    setLid(state, id) {
      state.lid = id;
    },
    clearAidLid(state) {
      state.aid = null;
      state.lid = null;
    },
    setCardOriginId(state, val) {
      state.cardOriginId = val;
    },
    setGiftId(state, val) {
      state.giftId = val;
    },
    setCartItems(state, val) {
      state.cartItems = val;
    },
    setMemberManageNo(state, val) {
      state.memberManageNo = val;
    },
    setTransactionId(state, val) {
      state.transactionId = val;
    },
    setPurchasesInfoId(state, val) {
      state.purchasesInfoId = val;
    },
    setAvailablePrice(state, val) {
      state.availablePrice = val;
    },
    clearAuPayProcessInfo(state) {
      state.giftId = null;
      state.memberManageNo = null;
      state.transactionId = null;
      state.purchasesInfoId = null;
    },
    clearPointPayProcessInfo(state) {
      state.giftId = null;
      state.memberManageNo = null;
      state.transactionId = null;
      state.purchasesInfoId = null;
    },
    setShowFooterMenu(state, val) {
      state.showFooterMenu = val;
    },
    setAuIdAuthorization(state, val) {
      state.isAuIdAuthorization = val;
    },
    setCategoryList(state, val) {
      state.categoryList = val;
    },
    setIsGiftDetailBack(state, val) {
      state.isGiftDetailBack = val;
    },
    setFilter(state, val) {
      state.filter = val;
    },
    clearFilter(state) {
      state.filter = null;
    },
    setFilterScrollProcess(state, val) {
      state.filterScrollProcess = val;
    },
    setPointCondition(state, val) {
      state.pointCondition = val;
    }
  },
  actions: {
    startLoading({ commit }) {
      commit("addLoading", 1);
    },
    stopLoading({ commit }) {
      commit("addLoading", -1);
    },
    clearLoading({ commit }) {
      commit("clearLoading");
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
