<template>
  <div class="purchase-info__card">
    <div class="purchase-info__card__header">
      <span class="title">Pontaポイント</span>
      <span class="point">{{ pointDisplay }}</span>
    </div>
    <div class="purchase-info__card__footer">
      <span class="title">お支払い額</span>
      <span class="price">{{ priceDisplay }}</span>
    </div>
  </div>

</template>

<script>
export default {
  name: "PointHistoryDetailBox",
  components: {},
  props: {
    usedPoints: {
      type: Number,
      nullable: true,
      default: 0,
    },
    isOnlyPoints: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
  computed: {
    pointDisplay() {
      if (this.usedPoints == 0 || this.usedPoints === null) {
        return "利用しない"
      } else {
        return `${Number(this.usedPoints).toLocaleString()}P 利用`
      }
    },
    priceDisplay() {
      if (this.price === null) {
        return "ー"
      } else {
        return `¥${Number(this.price).toLocaleString()}`
      }
    },
  },
  methods: {},
};


</script>

<style scoped lang="scss">
.purchase-info {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: #fff;
  margin: 0 1rem 1rem 1rem;
  margin-top: 1rem !important;

  &__header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    &__text {
      font: $font-noto-sans-jp-400;
      @include font-size(12, 19.2);
      color: $gray10;
    }
  }

  &__gift,
  &__card {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    background-color: $white1;
    margin: 0 1rem 1rem 1rem;

    &__header {
      .title {
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
        //color: $gray10;
      }
    }
  }

  &__card,
  &__gift {
    &__header {
      padding: 1rem 1rem 0 1rem;
    }

    &__content {
      padding: 1rem;
    }


    &__header, &__footer {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        color: var(--ion-color-dark);
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
      }

      .price {
        color: $black6;
        font: $font-roboto-700;
        @include font-size(22, 28.6);
      }
    }

    &__footer {
      padding: 13.5px 16px;

      .title, .label {
        font-weight: bold;
        font-size: 14px;
      }

      .point {
        font-size: 16px;
      }

      .price {
        color: $black6;
        font: $font-roboto-700;
        @include font-size(22, 28.6);
      }
    }
  }

  &__url {
    margin: 0 1rem;
  }
}
</style>
