<template>
  <div class="filter-button-list" ref="scrollContainer">
    <div class="search">
      <div class="search__condition">
        <div class="search__condition__area">
          <Button label="すべて"
                  id="price-filter-btn-all"
                  :type="isAllFilter? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow'"
                  size="x-small"
                  @click="clickFilterButton(null)"
          />
          <Button
              v-for="(price, index) in priceList"
              :id="'price-filter-btn-' + price.priceFilterId"
              :key="index"
              :label="price.label"
              :type="buttonClass(price.priceFilterId)"
              size="x-small"
              @click="clickFilterButton(price.priceFilterId)"
          />
          <Button
              id="price-filter-btn-other"
              v-show="showOtherButton"
              label="その他" type="fillgold-nonline-nonshadow" size="x-small"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from '@/components/button/Button';

import {mapGetters} from 'vuex';

export default {
  name: 'SearchFilterPriceButtonList',
  components: {
    Button,
  },
  emits: ['click:filter'],
  data() {
    return {
      selectedPriceFilterId: null,
    };
  },
  props: {
    priceList: {
      type: Object,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    isAllFilter() {
      return this.filters['price-from'] === null && this.filters['price-to'] === null;
    },
    showOtherButton() {
      if (this.filters['price-from'] === null && this.filters['price-to'] === null) return false;

      const isExist = this.isSelectedPriceFilterButton();
      return !isExist;
    }

  },
  mounted() {},
  methods: {
    /*
    絞り込み選択中の予算がフィルターボタンで表現できるか確認。一致しない場合はその他ボタンを表示する。
     */
    isSelectedPriceFilterButton() {
      let isExist = false;
      this.priceList.forEach((price) => {
        if (price.lowerBudget === this.filters['price-from'] && price.upperBudget === this.filters['price-to']) {
          isExist = true;
        }
      })
      return isExist;
    },
    findPriceFilter(priceFilterId) {
      return this.priceList.find((price) => price.priceFilterId === priceFilterId);
    },
    isEqual(priceFilterId) {
      const priceFilter = this.findPriceFilter(priceFilterId);
      // 価格の下限と上限が一致しているか確認
      return priceFilter.lowerBudget === this.filters['price-from'] && priceFilter.upperBudget === this.filters['price-to'];
    },
    buttonClass(priceFilterId) {
      return this.isEqual(priceFilterId) ? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow';
    },
    clickFilterButton(id) {
      this.$emit('click:filter', id);
    },
    /*
    検索フィルターのボタンを横スクロールする。
    @param {string} classificationName: category or scene or price
     */
    scrollToActiveButton(classificationName, categoryId) {
      if(!categoryId) {
        categoryId = 'all';
      }
      let activeButton = document.getElementById(`${classificationName}-filter-btn-${categoryId}`);
      if (!activeButton) {
        activeButton = document.getElementById(`${classificationName}-filter-btn-other`);
      }

      if (activeButton) {
        setTimeout(() => {
          activeButton.scrollIntoView({
            behavior: 'auto',
            inline: 'center',
          });
        }, 1000);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.filter-button-list {
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  margin-top: 14px;

  .search {
    background-color: var(--ion-color-light);

    .search__condition__area {
      white-space: nowrap;
      overflow-x: auto;
      padding: 0px 16px;
      gap: 12px;
    }

    .button:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  .divider-line {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
  }

}
</style>
