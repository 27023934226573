<template>
  <IonicModal
    ref="model"
    title="利用規約"
    :showFooter="false"
    :contentClasses="['ion-no-padding']"
    @on-close-dom="onCloseDom"
  >
    <template #body>
      <Rules :modal-mode="true" />
    </template>
  </IonicModal>
</template>

<script>
import IonicModal from "@/components/ionic/IonicModal";
import Rules from "@/components/static/Rules";

export default {
  name: "TermsUseModal",
  components: {
    IonicModal,
    Rules,
  },
  methods: {
    openModal() {
      this.$refs.model.openModal();
    },
    hideModal() {
      this.$refs.model.hideModal();
    },
  },
};
</script>
<style scoped lang="scss">
ion-modal {
  --height: 95%;
  align-items: flex-end;
}
</style>
