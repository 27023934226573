<template>
  <div class="filter-button-list" ref="scrollContainer">
    <div class="search">
      <div class="search__condition">
        <div class="search__condition__area" id="sean_search_confition_area">
          <Button
              id="scene-filter-btn-all"
              label="すべて" :type="isAllFilter? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow'" size="x-small"
                  @click="clickFilterButton(null)"
          />
          <Button
              v-for="scene in sceneList"
              :id="'scene-filter-btn-' + scene.categoryId"
              :key="scene.categoryId"
              :label="scene.categoryName"
              :type="buttonClass(scene.categoryId)"
              size="x-small"
              @click="clickFilterButton(scene.categoryId)"
          />
          <Button
              id="scene-filter-btn-other"
              v-show="showOtherButton"
              label="その他" type="fillgold-nonline-nonshadow"
              size="x-small"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from '@/components/button/Button';
import {mapGetters} from 'vuex';

export default {
  name: 'SearchFilterButtonList',
  components: {
    Button,
  },
  emits: ['click:filter'],
  data() {
    return {};
  },
  props: {
    sceneList: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    isAllFilter() {
      return this.filters['scene-ids'] === null;
    },
    showOtherButton() {
      if (this.filters['scene-ids'] === null) return false;
      const isExist = this.sceneList.find((scene) => this.isEqual(scene.categoryId))
      return !isExist;
    }
  },
  mounted() {},
  methods: {
    isEqual(sceneId) {
      return Number(this.filters['scene-ids']) === Number(sceneId)
    },
    buttonClass(sceneId) {
      return this.isEqual(sceneId) ? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow';
    },
    clickFilterButton(sceneId) {
      this.$emit('click:filter', sceneId);
    },
    /*
    検索フィルターのボタンを横スクロールする。
    @param {string} classificationName: category or scene or price
     */
    scrollToActiveButton(classificationName, categoryId) {
      if(!categoryId) {
        categoryId = 'all';
      }

      let activeButton = document.getElementById(`${classificationName}-filter-btn-${categoryId}`);
      if(!activeButton) {
        activeButton = document.getElementById(`${classificationName}-filter-btn-other`);
      }

      if(!activeButton) {
        activeButton = document.getElementById(`${classificationName}-filter-btn-all`);
      }

      if (activeButton) {
        setTimeout(() => {
          activeButton.scrollIntoView({
            behavior: 'auto',
            inline: 'center',
          });
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-button-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  margin-top: 14px;

  .search {
    background-color: var(--ion-color-light);

    .search__condition__area {
      white-space: nowrap;
      overflow-x: auto;
      padding: 0px 16px;
      gap: 12px;
    }

    .button:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  .divider-line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

}
</style>
