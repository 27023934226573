<template>
  <IonicModal
      ref="modal"
      title="絞り込み条件"
      :showFooter="true"
      :contentClasses="['ion-no-padding']"
      :closeFunc="close"
      @on-close-dom="closeGA"
  >
    <template #body>
      <ion-list class="budgets" lines="none">

        <ion-list-header class="header">
          <ion-label class="title margin-bottom">予算</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-select
              fill="solid"
              shape="round"
              aria-label="lower budget"
              interface="action-sheet"
              placeholder="下限なし"
              cancelText="キャンセル"
              :interface-options="{ header: '予算の下限', cssClass: 'friday-options' }"
              @ionChange="changeLowerBudgets($event.detail.value)"
              v-model="selectedLowerBudget"
          >
            <ion-select-option v-for="(budget, i) in lowerBudgets" :value="budget.value" :key="i">
              {{ budget.name }}
            </ion-select-option>
          </ion-select>

          <span class="tilde">〜</span>

          <ion-select
              aria-label="upper budget"
              interface="action-sheet"
              :interface-options="{ header: '予算の上限' }"
              placeholder="上限なし"
              cancelText="キャンセル"
              @ionChange="changeUpperBudgets($event.detail.value)"
              v-model="selectedUpperBudget"
          >
            <ion-select-option v-for="(budget, i) in upperBudgets" :value="budget.value" :key="i">
              {{ budget.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <ion-list
          v-for="(classification, i) in classifications"
          class="ion-padding-top"
          :class="classification.class"
          :key="i"
          lines="none"
      >
        <ion-list-header class="header">
          <ion-label class="title">{{ classification.name }}</ion-label>
        </ion-list-header>
        <ion-item v-for="(item, j) in classification.list" :key="j">
          <ion-checkbox
              justify="start"
              labelPlacement="end"
              :value="item.categoryId"
              :checked="item.checked"
              :disabled="item.categoryId == 0 ? item.checked : false"
              v-model="item.checked"
              @ionChange="checkCategoryClassification(i, $event.detail)"
          >{{ item.categoryName }}
          </ion-checkbox>
        </ion-item>
      </ion-list>
    </template>

    <template #footer>
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-justify-content-between ion-nowrap">
          <Button label="リセット" type="none" @click="clickReset()" @on-click-dom="clickResetGA"/>
          <Button label="この条件で絞り込み" type="fill" @click="clickFilter();" @on-click-dom="clickFilterGA"/>
        </ion-row>
      </ion-grid>
    </template>
  </IonicModal>
</template>

<script>
import {
  IonModal,
  IonButtons,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

import IonicModal from '@/components/ionic/IonicModal';
import Button from '@/components/button/Button';
import {listGiftCategoriesApi, listGiftPricesApi} from '@/plugins/ApiHelper';
import {emitter} from '@/eventBus';
import {gtmSendClick} from "@/plugins/GtmHelper";
import {PRICE_FILTER_BUTTONS} from "@/constants/searchFilter";
import {CATEGORY_CLASSIFICATION} from "@/constants/base";

export default {
  name: 'GiftFilterModal',
  components: {
    IonModal,
    IonButtons,
    IonButton,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonicModal,
    Button,
  },
  emits: ['click:filter'],
  data: () => ({
    lowerBudgets: [{value: null, name: '下限なし'}],
    upperBudgets: [{value: null, name: '上限なし'}],
    classifications: [
      {id: 2, name: 'シーン', class: 'scenes', list: [], ids: []},
      {id: 3, name: 'ギフトの利用方法', class: 'receives', list: [], ids: []},
      {id: 1, name: 'カテゴリー', class: 'categories', list: [], ids: []},
    ],
    selectedLowerBudget: null,
    selectedUpperBudget: null,
    filters: {
      lowerBudget: null,
      upperBudget: null,
      categoryIds: null,
      sceneIds: null,
      receiveIds: null,
      filterCount: null,
      priceFilterId: null,
    },
    currentfilters: {},
    priceFilters: PRICE_FILTER_BUTTONS,
  }),
  watch: {},

  async mounted() {
    /*
    this.fetchListBudgets();
    await this.fetchListCategories();
    const hasInitialFilter = await this.initialFilter();
    this.clickFilter(hasInitialFilter);
    */
    // const self = this;
    // emitter.on('resetFilterListGifts', () => {
    //   self.clickReset();
    //   self.currentfilters = {};
    // });
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    fetchListBudgets() {
      // 初期化
      this.lowerBudgets = [{value: null, name: '下限なし'}];
      this.upperBudgets = [{value: null, name: '上限なし'}];

      return new Promise((resolve) => {
        listGiftPricesApi()
            .then((res) => {
              res.forEach((item) => {
                item.name = `¥${Number(item.name).toLocaleString()}`;
              });
              this.lowerBudgets.push(...res);
              this.upperBudgets.push(...res);
              resolve();
            })
            .catch(() => {
              this.$router.push({name: 'PMG510'});
            });
      });
    },
    async fetchListCategories() {
      const promises = this.classifications.map((classification) =>
          listGiftCategoriesApi({classification: classification.id})
      );
      await Promise.all(promises)
          .then((res) => {
            this.classifications.forEach((classification, i) => {
              const list = res[i];
              // チェックリストにチェック状態を保持するプロパティ追加
              list.forEach((e) => ({...e, checked: false}));
              // チェックリストの先頭にすべてを追加
              list.unshift({
                categoryId: 0,
                categoryName: 'すべて',
                classification: classification.id,
                priority: 0,
                checked: true,
              });
              this.classifications[i].list = list;
            });
          })
          .catch(() => {
            this.$router.push({name: 'PMG510'});
          });
    },
    changeLowerBudgets(value) {
      this.filters.lowerBudget = value;
    },
    changeUpperBudgets(value) {
      this.filters.upperBudget = value;
    },
    checkCategoryClassification(index, detail) {

      if (detail.checked) {
        if (detail.value === 0) {
          // 「すべて」のチェックをONにした場合、すべて以外のチェックをOFFにする
          this.classifications[index].list.map((e) => {
            if (e.categoryId !== 0) e.checked = false;
          });
        } else {
          // 「すべて」以外のカテゴリーをチェックをONにした場合、選択したカテゴリー以外のチェックをOFFにする
          this.classifications[index].list.map((e) => {
            if (e.categoryId != detail.value) e.checked = false;
          });
        }
      }

      // チェックされた要素を取得
      const checkedIds = [];
      this.classifications.forEach((classification) => {

        const items = classification.list.filter((item) => item.checked);
        checkedIds.push(...items.map((item) => item.categoryId));
        classification.ids = [];
        classification.ids.push(...items.map((item) => item.categoryId));
        // 何もチェックされていない場合はすべてをチェックONにする
        if (items.length === 0) {
          classification.list.map((item) => {
            if (item.categoryId == 0) item.checked = true;
          });
        }
      });
    },
    searchFilter(hasInitialFilter = false) {
      const category = this.classifications.find((item) => item.class === 'categories');
      const scene = this.classifications.find((item) => item.class === 'scenes');
      const receive = this.classifications.find((item) => item.class === 'receives');

      let filterCount = 0;
      if (this.filters.lowerBudget || this.filters.upperBudget) {
        filterCount++;
      }
      // すべて以外の件数をカウント
      filterCount += category.ids.filter((id) => id !== 0).length;
      filterCount += scene.ids.filter((id) => id !== 0).length;
      filterCount += receive.ids.filter((id) => id !== 0).length;

      // すべて以外のカテゴリIDをフィルタ条件とする
      const categoryIds = category.ids.filter((id) => id !== 0);
      const sceneIds = scene.ids.filter((id) => id !== 0);
      const receiveIds = receive.ids.filter((id) => id !== 0);

      // フィルタ条件を保持
      this.currentfilters = Object.assign(
          {},
          {
            lowerBudget: this.filters.lowerBudget,
            upperBudget: this.filters.upperBudget,
            categories: categoryIds,
            scenes: sceneIds,
            receives: receiveIds,
          }
      );

      const priceFilter = this.findPriceFilterByBudget(this.filters.lowerBudget, this.filters.upperBudget);
      const priceFilterId = priceFilter ? priceFilter.priceFilterId : null

      const scrollOrder = {
        scene: sceneIds.length > 0 ? sceneIds[0] : null,
        category: categoryIds.length > 0 ? categoryIds[0] : null,
        price: priceFilter ? priceFilterId : null
      }

      this.$emit('click:filter', {
        'price-from': this.filters.lowerBudget,
        'price-to': this.filters.upperBudget,
        'price-filter-id': priceFilterId,
        'category-ids': categoryIds.length > 0 ? categoryIds.join(',') : null,
        'scene-ids': sceneIds.length > 0 ? sceneIds.join(',') : null,
        'receive-ids': receiveIds.length > 0 ? receiveIds.join(',') : null,
        'filter-count': filterCount > 0 ? filterCount : null,
        'initial-filter': hasInitialFilter,
      }, scrollOrder);
    },
    isEnableCountBudget(lowerBudget, upperBudget) {
      return !this.selectedLowerBudget && this.filters.lowerBudget && !this.selectedUpperBudget && this.filters.upperBudget
    },
    clickFilter(hasInitialFilter = false) {
      const category = this.classifications.find((item) => item.class === 'categories');
      const scene = this.classifications.find((item) => item.class === 'scenes');
      const receive = this.classifications.find((item) => item.class === 'receives');

      let filterCount = 0;
      if (this.filters.lowerBudget || this.filters.upperBudget) {
        // 〜円台のフィルターを選択した場合、filterCount はカウントしない
        if (!this.isEnableCountBudget()) {
          filterCount++;
        }
      }
      // すべて以外の件数をカウント
      filterCount += category.ids.filter((id) => id !== 0).length;
      filterCount += scene.ids.filter((id) => id !== 0).length;
      filterCount += receive.ids.filter((id) => id !== 0).length;

      // すべて以外のカテゴリIDをフィルタ条件とする
      const categoryIds = category.ids.filter((id) => id !== 0);
      const sceneIds = scene.ids.filter((id) => id !== 0);
      const receiveIds = receive.ids.filter((id) => id !== 0);

      // 予算は選択した項目の内容を正にする
      this.filters.lowerBudget = this.selectedLowerBudget
      this.filters.upperBudget = this.selectedUpperBudget

      const priceFilter = this.findPriceFilterByBudget(this.filters.lowerBudget, this.filters.upperBudget);
      const priceFilterId = priceFilter ? priceFilter.priceFilterId : null;

      // フィルタ条件を保持
      this.currentfilters = Object.assign(
          {},
          {
            lowerBudget: this.filters.lowerBudget,
            upperBudget: this.filters.upperBudget,
            priceFilterId: priceFilterId,
            categories: categoryIds,
            scenes: sceneIds,
            receives: receiveIds,
          }
      );

      const scrollOrder = {
        scene: sceneIds.length > 0 ? sceneIds[0] : null,
        category: categoryIds.length > 0 ? categoryIds[0] : null,
        price: priceFilter ? priceFilter.priceFilterId : null,
      }


      this.$emit('click:filter', {
        'price-from': this.filters.lowerBudget,
        'price-to': this.filters.upperBudget,
        'price-filter-id': priceFilterId,
        'category-ids': categoryIds.length > 0 ? categoryIds.join(',') : null,
        'scene-ids': sceneIds.length > 0 ? sceneIds.join(',') : null,
        'receive-ids': receiveIds.length > 0 ? receiveIds.join(',') : null,
        'filter-count': filterCount > 0 ? filterCount : null,
        'initial-filter': hasInitialFilter,
      }, scrollOrder);
      this.hideModal();
    },
    clickFilterGA(dom) {

      let filterString = "";

      filterString += "下限金額:" + (this.filters.lowerBudget ?? "下限なし") + ", ";
      filterString += "上限金額:" + (this.filters.upperBudget ?? "上限なし") + ", ";

      this.classifications.forEach((classification) => {

        filterString += classification.name + ":["
        const items = classification.list.filter((item) => item.checked && item.classification == classification.id);
        filterString += items.map((e) => e.categoryName).join(',');
        filterString += "], ";
      });

      gtmSendClick(dom, 'ギフト一覧', '絞り込み条件', filterString);

    },
    clickReset() {
      this.selectedLowerBudget = null;
      this.selectedUpperBudget = null;
      this.classifications.forEach((classification) => {
        classification.list.map((item) => {
          if (item.categoryId == 0) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
        classification.ids = [];
      });
      this.filters = {
        lowerBudget: null,
        upperBudget: null,
        categoryIds: null,
        sceneIds: null,
        receiveIds: null,
        filterCount: null,
      };
    },
    clickResetGA(dom) {
      gtmSendClick(dom, 'ギフト一覧', '絞り込み条件', 'リセット');
    },
    /**
     * 現在の予算フィルターが〜円台のフィルターかどうかを判定する。
     * 〜円台のフィルターを選択した場合、上限・下限のフィルターは選択しない。
     */
    isEnableSelected(lowerBudget, upperBudget) {
      const priceFilter = this.findPriceFilterByBudget(lowerBudget, upperBudget);
      return priceFilter && priceFilter.enableSelected
    },
    close() {
      // 閉じる時にフィルタ条件を現在の絞込り混んだ状態の条件に戻す。モーダルを開いたときは、常に現在の絞り込んだ条件と同じ状態にする。
      this.filters.lowerBudget = this.currentfilters?.lowerBudget;
      this.filters.upperBudget = this.currentfilters?.upperBudget;

      // 予算フィルターを選択してもいいか判断
      if(this.isEnableSelected(this.currentfilters.lowerBudget, this.currentfilters.upperBudget)) {
        this.selectedLowerBudget = this.currentfilters?.lowerBudget;
        this.selectedUpperBudget = this.currentfilters?.upperBudget;
      } else if(this.currentfilters.priceFilterId === 'other') {
        this.selectedLowerBudget = this.currentfilters?.lowerBudget;
        this.selectedUpperBudget = this.currentfilters?.upperBudget;
      } else {
        this.selectedLowerBudget = null;
        this.selectedUpperBudget = null;
      }

      this.classifications.forEach((classification) => {
        classification.list.map((item) => {
          if (item.categoryId == 0) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
        classification.ids = [];
      });

      const classifications = ['scenes', 'receives', 'categories'];
      classifications.forEach((classification) => {
        if (this.currentfilters[classification] && this.currentfilters[classification].length > 0) {
          const category = this.classifications.find((item) => item.class === classification);
          category.ids = this.currentfilters[classification];
          category.list.map((item) => {
            if (this.currentfilters[classification].includes(item.categoryId)) {
              item.checked = true;
            }
            if (item.categoryId == 0) {
              item.checked = false;
            }
          });
        }
      });
      this.hideModal();
    },

    closeGA(dom) {
      gtmSendClick(dom, 'ギフト一覧', '絞り込み条件', '閉じる');
    },

    initialFilter() {
      // クエリパラメータにフィルタ条件が指定された場合、各種条件の初期値を設定する
      let hasFilter = false;
      this.classifications.forEach(async (classification) => {
        const query = this.$route.query?.[classification.class];
        if (query) {
          const ids = [];
          const queryIds = query.split(',');
          // 対象カテゴリをチェックONにする
          classification.list.map((item) => {
            if (queryIds.find((e) => e == item.categoryId)) {
              ids.push(item.categoryId);
              item.checked = true;
            }
          });
          // 条件指定された場合はすべてのチェックをOFFにする
          if (ids.length > 0) {
            classification.list.map((e) => {
              if (e.categoryId === 0) e.checked = false;
            });
            hasFilter = true;
          }
          // フィルタ条件設定
          classification.ids = ids.filter((id) => id !== 0 && id !== null);
        }
      });

      //金額下限・上限の初期値設定
      const priceFilterId = this.$route.query?.prices;
      if (priceFilterId) {
        const priceFilter = this.findPriceFilter(priceFilterId);
        if (priceFilter) {
          this.selectedLowerBudget = this.filters.lowerBudget = priceFilter.lowerBudget;
          this.selectedUpperBudget = this.filters.upperBudget = priceFilter.upperBudget;
          hasFilter = true;
        }
      } else {
        const priceFromQuery = this.$route.query?.pricefrom;
        if (priceFromQuery) {
          let index = this.lowerBudgets.map((e) => Number(e.value).toString()).findIndex((e) => e == priceFromQuery);
          if (index >= 0) {
            this.selectedLowerBudget = this.filters.lowerBudget = this.lowerBudgets[index].value;
            hasFilter = true;
          }
        }
        const priceToQuery = this.$route.query?.priceto;
        if (priceToQuery) {
          let index = this.upperBudgets.map((e) => Number(e.value).toString()).findIndex((e) => e == priceToQuery);
          if (index >= 0) {
            this.selectedUpperBudget = this.filters.upperBudget = this.upperBudgets[index].value;
            hasFilter = true;
          }
        }
      }
      return hasFilter;
    },
    findPriceFilter(priceFilterId) {
      return this.priceFilters.find((price) => price.priceFilterId === Number(priceFilterId));
    },
    findPriceFilterByBudget(lowerBudget, upperBudget) {
      if (lowerBudget === null && upperBudget === null) {
        return null
      }

      const priceFilter = this.priceFilters.find((price) => price.lowerBudget === this.filters.lowerBudget && price.upperBudget === this.filters.upperBudget)

      return priceFilter ? priceFilter : {priceFilterId: "other"}
    },
    /*
    現在のフィルターを更新する。
    フィルターカウントを取得して返す。
     */
    setCurrentFilters(filters) {
      this.currentfilters = Object.assign(this.currentfilters, filters);

      // フィルターカウントを取得して返す
      let filterCount = 0;
      if (this.currentfilters.lowerBudget || this.currentfilters.upperBudget) {
        filterCount++;
      }
      // すべて以外の件数をカウント
      filterCount += this.currentfilters.categories.filter((id) => id !== 0).length;
      filterCount += this.currentfilters.scenes.filter((id) => id !== 0).length;
      filterCount += this.currentfilters.receives.filter((id) => id !== 0).length;

      this.currentfilters = Object.assign(this.currentfilters, {
        'filterCount': filterCount > 0 ? filterCount : null,
      });

      return filterCount;
    },
    changeFilterCheckBox(index, id, classification) {
      let isCheckedScene = false
      let isCheckedCategory = false
      this.classifications[index].list.map((item) => {
        if (item.categoryId == id) {
          item.checked = true;
          this.classifications[index].ids = [id]

          if (item.classification === CATEGORY_CLASSIFICATION.SCENE) {
            this.filters.sceneIds = id
            isCheckedScene = true
          } else if (item.classification === CATEGORY_CLASSIFICATION.CATEGORY) {
            this.filters.categoryIds = id
            isCheckedCategory = true
          }
        } else {
          item.checked = false;
        }
      });

      if (!isCheckedScene && classification === CATEGORY_CLASSIFICATION.SCENE) {
        this.filters.sceneIds = null
      }

      if (!isCheckedCategory && classification === CATEGORY_CLASSIFICATION.CATEGORY) {
        this.filters.categoryIds = null
      }
    },
    setSelectedPriceFilter(lowerBudget, upperBudget) {
      // 初期化
      this.selectedLowerBudget = null;
      this.selectedUpperBudget = null;

      this.filters['lowerBudget'] = lowerBudget;
      this.filters['upperBudget'] = upperBudget;

      //金額下限・上限の初期値設定
      if (lowerBudget && upperBudget) {
        const indexUpper = this.lowerBudgets.findIndex(e => Number(e.value) === lowerBudget);
        const indexLower = this.upperBudgets.findIndex(e => Number(e.value) === upperBudget);
        if (indexUpper >= 0 && indexLower >= 0) {
          this.selectedLowerBudget = this.lowerBudgets[indexUpper].value;
          this.selectedUpperBudget = this.upperBudgets[indexLower].value;
        }
      } else if (lowerBudget && !upperBudget) {
        const index = this.lowerBudgets.findIndex(e => Number(e.value) === lowerBudget);
        if (index >= 0) {
          this.selectedLowerBudget = this.lowerBudgets[index].value;
        }
      } else if (upperBudget && !lowerBudget) {
        const index = this.upperBudgets.findIndex(e => Number(e.value) === upperBudget);
        if (index >= 0) {
          this.selectedUpperBudget = this.upperBudgets[index].value;
        }
      }
    },

  },
};
</script>

<style scoped lang="scss">
.budgets,
.scenes,
.receives,
.categories {
  .header {
    .title {
      margin: 0;
      font: $font-roboto-400;

      @include font-size(14, 24);

      &.margin-bottom {
        margin-bottom: 8px;
      }
    }
  }

  .tilde {
    width: 20%;
    text-align: center;
    color: $gray10;
    font: $font-roboto-400;

    @include font-size(14, 24);
  }
}

ion-modal {
  --height: 95%;
  align-items: flex-end;
}

ion-select {
  --padding-start: 16px;
  --placeholder-opacity: 1;
  border-radius: 4px;
  background: $gray5;
  width: 40%;
  text-align: center;
  font: $font-roboto-400;

  @include font-size(14, 24);
}

ion-list-header {
  --color: #676767;
}

ion-item {
  --min-height: 40px;
}

.checkbox-disabled {
  opacity: 1 !important;
}
</style>
