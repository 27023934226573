<template>
  <div class="receiving-url">
    <div class="receiving-url__header">
      <span class="title">受け取り用URL</span>
      <div class="url-copy">
        <span class="url">{{ cardUrl }}</span>
        <div class="icon-copy" ref="copyIcon" @click="copyUrl('copyIcon')">
          <img src="@/assets/images/icon/copy-outline.svg"/>
        </div>
      </div>
    </div>

    <!--自分用ギフト-->
    <div v-if="isForMyself">
      <div class="receiving-url__content">
        <Button label="自分用として利用する" ref="copyBtn" @click="redirectCardDisplay"></Button>
      </div>
    </div>

    <!--通常ギフト-->
    <div v-else>
      <div class="receiving-url__content">
        <Button label="ギフト受け取り用URLをコピー" ref="copyBtn" @click="copyUrl('copyBtn')"></Button>
        <Button
          label="＋メッセージでURLを贈る"
          @click="sendPlusMessage()"
          ref="sendPlusMessage"
        ></Button>
        <div class="usage-rules" @click="openHowToGiveReceiveModal()">
        <span>
          <img src="@/assets/images/icon/help-circle-outline.svg"/>
        </span>
          <span class="usage-rules__label">贈り方・受け取り方を知りたい</span>
        </div>
      </div>

      <div class="receiving-url__precautions">
        <span class="title">ご注意</span>
        <span class="note"
        >受け取り用URLは誰でもアクセスして利用することができます。贈り先やご自分以外の方に共有しないようご注意ください。</span
        >
      </div>

      <div class="receiving-url__or">
      <span class="bar">
        <span class="or">OR</span>
      </span>
      </div>
      <div class="receiving-url__use">
        <Button
          label="ギフトを自分で利用する"
          type="nonfill-outline"
          ref="openGiftUrl"
          @click="openGiftUrl()"
        ></Button>
      </div>
    </div>

    <IonicAlert
      ref="copySuccessAlert"
      header="URLをコピーしました"
      message="メールやメッセージアプリにURLをペースト（貼り付け）して、相手に送ってください。"
      :buttons="createFailedAlertButtons"
      @click:didDismissDom="dissmissGA"
    ></IonicAlert>

    <HowToGiveReceiveModal ref="howToGiveReceiveModal"></HowToGiveReceiveModal>
  </div>
</template>

<script>
import Button from "@/components/button/Button";
import IonicAlert from "@/components/ionic/IonicAlert";
import HowToGiveReceiveModal from "@/components/modal/HowToGiveReceiveModal";
import {gtmSendClick} from "@/plugins/GtmHelper";

export default {
  name: "ComponentName",
  components: {
    Button,
    IonicAlert,
    HowToGiveReceiveModal,
  },
  props: {
    cardUrl: {
      type: String,
      default: null,
    },
    giftUrl: {
      type: String,
      default: null,
    },
    isForMyself: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    createFailedAlertButtons: [{text: "OK", role: "ok"}],
  }),
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    copyUrl(refName) {
      if (refName == "copyBtn") {
        gtmSendClick(this.$refs['copyBtn'].$el, '決済完了', 'ギフト受取用URLコピー', '');
      } else {
        gtmSendClick(this.$refs['copyIcon'], '決済完了', 'ギフト受取用URLコピー', '');
      }

      navigator.clipboard.writeText(this.cardUrl).then(
        () => {
          this.$refs.copySuccessAlert.openAlert();
        },
        () => {
          console.error("fail");
        }
      );
    },
    sendPlusMessage() {
      gtmSendClick(this.$refs['sendPlusMessage'].$el, '決済完了', '+メッセージでURLを送る', '');
      let url = process.env.VUE_APP_PLAME_DEEP_LINK + encodeURIComponent(this.cardUrl);
      console.debug(url);
      window.open(url);
    },
    openHowToGiveReceiveModal() {
      this.$refs.howToGiveReceiveModal.openModal();
    },
    openGiftUrl() {
      gtmSendClick(this.$refs['openGiftUrl'].$el, '決済完了', 'ギフトを自分で利用する', '');
      window.open(this.cardUrl);
    },
    dissmissGA(e, dom) {
      gtmSendClick(dom, 'ギフト受取用URLコピー', 'URLコピー成功のアラートOK', '');
    },
    redirectCardDisplay() {
      window.open(this.cardUrl)
    }
  },
};
</script>
<style scoped lang="scss">
.receiving-url {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: $white1;
  margin-bottom: 1rem;

  .button {
    width: 311px;
  }

  &__header {
    .title {
      font: $font-noto-sans-jp-400;
      @include font-size(14, 22.4);
      color: $gray10;
    }
  }

  &__header {
    text-align: center;
    padding: 24px 16px 16px;

    .title {
      font: $font-roboto-700;
      color: $black1;
      @include font-size(16, 22.4);
    }

    .url-copy {
      display: flex;
      align-items: center;
      background-color: $gray12;
      padding: 0.5rem;
      border-radius: 0.3rem;
      margin-top: 12px;

      .url {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        font: $font-roboto-400;
        @include font-size(16, 20);
      }

      .icon-copy {
        margin-left: 0.5rem;

        img {
          width: 22px;
          height: 24px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    &:last-child {
      padding-bottom: 24px;
    }

    .usage-rules {
      display: flex;
      align-items: center;

      &__label {
        font: $font-roboto-700;
        @include font-size(14, 18.2);
        color: $gold2;
      }
    }
  }

  &__precautions {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 1rem;

    .title,
    .note {
      font: $font-noto-sans-jp-400;
      @include font-size(12, 19.2);
    }

    .title {
      color: $red2;
    }

    .note {
      opacity: 0.87;
    }
  }

  &__or {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: 1rem 0;

    .bar {
      width: 100%;
      border: solid 1px $gray11;
      position: relative;

      .or {
        padding: 0 0.5rem;
        background-color: $white1;
        position: absolute;
        top: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        font: $font-noto-sans-jp-400;
        @include font-size(12, 19.2);
        color: $gray10;
      }
    }
  }

  &__use {
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
