<template>
  <Layout ref="layout" title="購入履歴" class="pmg800" :show-au-login="true">
    <div
      v-if="isAuIdAuthorization && histories?.length > 0"
      class="pmg800__list"
    >
      <div class="history" v-for="(history, i) in histories" :key="i">
        <GiftHistory
          :key="history.historyId"
          :id="history.historyId"
          :history="history"
          @click:giftHistory="openGiftHistory"
        />
      </div>
    </div>
    <div v-if="isAuIdAuthorization && histories?.length <= 0" class="pmg800__empty">
      <div class="messge">
        購入したギフトはありません
      </div>
    </div>
    <div v-if="!isAuIdAuthorization" class="pmg800__notloggedin">
      <div class="message">
        <div class="bold">現在ログインしていません</div>
        <div class="main">ログインすると購入履歴が表示されます</div>
      </div>

      <div class="button">
        <Button
          label="au IDでログイン"
          type="fill-orange"
          ref="login"
          @click="login"
        />
      </div>

    </div>

    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import AuLogin from "@/components/layout/AuLogin";
import Button from "@/components/button/Button";
import Loading from "@/components/loading/Loading";
import GiftHistory from "@/components/gift/GiftHistory";
import PopularGift from "@/components/gift/PopularGift";
import { mapGetters } from "vuex";
import { auIdLogin } from "@/plugins/AuId";
import { listPurchaseHistoriesApi } from "@/plugins/ApiHelper";
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: "PMG800",
  components: {
    Layout,
    AuLogin,
    Button,
    Loading,
    GiftHistory,
    PopularGift,
  },
  data: () => ({
    histories: [],
  }),
  computed: {
    ...mapGetters(["isLoading", "isAuIdAuthorization", "lid"]),
  },
  watch: {
    isAuIdAuthorization(newVal) {
      if (newVal) {
        this.fetchListPurchaseHistories();
      }
    },
  },
  async mounted() {
    this.fetchListPurchaseHistories();
  },
  methods: {
    fetchListPurchaseHistories() {
      if (!this.isAuIdAuthorization) {
        return;
      }

      listPurchaseHistoriesApi({ "history-type": 2, "lid": this.lid, })
        .then((res) => {
          this.histories = res.histories;
        })
        .catch(() => {
          this.$router.push({ name: "PMG510" });
        });
    },
    showGiftList() {
      this.$refs.layout.gtmPush('ギフトを購入', '/gifts/search');
      this.$router.push({ name: "PMG660" });
    },
    login() {
      gtmSendClick(this.$refs['login'].$el,'購入履歴','au IDでログイン','');
      const redirectUrl = process.env.VUE_APP_BASE_APP_URL + this.$route.path;
      auIdLogin(redirectUrl);
    },
    openGiftHistory(purchasesInfoId) {
      this.$refs.layout.gtmPush('購入履歴の詳細', `/purchase-histories/${purchasesInfoId}`);
      this.$router.push({
        name: "PMG810",
        params: { purchasesInfoId: purchasesInfoId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pmg800 {
  &__list {
    padding: 16px 16px 96px;

    .history {
      margin-bottom: 12px;
      cursor: pointer;
    }
  }

  &__empty {;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font: $font-roboto-400;
    color: rgba($gray6, 0.87);
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 21);

    .messge {
      margin-bottom: 32px;
      width: 248px;
      font: $font-noto-sans-jp-400;
      @include font-size(16, 25.6);
      color: $gray10;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    :deep(.button) {
      width: 248px;
    }
  }
  &__notloggedin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: 0.15px;

    .message {
      margin: 12px 0;
      max-width: 300px;
      color: $gray9;

      .bold{
        font: $font-noto-sans-jp-700;
        @include font-size(16, 25.6);
        margin-bottom: 1rem;
      }
      .main{
        font: $font-noto-sans-jp-400;
        @include font-size(13, 25.6);
        margin-bottom: 0.2rem;
      }
    }

    .button{
      width: 270px;
      height: 45px;
      max-width: 95%;
    }

  }

  .au-login {
    padding: 0 16px;
    margin-top: 32px;
  }
}
</style>
