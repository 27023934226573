<template>
  <Layout ref="layout" title="買い物カゴ" class="pmg700" :showAuLogin="true">
    <ion-content class="ion-no-padding" :scroll-events="true" @ionScroll="handleScroll">

      <div v-if="getCartItems.length > 0 && cartItems.length > 0 && cartItems[0]" class="pmg700__cart">
        <div class="gifts">
          <p class="gifts__title">購入するギフト</p>

          <div v-for="gift in cartItems" :key="gift.giftId">
            <PurchaseGiftItem
              :key="gift.giftId"
              :id="gift.giftId"
              :imgUrl="gift.imgUrl"
              :brandName="gift.brandName"
              :giftName="gift.giftName"
              :price="gift.price"
              :expiryDate="gift.expiryDate"
              :amount="amount"
              :isTaxDisplay="gift.isTaxDisplay"
            />
          </div>

        </div>

        <!-- カードが作成済みの場合 -->
        <div v-if="canShowCardBox">
          <div class="card">
            <div class="card__title">
              <span class="">一緒に贈るカード</span>
            </div>
            <CardViewMini
              :category-id="categoryId"
              :main-receiver-name="receiverName"
              :op-message="opMessage"
              :design-name="designName"
              :thumbnail-url="thumbnailUrl"
              @click:replace-card="checkCardReplace"
            ></CardViewMini>
          </div>
        </div>

        <CartPointBox
          ref="cartPointBox"
          v-if="isAuIdAuthorization"
          :price="giftPrice"
          :use-points="usePoints"
          :is-scroll="isScroll"
          @apply:usePoints="applyUsePoints"
          @clearPointCondition="clearPointCondition"
          :scroll-top="scrollTop"
        />

        <!-- カードが作成済みの場合 -->
        <div v-show="getCardOriginId">
          <div class="rules">
            <!-- ログイン済みの場合 -->
            <div v-if="isAuIdAuthorization">
              <div class="rules__note">
                決済に進むには、利用規約への同意が必要です。必ず利用規約をお読みの上、決済に進んでください。
                <span class="rules__note danger">決済が完了すると返品およびキャンセルができません。</span>
              </div>

              <div class="rules__footer">
                <span class="rules__footer__link" ref="rule" @click="openRule()">プラメギフト利用規約</span>
              </div>
            </div>
            <!-- 未ログインの場合 -->
            <div v-else>
              <div class="rules__note">
                購入手続きに進むにはau IDでログインが必要です。au IDをお持ちでない方はau IDの新規登録が必要になります。
              </div>
            </div>

          </div>

          <!-- 酒類の年齢確認 -->
          <div class="age-verification" v-if="isAgeVerificationRequired">
            <div class="age-verification__title">

              <CheckBoxButton
                class="age-verification__title__check"
                :name="'ageVerification'"
                @click-checkbox="checkAgeVerification"
                :checked="isCheckedAgeVerification"
              ></CheckBoxButton>

              <span class="age-verification__title__text">あなたは20歳以上ですか？</span>
            </div>
            <div class="age-verification__label">20歳未満での酒類の購入や飲酒は日本の法律では固く禁じられています。</div>
          </div>

          <div v-if="isAuIdAuthorization">
            <div class="buttons" v-if="isPointPay">
              <Button label="同意してPontaポイントで全額支払う" type="fill" ref="pointPay" @click="pointPayStart"
                      :disabled="disableButton"/>
            </div>

            <div class="buttons" v-else>
              <Button label="同意してauかんたん決済へ" type="fill" ref="auPay" @click="auPay"
                      :disabled="disableButton"/>
            </div>
          </div>
          <div v-else>
            <div class="buttons">
              <Button label="au ID でログイン / 新規登録" type="fill-orange" @click="login"/>
            </div>
          </div>

          <div class="notes">
            <!-- <div class="notes__row" v-show="!isAuIdAuthorization">
              <img src="@/assets/images/icon/alert-circle-outline.svg">
              <div>
                <span class="notes__row__text">次の画面でau IDでのログインまたは新規登録が必要になります。</span>
              </div>
            </div> -->
            <div class="notes__row" v-show="!isAuIdAuthorization">
              <img src="@/assets/images/icon/alert-circle-outline.svg">
              <div class="notes__row__text">お支払いには、<span class="notes__row__text__bold">Pontaポイント</span>または<span
                class="notes__row__text__bold">auかんたん決済</span>がご利用いただけます。
              </div>
            </div>

            <div class="notes__row" v-if="!isPointPay">
              <img src="@/assets/images/icon/alert-circle-outline.svg">
              <div class="notes__row__text">
                auかんたん決済については、
                <a href="https://kantan.auone.jp/service/" target="_blank" ref="kantan"
                   @click="kantanClick">auかんたん決済の概要</a>
                をご覧ください。
              </div>
            </div>
          </div>
        </div>

        <!-- カードが作成していない場合 -->
        <div class="no-card" v-show="!getCardOriginId">
          <div class="no-card__label">ギフトを贈るにはカードの作成が必要です</div>
          <div class="no-card__image">
            <img src="@/assets/images/PMG100/girl.svg">
          </div>
          <CardTypeSelect v-model="cardType"></CardTypeSelect>
          <Button label="カード作成に進む" type="fill" ref="createdCard" @click="goCreateCard()"/>
        </div>
        <div class="cart-reset">
          <Button label="買い物カゴをリセット" type="danger" ref="resetCart" @click="alertReset()"/>
        </div>
      </div>

      <div v-if="!getCartItems || getCartItems.length <= 0" class="pmg700__empty">
        <div class="messge">買い物カゴは空っぽです<br/>ギフトで気持ちを伝えてみませんか？</div>
        <PopularGift
          ref="popularGift"
          GALabel1="買い物カゴ"
        />
        <Button label="ギフトを探す" type="fill" ref="showGiftList" @click="showGiftList"/>
      </div>
    </ion-content>
    <Loading :is-show="isLoading"/>
    <CartReplaceCardMocal
      ref="cartReplaceCardMocal"
      @click:replaceCard="replaceCard()"
      @click:replaceCardDom="replaceCardDom"
      @click:checkCancel="checkCancel()"
      @click:checkCancelDom="checkCancelDom"
      @on-close-dom="closeReplaceGA"
    ></CartReplaceCardMocal>

    <TermsUseModal ref="termsUseModal" @on-close-dom="closeTermsGA"></TermsUseModal>
    <IonicAlert
      ref="resetAlert"
      header="現在の買い物カゴの内容をリセットしますか？"
      message="現在の買い物カゴ内にあるギフト、および作成したカードはすべて削除されます。よろしいですか？"
      :buttons="buttonsAlert"
      @click:didDismiss="confirmResult($event)"
      @click:didDismissDom="dissmissGA"
    ></IonicAlert>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import Button from '@/components/button/Button';
import PurchaseGiftItem from '@/components/gift/PurchaseGiftItem';
import CardTypeSelect from '@/components/messageCard/CardTypeSelect';
import CartReplaceCardMocal from '@/components/modal/CartReplaceCardMocal';
import TermsUseModal from '@/components/modal/TermsUseModal'
import IonicAlert from '@/components/ionic/IonicAlert';
import PopularGift from '@/components/gift/PopularGift'
import CardViewMini from "@/components/messageCard/CardViewMini";
import CheckBoxButton from "@/components/button/CheckBoxButton.vue";
import CartPointBox from "@/components/point/CartPointBox.vue";
import store from '@/store';
import {IonContent, IonToggle} from "@ionic/vue";
import _ from 'lodash';

import {mapGetters} from 'vuex';
import Loading from "@/components/loading/Loading";

import {getCartCardInfo, delCard, updateCart, pointSold} from '@/plugins/ApiHelper';
import {gtmSendClick} from "@/plugins/GtmHelper";
import {GtmEcommerce, GtmEcommerceItem} from '@//plugins/GtmEcommerce';
import {DateTime} from 'luxon';
import {CART_UPDATE_MODE} from "@/constants/cart";
import {checkVtktPay} from "@/plugins/AuId";
import {getPurchaceLimit} from "@/plugins/GiftHelper";
import {POINT_SELECT_MENUS} from "@/constants/cart";

export default {
  name: 'PMG700',
  components: {
    Layout,
    Button,
    PurchaseGiftItem,
    IonContent,
    Loading,
    CardTypeSelect,
    CartReplaceCardMocal,
    TermsUseModal,
    IonicAlert,
    PopularGift,
    CardViewMini,
    CheckBoxButton,
    CartPointBox,
  },
  emits: ["update:modelValue"],
  data: () => ({
    amount: 0,
    amountDisplay: 0,
    cartItems: [],
    giftId: null,
    categoryId: null,
    categoryName: null,
    designName: null,
    isForMyself: false,
    receiverName: null,
    opMessage: null,
    thumbnailUrl: null,
    cardType: '0',
    isCheckedAgeVerification: false,
    buttonsAlert: [
      {
        text: 'キャンセル',
        role: 'cancel',
      },
      {
        text: 'リセット',
        role: 'reset',
        cssClass: 'alert-button-reset',
      }
    ],
    usePoints: 0,
    isOnlyPoints: false,
    issueIdentity: null,
    isScroll: false //スクロールをしたかどうか
  }),
  computed: {
    ...mapGetters([
      'getCartItems',
      'getCardOriginId',
      'isLoading',
      'isAuIdAuthorization',
      'lid',
      'aid',
      'getPointCondition',
      'getPurchasesInfoId',
      'getGiftId',
    ]),
    canShowCardBox() {
      // ギフト用のカード作成済み
      if (this.getCardOriginId && this.isForMyself === false) {
        return true;
      }
      return false;
    },
    disableButton() {
      if (this.isAgeVerificationRequired === false) {
        return false
      }

      if (this.isCheckedAgeVerification === false) {
        return true
      }
      return false
    },
    isAgeVerificationRequired() {
      return this.getCartItems.some(item => item.ageVerification === true)
    },
    giftPrice() {
      if (this.cartItems.length > 0 && this.cartItems[0]) {
        return this.cartItems[0].price;
      }
      return null
    },
    purchasePrice() {
      if (this.cartItems.length > 0 && this.cartItems[0]) {
        const price = this.cartItems[0].price;
        if (this.usePoints) {
          return price - this.usePoints;
        }
        return price;
      }
      return 0
    },
    isPointPay() {
      return this.purchasePrice === 0;
    },
  },
  watch: {
    $route() {
      if (this.$route.name === "PMG700") {
        this.isCheckedAgeVerification = false
      }
    },
    getCartItems(newVal) {
      if (newVal && newVal.length > 0) {
        this.updateData();
      }
    },

    getCardOriginId(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCardInfo();
      }
    }
  },
  async mounted() {
    this.updateData();
    if (this.getCardOriginId) {
      this.getCardInfo();
    }
    await this.getPoint()
    this.initPointCondition()
    this.ecommerceViewCartPush();
    console.log(this.cartItems[0])
  },
  updated() {
    if (this.$route.name != 'PMG700') {
      return;
    }
    this.updateData();
    if (this.getCardOriginId) {
      this.getCardInfo();
    }
  },
  async ionViewDidEnter() {
    await this.getPoint()
    this.initPointCondition()
  },
  methods: {
    async getPoint() {
      const ref = this.$refs.cartPointBox
      if (ref) {
        await ref.getPoint()
      }
    },
    clearCart() {
      console.log('clearCart')
      store.commit('setPurchasesInfoId', null);
      store.commit('setGiftId', null);
      store.commit('setCartItems', []);
      store.commit('setCardOriginId', null);
      store.commit('setPointCondition', null);
    },
    getPointBalance() {
      const ref = this.$refs.cartPointBox
      if (ref) {
        return ref.getPointBalance()
      }
      return 0
    },
    clearPointCondition() {
      console.log('clearPointCondition')
      store.commit('setPointCondition', null)
      this.usePoints = 0
      this.isOnlyPoints = false
      const ref = this.$refs.cartPointBox
      if (ref) {
        ref.clearInputPoints()
      }
    },
    initPointCondition() {
      console.log('initPointCondition')
      this.usePoints = 0
      this.isOnlyPoints = false

      // 保有ポイント数取得
      const pointBalance = this.getPointBalance()

      const ref = this.$refs.cartPointBox
      if (ref) {
        ref.initSelectedPointMenu()
        if (this.getPointCondition) {
          this.usePoints = this.getPointCondition.usePoints
          this.isOnlyPoints = this.getPointCondition.isOnlyPoints

          if (this.isOnlyPoints) {
            ref.updateSelectedPointMenu(POINT_SELECT_MENUS.USE_ALL_POINTS)

          } else if(this.usePoints == pointBalance) {
            ref.updateSelectedPointMenu(POINT_SELECT_MENUS.USE_ALL_POINTS)
            ref.clearInputPoints()
          } else if (this.usePoints > 0) {
            console.log('USE_PART_OF_POINTS', this.usePoints)
            ref.updateSelectedPointMenu(POINT_SELECT_MENUS.USE_PART_OF_POINTS)
            ref.setInputPoints(this.usePoints)
          }
        }
      }
    },
    login() {
      this.$refs.layout.login();
    },
    handleScroll(event) {
      // スクロールが検知されたときの処理
      this.isScroll = !this.isScroll
    },
    applyUsePoints(points, isOnlyPoints = false) {
      this.usePoints = Number(points)
      this.isOnlyPoints = isOnlyPoints

      const pointCondition = {
        usePoints: Number(this.usePoints),
        isOnlyPoints: this.isOnlyPoints,
      }

      store.commit('setPointCondition', pointCondition)

      this.updateCartData(CART_UPDATE_MODE.SELECT_POINT)
    },
    checkAgeVerification(value) {
      console.log("checkAgeVerification", value)
      this.isCheckedAgeVerification = value;
    },
    showGiftList() {
      gtmSendClick(this.$refs['showGiftList'].$el, '買い物カゴ', 'ギフトを探す', '');
      this.$refs.layout.gtmPush('ギフトを購入', '/gifts/search');
      this.$router.push({name: 'PMG660'});
    },
    async auPay() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
        return;
      }
      // 陳列有効期限チェック
      const isValid = this.canDisplay();
      if (!isValid) {
        await this.$router.push({name: 'au_pay'});
        return;
      }

      gtmSendClick(this.$refs['auPay'].$el, '買い物カゴ', '同意して簡単決済へ', '');

      if (this.isAuIdAuthorization) {
        // ログイン済の場合、かんたん決済前に金額上限超過チェック
        const purchaceLimit = await this.$refs.layout.checkPurchaceLimit();
        if (purchaceLimit.isLimitOver) {
          return;
        }
      } else {
        console.log('aupay loginBeforePurchase')
        // 未ログインの場合はログイン
        const additionalParams = {};
        this.$refs.layout.login(additionalParams);
      }
      this.ecommerceBeginCheckoutPush();
      const cardId = this.getCardOriginId;
      store.commit('clearAuPayProcessInfo');
      store.commit('setGiftId', this.cartItems[0].giftId);
      store.commit('setCardOriginId', cardId);


      this.$refs.layout.gtmPush('auかんたん決済', '/au_pay');
      this.$router.push({name: 'au_pay'});
    },
    /**
     * ポイント決済処理 事前チェック
     * au ID チェック
     * @returns {Promise<void>}
     */
    async pointPayStart() {
      if (!this.getCartItems || this.getCartItems.length === 0) {
        await this.$router.push({name: "PMG510"});
        return;
      }

      if(!this.isPointPay) {
        console.error('使用ポイント数が不正です。')
        return
      }

      store.commit('setGiftId', this.cartItems[0].giftId);

      // 陳列有効期限チェック
      const isValid = this.canDisplay();
      if (!isValid) {
        // TODO: ポイントエラー画面へ遷移
        await this.$router.push({name: 'au_pay'});
        return;
      }

      // gtmSendClick(this.$refs['auPay'].$el, '買い物カゴ', '同意して簡単決済へ', '');

      if (this.isAuIdAuthorization) {
        // ログイン済の場合、かんたん決済前に金額上限超過チェック
        const purchaceLimit = await this.$refs.layout.checkPurchaceLimit();
        if (purchaceLimit.isLimitOver) {
          return;
        }
      } else {
        // TODO: 未ログインの場合はログイン
        const additionalParams = {
          loginBeforePointPay: true,
        };
        this.$refs.layout.login(additionalParams);
      }

      this.isOnlyPoints = true;

      const pointCondition = {
        usePoints: Number(this.usePoints),
        isOnlyPoints: this.isOnlyPoints,
      }
      store.commit('setPointCondition', pointCondition)

      this.message = "au ID チェック中";
      await store.dispatch("startLoading");
      // au ID トークンチェック
      await checkVtktPay(
        this.getPurchasesInfoId,
        this.getGiftId,
        this.getCardOriginId,
        this.usePoints,
        this.isOnlyPoints
      )
        .then((res) => {
          if (_.isNil(res)) {
            return
          }
          const lid = res.lid;
          const aid = res.aid;
          const purchases_info_id = res.purchases_info_id;
          if (!_.isNil(lid) && !_.isNil(aid) && !_.isNil(purchases_info_id)) {
            // ポイント決済処理
            this.pointPay();
          }
        })
        .catch((err) => {
          console.error(err);
          this.message = null;

          if(err.response?.data?.process_already_done) {
            // セッションストレージ カートクリア
            this.clearCart()

            // 既に購入済みエラー画面に遷移
            this.$router.push({name: "PMG513"});
          } else {
            // ポイント決済エラー画面に遷移
            this.$router.push({name: "PMG511"});
          }
        })
        .finally(() => {
          store.dispatch("stopLoading");
        });
    },
    /**
     * ポイント決済処理
     */
    async pointPay() {
      console.log('pointPay call')

      // 購入可能限度額チェック
      const purchaceLimit = await this.$refs.layout.checkPurchaceLimit();
      if (purchaceLimit.isLimitOver) {
        return;
      }
      // ポイント確定API
      const data = {
        purchases_info_id: this.getPurchasesInfoId,
        transaction_id: this.getTransactionId,
        gift_id: this.getGiftId,
        lid: this.lid,
        aid: this.aid,
        issue_identity: this.issueIdentity,
      };

      let purchases_info_id = null;
      await pointSold(data)
        .then(async (res) => {
          console.log('pointSold', res)
          purchases_info_id = res.purchases_info_id; // 購入番号
          store.commit('setPurchasesInfoId', null);
          store.commit('setGiftId', null);
          store.commit('setCartItems', []);
          store.commit('setCardOriginId', null);
          store.commit('setPointCondition', null);

          console.log('getPurchasesInfoId', this.getPurchasesInfoId)
          return await this.updateCartData();
        })
        .then(() => {
          getPurchaceLimit(this.lid);
          this.$refs.layout.gtmPush('購入完了', `/cart/payment-completion/${purchases_info_id}`);
          this.$router.push({name: "PMG420", params: {'purchasesInfoId': purchases_info_id}});
        })
        .catch((err) => {
          console.error(err);
          // ポイント決済失敗時はポイント利用設定をクリア
          store.commit('setPointCondition', null);

          if(err.response?.data?.process_already_done) {
            // セッションストレージ カートクリア
            this.clearCart()
            // 既に購入済みエラー画面に遷移
            this.$router.push({name: "PMG513"});
          } else {
            // ポイント決済エラー画面に遷移
            this.$router.push({name: "PMG511"});
          }
        })
        .finally(() => {
          store.dispatch("stopLoading");
        });

    },
    /*
      * 陳列有効期限チェック
      * 陳列有効期限が過ぎている場合はエラー画面へ遷移する
     */
    canDisplay() {
      try {
        let isValid = true
        for (let cartItem of this.getCartItems) {
          const displayEndDate = cartItem.displayEndDate;
          const diff = DateTime.fromSQL(displayEndDate).diffNow();
          if (diff.milliseconds < 0) {
            isValid = false
            break
          }
        }
        return isValid
      } catch (e) {
        console.error(e)
        return false
      }
    },
    backPrevious() {
      this.$router.go(-1);
    },
    updateData() {
      this.cartItems = this.getCartItems;
      if (this.cartItems.length > 0 && this.cartItems[0]) {
        this.amount = this.cartItems.reduce((sum, element) => sum + element.price, 0);
        this.amountDisplay = Number(this.amount).toLocaleString();
        this.giftId = this.cartItems[0].giftId;
        // this.updateCartData();
      } else {
        this.$refs.popularGift.getPopularGiftList();
      }
    },
    getCardInfo() {
      if (_.isNil(this.getCardOriginId)) {
        return;
      }
      const data = {
        'card_origin_id': this.getCardOriginId,
      };
      getCartCardInfo(data)
        .then((res) => {
          this.categoryId = res.category_id;
          this.categoryName = res.category_name;
          this.designName = res.category_name;
          this.receiverName = res.main_receiver_name;
          this.opMessage = res.op_message;
          this.thumbnailUrl = res.thumbnail_url;
          this.isForMyself = res.is_for_myself || false;
        })
        .catch((err) => {
          console.err(err);
          this.$router.push({name: "PMG510"});
        });
    },
    openRule() {
      gtmSendClick(this.$refs['rule'], '買い物カゴ', 'プラメギフト利用規約', '');
      this.$refs.termsUseModal.openModal();
    },
    alertReset() {
      gtmSendClick(this.$refs['resetCart'].$el, '買い物カゴ', '買い物カゴをリセット', '');
      this.$refs.resetAlert.openAlert();
    },
    async resetCart() {
      // カートクリア
      if (this.getCardOriginId && this.lid) {
        const data = {
          'card_origin_id': this.getCardOriginId,
        };
        await delCard(data);
      }

      // GAに必要な情報を変数に保持
      const backupCartItems = this.cartItems;

      this.giftId = null;
      store.commit('setCartItems', []);
      store.commit('setCardOriginId', null);
      store.commit('setPointCondition', null);
      store.commit('clearAuPayProcessInfo');
      this.cartItems = [];
      await this.updateCartData();

      // カートを空にする処理が終わったので関数呼び出し
      this.ecommerceRemoveFromCartPush(backupCartItems);
    },
    goCreateCard() {
      if (this.cardType == '0') {
        // 通常カード
        gtmSendClick(this.$refs['createdCard'].$el, '買い物カゴ', 'カードの作成に進む（通常カード）', '');
        this.$refs.layout.gtmPush('贈り主の名前を入力', `/gifts/${this.giftId}/input-name`);
        this.$router.push({name: "PMG330", params: {giftId: this.giftId}});
      } else {
        // リッチ版カード
        gtmSendClick(this.$refs['createdCard'].$el, '買い物カゴ', 'カードの作成に進む（リッチカード）', '');
        this.$refs.layout.gtmPush('カード一覧', '/cards');
        this.$router.push({name: "PMG300"});
      }
    },
    checkCardReplace() {
      gtmSendClick(this.$refs['cardReplace'], '買い物カゴ', 'カードを選びなおす', '');
      this.$refs.cartReplaceCardMocal.openModal();
    },
    async replaceCard() {
      // カード選び直し
      this.$refs.cartReplaceCardMocal.hideModal();
      if (this.getCardOriginId != null) {
        const data = {
          'card_origin_id': this.getCardOriginId,
        };
        await delCard(data)
          .then(() => {
            store.commit('setCardOriginId', null);
          })
          .catch((err) => {
            console.err(err);
            this.$router.push({name: "PMG510"});
          });
      }
      this.updateCartData();
    },
    replaceCardDom(dom) {
      gtmSendClick(dom, 'カードの選びなおし', '選びなおして新たに作成する', '');
    },
    checkCancel() {
      this.$refs.cartReplaceCardMocal.hideModal();
    },
    checkCancelDom(dom) {
      gtmSendClick(dom, 'カードの選びなおし', 'キャンセル', '');
    },
    closeReplaceGA(dom) {
      gtmSendClick(dom, 'カードの選びなおし', '閉じる', '');
    },
    closeTermsGA(dom) {
      gtmSendClick(dom, '利用規約モーダル', '閉じる', '');
    },
    confirmResult(e) {
      if (e.detail.role === 'reset') {
        this.resetCart();
      }
    },
    dissmissGA(e, dom) {
      gtmSendClick(dom, '現在の買い物カゴの中身を空にしますか？', e.detail.role === 'reset' ? '空にする' : 'キャンセル', '');
    },
    async updateCartData(updateMode = CART_UPDATE_MODE.DEFAULT) {
      if (!this.isAuIdAuthorization || !this.lid) {
        return;
      }

      const data = {
        lid: this.lid,
        giftId: this.giftId,
        cardOriginId: this.getCardOriginId,
        usedPoints: this.usePoints,
        isOnlyPoints: this.isOnlyPoints,
        updateMode: updateMode,
      };

      await updateCart(data).catch((err) => {
        console.error(err);
        if (err.response.data && err.response?.data?.card_origin_id_already_used) {
          // セッションストレージ カートクリア
          this.clearCart()

          //カードが既に決済済みの決済情報と紐づいた場合
          if(err.response?.data?.update_mode === CART_UPDATE_MODE.SELECT_POINT) {
            // ポイント設定エラー
            this.$router.push({name: "PMG513"});
          } else {
            // 購入手続き中エラー
            this.$router.push({name: "PMG512"});
          }
        }
        else{
          this.$router.push({name: "PMG500"});
        }
      });
    },

    kantanClick() {
      gtmSendClick(this.$refs['kantan'], '買い物カゴ', 'auかんたん決済の概要', '');
    },
    // GA(eコマース) event: view_cart
    ecommerceViewCartPush() {
      const items = []
      let value = 0
      if (this.cartItems.length > 0) {
        for (let cartItem of this.cartItems) {
          value += parseInt(cartItem.price)
          const item = GtmEcommerceItem.getEcommerceItem(cartItem)
          items.push(item)
        }
        GtmEcommerce.viewCart(value, items)
      }
    },
    // GA(eコマース) event: remove_from_cart
    ecommerceRemoveFromCartPush(cartItems) {
      const items = []
      let value = 0
      for (const cartItem of cartItems) {
        value += parseInt(cartItem.price)
        const item = GtmEcommerceItem.getEcommerceItem(cartItem)
        items.push(item)
      }
      GtmEcommerce.removeFromCart(value, items)
    },
    // GA(eコマース) event: begin_checkout
    ecommerceBeginCheckoutPush() {
      const items = []
      let value = 0
      for (let cartItem of this.cartItems) {
        value += parseInt(cartItem.price)
        const item = GtmEcommerceItem.getEcommerceItem(cartItem)
        items.push(item)
      }
      GtmEcommerce.beginCheckout(value, items)
    },
    scrollTop() {
      setTimeout(() => {
        this.$refs.layout.scrollToTop(0);
      }, 1000)
    },
  },
};
</script>

<style lang="scss">
.alert-button-reset {
  font-weight: 700;
  --ion-color-primary: #{$orange1};
}
</style>
<style scoped lang="scss">
ion-content {
  --background: #{$modelBackground};
}

ion-toggle {
  --track-background-checked: #{$gold2};

  label {
    font: $font-noto-sans-jp-400;
    @include font-size(14, 22.4);
  }
}

.pmg700 {
  &__cart {
    padding: 0 16px 0;

    .purchase-info {
      &__card {
        margin-top: 16px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

    }

    .age-verification {
      margin-bottom: 32px;
      padding: 16px;
      background: $white1;
      border-radius: 8px;


      &__title {
        text-align: center;
        margin: 0 0 16px;
        color: $black6;
        font: $font-roboto-700;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &__text {
          @include font-size(16, 20);
        }

        &__check {
          display: flex;
          margin-right: 8px;
        }
      }

      &__label {
        font: $font-noto-sans-jp-400;
        @include font-size(12, 19.2);
      }
    }

    .card,
    .gifts {
      margin-top: 12px;
      padding: 16px;
      background: $white1;
      border-radius: 8px;

      &__title {
        margin: 0 0 16px;
        color: $gray10;
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
        margin-bottom: 12px;
      }

      &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--ion-color-dark);
        font: $font-noto-sans-jp-400;

        @include font-size(14, 20);

        .amount {
          color: $black6;
          font: $font-roboto-700;

          @include font-size(22, 28.6);
        }
      }
    }

    .card {
      &__info {
        display: flex;

        &__image {
          width: 112px;
          height: 112px;
          border-radius: 6px;
        }

        &__detail-noraml {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 1rem;
          font: $font-noto-sans-jp-400;
        }

        &__detail-ricci {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 1rem;
          font: $font-noto-sans-jp-400;
          min-width: 0;

          .receiver-name,
          .message,
          .design-name {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .receiver-name {
            @include font-size(14, 22.4);
          }

          .message {
            @include font-size(14, 19.2);
          }

          .design-name {
            @include font-size(10, 16);
          }

        }

      }

      &__footer {
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;

        @include font-size(14, 35.98);

        font: $font-roboto-400;
        color: $gold2;
      }
    }

    .rules {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;;
      margin: 2rem 0;

      &__note {
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);

        &.danger {
          color: var(--ion-color-danger);
        }
      }

      &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.625rem;

        &__link {
          font: $font-roboto-400;
          @include font-size(14, 18.2);
          cursor: pointer;
          color: $gold2;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 16px 0;

      :deep(.button) {
        position: inherit;
        min-width: 311px;
        height: 50px;
        border-radius: 25px;
      }
    }

    .notes {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      // margin: 32px 0 24px;

      &__row {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        img {
          width: 14px;
          height: 14px;
        }

        &__text {
          font: $font-noto-sans-jp-400;
          @include font-size(12, 19.2);
          margin-left: 4px;

          &__bold {
            font: $font-noto-sans-jp-700;
            @include font-size(12, 19.2);
          }
        }
      }
    }

    .cart-reset {
      font: $font-roboto-400;
      @include font-size(16, 20.8);
      cursor: pointer;
      color: $red2;
      text-align: center;
      margin: 24px 0;

      :deep(.button) {
        position: inherit;
        min-width: 311px;
      }
    }

  }

  .no-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    &__label {
      font: $font-noto-sans-jp-700;
      @include font-size(14, 22.4);
    }

    &__image {
      margin: 1rem;
    }

    :deep(.button) {
      position: inherit;
      min-width: 311px;
      margin-top: 32px;
      height: 50px;
      border-radius: 25px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font: $font-roboto-400;
    color: rgba($gray6, 0.87);
    text-align: center;
    letter-spacing: 0.15px;

    @include font-size(14, 21);

    .messge {
      margin-bottom: 32px;
      width: 248px;
      font: $font-noto-sans-jp-400;
      @include font-size(16, 25.6);
      color: $gray10;
    }

    :deep(.button) {
      width: 248px;
    }
  }
}
</style>
