import { modalController } from '@ionic/vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store'
import { authorization } from '@/plugins/ApiHelper'
import { AuthorizationPage, HideFooterMenuPage, OpenSideMenuPage, NotOpenSideMenuToPage, RetentionGiftFilterPage } from '@/plugins/Constants'
import { emitter } from '@/eventBus';
import { createEndpointUrl, shouldNoIndexPage, setMetaData, queryStringDelete } from '@/plugins/Utils'
import { SIDE_MENU_PAGE, SPECIAL_FEATURE_PAGE } from '@/constants/router'
import { BOTTOME_NAVIGATION_IDS } from '@/constants/base'

import PMG100 from '@/views/PMG100'
import PMG200 from '@/views/PMG200'
import PMG210 from '@/views/PMG210'
import PMG300 from '@/views/PMG300'
import PMG310 from '@/views/PMG310'
import PMG320 from '@/views/PMG320'
import PMG330 from '@/views/PMG330'
import PMG400 from '@/views/PMG400'
import PMG410 from '@/views/PMG410'
import PMG420 from '@/views/PMG420'
import PMG500 from '@/views/PMG500'
import PMG510 from '@/views/PMG510'
import PMG511 from '@/views/PMG511'
import PMG512 from '@/views/PMG512'
import PMG513 from '@/views/PMG513'
import PMG600 from '@/views/PMG600'
import PMG700 from '@/views/PMG700'
import PMG720 from '@/views/PMG720'
import PMG800 from '@/views/PMG800'
import PMG810 from '@/views/PMG810'
import AuPay from '@/views/AuPay'
import PMG610 from '@/views/PMG610'
import PMG620 from '@/views/PMG620'
import PMG630 from '@/views/PMG630'
import PMG640 from '@/views/PMG640'
import PMG650 from '@/views/PMG650'
import PMG660 from '@/views/PMG660'
import SpecialFeatureView from '@/components/specialFeature/SpecialFeatureView'
import FooterMenu from '@/components/layout/FooterMenu'

const routes = [
  {
    path: '/',
    redirect: '/gifts',
  },
  {
    path: '/',
    component: FooterMenu,
    children: [
      {
        path: '',
        redirect: '/gifts',
      },
      {
        path: '/gifts',
        name: 'PMG600',
        component: PMG600,
        meta: {
          title: 'ホーム | プラメギフト',
          description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
          noindex: false,
          menuId: BOTTOME_NAVIGATION_IDS.HOME,
        },
      },
      // {
      //   path: '/gifts/:giftId',
      //   name: 'PMG610',
      //   component: PMG610,
      //   meta: {
      //     title: 'ギフトの詳細｜プラメギフト',
      //     description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
      //     noindex: false,
      //     menuId: BOTTOME_NAVIGATION_IDS.GIFT,
      //   },
      // },
      // {
      //   path: '/gifts/search',
      //   name: 'PMG660',
      //   component: PMG660,
      //   meta: {
      //     title: 'ギフト一覧 | プラメギフト',
      //     description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
      //     noindex: false,
      //     menuId: BOTTOME_NAVIGATION_IDS.GIFT,
      //   },
      // },
      // {
      //   path: '/gifts/brands/:brandId',
      //   name: 'PMG620',
      //   component: PMG620,
      //   meta: {
      //     title: 'ブランド別ギフト一覧｜プラメギフト',
      //     description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
      //     noindex: false,
      //     menuId: BOTTOME_NAVIGATION_IDS.GIFT,
      //   },
      // },
      // {
      //   path: '/gifts/brands',
      //   name: 'PMG650',
      //   component: PMG650,
      //   meta: {
      //     title: 'ブランド一覧｜プラメギフト',
      //     description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
      //     noindex: false,
      //     menuId: BOTTOME_NAVIGATION_IDS.GIFT,
      //   },
      // },
      // {
      //   path: '/gifts/:giftId/chose-card-type',
      //   name: 'PMG720',
      //   component: PMG720,
      //   meta: {
      //     title: 'カードを選ぶ | プラメギフト',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   },
      // },
      // {
      //   path: '/gifts/:giftId/input-name',
      //   name: 'PMG330',
      //   component: PMG330,
      //   meta: {
      //     title: '贈り主の名前を入力 | プラメギフト',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   },
      // },
      // {
      //   path: '/cart',
      //   name: 'PMG700',
      //   component: PMG700,
      //   meta: {
      //     title: 'お買い物カゴ | プラメギフト',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   },
      // },
      // {
      //   path: '/au-pay',
      //   name: 'au_pay',
      //   component: AuPay,
      //   meta: {
      //     title: 'プラメギフト',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   }
      // },
      // {
      //   path: '/cart/payment-completion/:purchasesInfoId',
      //   name: 'PMG420',
      //   component: PMG420,
      //   meta: {
      //     title: '購入完了 | プラメギフト',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.CART,
      //   },
      // },
      {
        path: '/purchase-histories',
        name: 'PMG800',
        component: PMG800,
        meta: {
          title: '購入履歴 | プラメギフト',
          noindex: true,
          menuId: BOTTOME_NAVIGATION_IDS.PURCHASE_HISTORY,
        },
      },
      {
        path: '/purchase-histories/:purchasesInfoId',
        name: 'PMG810',
        component: PMG810,
        meta: {
          title: '購入履歴の詳細 | プラメギフト',
          noindex: true,
          menuId: BOTTOME_NAVIGATION_IDS.PURCHASE_HISTORY,
        },
      },
      // {
      //   path: '/cards',
      //   name: 'PMG300',
      //   component: PMG300,
      //   meta: {
      //     title: 'カード一覧 | プラメギフト',
      //     noindex: true
      //   },
      // },
      // {
      //   path: '/cards/:designId/preview',
      //   name: 'PMG310',
      //   component: PMG310,
      //   meta: {
      //     title: 'カードサンプル | プラメギフト',
      //     noindex: true
      //   },
      // },
      // {
      //   path: '/cards/:designId/edit',
      //   name: 'PMG320',
      //   component: PMG320,
      //   meta: {
      //     title: 'カード編集 | プラメギフト',
      //     noindex: true
      //   },
      // },
      // {
      //   path: '/gifts/special-feature/:page',
      //   name: 'special-feature',
      //   component: SpecialFeatureView,
      //   meta: {
      //     title: '特集ページ｜プラメギフト',
      //     description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
      //     noindex: true,
      //     menuId: BOTTOME_NAVIGATION_IDS.HOME,
      //     is_static_page: true
      //   }
      // },
      {
        path: '/infomation/:url',
        name: 'PMG630',
        component: PMG630,
        meta: {
          title: 'お知らせ｜プラメギフト',
          description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
          noindex: false,
          menuId: BOTTOME_NAVIGATION_IDS.HOME,
          is_static_page: true,
        },
      },
      {
        path: '/gifts/static/:url',
        name: 'PMG640',
        component: PMG640,
        meta: {
          title: '静的ページ｜プラメギフト',
          description: 'プラメギフトは簡単に贈れて便利に受け取れるソーシャルギフトです。住所を知らずに贈れます。',
          noindex: true,
          menuId: BOTTOME_NAVIGATION_IDS.HOME,
          is_static_page: true,
        },
      },
    ],
  },
  // {
  //   path: '/PMG100',
  //   name: 'PMG100',
  //   component: PMG100,
  //   meta: {
  //     title: 'プラメギフト',
  //     noindex: true
  //   },
  // },
  // {
  //   path: '/PMG200',
  //   name: 'PMG200',
  //   component: PMG200,
  //   meta: {
  //     title: 'プラメギフト',
  //     noindex: true
  //   },
  // },
  {
    path: '/card-display',
    name: 'PMG210',
    component: PMG210,
    meta: {
      title: 'ギフトが届きました | プラメギフト',
      description: 'タップしてギフトを受け取りましょう！',
    },
  },
  // {
  //   path: '/PMG400',
  //   name: 'PMG400',
  //   component: PMG400,
  //   meta: {
  //     title: 'カード作成完了 | プラメギフト',
  //     noindex: true,
  //   },
  // },
  // {
  //   path: '/PMG410',
  //   name: 'PMG410',
  //   component: PMG410,
  //   meta: {
  //     title: 'カード作成完了 | プラメギフト',
  //     noindex: true,
  //   },
  // },
  {
    path: '/PMG500',
    name: 'PMG500',
    component: PMG500,
    meta: {
      title: 'ページが表示できません | プラメギフト',
      noindex: true,
    },
  },
  {
    path: '/PMG510',
    name: 'PMG510',
    component: PMG510,
    meta: {
      title: 'ページが表示できません | プラメギフト',
      noindex: true,
    },
  },
  // {
  //   path: '/PMG511',
  //   name: 'PMG511',
  //   component: PMG511,
  //   meta: {
  //     title: 'プラメギフト',
  //     noindex: true,
  //   },
  // },
  // {
  //   path: '/PMG512',
  //   name: 'PMG512',
  //   component: PMG512,
  //   meta: {
  //     title: 'プラメギフト',
  //     noindex: true,
  //   },
  // },
  // {
  //   path: '/PMG513',
  //   name: 'PMG513',
  //   component: PMG513,
  //   meta: {
  //     title: 'プラメギフト',
  //     noindex: true,
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    component: PMG510,
    meta: {
      title: 'プラメギフト',
      noindex: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async (to, from, next) => {

  // noindex設定
  setMetaData(to)

  // リロード対策でURLに含まれたUUIDをsessionStorageに格納
  const uuid = to.query.id;
  if (uuid) {
    sessionStorage.setItem('UUID', String(uuid));
  }
  // アカウント認証
  if (AuthorizationPage.includes(to.name) && !store.getters.apiKey) {
    await authorization();
  }
  // ローディングクリア
  await store.dispatch('clearLoading');

  // タイトルの設定
  document.title = to.meta.title;
  const isStaticPage = to.meta.is_static_page || false;
  if (isStaticPage) {
    // パスを取得
    const baseUrl = process.env.VUE_APP_BASE_APP_URL
    const path = new URL(to.path, baseUrl).pathname;

    // パスを '&' で分割
    const decodedParam = queryStringDelete(path)
    // パスを '/' で分割
    const parts = decodedParam.split('/');
    // 最後の部分をデコード
    const decodedTitle = decodeURIComponent(parts[parts.length - 1]);

    let foundItem = null
    if (parts.find(item => item === 'special-feature')) {
      // 特集ページの場合
      foundItem = SPECIAL_FEATURE_PAGE.find(item => item.page === Number(decodedTitle));
    } else if (parts.find(item => item === 'static')) {
      // サイドメニューの静的ページの場合
      foundItem = SIDE_MENU_PAGE.find(item => item.url === decodedTitle);
    }
    if (foundItem && foundItem.name) {
      document.title = `${foundItem.name} | プラメギフト`;
    }
  }

  // Adobe Font
  if (document.querySelectorAll('.adobeTypekit').length < 1) {
    import('@/plugins/AdobeFont');
  }

  next();
})

router.beforeEach(async (to, from, next) => {
  // ギフト一覧遷移時に特定ページ以外から遷移してきた場合、フィルタ条件をリセット
  if (!RetentionGiftFilterPage.includes(from?.name) && to?.name?.includes('PMG600')) {
    emitter.emit('resetFilterListGifts');
  }

  // toとfromのpathが同じでモーダルが表示されている場合、モーダルを非表示
  if (to?.path === from?.path) {
    const modal = modalController.getTop();
    if (modal) {
      modalController.dismiss();
    }
  }

  // フッターメニュー表示、非表示
  let showFooterMenu = true;
  if (HideFooterMenuPage.includes(to?.name)) {
    showFooterMenu = false;
  }
  store.commit('setShowFooterMenu', showFooterMenu);

  // 画面遷移（戻り）時にメニューを表示
  let showSideMenu = false;
  if (NotOpenSideMenuToPage.includes(to?.name) || to?.query?.ppsm) {
    showSideMenu = false;
  } else if (from?.query?.showSideMenu) {
    showSideMenu = true;
  }
  emitter.emit('showSideMenu', showSideMenu);

  // ボトムナビのメニュー選択
  emitter.emit('selectMenu', to?.fullPath);

  next();
});

export default router

