<template>
  <div>
    <SeasonalEvent
      v-for="(seasonal, index) in seasonalData"
      :key="index"
      :promotionId="seasonal.promotion_id"
      :href="seasonal.href"
      :redirect-url-info="seasonal.redirectUrlInfo"
      :redirect-button-label="seasonal.redirectButtonLabel"
      :title="seasonal.title"
      :subTitle="seasonal.subTitle"
      :image="seasonal.image"
      :imageWidth="seasonal.imageWidth"
      :giftItems="seasonal.giftItems"
      :backgroundImage="seasonal.backgroundImage"
      @seasonalOpenDetail="$emit('click:seasonalOpenDetail', $event)"
      @click:ecommerceSelectPromotionPush="ecommerceSelectPromotionPush"
    ></SeasonalEvent>

  </div>
</template>

<script>
import SeasonalEvent from "@/components/seasonalEvent/SeasonalEvent.vue";
import {SEASONAL_DATA} from "@/constants/seasonalEvent.js";
import {GtmEcommerce, GtmEcommerceItem} from '@/plugins/GtmEcommerce';

export default {
  name: "SeasonalEventList",
  emits: ['click:seasonalOpenDetail'],
  components: {
    SeasonalEvent,
  },
  data() {
    return {
      seasonalData: SEASONAL_DATA,
    };
  },
  mounted() {
    this.ecommerceViewPromotionPush()
  },
  methods: {
    // GA(eコマース) event: view_promotion
    ecommerceViewPromotionPush() {
      const items = []
      for (const seasonalData of this.seasonalData) {
        for (const gift of seasonalData.giftItems) {
          const item = GtmEcommerceItem.getEcommerceItem(gift, false, true)
          items.push(item)
        }
        GtmEcommerce.viewPromotion(seasonalData.promotion_id, seasonalData.title, items)
      }
    },
    // GA(eコマース) event: select_promotion
    ecommerceSelectPromotionPush(data) {
      const items = []
      const item = GtmEcommerceItem.getEcommerceItem(data, false, true)
      items.push(item)
      GtmEcommerce.selectPromotion(data.promotion_id, data.promotion_title, items)
    },
  }
};
</script>


<style scoped lang="scss">
.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}
</style>
