<template>
  <div class="pmg100">
    <Layout
      title="作成したカード"
      :sub-title="count"
      active="send"
      :show-create-button ="isAu"
      :show-au-only="!isAu"
      @click:send="clickSend"
      @click:inbox="clickInbox"
      @click:edit="clickEdit"
      @click:create="clickCreate"
      @click:gift="clickGift"
      @click:cart="clickCart"
      @click:history="clickHistory"
    >
      <div v-if="cards.length > 0" class="pmg100__list">
        <ListItem
          v-for="card in cards"
          :key="card.cardOriginId"
          :data="card"
          title-key="receiverName"
          date-key="createdAt"
          @click="clickCard(card.cardOriginId)"
        />
      </div>
      <div v-else class="pmg100__empty">
        <div class="message">作成したカードはまだありません</div>
        <div class="suggestion">お好きなデザインを選んで、<br>メッセージを書くだけ。<br>さぁ、早速作ってみましょう。</div>
      </div>
    </Layout>

    <PreviewModal
      ref="modal"
      :design-id="createdCard?.designId"
      :header="createdCard?.receiverName"
      :value="createdCard"
      type="send"
      @click:button="clickPreviewModalButton"
    />

    <Loading :is-show="isLoading"/>
  </div>
</template>

<script>
import Layout from '@/components/layout/Layout'
import ListItem from '@/components/layout/ListItem'
import PreviewModal from '@/components/modal/MessageCard/PreviewModal'
import Loading from '@/components/loading/Loading'

import { mapGetters } from 'vuex';

import { chooseMethodApi, createdCardListApi, messageCardDataApi } from '@/plugins/ApiHelper'

export default {
  name: 'PMG100',
  components: {
    Layout,
    ListItem,
    PreviewModal,
    Loading,
  },
  data: () => ({
    count: null,
    cards: [],
    createdCard: null,
    selectCardOriginId: null,
  }),
  computed: {
    ...mapGetters(['isAu', 'isLoading']),
  },
  mounted () {
    if (this.isAu) {
      this.fetchCreatedCardList();
    }
  },
  methods: {
    fetchCreatedCardList() {
      createdCardListApi()
        .then(res => {
          this.count = res.count;
          this.cards = res.cards;
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    async fetchMessageCardData(cardOriginId) {
      return new Promise(resolve => {
        const data = {
          'card-origin-id': cardOriginId,
        };
        messageCardDataApi(data)
          .then(res => {
            this.createdCard = res;
            resolve();
          })
          .catch(() => {
            this.$router.push({ name: 'PMG510' });
          });
      });
    },
    fetchChooseMethodApi() {
      const data = {
        cardOriginId: this.selectCardOriginId,
      };
      chooseMethodApi(data)
        .then(() => {
          this.$router.push({ name: 'PMG400' });
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    // modal
    openModal() {
      this.$refs.modal.openModal();
    },
    // click
    clickSend() {
      this.fetchCreatedCardList();
    },
    clickInbox() {
      this.$router.push({ name: 'PMG200' });
    },
    clickEdit() {
      this.$router.push({ name: 'PMG300' });
    },
    clickCreate() {
      this.$router.push({ name: 'PMG300' });
    },
    clickGift() {
      this.$router.push({ name: 'PMG600' });
    },
    clickCart() {
      this.$router.push({ name: 'PMG700' });
    },
    clickHistory() {
      this.$router.push({ name: 'PMG800' });
    },
    async clickCard(cardOriginId) {
      await this.fetchMessageCardData(cardOriginId);
      this.selectCardOriginId = cardOriginId;
      this.openModal();
    },
    clickPreviewModalButton() {
      this.fetchChooseMethodApi();
    },
  },
}
</script>

<style scoped lang="scss">
.pmg100 {
  &__list {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 100%;

    .message {
      position: absolute;
      top: 31.7%;
      left: 0;
      width: 100%;
      padding-top: 143px;
      background-image: url("../assets/images/PMG100/girl.svg");
      background-position: center top;
      background-size: auto 119px;
      background-repeat: no-repeat;
      font: $font-roboto-700;
      color: rgba($gray6, 0.87);
      text-align: center;
      letter-spacing: 0.15px;

      @include font-size(14, 16);
    }

    .suggestion {
      position: absolute;
      bottom: 30px;
      right: 76px;
      width: 223px;
      padding-bottom: 63px;
      background-image: url("../assets/images/PMG100/arrow.svg");
      background-position: left calc(50% + 25px) bottom;
      background-size: auto 53px;
      background-repeat: no-repeat;
      font: $font-roboto-400;
      color: rgba($gray6, 0.87);
      text-align: center;
      letter-spacing: 0.15px;

      @include font-size(12, 18);
    }
  }
}
</style>
