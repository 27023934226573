<template>
  <div class="purchase-info__card">
    <div id="popover">
      <ion-popover class="disable-click" :is-open="isPopOver" :dismiss-on-select="true"
                   @ionPopoverDidDismiss="closePopOver" trigger="popover" side="top" alignment="center">
        <ion-content class="ion-padding" style="pointer-events: auto;" scroll-y="false">
          利用ポイントがリセットされました
        </ion-content>
      </ion-popover>
    </div>
    <div id="purchase_info_card_header" class="purchase-info__card__header">
      <div>
        <div class="title">Pontaポイント</div>
        <div class="point">
          <span v-if="pointBalance > 0">
            <span class="available-points"
            >{{ pointBalanceDisplay }}(円分)</span>
            <span v-if="this.pointBalance > 0">利用可能</span>
          </span>
          <span v-else>
            -
          </span>
        </div>
      </div>
      <div>
        <span class="point-status" v-show="selectedPointMenu === '1' || selectedPointMenu === '2'">{{
            usePointsDisplay
          }}P 利用</span>
        <span class="point-status__error" v-if="pointBalance === null"
              @click="errorUpdate()">保有ポイントを更新</span>
        <span class="point-status" v-else-if="selectedPointMenu === '3'">利用しない</span>

        <span class="change-btn" @click="openCartPointSelectModal()" v-if="enablePointSelect">変更</span>
      </div>

    </div>
    <div class="purchase-info__card__footer">
      <span class="title">今回お支払い額</span>
      <span class="price">{{ purchasesPriceDisplay }}</span>
    </div>
  </div>

  <CartPointSelectModal
    ref="cartPointSelectModal"
    :price="price"
    :point-balance="pointBalance"
    :selected-point-menu="selectedPointMenu"
    @update:selectedPointMenu="updateSelectedPointMenu"
    @apply:usePoints="applyUsePoints"
  ></CartPointSelectModal>

</template>

<script>
import store from '@/store';
import CartPointSelectModal from "@/components/modal/CartPointSelectModal";
import _ from "lodash";
import {getPontaPointApi} from "@/plugins/ApiHelper";
import {mapGetters} from "vuex";
import {POINT_SELECT_MENUS} from "@/constants/cart";
import {IonContent, IonPopover} from '@ionic/vue';

export default {
  name: "CartPointBox",
  components: {
    CartPointSelectModal,
    IonContent,
    IonPopover
  },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    usePoints: {
      type: Number,
      default: 0,
    },
    isScroll: {
      type: Boolean,
      default: false
    },
    scrollTop: {
      type: Function,
      default: null
    },
  },
  data: () => ({
    isOnlyPoints: false,
    pointBalance: null, // ポイント残高
    selectedPointMenu: POINT_SELECT_MENUS.NO_USE_POINT,
    isPopOver: false
  }),
  emits: ["apply:usePoints", "clearPointCondition"],
  computed: {
    ...mapGetters(["lid", "isAuIdAuthorization", "getPointCondition"]),
    purchasesPrice() {
      if (!this.usePoints) {
        return this.price
      } else {
        return Number(this.price) - Number(this.usePoints)
      }
    },
    purchasesPriceDisplay() {
      if (!this.purchasesPrice) {
        return "¥0"
      } else {
        return `¥${Number(this.purchasesPrice).toLocaleString()}`
      }
    },
    pointBalanceDisplay() {
      if (this.points === null) {
        return "ー"
      } else {
        return `${Number(this.pointBalance).toLocaleString()}P`
      }
    },
    pointDisplay() {
      if (this.usedPoints === null) {
        return "ー"
      } else if (this.usedPoints === 0) {
        return "利用しない"
      } else {
        return `${Number(this.usedPoints).toLocaleString()}P 利用`
      }
    },
    priceDisplay() {
      if (this.price === null) {
        return "ー"
      } else {
        return `¥${Number(this.price).toLocaleString()}`
      }
    },
    usePointsDisplay() {
      if (this.usePoints === null) {
        return "ー"
      } else {
        return `${Number(this.usePoints).toLocaleString()}`
      }
    },
    enablePointSelect() {
      return this.pointBalance > 0;
    },
  },
  watch: {
    pointBalance(newValue) {
      // 保有ポイントの変更があった際に実行
      if (newValue) {
        this.pointCheck()
      }
    },
    $route() {
      if (this.$route.name !== "PMG700") {
        this.closePopOver()
      }
    },
    isScroll() {
      if (this.isPopOver) {
        this.closePopOver()
      }
    }
  },
  mounted() {
    console.log('CartPointBox mounted')
    console.log('this.$route.name', this.$route.name)
    // Pontaポイント取得
    if(this.$route.name === "PMG700") {
      setTimeout(() => {
        this.getPoint();
      }, 1000);
    }

  },
  methods: {
    setInputPoints(inputPoints) {
      const ref = this.$refs.cartPointSelectModal;
      if (ref) {
        ref.setInputPoints(inputPoints);
      }
    },
    clearInputPoints() {
      const ref = this.$refs.cartPointSelectModal;
      if (ref) {
        ref.clearInputPoints();
      }
    },
    initSelectedPointMenu() {
      this.selectedPointMenu = POINT_SELECT_MENUS.NO_USE_POINT;
    },
    updateSelectedPointMenu(selectedPointMenu) {
      this.selectedPointMenu = selectedPointMenu;
    },
    applyUsePoints(usePoints, isOnlyPoints = false) {
      this.$emit("apply:usePoints", usePoints, isOnlyPoints);
    },
    openCartPointSelectModal() {
      this.closePopOver()
      this.$refs.cartPointSelectModal.openModal();
    },
    getPointBalance() {
      return this.pointBalance;
    },
    async getPoint() {
      this.scrollTop()
      if (_.isNil(this.lid) || !this.isAuIdAuthorization) {
        this.point = "";
        return;
      }
      const param = {
        lid: this.lid,
      };
      this.point = "...";

      this.pointBalance = await getPontaPointApi(param)
        .then((res) => {
          if (this.selectedPointMenu == POINT_SELECT_MENUS.ERROR) {
            this.updateSelectedPointMenu(POINT_SELECT_MENUS.NO_USE_POINT);
          }
          return _.isNumber(res.point) ? Number(res.point) : null;
        })
        .catch((error) => {
          console.error(error)
          // エラー用の表示に変更
          this.updateSelectedPointMenu(POINT_SELECT_MENUS.ERROR)
          // ポイント使用情報がある場合
          if (this.getPointCondition && this.isPopOver === false) {
            // ポイント情報リセット
            this.isPopOver = true
            this.$emit("clearPointCondition");
          }
          return 0;
        });
    },
    pointCheck() {
      console.log('pointCheck', this.price, this.pointBalance, this.getPointCondition)
      /**
       * 前回の設定を確認して、再表示時の設定を行う。
       */
      const pointCondition = this.getPointCondition
      if (!pointCondition) {
        return
      }

      // 前回設定した使用するポイント数より保有ポイント数が減った場合
      if (pointCondition.usePoints > this.pointBalance) {
        // 設定クリア
        return this.resetPointCondition()
      }

      if (pointCondition.isOnlyPoints) {
        /**
         * 前回の設定時に全額ポイント決済を選択している場合
         */

        if (this.price <= this.pointBalance && pointCondition.usePoints <= this.pointBalance) {
          // ポイントで全額支払うに変わる
          return this.changePointCondition('all', pointCondition.usePoints, pointCondition.isOnlyPoints)
        }

      } else {
        /**
         * 前回の設定時に一部ポイント利用を選択している場合
         */
        if (this.price <= this.pointBalance && pointCondition.usePoints === this.price && pointCondition.usePoints > 0) {
          // ポイントで全額支払うに変わる
          return this.changePointCondition('all', pointCondition.usePoints)
        }

        if (this.price > this.pointBalance && pointCondition.usePoints === this.pointBalance && pointCondition.usePoints > 0) {
          // 一部→全額に変更する。
          return this.changePointCondition('all', pointCondition.usePoints, pointCondition.isOnlyPoints)
        }

        return this.changePointCondition('part', pointCondition.usePoints, pointCondition.isOnlyPoints)
      }

    },
    changePointCondition(type, usePoints = 0, isOnlyPoints = false) {
      console.log('changePointCondition', type, usePoints)
      if (type === 'all') {
        // 全額ポイント決済に変更
        this.updateSelectedPointMenu(POINT_SELECT_MENUS.USE_ALL_POINTS)
        this.applyUsePoints(usePoints)
        const setPointCondition = {
          "isOnlyPoints": isOnlyPoints,
          "usePoints": Number(usePoints)
        }
        console.log('setPointCondition', setPointCondition)
        store.commit('setPointCondition', setPointCondition);
      } else if (type === 'part') {
        // 一部ポイント利用に変更する。
        this.updateSelectedPointMenu(POINT_SELECT_MENUS.USE_PART_OF_POINTS)
        this.applyUsePoints(usePoints)
        const setPointCondition = {
          "isOnlyPoints": false,
          "usePoints": Number(usePoints)
        }
        console.log('setPointCondition', setPointCondition)
        store.commit('setPointCondition', setPointCondition);
        this.setInputPoints(usePoints)
      }
    },
    resetPointCondition() {
      if (this.isPopOver === false) {
        this.isPopOver = true
      }
      this.$emit("clearPointCondition");
    },
    async errorUpdate() {
      this.closePopOver()
      await this.getPoint()
    },
    closePopOver() {
      this.isPopOver = false
    }
  },
};


</script>

<style scoped lang="scss">
.purchase-info {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: #fff;
  margin: 0 1rem 1rem 1rem;
  margin-top: 1rem !important;

  &__header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    &__text {
      font: $font-noto-sans-jp-400;
      @include font-size(12, 19.2);
      color: $gray10;
    }
  }

  &__gift,
  &__card {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    background-color: $white1;
    margin: 16px 0 1rem 0;

    &__header {
      .title {
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
        //color: $gray10;
      }

      .point {
        font-size: 10px;
        color: #666666;
      }

      .point-status {

        &__error {
          color: #886C42;
          cursor: pointer;
        }
      }

      .change-btn {
        color: #886C42;
        cursor: pointer;
        margin-left: 12px;
      }
    }

    .available-points {
      color: #EA5404;
    }
  }

  &__card,
  &__gift {
    &__header {
      padding: 1rem 1rem 0 1rem;
    }

    &__content {
      padding: 1rem;
    }


    &__header, &__footer {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        color: var(--ion-color-dark);
        font: $font-noto-sans-jp-400;
        @include font-size(14, 22.4);
      }

      .price {
        color: $black6;
        font: $font-roboto-700;
        @include font-size(22, 28.6);
      }
    }

    &__footer {
      padding: 13.5px 16px;

      .title, .label {
        font-weight: bold;
        font-size: 14px;
      }


      .price {
        color: $black6;
        font: $font-roboto-700;
        @include font-size(22, 28.6);
      }
    }
  }

  &__url {
    margin: 0 1rem;
  }
}

.disable-click {
  pointer-events: none;
}

ion-popover {
  --backdrop-opacity: 0;
  --background: black;
  // サイズ調整する
  --width: max-content;
  cursor: pointer;
  --offset-y: 15px;

  ion-content {
    --background: black;
    --color: white;
    font: $font-roboto-400;

  }
}

ion-popover::part(arrow)::after {
  border-radius: 0;
}

ion-popover::part(content) {
  border: none;
}

.ion-padding {
  --ion-padding: 15px
}


</style>

