<template>
  <div v-show="isShow">
    <transition name="fade">
      <div  class="loading">
        <div class="loading__spinner"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LoadingMini',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    spinnerSize: {
      type: String,
      default: 'medium',
    }
  },
  computed: {
    size() {
      let s = '';
      switch(this.spinnerSize) {
        case 'smail':
          s = '30px';
          break
        case 'medium':
          s = '44px';
          break
        case 'large':
          s = '70px';
          break
        default:
          s = this.spinnerSize;
      }
      return s
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  padding: 32px 0;
  // position: fixed;
  // top: 0;
  // left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // background: $white1;
  z-index: 10000;

  &.fade-enter-active {
    animation: fade-in 0ms;
  }

  &.fade-leave-active {
    animation: fade-in 200ms reverse;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__spinner {
    width: v-bind(size);
    height: v-bind(size);
    border-radius: 50%;
    border: solid 7px $gold1;
    border-right-color: transparent;
    animation: rotate 1.1s infinite linear;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
