<template>
  <div class="form-block">
    <div v-if="required || label || subLabel" class="form-block__label">
      <div class="label">
        {{ label }}
        <span v-if="required" class="label__required">必須</span>
      </div>
      <div class="sub-label">{{ subLabel }}</div>
    </div>
    <div class="form-block__input">
      <slot/>
    </div>
    <div v-if="help" class="form-block__help">{{ help }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormBlock',
  props: {
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
.form-block {
  font: $font-inter-400;

  @include font-size(14, 20);

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .label {
      display: flex;
      color: $black3;

      &__required {
        margin-left: 0.3em;
        color: $red1;
      }
    }

    .sub-label {
      color: $gray4;
    }
  }

  &__help {
    margin-top: 8px;
    color: $gray4;
  }
}
</style>
