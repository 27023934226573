<template>
  <div class="gift-history" @click="openDetail()">
    <div class="header">
      <span class="history-date">購入日 : {{ historyDateDislay }}</span>
      <span class="payment-info-no">注文番号 : {{ dispOrderNumber }}</span>
    </div>

    <div  v-if="history.isForMyself">
      <div class="card">
        <GiftInfo
          :gift-name="history.giftName"
          :brand-name="history.brandName"
          :expiry-date="history.expiryDate"
          :price="history.price"
          :gift-image="giftImage"
          :is-show-price="false"
          :is-tax-display="history.isTaxDisplay"
        />
      </div>
    </div>
    <div v-else>
      <div class="gift-info" >
        <GiftInfo
          :gift-name="history.giftName"
          :brand-name="history.brandName"
          :expiry-date="history.expiryDate"
          :price="history.price"
          :gift-image="giftImage"
          :is-show-price="false"
          :is-tax-display="history.isTaxDisplay"
        />
      </div>
      <div class="card">
        <CardViewMini
          :category-id="history.designCategoryId"
          :main-receiver-name="history.mainReceiverName"
          :op-message="history.opMessage"
          :design-name="history.categoryName"
          :thumbnail-url="history.thumbnailUrl"
          :is-show-card-replace="false"
        />
      </div>
    </div>


  </div>
</template>

<script>
import {DateTime} from "luxon";
import {getGiftLoadingImage, getGiftNoImage, getItemImageUrl} from "@/plugins/GiftHelper";
import GiftInfo from "@/components/gift/GiftInfo";
import CardViewMini from "@/components/messageCard/CardViewMini";

export default {
  name: "GiftHistory",
  components: {
    GiftInfo,
    CardViewMini,
  },
  emits: ["click:giftHistory"],
  props: {
    history: {
      type: Object,
      default: () => ({
        cardType: null,
        historyId: 0,
        giftId: 0,
        giftImage: getGiftLoadingImage(),
        giftName: "gift name",
        purchasesInfoId: null,
        giftUrl: null,
        price: 0,
        taxText: "税込",
        brandId: 0,
        brandName: "brand name",
        cardOriginId: null,
        categoryName: "card category",
        createdCardId: 0,
        imageText: "card image text",
        designCategoryId: null,
        designName: null,
        mainCreatorName: "card creator name",
        mainReceiverName: "card receiver name",
        opMessage: "card message",
        thumbnailUrl: "",
        phoneNumber: null,
        paymentInfoNo: null,
        historyDate: null,
        historyCreatedAt: null,
        purchaseDatetime: null,
        isForMyself: false,
        orderNumber: null
      }),
    },
  },
  data: () => ({
    priceDisplay: 0,
    historyDateDislay: null,
    giftImgSrc: getGiftLoadingImage(),
    cardImgSrc: getGiftLoadingImage(),
    cardMessage: null,
    cardCategoryName: null,
  }),
  computed: {
    dispOrderNumber() {
      if (!this.history.orderNumber) {
        return "";
      }
      return this.history.orderNumber;
    },
    giftImage() {
      if (!this.history.giftImage) {
        return ""
      }
      return getItemImageUrl(this.history.giftImage);
    },
  },
  mounted() {
    if (this.history.price) {
      this.priceDisplay = Number(this.history.price).toLocaleString();
    }
    if (this.history.historyDate) {
      this.historyDateDislay = DateTime.fromSQL(
        this.history.historyDate
      ).toFormat("yyyy/M/d");
    }
    if (this.history.opMessage) {
      this.cardMessage = this.history.opMessage.replace(/\r?\n/g, "");
    }
    if (this.history.imageText && this.history.categoryName) {
      this.cardCategoryName =
        this.history.imageText + " | " + this.history.categoryName;
    }
  },
  methods: {
    loadGiftImage() {
      this.giftImgSrc = this.history.giftImage;
    },
    loadCardImage() {
      this.cardImgSrc = this.history.thumbnailUrl;
    },
    errorImage(e) {
      e.target.src = getGiftNoImage();
    },
    isCreated() {
      return this.history.cardType === "create";
    },
    isReceived() {
      return this.history.cardType === "receive";
    },
    openDetail() {
      if (this.history.purchasesInfoId) {
        this.$emit("click:giftHistory", this.history.purchasesInfoId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gift-history {
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  background: $white1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $gray11;

    .history-date,
    .payment-info-no {
      color: $gray10;
      font: $font-roboto-400;

      @include font-size(12, 19.2);
    }
  }

  .gift-info,
  .card {
    display: flex;
    background: $white1;
    padding: 1rem;
  }

  .gift-info {
    border-bottom: 1px solid $gray11;
  }

  .card {
    border-radius: 0 0 8px 8px;
  }
}
</style>
