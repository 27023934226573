import SpecialFeatureSwi001 from '@/components/specialFeature/SpecialFeature001';
import SpecialFeatureSwi002 from '@/components/specialFeature/SpecialFeature002';
import SpecialFeatureSwi006 from '@/components/specialFeature/SpecialFeature006';
/*
商品購入フローで使用しているルート名
 */
export const PURCHASE_FLOW_ROUTE_NAMES = [
  'PMG720', // カードタイプ選択
  'PMG330', // 通常カード入力
  'PMG300', // カード一覧
  'PMG310', // カードサンプル
  'PMG320', // カード編集
];

/*
サイドメニューページのurl, title
 */
export const SIDE_MENU_PAGE = [
  // サイドメニュー
  {
    name: 'ご利用ガイド',
    url: 'guide',
  },
  {
    name: 'よくある質問・お問い合わせ',
    url: 'faq',
  },
  {
    name: 'プラメギフトとは',
    url: 'about',
  },
  {
    name: '利用規約',
    url: 'rules',
  },
  {
    name: '特定商取引法に基づく表記',
    url: 'commerce',
  }
]
/*
特集ページのurl, title
 */
export const SPECIAL_FEATURE_PAGE = [
  { component: SpecialFeatureSwi001, name: '初心者おすすめギフト特集', page: 1 },
  { component: SpecialFeatureSwi002, name: 'シーン別ギフト特集', page: 2 },
  { component: SpecialFeatureSwi006, name: '同僚・仕事仲間に贈るギフト特集2024', page: 6 },

]

export const ALWAYS_SHOW_HEADER_PAGE = [
  'PMG600', // ホーム
  'PMG660', // ギフト一覧
  'PMG800', // 購入履歴
  'PMG700', // 買い物カゴ
  'PMG330', // カード作成(通常)
  'PMG320', // カード作成(リッチ)
  'PMG300', // カード一覧
  'PMG310', // カードプレビュー
  'PMG720', // カードを選ぶ
]

