<template>
  <Layout
    :title="title"
    :showMenu="false"
    :showBack="false"
    :show-custom-back="true"
    :custom-back-func="backView"
    backLabel="戻る"
  >
    <About v-if="page === 'about'" />
    <Commerce v-else-if="page === 'commerce'" />
    <Faq v-else-if="page === 'faq'" />
    <Guide v-else-if="page === 'guide'" />
    <Rules v-else-if="page === 'rules'" />
    <div v-else class="content">
      <iframe class="content__iframe" :src="url"></iframe>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/Layout';
import About from '@/components/static/About';
import Commerce from '@/components/static/Commerce';
import Faq from '@/components/static/Faq';
import Guide from '@/components/static/Guide';
import Rules from '@/components/static/Rules';
import { emitter } from '@/eventBus';
import { queryStringDelete } from '@/plugins/Utils'
import { SIDE_MENU_PAGE} from '@/constants/router'

export default {
  name: 'PMG640',
  components: {
    Layout,
    About,
    Commerce,
    Faq,
    Guide,
    Rules,
  },
  props: {},
  data() {
    return {
      page: null,
    };
  },
  computed: {
    title() {
      if (this.$route.params.title) {
        return this.$route.params.title;  
      }
      const foundItem = SIDE_MENU_PAGE.find(item => item.url === this.page);
      if (foundItem){
        return foundItem.name;
      }
    },
    url() {
      return this.$route.params.url;
    },
  },
  watch: {},
  mounted() {
    this.page = queryStringDelete(this.$route.params.url);
    emitter.on('showStaticPage', (params) => this.showStaticPage(params));
  },
  methods: {
    backView() {
      this.$router.go(-1);
    },
    showStaticPage(params) {
      this.$router.push({ name: 'PMG640', params: params.params, query: params.query });
    },
  },
};
</script>

<style scoped lang="scss">
.button-back {
  font: $font-roboto-400;
  font-size: 14px;
  line-height: 18.2px;
  color: $gold2;
  display: flex;
  align-items: center;
}
ion-toolbar {
  height: 74px;
  display: flex;
  align-items: flex-end;
  ion-title {
    font: $font-roboto-400;
    font-size: 16px;
    line-height: 22.4px;
    color: $black6;
  }
}

.content {
  width: 100%;
  height: 100%;
  &__iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
