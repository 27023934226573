<template>
  <div class="card-view">
    <div
      class="card-view__content"
      v-if="categoryId && categoryId != 'NORMAL'"
    >
      <div class="card-view__content__image">
        <img :src="thumbnailUrl" />
      </div>
      <div class="card-view__content__info">
        <span class="receiver"> {{ mainReceiverName }} </span>
        <span class="message">
          {{ opMessage }}
        </span>
        <span class="design"> リッチカード・{{ designName }} </span>
        <!-- カードを選び直すボタンをPGM700から移動 -->
        <span v-if="isShowCardReplace" class="card-replace" @click="openCardReplaceModal">カードを選び直す</span>
      </div>
    </div>
    <div
      class="card-view__content"
      v-else
    >
      <div class="card-view__content__image">
        <img :src="normalCardImgUrl" />
      </div>
      <div class="card-view__content__info">
        <span class="normal-text">通常カード</span>
        <span v-if="isShowCardReplace" class="card-replace" @click="openCardReplaceModal">カードを選び直す</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardViewMini",
  components: {},
  props: {
    categoryId: {
      type: String,
      default: null,
    },
    mainReceiverName: {
      type: String,
      default: null,
    },
    opMessage: {
      type: String,
      default: null,
    },
    designName: {
      type: String,
      default: null,
    },
    thumbnailUrl: {
      type: String,
      default: null,
    },
    isShowCardReplace: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
  }),
  computed: {
    normalCardImgUrl() {
      return require("@/assets/images/normal_card_20240222.png");
    },
  },
  watch: {},
  mounted() {},
  emits: [
    'click:replaceCard',
  ],
  methods: {
    openCardReplaceModal() {
      this.$emit('click:replaceCard');
    },
  },
};
</script>
<style scoped lang="scss">
.card-view {
  width: 100%;
  &__content {
    display: flex;
    &__image,
      img {
        width: 112px;
        height: 112px;
        border-radius: 6px;
        background-size: 100%;
        background-position: center;
        flex-shrink: 0;
      }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font: $font-noto-sans-jp-400;
      padding-left: 1rem;
      min-width: 0;
      flex: 1;
      .receiver {
        @include font-size(14, 22.4);
        color: $black6;
      }
      .message {
        @include font-size(12, 19.2);
        color: $black6;
      }
      .design {
        @include font-size(10, 16);
        color: $gray10;
      }
      .normal-text {
        @include font-size(14, 22.4);
        color: $black6;
      }
      .card-replace {
        cursor: pointer;
        margin-top: 16px;
        font: $font-roboto-400;
        @include font-size(12, 19.2);
        color: $gold2;
      }
      .receiver,
      .message,
      .design {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
