<template>
  <div class="plus-message">
    <div class="plus-message__step">
      <div class="plus-message__step__header">
        <span class="step">Step</span>
        <span class="number">1</span>
        <span class="label">＋メッセージアプリをダウンロード</span>
      </div>
      <ion-grid class="plus-message__step__content">
        <ion-row fixed="true" class="badges">
          <ion-col size="6" class="appstore">
            <a
              href="https://apps.apple.com/app/apple-store/id1358576433?pt=119001307&ct=au_qcamp_202309&mt=8"
              target="_blank"
              ref="appStore"
              @click="clickAppStore"
            >
              <img
                alt="App Store からダウンロード"
                src="@/assets/images/btn_appstore.png"
              />
            </a>
          </ion-col>

          <ion-col size="6" class="googleplay">
            <a
              href="https://www.au.com/mobile/service/plus-message/download/"
              target="_blank"
              ref="googlePlay"
              @click="clickGooglePlay"
              ><img
                alt="Google Play で手に入れよう"
                src="@/assets/images/btn_googleplay.png"
            /></a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="plus-message__step">
      <div class="plus-message__step__header">
        <span class="step">Step</span>
        <span class="number">2</span>
        <span class="label">公式アカウントをフォロー</span>
      </div>
      <div class="plus-message__step__content">
        <div class="badges">
          <PlusMessageImageBadges
            :sendTrClickEvent="sendTrClickEvent"
            :GALabel1="GALabel1"
          ></PlusMessageImageBadges>
        </div>
        <div>
          <span class="comment"
            >※＋メッセージの最新バージョンをダウンロードしてからタップしてください</span
          >
        </div>
      </div>
      <div class="plus-message__step__LinkDetail">
        <span @click="clickAbout()" onclick="trEventClick(this,'プラメギフトとは','適用条件・詳細はこちら','');">適用条件・詳細はこちら</span>
      </div>

    </div>
  </div>
</template>

<script>
import PlusMessageImageBadges from "@/components/etc/PlusMessageImageBadges";
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: "PlusMessageGuide",
  components: {
    PlusMessageImageBadges,
  },
  props: {
    sendTrClickEvent: {
      type: Boolean,
      default: false,
    },
    GALabel1: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    officalLink: process.env.VUE_APP_CHAT_BOT_DEEP_LINK,
  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    clickAppStore(){
      if(!this.sendTrClickEvent)
        return;

      gtmSendClick(this.$refs['appStore'],this.GALabel1,'App Store','');
    },
    clickGooglePlay(){
      if(!this.sendTrClickEvent)
        return;

      gtmSendClick(this.$refs['googlePlay'],this.GALabel1,'Google Play','');
    },
    clickAbout() {
      this.$router.push('/gifts/static/about#follow');
    }

  },
};
</script>
<style scoped lang="scss">
.plus-message {
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  max-width: 303px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0.5rem 0;
    .title {
      font: $font-roboto-700;
      @include font-size(16, 22.4);
    }
    .image {
      margin: 1rem 0;
    }
    .text {
      font: $font-noto-sans-jp-400;
      @include font-size(12, 19.2);
    }
  }
  &__step {
    &:not(:first-of-type) {
      margin: 1.25rem 0 0 0;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      .step {
        font: $font-roboto-700;
        @include font-size(16, 22.4);
        color: $orange1;
      }
      .number {
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        font: $font-roboto-700;
        color: $white1;
        background-color: $orange1;
        border-radius: 0.6rem;
        text-align: center;
        margin: 0 8px 0 4px;
      }
      .label {
        font: $font-noto-sans-jp-700;
        @include font-size(14, 22.4);
      }
    }
    &__content {
      padding: 0;
      .badges {
        .appstore {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 4.5px 0 0;

          img {
            width: 280px;
          }
        }
        .googleplay {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 0 0 4.5px;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      .comment {
        font: $font-noto-sans-jp-400;
        @include font-size(12, 19.2);
        color: $gray10;
        margin-top: 0.5rem;
        display: inline-block;
      }
    }
    &__LinkDetail {
      text-align: center;
      @include font-size(16, 20.8);
      margin-top: 20px;
      & > span {
        color: $orange1;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
