<template>
  <div class="card">
    <ion-card>
      <div class="card__image">
        <img alt="ギフト" src="@/assets/images/normal_card_header_20240222.png" />
        <div class="card__image__message" v-if="isForMyself">
          <span>for myself</span>
        </div>

        <div class="card__image__message" v-else>
          <span>{{ senderName }}さんからの</span>
          <span>ギフトです</span>
        </div>
      </div>

      <ion-card-content class="ion-no-padding card__content">
        <div class="card__content__item">
          <div class="gift-item">
            <GiftInfo
              :gift-name="giftName"
              :brand-name="brandName"
              :expiry-date="expiryDate"
              :price="price"
              :gift-image="imgSrc"
              :is-show-price="isShowPrice"
              :is-tax-display="isTaxDisplay"
            ></GiftInfo>
          </div>
          <Button
            class="button-use"
            label="使う"
            type="fill"
            :disabled="giftUrl == null"
            @click="openGiftUrl()"
          />
        </div>
        <div v-if="isLinkEnabled" class="card__content__footer" @click="goPlame()">
          <img class="icon" src="@/assets/images/icon/gift_icon.png" />
          <span class="label"> プラメギフトについて </span>
        </div>
        <div v-else class="card__content__footer">
          <img class="icon" src="@/assets/images/icon/gift_icon.png" />
          <span class="label"> プラメギフトについて </span>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import GiftInfo from "@/components/gift/GiftInfo";
import Button from "@/components/button/Button";
import { getItemImageUrl } from "@/plugins/GiftHelper";
import { gtmDataLayerPush } from "@/plugins/GtmHelper";
import { getUrl } from "@/plugins/UrlHelper";

export default {
  name: "NormalGift",
  components: {
    IonCard,
    IonCardContent,
    GiftInfo,
    Button,
  },
  props: {
    senderName: {
      type: String,
      default: "",
    },
    giftUrl: {
      type: String,
      default: null,
    },
    giftName: {
      type: String,
      default: "",
    },
    brandName: {
      type: String,
      default: "",
    },
    expiryDate: {
      type: String,
      default: "",
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    giftImage: {
      type: String,
      default: "",
    },
    isLinkEnabled: {
      type: Boolean,
      default: false,
    },
    isShowPrice: {
      type: Boolean,
      default: true,
    },
    isTaxDisplay: {
      type: Boolean,
      default: true,
    },
    isForMyself: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    imgSrc() {
      if (this.giftImage && this.giftImage.indexOf("http") >= 0) {
        return this.giftImage;
      }
      return getItemImageUrl(this.giftImage);
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openGiftUrl() {
      window.open(this.giftUrl);
    },
    goPlame() {
      const url = 'about';
      const title = "プラメギフトとは";
      const virtualPageUrl = getUrl(`/gifts/static/${url}&${title}`);
      gtmDataLayerPush(virtualPageUrl, title);
      this.$router.push({
        name: "PMG640",
        params: {
          url: url,
          title: title,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  .ios {
    margin-inline: 0px;
  }
  &__image {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    &__message {
      font: $font-roboto-700;
      font-size: 24px;
      line-height: 36px;
      color: $white1;
      position: absolute;
      bottom: 8px;
      left: 8px;
      display: flex;
      flex-direction: column;
    }
  }
  &__content {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $gray2;
      padding-top: 1rem;

      .gift-item {
        padding: 0 1rem;
        width: 100%;
      }

      .button-use {
        margin: 0.5rem 0 1rem;
        width: 120px;
        height: 48px;
        border-radius:24px;
      }

      :deep(.button) {
        position: inherit;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      .icon {
        width: 20px;
        height: 20px;
      }
      .label {
        font: $font-noto-sans-jp-400;
        font-size: 14px;
        line-height: 22.4px;
        margin-left: 8px;
      }
    }
  }
}
</style>
