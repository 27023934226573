<template>
  <div class="form-file">
    <div v-show="!value" class="form-file__input">
      <label class="button" :class="type">
        <input ref="input" class="button__input" type="file" :accept="accept" @change="changeFile">
      </label>
    </div>
    <div v-if="value" class="form-file__preview">
      <img v-if="type === 'image'" class="image" :src="src" alt="preview">
      <video v-if="type === 'video'" class="video" :src="src" controls playsinline muted autoplay @loadeddata="onLoadeddata"/>
      <button class="reset" @click="resetFile"/>
    </div>

    <AlertModal ref="alert" :messages="alertMessages"/>
  </div>
</template>

<script>
import AlertModal from '@/components/modal/AlertModal'

import heic2any from 'heic2any';

import { ValidationFileSize, ValidationFileType } from '@/plugins/Constants'

export default {
  name: 'FormFile',
  components: {
    AlertModal,
  },
  props: {
    modelValue: {
      type: File,
    },
    type: {
      type: String,
      default: 'image', // image or video
    },
  },
  emits: ['update:modelValue'],
  data: () => ({
    alertMessages: [],
  }),
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    accept() {
      return ValidationFileType[this.type].type.join(',');
    },
    src() {
      return this.value ? URL.createObjectURL(this.value) : null;
    },
  },
  methods: {
    async changeFile (e) {
      const file = e.target.files[0];
      let value = null;
      if (file?.type === 'image/heic') {
        value = await this.convertHeic(file);
      } else {
        value = file || null;
      }

      const checkFileSize = this.checkFileSize(value);
      const checkFileType = this.checkFileType(value);
      if (checkFileSize && checkFileType) {
        this.value = value;
      } else {
        let messages = [];
        if (!checkFileSize) messages.push(ValidationFileSize[this.type].message);
        if (!checkFileType) messages.push(ValidationFileType[this.type].message);

        this.openAlertModal(messages);
        this.resetFile();
      }
    },
    resetFile() {
      this.value = null;
      this.$refs.input.value = null;
    },
    checkFileSize(value) {
      return (value?.size | 0) <= ValidationFileSize[this.type].size;
    },
    checkFileType(value) {
      return ValidationFileType[this.type].type.includes(value.type);
    },
    async convertHeic (file) {
      const blob = new Blob([file], { type: file.type });
      try {
        const conversionBlob = await heic2any({ blob, toType: 'image/jpeg' });
        const fileName = file.name.slice(0, file.name.lastIndexOf('.')) || file.name;
        return new File([conversionBlob], fileName + '.jpeg', { type: 'image/jpeg' });
      } catch {
        return null;
      }
    },
    openAlertModal(messages) {
      this.alertMessages = messages;
      this.$refs.alert.openModal();
    },
    onLoadeddata(e) {
      e.target.pause();
    },
  },
}
</script>

<style scoped lang="scss">
.form-file {
  width: 100%;

  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    padding: 10px;
    background: $gray5;
    border: solid 10px $white1;
    box-shadow: 0 0 10px $shadow1;

    .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      padding: 0 20px;
      background: $gold1;
      box-shadow: 0 2px 10px $shadow1;
      border-radius: 22px;
      cursor: pointer;
      overflow: hidden;

      &:hover,
      &:focus,
      &:active {
        background: $gold2;
      }

      &::before {
        width: 18px;
        height: 18px;
        margin: 0 9px 0 3px;
      }

      &::after {
        font: $font-roboto-400;
        color: $white1;

        @include font-size(16, 20);
      }

      &.image {
        &::before {
          content: url("../../assets/images/icon/image.svg");
        }

        &::after {
          content: "写真を選択";
        }
      }

      &.video {
        &::before {
          content: url("../../assets/images/icon/video.svg");
        }

        &::after {
          content: "動画を選択";
        }
      }

      &__input {
        display: none;
      }
    }
  }

  &__preview {
    position: relative;
    width: 100%;

    .image,
    .video {
      width: 100%;
      vertical-align: middle;
    }

    .reset {
      position: absolute;
      top: -16px;
      right: -12px;
      width: 32px;
      height: 32px;
      background: $black1;
      border-radius: 50%;
      filter: drop-shadow(0 2px 4px $shadow2) drop-shadow(0 4px 5px $shadow3) drop-shadow(0 1px 10px $shadow4);

      &::before {
        content: url("../../assets/images/icon/close_fff.svg");
        width: 8px;
        height: 8px;
      }
    }
  }
}
</style>
