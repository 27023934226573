<template>
  <main>
    <h1>
      <img
        class="img_auto_width"
        src="@/assets/images/special-feature-001/eye_catching.png"
        width="375"
        height="375"
        alt="プラメギフト初めての方におすすめ！感謝の気持ちを伝えよう~自分へのご褒美にも~"
      />
    </h1>
    <p class="sub_text">
       「プラメギフト」なら気軽に贈れる・使える！
    </p>
    <div class="introduction">
      <h2 class="heading">簡単・便利なソーシャルギフト</h2>
      <ul class="decoration_card">
        <li>
          <img
            src="@/assets/images/special-feature-001/Illustration_gift.svg"
            width="60"
            height="60"
            alt=""
          />ギフト受け取り<br />URLを相手に<br />
          送るだけ！
        </li>
        <li>
          <img
            src="@/assets/images/special-feature-001/Illustration_hand.svg"
            width="60"
            height="60"
            alt=""
          />ちょっとした<br />気持ちを<br />
          すぐに贈れる！
        </li>
        <li>
          <img
            src="@/assets/images/special-feature-001/Illustration_home.svg"
            width="60"
            height="60"
            alt=""
          />住所を<br />知らなくても<br />
          贈れる！
        </li>
      </ul>
    </div>

    <div class="pick_up_gifts">
      <h2 class="heading">ピックアップギフト</h2>
      <h3>おすすめの有名・定番ブランド</h3>
      <div class="slide">
        <div class="swiper mySwiper-feature001" ref="swiperContainer">
          <div class="swiper-wrapper">

            <div class="swiper-slide card">
              <a
                href="javascript:void(0);"
                ref="pickUpGift02"
                @click="() => { clickEvent('pickUpGift02','ピックアップギフト_02','サーティワン アイスクリーム 500円ギフト券_22_500');
                                ecommerceSelectPromotionPush(22, 'サーティワン アイスクリーム 500円ギフト券', 'サーティワン アイスクリーム', 500);
                                openDetail(22);
                }"
              >
                <img
                  src="@/assets/images/special-feature-001/card/pickup/pickup_31ice_002.png"
                  width="240"
                  height="240"
                  alt="サーティワン アイスクリーム"
                />
                <div class="card_text_container">
                  <p class="card_brand_name">サーティワン アイスクリーム</p>
                  <p class="card_title">
                    サーティワン アイスクリーム 500円ギフト券
                  </p>
                  <p class="card_price">¥500</p>
                </div>
              </a>
            </div>

            <div class="swiper-slide card">
              <a
                href="javascript:void(0);"
                ref="pickUpGift03"
                @click="() => { clickEvent('pickUpGift03','ピックアップギフト_03','タリーズデジタルギフト(500円)_43_500');
                                ecommerceSelectPromotionPush(43, 'タリーズデジタルギフト(500円)', 'TULLY\'S COFFEE', 500);
                                openDetail(43);
                }"
              >
                <img
                  src="@/assets/images/special-feature-001/card/pickup/pickup_tullys.jpeg"
                  width="240"
                  height="240"
                  alt="タリーズコーヒー"
                />
                <div class="card_text_container">
                  <p class="card_brand_name">TULLY'S COFFEE</p>
                  <p class="card_title">タリーズデジタルギフト(500円)</p>
                  <p class="card_price">¥500</p>
                </div>
              </a>
            </div>

            <div class="swiper-slide card">
              <a
                href="javascript:void(0);"
                ref="pickUpGift04"
                @click="() => { clickEvent('pickUpGift04','ピックアップギフト_04','ミスタードーナツ ギフトチケット(200円)_24_200');
                                ecommerceSelectPromotionPush(24, 'ミスタードーナツ ギフトチケット(200円)', 'ミスタードーナツ', 200);
                                openDetail(24);
                }"
              >
                <img
                  src="@/assets/images/special-feature-001/card/pickup/pickup_mister_donut.png"
                  width="240"
                  height="240"
                  alt="ミスタードーナツ"
                />
                <div class="card_text_container">
                  <p class="card_brand_name">ミスタードーナツ</p>
                  <p class="card_title">
                    ミスタードーナツ ギフトチケット(200円)
                  </p>
                  <p class="card_price">¥200</p>
                </div>
              </a>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination001"></div>
        </div>
      </div>
      <a
        class="button"
        href="/gifts/search#gift-list"
        ref="pickUpGiftList01"
        @click="clickEvent('pickUpGiftList01','ピックアップギフト_一覧','さらにギフトを見る')"
        >さらにギフトを見る</a
      >
    </div>

    <div class="card_container">
      <h2 class="heading">ちょっとしたお礼に</h2>
      <ul class="card_list">
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks01"
            @click="() => { clickEvent('Thanks01','ちょっとしたお礼に_01','Coke ON ドリンクチケット（お好きなコカ・コーラ社製品1本）_28_170');
                                ecommerceSelectPromotionPush(28, 'Coke ON ドリンクチケット（お好きなコカ・コーラ社製品1本）', 'コカ・コーラ', 170);
                                openDetail(28);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/cocacola_002.jpeg"
              width="164"
              height="164"
              alt="コカ・コーラ"
            />
            <div class="card_text_container">
              <p class="card_brand_name">コカ・コーラ</p>
              <p class="card_title">Coke ON ドリンクチケット（お好きなコカ・コーラ社製品1本）</p>
              <p class="card_price">¥170<span>税込</span></p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks02"
            @click="() => { clickEvent('Thanks02','ちょっとしたお礼に_02','GODIVAギフト券(1,000円)_14_1000');
                                ecommerceSelectPromotionPush(14, 'GODIVAギフト券(1,000円)', 'ゴディバ', 1000);
                                openDetail(14);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/godiva.png"
              width="164"
              height="164"
              alt="ゴディバ"
            />
            <div class="card_text_container">
              <p class="card_brand_name">ゴディバ</p>
              <p class="card_title">GODIVAギフト券(1,000円)</p>
              <p class="card_price">¥1,000</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks03"
            @click="() => { clickEvent('Thanks03','ちょっとしたお礼に_03','MISAKI DONUTS ギフトチケット 500円券_83_500');
                                ecommerceSelectPromotionPush(83, 'MISAKI DONUTS ギフトチケット 500円券', 'MISAKI DONUTS', 500);
                                openDetail(83);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/misakidonuts.png"
              width="164"
              height="164"
              alt="MISAKI DONUTS"
            />
            <div class="card_text_container">
              <p class="card_brand_name">MISAKI DONUTS</p>
              <p class="card_title">MISAKI DONUTS ギフトチケット 500円券</p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks04"
            @click="() => { clickEvent('Thanks04','ちょっとしたお礼に_04','大戸屋500円ギフトチケット_68_500');
                                ecommerceSelectPromotionPush(68, '大戸屋500円ギフトチケット', '大戸屋ごはん処', 500);
                                openDetail(68);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/ootoya.png"
              width="164"
              height="164"
              alt="大戸屋ごはん処"
            />
            <div class="card_text_container">
              <p class="card_brand_name">大戸屋ごはん処</p>
              <p class="card_title">大戸屋500円ギフトチケット</p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks05"
            @click="() => { clickEvent('Thanks05','ちょっとしたお礼に_05','ファミリーマート100円お買い物券_36_100');
                                ecommerceSelectPromotionPush(36, 'ファミリーマート100円お買い物券', 'ファミリーマート', 100);
                                openDetail(36);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/familymart02.jpg"
              width="164"
              height="164"
              alt="ファミリーマート"
            />
            <div class="card_text_container">
              <p class="card_brand_name">ファミリーマート</p>
              <p class="card_title">ファミリーマート100円お買い物券</p>
              <p class="card_price">¥100</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Thanks06"
            @click="() => { clickEvent('Thanks06','ちょっとしたお礼に_06','ローソンお買い物券（500円）_4_500');
                                ecommerceSelectPromotionPush(4, 'ローソンお買い物券（500円）', 'ローソン', 500);
                                openDetail(4);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/lawson.jpeg"
              width="164"
              height="164"
              alt="ローソン"
            />
            <div class="card_text_container">
              <p class="card_brand_name">ローソン</p>
              <p class="card_title">ローソンお買い物券（500円）</p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
      </ul>
      <a
        class="button"
        href="/gifts/search?scenes=14#gift-list"
        ref="ThanksList01"
        @click="clickEvent('ThanksList01','ちょっとしたお礼に_一覧','さらにギフトを見る')"
        >さらにギフトを見る</a
      >
    </div>

    <div class="card_container">
      <h2 class="heading">自分へのご褒美に</h2>
      <ul class="card_list">
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself01"
            @click="() => { clickEvent('Myself01','自分へのご褒美に_01','リンツ ギフトチケット 600円_47_600');
                                ecommerceSelectPromotionPush(47, 'リンツ ギフトチケット 600円', 'Lindt リンツ', 600);
                                openDetail(47);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/lindt.jpeg"
              width="164"
              height="164"
              alt="リンツ"
            />
            <div class="card_text_container">
              <p class="card_brand_name">Lindt リンツ</p>
              <p class="card_title">リンツ ギフトチケット 600円</p>
              <p class="card_price">¥600</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself02"
            @click="() => { clickEvent('Myself02','自分へのご褒美に_02','サーティワン アイスクリーム 500円ギフト券_22_500');
                                ecommerceSelectPromotionPush(22, 'サーティワン アイスクリーム 500円ギフト券', 'サーティワン アイスクリーム', 500);
                                openDetail(22);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/31ice.png"
              width="164"
              height="164"
              alt="サーティワン アイスクリーム"
            />
            <div class="card_text_container">
              <p class="card_brand_name">サーティワン アイスクリーム</p>
              <p class="card_title">
                サーティワン アイスクリーム 500円ギフト券
              </p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself03"
            @click="() => { clickEvent('Myself03','自分へのご褒美に_03','ギフト券500円_72_500');
                                ecommerceSelectPromotionPush(72, 'ギフト券500円', '上島珈琲店', 500);
                                openDetail(72);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/ueshima-coffee-ten.jpeg"
              width="164"
              height="164"
              alt="上島珈琲店"
            />
            <div class="card_text_container">
              <p class="card_brand_name">上島珈琲店</p>
              <p class="card_title">ギフト券500円</p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself04"
            @click="() => { clickEvent('Myself04','自分へのご褒美に_04','コメダ珈琲店 eギフト500円_41_500');
                                ecommerceSelectPromotionPush(41, 'コメダ珈琲店 eギフト500円', 'コメダ珈琲店', 500);
                                openDetail(41);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/komeda.jpeg"
              width="164"
              height="164"
              alt="コメダ珈琲店"
            />
            <div class="card_text_container">
              <p class="card_brand_name">コメダ珈琲店</p>
              <p class="card_title">コメダ珈琲店 eギフト500円</p>
              <p class="card_price">¥500</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself05"
            @click="() => { clickEvent('Myself05','自分へのご褒美に_05','カフェで使えるドリンクチケット（700円）_64_700');
                                ecommerceSelectPromotionPush(64, 'カフェで使えるドリンクチケット（700円）', 'ブルーボトルコーヒー', 700);
                                openDetail(64);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/bluebottlecoffee.jpeg"
              width="164"
              height="164"
              alt="ブルーボトルコーヒー"
            />
            <div class="card_text_container">
              <p class="card_brand_name">ブルーボトルコーヒー</p>
              <p class="card_title">カフェで使えるドリンクチケット（700円）</p>
              <p class="card_price">¥700</p>
            </div>
          </a>
        </li>
        <li class="card small_card">
          <a
            href="javascript:void(0);"
            ref="Myself06"
            @click="() => { clickEvent('Myself06','自分へのご褒美に_06','ビアードパパ200円ギフト券_98_200');
                                ecommerceSelectPromotionPush(98, '200円ギフト券', 'ビアードパパ', 200);
                                openDetail(98);
            }"
          >
            <img
              src="@/assets/images/special-feature-001/card/beardpapa.jpeg"
              width="164"
              height="164"
              alt="ビアードパパ"
            />
            <div class="card_text_container">
              <p class="card_brand_name">ビアードパパ</p>
              <p class="card_title">200円ギフト券</p>
              <p class="card_price">¥200</p>
            </div>
          </a>
        </li>
      </ul>
      <a
        class="button"
        href="/gifts/search?scenes=15#gift-list"
        ref="MyselfList01"
        @click="clickEvent('MyselfList01','自分へのご褒美に_一覧','さらにギフトを見る')"
        >さらにギフトを見る</a
      >
    </div>

    <div class="app_induction">
      <div class="contents_gap">
        <p class="app_induction_title">便利・お得なご案内</p>
        <img
          class="plus-message-logo"
          src="@/assets/images/special-feature-001/plus-message-logo.png"
          width="143"
          height="34"
          alt="プラスメッセージ"
        />
        <div class="app_description">
          <p>
            購入履歴へのアクセスは「＋メッセージ」のプラメギフト公式アカウントのフォローが便利です。<br />また同アカウントではギフトに関するお得な情報を配信します。是非ご利用ください。
          </p>
        </div>
      </div>
      <div class="contents_gap">
        <div class="step_box_container">
          <div class="step_box">
            <p>Step</p>
            <span>1</span>
          </div>
          <p>＋メッセージアプリをダウンロード</p>
        </div>
        <div class="badge_box">
          <a
            href="https://apps.apple.com/jp/app/apple-store/id1358576433"
            ref="Appeal01"
            @click="clickEvent('Appeal01','アプリ訴求','Apple Storeからダウンロード')"
          >
            <img
              src="@/assets/images/special-feature-001/apple_store.png"
              width="143"
              height="43"
              alt="Apple Storeからダウンロード"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.kddi.android.cmail"
            ref="Appeal02"
            @click="clickEvent('Appeal02','アプリ訴求','Google Playからダウンロード')"
          >
            <img
              src="@/assets/images/special-feature-001/google-play-badge.png"
              width="142"
              height="55"
              alt="Google Playで手に入れよう"
            />
          </a>
        </div>
      </div>
      <div class="contents_gap">
        <div class="step_box_container">
          <div class="step_box">
            <p>Step</p>
            <span>2</span>
          </div>
          <p>公式アカウントをフォロー</p>
        </div>
        <div class="step2_contents">
          <a
            href="https://bot.plus-msg.com/?botId=sip:audigitalgift65@maap.plus-msg.com&msg="
          >
            <img
              src="@/assets/images/special-feature-001/plamegift_official_account.png"
              width="295"
              height="56"
              alt="プラメギフト公式アカウント"
            />
          </a>
          <p class="caution">
            ※＋メッセージの最新バージョンをダウンロードしてからタップしてください
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { gtmSendClick } from "@/plugins/GtmHelper";
import { GtmEcommerce, GtmEcommerceItem } from '@/plugins/GtmEcommerce';

export default {
  name: "SpecialFeature001",
  components: {},
  data: () => ({
    gifts: [],
    promotion_id: "B1",
    swiper: null
  }),
  mounted() {
    this.initSwiper();
    this.ecommerceViewPromotionPush()
  },
  watch: {
    $route() {
      if (this.swiper && this.$route.name === "special-feature") {
        this.swiper.destroy();
        this.initSwiper();
      }
    },
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$refs.swiperContainer, {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination001",
          clickable: true,
        },
        on: {
          resize: () => {
            this.swiper.autoplay?.start();
          }
        },
      });
    },
    clickEvent(refName,label2,label3){
      gtmSendClick(this.$refs[refName],'初めてのギフト特集',label2,label3);
    },
    // GA(eコマース) event: view_promotion
    ecommerceViewPromotionPush() {
      const items = []
      this.pushItems()
      for(const gift of this.gifts){
        const item = GtmEcommerceItem.getEcommerceItem(gift, false, true)
        items.push(item)
      }
      GtmEcommerce.viewPromotion(this.promotion_id, "初めてのギフト特集", items)
    },
    pushItems(){
      const data = [
        // ピックアップギフト
        {
          "giftId": 22,
          "giftName": "サーティワン アイスクリーム 500円ギフト券",
          "brandName": "サーティワン アイスクリーム",
          "price": 500
        },
        {
          "giftId": 43,
          "giftName": "タリーズデジタルギフト(500円)",
          "brandName": "TULLY'S COFFEE",
          "price": 500
        },
        {
          "giftId": 24,
          "giftName": "ミスタードーナツ ギフトチケット(200円)",
          "brandName": "ミスタードーナツ",
          "price": 200
        },
        // ちょっとしたお礼に
        {
          "giftId": 27,
          "giftName": "Coke ON ドリンクチケット（お好きな「ジョージア」製品1本）",
          "brandName": "コカ・コーラ",
          "price": 170 
        },
        {
          "giftId": 14,
          "giftName": "GODIVAギフト券(1,000円)",
          "brandName": "ゴディバ",
          "price": 1000
        },
        {
          "giftId": 83,
          "giftName": "MISAKI DONUTS ギフトチケット 500円券",
          "brandName": "MISAKI DONUTS",
          "price": 500
        },
        {
          "giftId": 68,
          "giftName": "大戸屋500円ギフトチケット",
          "brandName": "大戸屋ごはん処",
          "price": 500
        },
        {
          "giftId": 36,
          "giftName": "ファミリーマート100円お買い物券",
          "brandName": "ファミリーマート",
          "price": 100
        },
        {
          "giftId": 4,
          "giftName": "ローソンお買い物券（500円）",
          "brandName": "ローソン",
          "price": 500
        },
        // 自分へのご褒美に
        {
          "giftId": 47,
          "giftName": "リンツ ギフトチケット 600円",
          "brandName": "Lindt リンツ",
          "price": 600
        },
        {
          "giftId": 22,
          "giftName": "サーティワン アイスクリーム 500円ギフト券",
          "brandName": "サーティワン アイスクリーム",
          "price": 500
        },
        {
          "giftId": 72,
          "giftName": "ギフト券500円",
          "brandName": "上島珈琲店",
          "price": 500
        },
        {
          "giftId": 41,
          "giftName": "コメダ珈琲店 eギフト500円",
          "brandName": "コメダ珈琲店",
          "price": 500
        },
        {
          "giftId": 64,
          "giftName": "カフェで使えるドリンクチケット（700円）",
          "brandName": "ブルーボトルコーヒー",
          "price": 700
        },
        {
          "giftId": 98,
          "giftName": "200円ギフト券",
          "brandName": "ビアードパパ",
          "price": 200
        },
      ]

      this.gifts = data
    },
    // GA(eコマース) event: select_promotion
    ecommerceSelectPromotionPush(giftId, giftName, brandName, price) {
      const items = []
      const data = {
        "giftId": giftId,
        "giftName": giftName,
        "brandName": brandName,
        "price": price
      }
      const item = GtmEcommerceItem.getEcommerceItem(data, false, true)
      items.push(item)
      GtmEcommerce.selectPromotion(this.promotion_id, "初めてのギフト特集", items)
    },
    // ギフト詳細に遷移
    openDetail(id) {
      this.$router.push({ name: 'PMG610', params: { giftId: id } });
    },
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
<style src="@/assets/css/special-feature-001.css" scoped></style>

