<template>
  <IonicModal
    ref="modal"
    title="ギフトの差し替え"
    @on-close-dom="closeGA"
  >
    <template #body>
      <div class="block">
        <div class="block__header">
          <span class="red warn">1度にご購入いただけるギフトは1つまでです。</span>
          <span>2つ以上を購入したい場合は、1つずつ購入手続きを実施してください。</span>
        </div>
        <div class="block__gift">
          <div class="block__gift__header">
            現在の買い物カゴの内容
          </div>
          <div class="block__gift__card">
            <GiftInfo
            :gift-name="giftName"
            :brand-name="brandName"
            :expiry-date="expiryDate"
            :price="price"
            :gift-image="image"
            :is-tax-display="isTaxDisplay"
            ></GiftInfo>
          </div>
          <div class="block__gift__button">
            <Button label="現在の買い物カゴを確認" type="fill" ref="checkCart" @click="clickCheckCart()" />
          </div>
        </div>
        <div class="block__footer">
          <Button label="このギフトに差し替え" type="fill-outline" ref="replaceGift" @click="clickReplaceGift()" />
          <span class="red note">※ギフトを差し替えると、現在の買い物カゴにあるギフト・作成済みのカードは削除されます。</span>
        </div>
      </div>
    </template>
  </IonicModal>
</template>

<script>
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import { mapGetters } from 'vuex';
import Button from "@/components/button/Button";
import GiftInfo from "@/components/gift/GiftInfo";
import {
  getItemImageUrl,
} from "@/plugins/GiftHelper";
import IonicModal from "@/components/ionic/IonicModal";
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: "GiftReplacModal",
  components: {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonicModal,
    Button,
    GiftInfo,
  },
  data: () => ({
    giftData: null,
    giftName: null,
    brandName: null,
    expiryDate: null,
    price: null,
    image: null,
    isTaxDisplay: null,
  }),
  props: {
    certGift: null,
    replaceGiftName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getCartItems']),
  },
  watch: {
    giftData (newVal) {
      if (!newVal || !Array.isArray(newVal) || newVal.length <= 0) {
        return;
      }
      const data = newVal[0];
      if (!data) {
        return;
      }
      this.giftName = data.giftName;
      this.brandName = data.brandName;
      this.expiryDate = data.expiryDate;
      this.price = Number(data.price);
      this.isTaxDisplay = data.isTaxDisplay;
      if (data.imgUrl.indexOf("http") >= 0) {
        this.image = data.imgUrl;
      } else {
        this.image = getItemImageUrl(data.imgUrl);
      }
    },
    getCartItems() {
      this.setGiftData();
    },
  },
  emit: [
    'click:checkCart',
    'click:replaceGift',
    'click:clockClose',
  ],
  mounted() {
    this.setGiftData();
  },
  updated() {
    this.setGiftData();
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
      this.$emit('click:clockClose');
    },
    clickCheckCart() {
      gtmSendClick(this.$refs['checkCart'].$el,'ギフト差し替え','現在の買い物かごを確認','');
      this.$emit('click:checkCart');
    },
    clickReplaceGift() {
      gtmSendClick(this.$refs['replaceGift'].$el,'ギフト差し替え','このギフトに差し替え',this.replaceGiftName);
      this.$emit('click:replaceGift');
    },
    setGiftData() {
      this.giftData = null;
      if (this.certGift != null) {
        this.giftData = [this.certGift];
      } else {
        this.giftData = this.getCartItems;
      }
    },
    closeGA(dom){
      gtmSendClick(dom,'ギフト差し替え','閉じる','');
    },
  },
};
</script>
<style scoped lang="scss">
.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  .red {
    color: $red2;
  }
  button {
    width: 311px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    font:$font-noto-sans-jp-400;
    font-size: 14px;
    line-height: 22.4px;
    .warn {
      font: $font-noto-sans-jp-700;
    }
  }
  &__gift {
    margin: 1rem;
    border: 1px solid $gold2;
    border-radius: 12px;
    width: 100%;
    &__header {
      margin: 1rem;
      font: $font-noto-sans-jp-700;
      font-size: 12px;
      line-height: 19.2px;
    }
    &__card {
      margin: 0 1rem 1rem 1rem;
    }
    &__button {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .note {
      font: $font-noto-sans-jp-400;
      font-size: 12px;
      line-height: 19.2px;
      text-align: center;
      margin: 1rem;
    }
  }
}
ion-modal {
  --max-height: 95%;
  --height: 600px;
  align-items: flex-end;
}
</style>
