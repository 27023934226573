<template>
  <button class="button" :class="classes" :disabled="disabled" @click="onClickButton">
    <img v-if="icon" class="button__icon" :src="iconSrc" alt="icon">
    <span v-if="label" class="button__label">{{ label }}</span>
  </button>
</template>

<script>
import edit from '@/assets/images/icon/edit.svg'
import image from '@/assets/images/icon/image.svg'
import video from '@/assets/images/icon/video.svg'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'fill',
    },
    size: {
      type: String,
      default: 'medium',
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mainClass: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    classes() {
      let classes = [this.type, this.size];
      if (!this.label && this.icon) classes.push('icon-only')
      return classes;
    },
    iconSrc() {
      switch (this.icon) {
        case 'edit': return edit;
        case 'image': return image;
        case 'video': return video;
        default: return null;
      }
    },
  },
  methods: {
    onClickButton() {
        this.$emit("onClickDom", this.$el);
    },
  },
}
</script>

<style scoped lang="scss">
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 10px $shadow1;
  overflow: hidden;

  &:disabled {
    opacity: 0.4;
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin: 0 9px 0 3px;
  }

  &__label {
    white-space: nowrap;
  }

  // valiant
  &.fill {
    background: $gold1;
    color: $white1;

    &:hover,
    &:focus,
    &:active {
      background: $gold2;
    }
  }

  &.fill-self {
    background: #D7C4A7;
    color: #492D03;

    &:hover,
    &:focus,
    &:active {
      background: $gold2;
    }
  }
  &.fill-pink {
    background: $pink1;
    color: $white1;

    &:hover,
    &:focus,
    &:active {
      background: $pink2;
    }
  }
  &.fill-orange {
    background: $orange1;
    color: $white1;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background: $orange3;
      color: $orange2;
      border: 1px solid $orange2;
    }
  }
  &.fill-outline {
    background: $white1;
    border: $gold1;
    color: $gold1;
    border: 1px solid $gold1;
  }
  &.fill-nonline {
    background: $white1;
    color: $gold1;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background: $gray11;
    }
  }

  &.border {
    background: transparent;
    border: 1px solid $gold1;
    color: $gold1;

    &:hover,
    &:focus,
    &:active {
      background: $gold1;
      color: $white1;
    }
  }

  &.none {
    background: transparent;
    box-shadow: none;
    color: $gray6;

    &:hover,
    &:focus,
    &:active {
      background: $white2;
    }
  }

  &.primary {
    background: transparent;
    box-shadow: none;
    color: var(--ion-color-primary);

    &:hover,
    &:focus,
    &:active {
      background: $white2;
    }
  }

  &.danger {
    background: transparent;
    box-shadow: none;
    color: var(--ion-color-danger);

    &:hover,
    &:focus,
    &:active {
      background: $white2;
    }
  }

  &.nonfill-outline {
    background: transparent;
    border: 1px solid #9B7B4B;
    box-shadow: none;
    color: #9B7B4B;
  }

  &.fillgold-nonline-nonshadow {
    background: #9B7B4B;
    color: $white1;
    border: none;
    box-shadow: none;
  }

  &.fillwhite-nonline-nonshadow {
    background: $white1;
    color: #9B7B4B;
    border: none;
    box-shadow: none;
  }

  // size
  &.xx-small {
    min-width: 44px;
    height: 30px;
    border-radius: 22px;
    font: $font-roboto-400;
    padding: 0 24px;
    @include font-size(14, 18.2);
  }
  &.x-small {
    min-width: 44px;
    height: 34px;
    border-radius: 22px;
    font: $font-roboto-400;
    padding: 0 14px;
    @include font-size(14, 18.2);
  }
  &.small {
    min-width: 44px;
    height: 44px;
    border-radius: 22px;
    font: $font-roboto-400;

    @include font-size(14, 18.2);
  }

  &.medium {
    min-width: 44px;
    height: 44px;
    border-radius: 22px;
    font: $font-roboto-400;

    @include font-size(16, 20.8);
  }

  &.large {
    min-width: 56px;
    height: 56px;
    border-radius: 28px;
    font: $font-roboto-700;
    letter-spacing: 1.2px;

    @include font-size(20, 20);
  }

  // icon
  &.icon-only {
    padding: 0;

    .button__icon {
      margin: 0;
    }
  }
}
</style>
