<template>
  <transition name="fade">
    <div v-if="isShowModal" class="modal">
      <div class="modal__container" :class="containerClasses" @scroll="onScroll">
        <div v-if="showHeader" class="header" :class="headerClasses">
          <button class="header__close" :class="closeIcon" @click="close"/>
          <div class="header__contents" :class="headerAlign">
            <slot name="header"/>
          </div>
        </div>
        <div class="body">
          <slot name="body"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { isScrollContents } from '@/plugins/Utils'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Modal',
  props: {
    name: {
      type: String,
      default: null,
    },
    closeIcon: {
      type: String,
      default: 'close',
    },
    scrollToHideHeader: {
      type: Boolean,
      default: false,
    },
    defaultHideHeader: {
      type: Boolean,
      default: false,
    },
    headerAlign: {
      type: String,
      default: 'left',
    },
    closeFunc: {
      type: Function,
      default: null,
    },
    isScrollStop: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    isShowModal: false,
    isShowHeader: false,
    scrollTop: 0,
  }),
  computed: {
    containerClasses() {
      let classes = [];
      if (this.isScrollStop) classes.push('is-scroll-stop');
      return classes;
    },
    headerClasses() {
      let classes = [];
      if (this.scrollToHideHeader) {
        classes.push('scroll-to-hide-header');
        if (this.isShowHeader) classes.push('is-show-header');
      }
      return classes;
    },
  },
  watch: {
    isShowModal(newValue) {
      this.setOpenModal(newValue);
    },
    scrollTop(newValue, oldValue) {
      this.isShowHeader = newValue < oldValue;
    }
  },
  unmounted () {
    this.setOpenModal(false);
  },
  methods: {
    openModal() {
      this.isShowModal = true;
      this.isShowHeader = !this.defaultHideHeader;
    },
    hideModal() {
      this.isShowModal = false;
    },
    setOpenModal(value) {
      if (!this.name) return;

      this.$store.commit('setOpenModal', {
        key: this.name,
        value: value
      });
    },
    close() {
      if (this.closeFunc) this.closeFunc();
      else this.hideModal();
    },
    onScroll(e) {
      if (!this.scrollToHideHeader) return false;

      if (isScrollContents(e.target)) this.scrollTop = e.target.scrollTop;
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white1;

  &.fade-enter-active {
    animation: fade-in 200ms;
  }

  &.fade-leave-active {
    animation: fade-in 200ms reverse;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &.is-scroll-stop {
      overflow: hidden;
    }

    .header {
      position: sticky;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      padding: 0 68px;
      background: $white1;
      box-shadow: 0 0 2px $shadow2, 0 2px 2px $shadow3, 0 1px 3px $shadow4;
      z-index: 10;

      &.scroll-to-hide-header {
        position: fixed;
        transition: transform 300ms;
        transform: translateY(-70px);

        &.is-show-header {
          transform: translateY(0);
        }
      }

      &__close {
        position: absolute;
        top: 16px;
        left: 20px;
        width: 24px;
        height: 24px;
        padding: 0;
        background-color: $white1;
        background-position: center;
        background-size: 24px 24px;
        background-repeat: no-repeat;

        &.close {
          background-image: url("../../assets/images/icon/close_button.svg");
        }

        &.back {
          background-image: url("../../assets/images/icon/back_button.svg");
        }
      }

      &__contents {
        width: 100%;
        font: $font-roboto-500;
        color: $black1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        opacity: 0.87;

        @include font-size(20, 24);

        &.center {
          text-align: center;
        }

        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
      }
    }
  }
}
</style>
