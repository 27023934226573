<template>
  <div class="info">
    <div
      v-show="isGetList"
      class="info__column"
      v-for="(info, index) in info_list"
      :key="index"
      :ref="'info_'+info.url"
      @click="showInfo(info.url, info.title)"
    >
      <span class="info__column__ymd">{{ info.ymd }}</span>
      <span class="info__column__title">{{ info.title }}</span>
    </div>
    <LoadingMini :spinner-size="'smail'" :is-show="!isGetList"></LoadingMini>
  </div>
</template>

<script>
import LoadingMini from "@/components/loading/LoadingMini.vue";

import { getGiftInfoList } from "@/plugins/ApiHelper";
import { gtmDataLayerPush } from "@/plugins/GtmHelper";
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from "@/plugins/UrlHelper";

export default {
  name: "InfoColumn",
  components: {
    LoadingMini,
  },
  data: () => {
    return {
      info_list: [],
      isGetList: false,
    };
  },
  mounted() {
    this.getInfoList();
  },
  methods: {
    getInfoList() {
      this.isGetList = false;
      getGiftInfoList()
        .then((res) => {
          this.info_list = res.info_list;
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "PMG510" });
        })
        .finally(() => {
          this.isGetList = true;
        });
    },
    showInfo(url, title) {
      const virtualPageUrl = getUrl(`/infomation/${url}&${title}`);
      gtmDataLayerPush(virtualPageUrl, `お知らせ | ${title}`);
      gtmSendClick(this.$refs['info_'+url][0],'ギフト一覧','お知らせ',title);
      this.$router.push({ name: "PMG630", params: { url: url, title: title } });
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  &__column {
    display: flex;
    flex-wrap: nowrap;
    font: $font-roboto-400;
    @include font-size(12, 14.4);
    margin-bottom: 1rem;
    &__ymd,
    &__title {
      font: $font-roboto-400;
      @include font-size(12, 14.4);
    }
    &__ymd {
      margin-right: 1rem;
      color: $gray10;
      white-space: nowrap;
    }
    &__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
