
import { EVENT, CURRENCY } from '@/constants/ecommerceEvent';
import { gtmEcommerceDataLayerPush } from '@/plugins/GtmHelper';
import { CategoryHelper } from '@/plugins/CategoryHelper';

export class GtmEcommerce {
  static selectItem = (items = []) => {
    const data = {
      'event': EVENT.SELECT_ITEM,
      'ecommerce': {
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static viewItem = (value, items = []) => {
    const data = {
      'event': EVENT.VIEW_ITEM,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'value': value,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static viewItemList = (items = []) => {
    const data = {
      'event': EVENT.VIEW_ITEM_LIST,
      'ecommerce': {
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static viewPromotion = (promotion_id = null, promotion_name = null, items = []) => {
    const data = {
      'event': EVENT.VIEW_PROMOTION,
      'ecommerce': {
        'promotion_id': promotion_id,
        'promotion_name': promotion_name,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static selectPromotion = (promotion_id = null, promotion_name = null, items = []) => {
    const data = {
      'event': EVENT.SELECT_PROMOTION,
      'ecommerce': {
        'promotion_id': promotion_id,
        'promotion_name': promotion_name,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static addToCart = (value, items = []) => {
    const data = {
      'event': EVENT.ADD_TO_CART,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'value': value,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static viewCart = (value, items = []) => {
    const data = {
      'event': EVENT.VIEW_CART,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'value': value,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static removeFromCart = (value, items = []) => {
    const data = {
      'event': EVENT.REMOVE_FROM_CART,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'value': value,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static beginCheckout = (value, items = []) => {
    const data = {
      'event': EVENT.BEGIN_CHECKOUT,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'value': value,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };

  static purchase = (value, transaction_id, items = []) => {
    const data = {
      'event': EVENT.PURCHASE,
      'ecommerce': {
        'currency': CURRENCY.JPY,
        'transaction_id': transaction_id,
        'value': value,
        'tax': 0,
        'items': items
      }
    };
    gtmEcommerceDataLayerPush(data)
  };
}

/**
 * GA(eコマース) ecommerce.items 作成
 */
export class GtmEcommerceItem {
  static getEcommerceItem = (item, is_purchase = false, is_promotion = false) => {
    const categoryIds = item.categoryIds ? item.categoryIds : null
    const itemCategory = CategoryHelper.getItemCategories(categoryIds)
    let Item = {
      'item_id': item.giftId,
      'item_name': item.giftName,
      'item_brand': item.brandName ? item.brandName : null,
      'item_category': itemCategory.item_category ? itemCategory.item_category : null,
      'item_category2': itemCategory.item_category2 ? itemCategory.item_category2 : null,
      'item_category3': itemCategory.item_category3 ? itemCategory.item_category3 : null,
      // 'item_category4': null,
      // 'item_category5': null,
    }
    if (is_purchase) {
      Item['currency'] = CURRENCY.JPY
      Item['price'] = parseFloat(item.price)
      Item['quantity'] = 1 //現状個数は1固定
    }
    if (is_promotion) {
      Item['currency'] = CURRENCY.JPY
      Item['price'] = parseFloat(item.price)
    }
    return Item
  }
}