
/*
  *eコマースのイベント定義
 */
export const EVENT = {
    SELECT_ITEM: 'select_item',
    VIEW_ITEM: 'view_item',
    VIEW_ITEM_LIST: 'view_item_list',
    VIEW_PROMOTION: 'view_promotion',
    SELECT_PROMOTION: 'select_promotion',
    ADD_TO_CART: 'add_to_cart',
    VIEW_CART: 'view_cart',
    REMOVE_FROM_CART: 'remove_from_cart',
    BEGIN_CHECKOUT: 'begin_checkout',
    PURCHASE: 'purchase'
}

export const CURRENCY = {
    JPY: "JPY"
}