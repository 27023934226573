<template>
  <DialogModal ref="modal" class="confirm-modal">
    <template #header>{{ null }}</template>
    <template #body>
      <p v-for="(message, i) in messages" :key="i" class="body__text">{{ message }}</p>
    </template>
    <template #buttons>
      <button class="buttons__button" @click="hideModal">キャンセル</button>
      <button class="buttons__button" @click="ok">OK</button>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from '@/components/modal/DialogModal'

export default {
  name: 'ConfirmModal',
  components: {
    DialogModal,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click:ok'],
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    ok() {
      this.$emit('click:ok');
      this.hideModal();
    },
  }
}
</script>

<style scoped lang="scss">
.confirm-modal {
  z-index: 1000;
}
</style>
