import store from '@/store';
import { DateTime } from 'luxon';
import { checkPurchaceLimit } from '@/plugins/ApiHelper'

export const formatExpiryDate = (date) => {
  const expiryDate = DateTime.fromSQL(date);
  return expiryDate.toFormat('yyyy年M月d日まで');
}

export const getGiftLoadingImage = () => {
  return require('@/assets/images/gift/gift_loading.jpg');
}

export const getGiftNoImage = () => {
  return require('@/assets/images/gift/gift_no_image.jpg');
}

export const getPurchaceLimit = async (lid, gift_id) => {
  store.commit('setAvailablePrice', null);
  const data = {
    'lid': lid,
    'giftId': gift_id,
  };
  const purchaceLimit = await checkPurchaceLimit(data)
    .then((res) => {
      console.log('checkPurchaceLimit res', res)
      store.commit('setAvailablePrice', res.availablePrice);
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  if (Object.keys(purchaceLimit).length) {
    return purchaceLimit;
  } else {
    return null;
  }
};

export const getItemImageUrl = (path) => {
  let url = null;
  if (path) {
    const baseUrl = process.env.VUE_APP_GIFT_ITEM_IMG_BASE_URL;
    if (!path.startsWith('/')) {
      url = baseUrl + '/' + path;
    } else {
      url = baseUrl + path;
    }
  }
  return url;
}

export const getBrandImageUrl = (path) => {
  let url = null;
  if (path) {
    const baseUrl = process.env.VUE_APP_GIFT_BRAND_IMG_BASE_URL;
    if (!path.startsWith('/')) {
      url = baseUrl + '/' + path;
    } else {
      url = baseUrl + path;
    }
  }
  return url;
}
