<template>
  <div class="popular">
    <span class="popular__note">例えばこんなギフトが人気です</span>
    <div class="popular__gifts">
      <div
        class="popular__gifts__item"
        v-for="gift in gifts"
        :key="gift.id"
        :ref="'gift_'+gift.id"
        @click="goGiftDetail(gift)"
      >
        <img :src="imageUrl(gift.image)" @error="errorImage" />
      </div>
    </div>
  </div>
</template>

<script>
import { getGiftLoadingImage, getGiftNoImage, getItemImageUrl } from "@/plugins/GiftHelper";
import { getPopularGift } from "@/plugins/ApiHelper";
import { gtmDataLayerPush } from "@/plugins/GtmHelper";
import { getUrl } from "@/plugins/UrlHelper";
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: "PopularGift",
  components: {},
  props: {
    GALabel1:{
      type: String,
      default: '',
    },
    GALabel2:{
      type: String,
      default: 'ギフト選択',
    },
  },
  data: () => ({
    gifts: [],
    imgSrc: getGiftLoadingImage(),
  }),
  computed: {},
  watch: {},
  mounted() {
    this.getPopularGiftList();
  },
  methods: {
    getPopularGiftList() {
      getPopularGift()
        .then((res) => {
          this.gifts = res.gifts;
        })
        .catch((err) => {
          console.err(err);
          this.$router.push({ name: "PMG510" });
        });
    },
    goGiftDetail(gift) {

      gtmSendClick(this.$refs['gift_'+gift.id][0],this.GALabel1,this.GALabel2,gift.name ?? gift.id);
      const virtualPageUrl = getUrl(`/gifts/${gift.id}`);
      gtmDataLayerPush(virtualPageUrl, 'ギフト詳細');
      this.$router.push({ name: "PMG610", params: { giftId: gift.id } });
    },
    loadImage() {
      this.imgSrc = this.thumbnailUrl;
    },
    errorImage(e) {
      e.target.src = getGiftNoImage();
    },
    imageUrl(image) {
      return getItemImageUrl(image);
    },
  },
};
</script>
<style scoped lang="scss">
.popular {
  &__note {
    font: $font-noto-sans-jp-700;
    @include font-size(12, 19.2);
    display: inline-block;
    margin-bottom: 16px;
  }
  &__gifts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 0 16px;
    margin-bottom: 32px;
    &__item {
      display: flex;
      img {
        border-radius: 8px;
      }
    }
  }
}
</style>
