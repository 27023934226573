<template>
  <Layout
    ref="layout"
    :title="title"
    class="pmg210"
    :showMenu="false"
    :showBack="false"
    :scrollEvents="true"
    :scroll-func="scrollFunc"
    :scroll-to-hide-header="designCategoryId != 'NORMAL'"
    :fixedHeader="designCategoryId != 'NORMAL'"
  >
    <div v-show="designCategoryId != 'NORMAL'" class="pmg210__card-ricci">
      <BaseTemplate :modelValue="cardValue" :design-id="designId" :scrollTop="scrollTop" :gift-data="gift" :is-show-price="false" :is-link-enabled="true">
        <FirstView :modelValue="cardValue" :design-id="designId" />
      </BaseTemplate>
    </div>
    <div v-show="designCategoryId == 'NORMAL'" class="pmg210__card-normal">
      <NormalGift
        :sender-name="cardData?.creatorName"
        :gift-url="gift?.giftUrl"
        :gift-name="gift?.giftName"
        :brand-name="gift?.brandName"
        :expiry-date="gift?.expiryDate"
        :price="gift?.paymentPrice"
        :gift-image="giftImage"
        :is-link-enabled="true"
        :is-show-price="false"
        :is-for-myself="cardData?.isForMySelf"
      />
    </div>

    <Loading :is-show="isLoading" />
  </Layout>
</template>

<script>

import Layout from '@/components/layout/Layout'
import Loading from '@/components/loading/Loading'
import BaseTemplate from '@/components/messageCard/BaseTemplate';
import FirstView from '@/components/messageCard/FirstView';
import NormalGift from "@/components/gift/NormalGift";
import {
  getItemImageUrl,
} from '@/plugins/GiftHelper';

import { mapGetters } from 'vuex';

import { receivedCardListApi, messageCardDataApi } from '@/plugins/ApiHelper'
import {CARD_DISPLAY_TITLE_FOR_MYSELF} from '@/constants/card'

export default {
  name: "PMG210",
  components: {
    Layout,
    Loading,
    BaseTemplate,
    FirstView,
    NormalGift,
  },
  props: {
  },
  data: () => ({
    cards: [],
    receivedCard: null,
    gift: null,
    scrollTop: 0,
  }),
  computed: {
    ...mapGetters(['isLoading']),
    cardData() {
      if (!this.cards || this.cards.length <= 0) {
        return null;
      }
      return this.cards[0];
    },
    title() {
      // 自分用ギフト タイトル
      if (this.cardData != null && this.cardData.isForMySelf) {
        return CARD_DISPLAY_TITLE_FOR_MYSELF
      }

      if (this.cardData != null) {
        return this.cardData.creatorName + 'さんから';
      }
      return ''
    },
    cardValue() {
      if (this.cardData != null) {
        return {
        openingMessage: this.cardData.messageText,
        openingDate: this.cardData.opDate,
        mainMessage: this.cardData.mainMessage,
        creatorName: this.cardData.creatorName,
        receiverName: this.cardData.receiverName,
        contents: this.receivedCard?.contents,
      };
      }
      return {
        openingMessage: '',
        openingDate: '',
        mainMessage: '',
        creatorName: '',
        receiverName: '',
        contents: [],
      };
    },
    designId() {
      if (this.cardData != null) {
        return this.cardData.cardDesignId;
      }
      return null;
    },
    designCategoryId() {
      if (this.cardData != null) {
        return this.cardData.designCategoryId;
      }
      return null;
    },
    giftImage() {
      if (this.gift) {
        return getItemImageUrl(this.gift.giftImageUrl);
      }
      return null;
    }
  },
  watch: {
  },
  mounted() {
    const id = this.$route.query['card-origin-id'];
    this.fetchMessageCardData(id);
    this.fetchReceivedCardList(id);
  },
  methods: {
    fetchReceivedCardList(cardOriginId) {
      const data = {
        'card-origin-id': cardOriginId,
      };
      receivedCardListApi(data)
        .then(res => {
          this.count = res.count;
          this.cards = res.cards;
          this.gift = res.gift;
        })
        .catch(() => {
          this.$router.push({ name: 'PMG510' });
        });
    },
    async fetchMessageCardData(cardOriginId) {
      return new Promise(resolve => {
        const data = {
          'card-origin-id': cardOriginId,
        };
        messageCardDataApi(data)
          .then(res => {
            this.receivedCard = res;
            resolve();
          })
          .catch(() => {
            this.$router.push({ name: 'PMG510' });
          });
      });
    },
    scrollFunc(e) {
      this.scrollTop = e.detail.scrollTop;
    },
  }
}
</script>
<style scoped lang="scss">
.pmg210 {
  &__card-normal {
    padding: 1rem;
  }
}
</style>
