<template>
  <span class="radio-img" >
    <input type="radio" :id="id" :name="name" :value="onValue" v-model="value" :class="{active: isActive}">
    <label :for="id">
    </label>
  </span>
</template>

<script>
export default {
  name: "ComponentName",
  components: {
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'radio',
    },
    onValue: {
      type: String,
      default: 'val',
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
  }),
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    isActive() {
      if (this.onValue == this.value) {
        return true;
      }
      return false;
    },
    id() {
      return this.name + '_' + this.onValue;
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
input[type=radio]{
    display: none;
    & + label {
      position: relative;
      display: inline-block;
      background-image:url(@/assets/images/icon/checkbox_off.svg);
      background-repeat: no-repeat;
      background-size:contain;
      width: 23px;
      height:23px;
    }
    &.active {
      & + label {
        background-image: url(@/assets/images/icon/checkbox_on.svg);
        background-repeat: no-repeat;
        background-size:contain;
        width: 23px;
        height:23px;
      }
    }
}
</style>
