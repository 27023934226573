<template>
  <div class="filter-button-list" ref="scrollContainer">
    <div class="search">
      <div class="search__condition">
        <div class="search__condition__area">
          <Button label="すべて"
                  id="category-filter-btn-all"
                  :type="isAllFilter? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow'"
                  size="x-small"
                  @click="clickFilterButton(null)"
          />
          <Button
              v-for="category in categoryList"
              :id="'category-filter-btn-' + category.categoryId"
              :key="category.categoryId"
              :label="category.categoryName"
              :type="buttonClass(category.categoryId)"
              size="x-small"
              @click="clickFilterButton(category.categoryId)"
          />
          <Button
              id="category-filter-btn-other"
              v-show="showOtherButton"
              label="その他" type="fillgold-nonline-nonshadow" size="x-small"/>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Button from '@/components/button/Button';

import {mapGetters} from 'vuex';

export default {
  name: 'SearchFilterButtonList',
  components: {

    Button,
  },
  emits: ['click:filter'],
  data() {
    return {};
  },
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    isAllFilter() {
      return this.filters['category-ids'] === null;
    },
    showOtherButton() {
      if (this.filters['category-ids'] === null) return false;
      const isExist = this.categoryList.find((scene) => this.isEqual(scene.categoryId))
      return !isExist;
    },
  },
  mounted() {},
  methods: {
    isEqual(id) {
      return Number(this.filters['category-ids']) === Number(id)
    },
    buttonClass(id) {
      return this.isEqual(id) ? 'fillgold-nonline-nonshadow' : 'fillwhite-nonline-nonshadow';
    },
    clickFilterButton(id) {
      this.$emit('click:filter', id);
    },
    /*
    検索フィルターのボタンを横スクロールする。
    @param {string} classificationName: category or scene or price
     */
    scrollToActiveButton(classificationName, categoryId) {
      if (!categoryId) {
        categoryId = 'all';
      }
      let activeButton = document.getElementById(`${classificationName}-filter-btn-${categoryId}`);
      if (!activeButton) {
        activeButton = document.getElementById(`${classificationName}-filter-btn-other`);
      }

      if (activeButton) {
        setTimeout(() => {
          activeButton.scrollIntoView({
            behavior: 'auto',
            inline: 'center',
          });
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-button-list {
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  margin-top: 14px;

  .search {
    background-color: var(--ion-color-light);

    .search__condition__area {
      white-space: nowrap;
      overflow-x: auto;
      padding: 0px 16px;
      gap: 12px;
    }

    .button:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  .divider-line {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
  }

}
</style>
