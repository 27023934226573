<template>
  <input v-model="value" class="form-input" :type="type" :placeholder="placeholder" :maxlength="maxlength"
         :class="{ 'disabled-input': disabled }"
         :disabled="disabled">
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    maxlength: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.form-input {
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  background: $white1;
  border: solid 1px $gray2;
  border-radius: 6px;
  font: $font-inter-400;
  color: $black1;
  outline: none;

  @include font-size(16, 20);

  &:focus {
    border-color: $gold1;
  }

  &::placeholder {
    color: $gray3;
  }
}

.disabled-input {
  background: $modelBackground; /* グレーの背景色 */
}
</style>
