<template>
  <IonicModal ref="modal">
    <template #header>{{ header }}</template>
    <template #body>
      <input ref="dummy" type="text" class="form-modal__dummy">
      <div class="form-modal__form">
        <slot name="default"/>
      </div>
      <div class="form-modal__buttons">
        <Button label="キャンセル" type="none" @click="hideModal"/>
        <Button label="保存" type="fill" :disabled="disabledSave" @click="save"/>
      </div>
    </template>
  </IonicModal>
</template>

<script>
import IonicModal from '@/components/ionic/IonicModal';
import Button from '@/components/button/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'FormModal',
  components: {
    IonicModal,
    Button,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    disabledSave: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:save'],
  computed: {
    ...mapGetters(['isOpenDialogModal']),
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.returnScrollSafari();
      this.$refs.modal.hideModal();
    },
    save() {
      this.$emit('click:save');
      this.hideModal();
    },
    returnScrollSafari() {
      /*
       Safariでソフトウェアキーボード表示時の上方向のズレっぱなし対応
       参考: https://qiita.com/kazuya_reas/items/0b300a2d9b6cbd6965fc
       */
      const dummy = this.$refs.dummy;
      dummy.focus();
      dummy.blur();
    },
  }
}
</script>

<style scoped lang="scss">
ion-modal {
  --height: 95%;
  align-items: flex-end;
}

.form-modal {
  :deep(.modal__container) {
    .body {
      max-width: 768px;
      padding: 32px 16px;
      margin: 0 auto;
    }
  }

  &__dummy {
    position: absolute;
    top: 0;
    opacity: 0;
    pointer-events: none;
    z-index: -10000;
  }

  &__form {
    :deep(.form-block) {
      margin-bottom: 32px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
