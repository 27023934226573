<template>
  <div v-if="cartItems.length > 0" class="gift-toast">
    <div class="gift-toast__image">
      <img :src="imgUrl" />
    </div>
    <div class="gift-toast__info">
      <p class="message">このギフトに紐付けられます</p>
      <p class="gift-name">{{ cartItems[0].giftName }}</p>
    </div>
  </div>
</template>

<script>
import { getItemImageUrl } from "@/plugins/GiftHelper";

export default {
  name: 'GiftToast',
  components: { },
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    giftName: null,
  }),
  computed: {
    imgUrl() {
      if (this.cartItems.length > 0) {
        const imgUrl = this.cartItems[0].imgUrl;
        if (imgUrl && imgUrl.indexOf("http") >= 0) {
          return imgUrl;
        }
        return getItemImageUrl(imgUrl);
      }
      return null;
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.gift-toast {
  position: fixed;
  display: flex;
  bottom: -24px;
  left: 50%;
  width: 90vw;
  max-width: calc($baseScreenWidth1 - 16px);
  height: 80px;
  border-radius: 6px;
  background-color: $white1;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 6px 0px #00000026;
  padding: 12px;

  &__image {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    img {
      border-radius: 6px;
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .message {
      margin-bottom: 4px;
      color: $gray10;
      font: $font-roboto-400;

      @include font-size(12, 20);
    }

    .gift-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-align: left;
      color: $black1;
      font: $font-roboto-400;

      @include font-size(14, 20);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 15%;
  }

  :deep(.button) {
    position: inherit;
    width: auto;
  }
}
</style>
