<template>
  <ion-modal
    :is-open="isOpen"
    @didDismiss="hideModal"
    :backdrop-dismiss="backdropDismiss"
  >
    <ion-header class="header">
      <ion-toolbar>
        <ion-title class="header__title">{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button class="header__close" ref="close" @click="close">閉じる</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :class="contentClasses">
      <slot name="body" />
    </ion-content>

    <ion-footer v-if="showFooter" :class="footerClasses">
      <slot name="footer" />
    </ion-footer>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonButtons,
  IonButton,
  IonFooter,
} from '@ionic/vue';

export default {
  name: 'IonicModal',
  props: {
    title: {
      type: String,
      default: null,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    contentClasses: {
      type: Array,
      default: () => ['ion-padding'],
    },
    footerClasses: {
      type: Array,
      default: () => ['footer', 'ion-padding'],
    },
    closeFunc: {
      type: Function,
      default: null,
    },
    backdropDismiss: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonModal,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonButtons,
    IonButton,
    IonFooter,
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    openModal() {
      this.isOpen = true;
      // NOTE ブラウザバックしてもmodalは閉じられないため、ダミー履歴を追加
      location.hash = '';
      history.pushState(null, null, `${location.href}#modal`);
    },
    hideModal() {
      this.isOpen = false;
      // NOTE ダミーの履歴を取り除く
      const url = location.href.replace('#modal', '');
      history.replaceState(null, null, url);
    },
    close() {
      this.$emit("onCloseDom", this.$refs.close.$el);
      if (this.closeFunc) {
        this.closeFunc();
      } else {
        this.hideModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
ion-modal {
  --border-radius: 12px 12px 0px 0px;
  --height: auto;
  --height: 300px;
  align-items: end;
}

ion-toolbar {
  --min-height: 56px;
  --background: #fff;
}

.header {
  &__title {
    font: $font-roboto-400;
    @include font-size(16, 22);
  }

  &__close {
    font: $font-roboto-400;
    @include font-size(14, 18);
  }
}

.footer {
  border-top: 1px solid $gray2;
}
</style>
