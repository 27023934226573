<template>
  <ion-card class="gift-item ion-no-margin" @click="clickItem(id)">
    <div class="gift-item__image">
      <img :src="imgSrc" @load="loadImage" @error="errorImage" :class="imgType" />
    </div>
    <div class="gift-item__info">
      <ion-label class="brand-name" color="medium">{{ brandName }}</ion-label>
      <ion-label class="gift-name" color="dark">{{ giftName }}</ion-label>
      <ion-label class="price-area">
        <ion-note class="price" color="dark">
          ¥{{ priceDisplay }}<ion-note color="dark" v-if="variationGroup">〜</ion-note>
        </ion-note>
        <ion-note v-if="isTaxDisplay" class="tax-text" color="medium">{{ taxText }}</ion-note>
      </ion-label>
      <ion-label v-if="expiryDate != ''" class="expiry-date" color="dark">{{
        expiryDateDisplay(expiryDate)
      }}</ion-label>
    </div>
  </ion-card>
</template>

<script>
import {
  formatExpiryDate,
  getGiftLoadingImage,
  getGiftNoImage,
  getItemImageUrl,
} from '@/plugins/GiftHelper';
import { IonCard, IonLabel, IonNote } from '@ionic/vue';
import { gtmSendClick } from "@/plugins/GtmHelper";

export default {
  name: 'GiftItem',
  components: { IonCard, IonLabel, IonNote },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
    giftName: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    taxText: {
      type: String,
      default: '税込',
    },
    isTaxDisplay: {
      type: Boolean,
      default: true,
    },
    expiryDate: {
      type: String,
      default: '',
    },
    variationGroup: {
      type: String,
      default: '',
    },
    GALabel1:{
      type: String,
      default: '',
    },
    GALabel2:{
      type: String,
      default: '',
    },
    imgType:{
      type: String,
      default: '',
    },
  },
  data: () => ({
    priceDisplay: 0,
    imgSrc: getGiftLoadingImage(),
  }),
  computed: {
    imageUrl() {
      return getItemImageUrl(this.imgUrl);
    },
  },
  mounted() {
    this.priceDisplay = Number(this.price).toLocaleString();
  },
  methods: {
    loadImage() {
      this.imgSrc = this.imageUrl;
    },
    errorImage(e) {
      e.target.src = getGiftNoImage();
    },
    clickItem(id) {
      gtmSendClick(this.$el,this.GALabel1,this.GALabel2,this.giftName);

      this.$emit('click-item', id);
    },
    expiryDateDisplay() {
      return this.expiryDate ? formatExpiryDate(this.expiryDate) : null;
    },
  },
};
</script>

<style scoped lang="scss">
.gift-item {
  cursor: pointer;
  flex-direction: column;
  width: calc((100% - 16px) / 2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

  &__image {
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
      &.popularItemImage {
      height: 164px;
    }
    }
    
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 4px;

    .brand-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font: $font-noto-sans-jp-400;

      @include font-size(10, 16);
    }

    .gift-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: left;
      height: calc(22.4px * 2);
      font: $font-noto-sans-jp-400;

      @include font-size(14, 22.4);
    }

    .price-area {
      .price {
        margin-right: 4px;
        font: $font-roboto-700;

        @include font-size(16, 22.4);
      }

      .tax-text {
        font: $font-noto-sans-jp-400;

        @include font-size(12, 19.2);
      }
    }

    .expiry-date {
      color: $gray6;
      font: $font-roboto-400;

      @include font-size(12, 16);
    }
  }
}
</style>
