<template>
  <DialogModal ref="modal">
    <template #header>{{ null }}</template>
    <template #body>
      <div class="card__content__item">
        <GiftInfo
          :gift-name="giftName"
          :brand-name="brandName"
          :expiry-date="expiryDate"
          :price="price"
          :gift-image="giftImage"
          :is-show-price="false"
        ></GiftInfo>
        <Button
          class="button-use"
          label="使う"
          type="fill"
          :disabled="giftUrl == null"
          @click="openGiftUrl()"
        />
      </div>
      <div class="card__content__footer" @click="goPlame()">
        <img class="icon" src="@/assets/images/icon/gift_icon.png" />
        <span class="label"> プラメギフトについて </span>
      </div>
    </template>
    <template #buttons>{{ null }} </template>
  </DialogModal>
</template>

<script>
import GiftInfo from "@/components/gift/GiftInfo";
import Button from "@/components/button/Button";
import DialogModal from "@/components/modal/DialogModal";
import { gtmDataLayerPush } from "@/plugins/GtmHelper";
import { getUrl } from "@/plugins/UrlHelper";

export default {
  name: "GiftUseModel",
  components: {
    GiftInfo,
    Button,
    DialogModal,
  },
  props: {
    giftUrl: {
      type: String,
      default: null,
    },
    giftName: {
      type: String,
      default: "",
    },
    brandName: {
      type: String,
      default: "",
    },
    expiryDate: {
      type: String,
      default: "",
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    giftImage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    // isOpen: false,
  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    goPlame() {
      const url = 'about';
      const title = "プラメギフトとは";
      const virtualPageUrl = getUrl(`/gifts/static/${url}&${title}`);
      gtmDataLayerPush(virtualPageUrl, title);
      this.$router.push({
        name: "PMG640",
        params: {
          url: url,
          title: title,
        },
      });
    },
    openGiftUrl() {
      window.open(this.giftUrl);
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  &__content {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $gray2;
      padding-top: 1rem;

      .button-use {
        margin: 1rem 0;
        width: 100px;
      }

      :deep(.button) {
        position: inherit;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      .icon {
        width: 20px;
        height: 20px;
      }
      .label {
        font: $font-noto-sans-jp-400;
        font-size: 14px;
        line-height: 22.4px;
        margin-left: 8px;
      }
    }
  }
}
</style>
