<template>
  <div class="first-view-base">
    <div class="lottie">
      <img v-if="disableAnimation" class="animation-image" :src="animationImage" alt="animation-image">
      <AnimationLottie v-else ref="lottie" :animation-data="animationData" @completed="lottieComplete"/>

      <div class="lottie__text">
        <div class="message" :class="{'is-show': showMessage || disableAnimation}">{{ replaceWrap(message) }}</div>
        <div class="date" :class="{'is-show': showDate || disableAnimation}">{{ formatCardDate(date) }}</div>
      </div>
    </div>
    <AnimationBackground ref="background" :images="backgroundImages" :disable-animation="disableAnimation"/>
  </div>
</template>

<script>
import AnimationLottie from '@/components/animation/AnimationLottie'
import AnimationBackground from '@/components/animation/AnimationBackground'

import { DateTime } from 'luxon'

export default {
  name: 'FirstViewBase',
  components: {
    AnimationLottie,
    AnimationBackground,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    animationData: {
      type: Object,
    },
    animationImage: {
      type: String,
    },
    backgroundImages: {
      type: Array,
      default: () => ([]),
    },
    dateFormat: {
      type: String,
      default: 'yyyy.M.d',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMessage: false,
    showDate: false,
  }),
  mounted () {
    if (!this.disableAnimation) {
      this.$nextTick(async () => {
        this.$refs.background?.play();
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.$refs.lottie?.play();
      });
    }
  },
  methods: {
    async lottieComplete() {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (this.message) {
        this.showMessage = true;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      if (this.date) {
        this.showDate = true;
      }
    },
    replaceWrap(str) {
      return str ? str.replaceAll('\\n', '\n') : str;
    },
    formatCardDate(date) {
      return date
        ? DateTime.fromSQL(date).toFormat(this.dateFormat)
        : '';
    },
  },
}
</script>

<style scoped lang="scss">
.first-view-base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .lottie {
    position: relative;
    display: inline-block;
    z-index: 1;

    .animation-image {
      width: 100%;
    }

    .animation-lottie {
      width: 100%;
    }

    &__text {
      position: absolute;
      left: 50%;
      display: flex;
      flex-direction: column;
      width: 100%;
      transform: translateX(-50%);
      text-align: center;

      .message {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-fv-message !important;
        line-height: 137.5% !important;
        word-break: break-all;
        white-space: pre-wrap;
        transition: opacity 1000ms;
        opacity: 0;

        &.is-show {
          opacity: 1;
        }
      }

      .date {
        font-size: $font-size-fv-date !important;
        line-height: 100% !important;
        transition: opacity 1000ms;
        opacity: 0;

        &.is-show {
          opacity: 1;
        }
      }
    }
  }

  .animation-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
