<template>
  <div class="seasonal-event-list">
    <div class="seasonal-event-search">
      <div class="seasonal-event-search__condition">
        <div class="seasonal-event-label-heading">
          <div class="seasonal-event-label-container">
            <span class="seasonal-event-label">{{ title }}</span>
          </div>
          <div class="seasonal-event-label-container">
            <span class="seasonal-event-label-text">{{ subTitle }}</span>
          </div>
        </div>
        <div class="seasonal-event-label-image">
          <img :src="image" alt="seasonal image" :style="{ width: imageWidth + 'px' }">
        </div>
      </div>
      <div class="seasonal-event-slider">
        <div class="seasonal-event-slider__wrapper">
          <div
            class="seasonal-event-slider__slide"
            v-for="gift in giftItems"
            :key="gift.giftId">
            <SeasonalEventItem
              :id="gift.giftId"
              :imgUrl="gift.img"
              :brandName="gift.brandName"
              :giftName="gift.giftName"
              :price="gift.price"
              :isTaxDisplay="gift.isTaxDisplay || false"
              :hasVariation="gift.hasVariation || false"
              @seasonalOpenDetail="$emit('seasonalOpenDetail', $event)"
              @ecommerceSelectPromotion="ecommerceSelectPromotion"
              class="GiftItem"
            ></SeasonalEventItem>
          </div>
        </div>
        <div class="list-info">
          <div class="list-info-text">
            <Button :label="redirectButtonLabel"
                    type="nonfill-outline"
                    size="xx-small"
                    @click="clickGiftList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="divider-line"></div>
  </div>
</template>

<script>
import SeasonalEventItem from "@/components/seasonalEvent/SeasonalEventItem.vue";
import Button from "@/components/button/Button.vue";


export default {
  name: "SeasonalEvent",
  props: {
    promotionId: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
    },
    redirectUrlInfo: {
      type: Object,
      required: true,
    },
    redirectButtonLabel: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: Number,
      required: true,
    },
    backgroundImage: {
      type: String,
      required: true,
    },
    giftItems: {
      type: Array,
      required: true,
    },
  },
  components: {
    SeasonalEventItem,
    Button
  },
  mounted() {
  },
  emits: ['seasonalOpenDetail', 'clickGiftList', 'click:ecommerceSelectPromotionPush'],
  methods: {
    clickGiftList() {
      // 同期的な遷移
      if(this.href) {
        location.href = this.href;
      } else {
        // 非同期的な遷移
        const routeInfo = Object.assign({}, this.redirectUrlInfo);
        this.$router.push(routeInfo)
      }
    },
    seasonalOpenDetail(id) {
      this.$emit('click:seasonalOpenDetail', id);
    },
    ecommerceSelectPromotion(giftId, giftName, brandName, price) {
      const data = {
        "promotion_id": this.promotionId,
        "promotion_title": this.title,
        "giftId": giftId,
        "giftName": giftName,
        "brandName": brandName,
        "price": price,
      }
      this.$emit('click:ecommerceSelectPromotionPush', data);
    }
  },
};
</script>

<style scoped lang="scss">
.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}

.seasonal-event-list {
  margin-top: 24px;

  .seasonal-event-search {
    border-radius: 12px;
    background-size: cover;
    background-position: center center;

    .seasonal-event-search__condition {
      padding: 0 0 0 16px;
      margin-bottom: 20px;
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .seasonal-event-label-heading {
        flex: 1;

        .seasonal-event-label-container {
          display: flex;
          flex-direction: column;
          color: $black1;

          .seasonal-event-label {
            font: $font-roboto-700;
            @include font-size(16, 22.4);
            text-align: left;
          }

          .seasonal-event-label-text {
            font: $font-noto-sans-jp-400;
            @include font-size(12, 19.2);
            // text-align: left;
            margin-top: 8px;
            white-space: pre-line;
          }
        }
      }

      .seasonal-event-label-image {
        width: 110px;
        height: 70px;
        text-align: center;
      }
    }

    .button .x-small {
      width: 118px;

    }

    .seasonal-event-slider {
      &__wrapper {
        overflow-x: auto;
        display: flex;
        gap: 12px;
        padding: 0 16px 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }

      &__slide {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 152px;
        min-width: 152px;
        height: 278px;

        .GiftItem {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }



    .list-info {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 16px 24px;

      .list-info-text {
        color: $white1;
        @include font-size(14, 22.4);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
