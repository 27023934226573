<template>
  <div class="content">
    <div
      class="card"
      :class="{ active: cardVal == '0' }"
      @click="clickCard('0')"
    >
      <div class="card__check">
        <CheckButton
          :name="'cardType'"
          :on-value="'0'"
          v-model="cardVal"
        ></CheckButton>
      </div>
      <img class="card__image" src="@/assets/images/normal_card_20240222.png" />
      <div class="card__note">
        <span class="card__note__title">通常カード</span>
        <span class="card__note__text"
          >贈り主名を入力するだけのシンプルなカードで、すぐにギフトを贈ることができます。</span
        >
      </div>
    </div>
    <div
      class="card"
      :class="{ active: cardVal == '1' }"
      @click="clickCard('1')"
    >
      <div class="card__check">
        <CheckButton
          :name="'cardType'"
          :on-value="'1'"
          v-model="cardVal"
        ></CheckButton>
      </div>
      <img class="card__image" src="@/assets/images/ricci_card.png" />
      <div class="card__note">
        <span class="card__note__title">リッチカード</span>
        <span class="card__note__text"
          >複数のデザインから選べ、メッセージや写真・動画などを自由に設定してあなただけのカードを作ることができます。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import CheckButton from "@/components/button/CheckButton.vue";
export default {
  name: "CardTypeSelect",
  components: {
    CheckButton,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
  }),
  computed: {
    cardVal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {
    clickCard(val) {
      this.cardVal = val;
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .card {
    display: flex;
    align-items: center;
    background-color: $white1;
    padding: 12px;
    border: 3px solid $gray11;
    border-radius: 12px;
    &__image {
      margin: 0px 1rem;
      width: 100px;
      height: 100px;
    }
    &__note {
      display: flex;
      flex-direction: column;
      &__title {
        font: $font-noto-sans-jp-700;
        font-size: 14px;
        line-height: 22.4px;
      }
      &__text {
        font: $font-noto-sans-jp-400;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
  .active {
    border-color: $gold2;
  }
}
</style>
