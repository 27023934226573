<template>
  <IonicModal
    ref="modal"
    title="カードの選びなおし"
    @on-close-dom="onCloseDom"
  >
    <template #body>
      <div class="block">
        <div class="block__header">
          <span class="block__header__text">既に作成しているカードは削除されます。よろしいですか？</span>
          <span class="red warn">カードを選びなおすと、既存の作成済みカードは削除されます。ご注意ください。</span>
        </div>
        <div class="block__footer">
          <Button label="選び直して新たに作成する" type="fill" ref="replace" @click="clickReplaceCard()" />
          <Button label="キャンセル" type="fill-nonline" ref="cancel" @click="clickCancel()" />
        </div>
      </div>
    </template>
  </IonicModal>
</template>

<script>
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import IonicModal from "@/components/ionic/IonicModal";
import Button from "@/components/button/Button";

export default {
  name: "CartReplaceCardMocal",
  components: {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonicModal,
    Button,
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  emit: [
    'click:replaceCard',
    'click:checkCancel',
  ],
  mounted() {
  },
  updated() {
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    clickCancel() {
      this.$emit('click:checkCancel');
      this.$emit('click:checkCancelDom',this.$refs['cancel'].$el);
    },
    clickReplaceCard() {
      this.$emit('click:replaceCard');
      this.$emit('click:replaceCardDom',this.$refs['replace'].$el);
    },
  },
};
</script>
<style scoped lang="scss">
.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  .red {
    color: $red2;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    font:$font-noto-sans-jp-400;
    @include font-size(14, 22.4);
    padding: 1rem 0;
    &__text {
      font: $font-noto-sans-jp-700;
      @include font-size(16, 25.6);
      margin-bottom: 1rem;;
    }
    .warn {
      font: $font-noto-sans-jp-400;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    button {
      width: 311px;
      margin-bottom: 1rem;
    }
  }
}
ion-modal {
  --max-height: 95%;
  --height: 400px;
  align-items: flex-end;
}
</style>
